import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PProductsService} from '../../providers/plansee/p-products-service';
import { ProductWsDTO, UserLocation } from '../../providers/types/ycommercewebservices';
import {PRequestWsDTO, PVolumeHistoryWsDTO} from '../../providers/types/planseeoccaddon';
import { combineLatest } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { skip, switchMap } from 'rxjs/operators';

@Component({
  selector: 'product-page',
  template: require('./product.page.html'),
  styles: []
})
export class ProductPageComponent implements OnInit {
  product: ProductWsDTO;
  volumeHistory: PVolumeHistoryWsDTO;
  location: UserLocation;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productsService: PProductsService,
    private authorizationService: AuthorizationService,
    private planseeTranslateService: PlanseeTranslateService,
  ) {}

  ngOnInit() {
    this.setProductAndVolumeHistory();
    this.authorizationService.permissionsChanged
      .pipe(skip(1))
      .subscribe(() => {
        this.router.navigate(['/menu/dashboard']);
      });
  }

  setProductAndVolumeHistory() {
    let query: PRequestWsDTO = null;

    combineLatest([
      this.route.paramMap,
      this.planseeTranslateService.onLangChange()
    ])
      .pipe(switchMap(([params, _]) => this.productsService.getProduct(params.get('id'), params.get('b2bUnit'), query)))
      .subscribe(
        product => {
          this.product = product;
          // TODO: check if location can be retrieved from product;
          // check if "volumehistory" is blacklisted
          if (!this.authorizationService.permissions.blacklist.includes('volumehistory')) {
            this.productsService
              .getVolumeHistory(product.code || '', query)
              .subscribe(volumeHistory => {
                this.volumeHistory = volumeHistory;
              });
          } else {
            this.volumeHistory = {};
          }
        },
        error => {
          this.router.navigate(['/error'], { skipLocationChange: true });
        }
      );
  }
}
