import { Injectable } from '@angular/core';

@Injectable()
export class PCookiePanelService {

  init() {
    this.addContentToBody();
    this.addScriptToHeader();
    // footer panel contains <div id="teconsent"></div>.
    // This is use by trustarc to add cookies preferences button
  }

  private addContentToBody() {
    const bodyElement = document.getElementsByTagName('body')[0];

    const consentBanner = document.createElement('div');
    consentBanner.id = 'consent-banner';

    bodyElement.insertBefore(consentBanner, bodyElement.firstChild);
  }

  private addScriptToHeader() {
    const headElement = document.getElementsByTagName('head')[0];

    const trustarcScript = document.createElement('script');
    trustarcScript.src = '//consent.trustarc.com/v2/notice/qqynh0';
    trustarcScript.async = true;
    trustarcScript.type = 'text/javascript';

    headElement.appendChild(trustarcScript);
  }
}
