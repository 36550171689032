
import {throwError as observableThrowError, BehaviorSubject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PConfig} from '../types/planseeoccaddon';

@Injectable()
export class ConfigService {

  configFile = 'config.json';
  onConfigSet = new BehaviorSubject<boolean>(false);

  config: PConfig = {};

  constructor(protected http: HttpClient) {
  }

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.configFile).pipe(catchError(err => {
        reject(err);
        return observableThrowError(err);
      })).subscribe(config => {
        this.config = config;
        this.setBaseHref();
        this.onConfigSet.next(true);
        resolve();
      });
    });
  }

  get(): PConfig {
    return this.config;
  }

  setBaseHref() {
    const baseElement = document.querySelector('base');
    const baseHref = this.config.baseHref;

    if (baseElement && baseHref && baseElement.href !== baseHref) {
      baseElement.href = baseHref;
    }
  }
}
