import {Component, EventEmitter, Optional, Output, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validator} from '@angular/forms';

@Component({
  selector: 'term-conditions-checkbox',
  template: require('./term-conditions-checkbox.component.html')
})
export class TermConditionsCheckboxComponent implements ControlValueAccessor, Validator {

  @Output() privacyPolicyClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  value = false;

  constructor(@Self() @Optional() public control: NgControl) {
    this.control.valueAccessor = this;
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  onValidationChange: any = () => {};

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(control: AbstractControl): ValidationErrors {
      return this.value ? null : {required: true};
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  onCheckboxClick(value: boolean) {
    this.value = value;
    this.onChange(this.value);
    this.onValidationChange();
  }
}
