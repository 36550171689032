import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ChartViewType, PProductWsDTO, PVolumeHistoryWsDTO } from '../../providers/types/planseeoccaddon';
import { Chart, ChartData, ChartOptions, ChartTooltipItem, ChartTooltipOptions, LinearTickOptions } from 'chart.js';
import { PDatePipe } from '../../filters/pDate.pipe';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

const MONTH_POINTS = 12;
const WEEK_POINTS = 14;

@Component({
  selector: 'product-volume-chart',
  template: require('./product-volume-chart.component.html'),
  styles: [require('./product-volume-chart.component.scss')]
})
export class ProductVolumeChartComponent extends SubscriptionManager implements OnInit {

  @Input()
  volumeHistory: PVolumeHistoryWsDTO;
  @Input()
  product: PProductWsDTO;

  lineData: ChartData = {
    labels: [],
    datasets: [{
      hideInLegendAndTooltip: true,
      type: 'line',
      label: this.i18nService.instant('quotationEntryForm.productQuantity'),
      fill: true,
      lineTension: 0.1,
      backgroundColor: '#00b8f7',
      borderColor: '#fff',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#00b8f7',
      pointBorderWidth: 4,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: '#000',
      pointHoverBorderColor: '#fff',
      pointHoverBorderWidth: 4,
      pointRadius: 8,
      pointHitRadius: 8,
      data: [],
      spanGaps: false
    }]
  };
  lineOptions: { tooltips: { yAlign: string } & ChartTooltipOptions } & ChartOptions = {
    maintainAspectRatio: false,
    tooltips: <{
      yAlign: string
    } & ChartTooltipOptions>{
      backgroundColor: '#fff',
      bodyFontColor: '#000',
      borderColor: '#97b3ce',
      borderWidth: 1,
      displayColors: false,
      yAlign: 'bottom',
      callbacks: {
        title: () => {
          return '';
        },
        label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
          return `${formatNumber(+tooltipItem.yLabel, 'de', '1.0-3')} ${this.product ? this.product.unitLocalized : ''}`;
        }
      }
    },
    legend: {
      position: 'bottom',
      display: false
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0, 0, 0, 0)'
        },
        ticks: {
          padding: 20
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0, 0, 0, 0)'
        },
        ticks: <LinearTickOptions>{
          min: 0,
          padding: 30,
          labelOffset: 10
        }
      }]
    },
    layout: {
      padding: {
        left: 0,
        right: 40,
        top: 35,
        bottom: 10
      }
    },
    events: ['mousemove', 'mouseout'],
    onHover: (evt) => {
      let item = this.chart.getElementAtEvent(evt);
      if (Array.isArray(item) && item.length) {
        this.chart.ctx.save();
        this.chart.ctx.beginPath();
        this.chart.ctx.setLineDash([5, 15]);
        this.chart.ctx.moveTo(item[0]._model.x, this.chart.canvas.height);
        this.chart.ctx.lineTo(item[0]._model.x, item[0]._model.y + 10);
        this.chart.ctx.lineWidth = 2;
        this.chart.ctx.strokeStyle = '#fff';
        this.chart.ctx.stroke();
        this.chart.ctx.restore();
      }
    }
  };
  currentMaxNumberOfEntries: number = MONTH_POINTS;


  constructor(
    private i18nService: TranslateService,
    private elementRef: ElementRef,
    private planseeTranslateService: PlanseeTranslateService,
  ) {
    super();
  }

  private datePipe = new PDatePipe;
  private labels: string[] = [];
  private values: number[] = [];
  private pos = 0;
  private chart: Chart;
  private bufferedPosition = 0;
  private todayIndex: number;
  private fullLabels: string[] = [];
  private viewType: string;

  ngOnInit() {
    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .subscribe(() => {
          if (this.volumeHistory && this.volumeHistory.segments) {
            this.labels = [];
            this.values = [];
            this.fullLabels = [];
            this.init();
          }
        })
    );
  }

  init() {
    this.viewType = this.getViewType();
    this.lineData.datasets[0].backgroundColor = this.buildFillGradient();
    let key = 'volumeHistory.week';

    if (this.viewType === ChartViewType.YEARLY) {
      key = 'volumeHistory.year';
    } else if (this.viewType === ChartViewType.MONTHLY) {
      key = 'volumeHistory.month';
    }

    this.generateLabels(key);
    this.todayIndex = this.getTodayIndex();
    for (let entry of this.volumeHistory.segments) {
      const dateObject = this.datePipe.transform(entry.firstDayOfPeriod, 'full');
      let fullLabel = this.viewType === ChartViewType.WEEKLY ?
        `${dateObject.week} ${dateObject.year}` :
        `${dateObject.month} ${dateObject.year}`;
      let index = this.fullLabels.indexOf(fullLabel);
      if (index >= 0) {
        this.values[index] = entry.orderedQuantity;
      }
    }

    if (this.viewType === ChartViewType.WEEKLY) {
      this.bufferedPosition = this.pos = (this.todayIndex > WEEK_POINTS) ? this.todayIndex - WEEK_POINTS + 1 : this.bufferedPosition;
    } else {
      this.pos = (this.todayIndex > MONTH_POINTS) ? this.todayIndex - MONTH_POINTS + 1 : this.pos;
    }
    this.setChartData();
  }

  generateLabels(key: string) {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.currentMaxNumberOfEntries = WEEK_POINTS;
      const lastDate = new Date();
      let firstDate = new Date();
      // Generate Week points
      firstDate.setDate(lastDate.getDate() - (WEEK_POINTS - 1) * 7);
      const firstDayOfPeriod = this.volumeHistory.segments[0].firstDayOfPeriod;
      const strDate = firstDayOfPeriod.toString().slice(0, firstDayOfPeriod.toString().indexOf('+')) + '+00:00';
      const firstDateFromData = new Date(strDate);
      firstDate = firstDateFromData < firstDate ? firstDateFromData : firstDate;
      while (firstDate <= lastDate) {
        const dateObject = this.datePipe.transform(firstDate, 'full');
        this.labels.push(this.i18nService.instant(key, {number: dateObject.week}));
        this.fullLabels.push(`${dateObject.week} ${dateObject.year}`);
        this.values.push(0);
        firstDate.setDate(firstDate.getDate() + 7);
      }
      /*
        add today full label if it is not done yet in above loop;
        when the firstDate in the last loop will be bigger than today,
        it will not add today's labels;
      */
      const todayDateObject = this.datePipe.transform(lastDate, 'full');
      const todayLabel = `${todayDateObject.week} ${todayDateObject.year}`;
      if (this.fullLabels.indexOf(todayLabel) < 0) {
        this.labels.push(this.i18nService.instant(key, {number: todayDateObject.week}));
        this.fullLabels.push(todayLabel);
        this.values.push(0);
      }
    } else {
      this.currentMaxNumberOfEntries = MONTH_POINTS;
      const lastDate = new Date();
      let firstDate = new Date();
      // Generate Month points
      firstDate.setMonth(firstDate.getMonth() - MONTH_POINTS);
      firstDate.setDate(1);
      lastDate.setDate(1);
      while (firstDate <= lastDate) {
        const dateObject = this.datePipe.transform(firstDate, 'full');
        const monthDotYear = `${dateObject.month}.${dateObject.year}`;
        this.labels.push(monthDotYear);
        this.fullLabels.push(monthDotYear);
        this.values.push(0);
        firstDate.setMonth(firstDate.getMonth() + 1);
      }
    }
  }

  buildFillGradient() {
    const ctx = this.elementRef.nativeElement.querySelector('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, '#36d4fa');
    gradient.addColorStop(1, '#0793f6');
    return gradient;
  }

  getViewType() {
    const {segments, historyViewType} = this.volumeHistory;
    if (segments) {
      // historyViewType given in response
      if (historyViewType) {
        return historyViewType;
      }

      // no historyViewType in response
      const firstDateString = segments[0].firstDayOfPeriod;
      const secondDateString = segments[1] ? segments[1].firstDayOfPeriod : firstDateString;
      const val = new Date(secondDateString).getTime() - new Date(firstDateString).getTime();
      const days = val / 86400000;
      if (days > 360) {
        return ChartViewType.YEARLY;
      }
      if (days > 27) {
        return ChartViewType.MONTHLY;
      }
      return ChartViewType.WEEKLY;
    }
  }

  hasNext() {
    if (this.viewType === ChartViewType.WEEKLY) {
      return this.pos + WEEK_POINTS < this.labels.length;
    }
    return this.pos + MONTH_POINTS < this.labels.length;
  }


  hasPrevious() {
    return this.pos > 0;
  }

  next() {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.bufferedPosition = this.pos = this.bufferedPosition + 4;
      if (this.bufferedPosition >= this.labels.length - WEEK_POINTS) {
        this.pos = this.labels.length - WEEK_POINTS;
      } else if (this.bufferedPosition <= 0) {
        this.pos = 0;
      }
    } else {
      this.pos = this.pos + 1;
      if (this.pos >= this.labels.length - MONTH_POINTS) {
        this.pos = this.labels.length - MONTH_POINTS;
      } else if (this.pos <= 0) {
        this.pos = 0;
      }
    }
    this.setChartData();
  }

  decDate() {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.bufferedPosition = this.pos = this.bufferedPosition - 4;
      if (this.bufferedPosition <= 0) {
        this.pos = 0;
      } else if (this.bufferedPosition >= this.labels.length - WEEK_POINTS) {
        this.pos = this.labels.length - WEEK_POINTS;
      }
    } else {
      this.pos = this.pos - 1;
      if (this.pos <= 0) {
        this.pos = 0;
      } else if (this.pos >= this.labels.length - MONTH_POINTS) {
        this.pos = this.labels.length - MONTH_POINTS;
      }
    }
    this.setChartData();
  }

  setChartData() {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.lineData.labels = this.labels.slice(this.pos, this.pos + WEEK_POINTS);
      this.lineData.datasets[0].data = this.values.slice(this.pos, this.pos + WEEK_POINTS);
    } else {
      this.lineData.labels = this.labels.slice(this.pos, this.pos + MONTH_POINTS);
      this.lineData.datasets[0].data = this.values.slice(this.pos, this.pos + MONTH_POINTS);
    }

    if (this.chart) {
      this.chart.update();
    }
  }

  onChartCreated($event) {
    this.chart = $event.chart;
    this.addChartText();
  }

  private addChartText() {
    Chart.pluginService.register({
      afterDraw: () => {
        const height = (this.chart as any).chart.height;
        const ctx = this.elementRef.nativeElement.querySelector('canvas').getContext('2d');
        ctx.restore();
        ctx.font = '16px sans-serif';
        ctx.fillStyle = '#ffffff';
        ctx.fillText('Quantity', 100, height - height / 4);
        ctx.save();
      }
    });
  }

  private getTodayIndex() {
    const segments = this.volumeHistory.segments;
    const today = new Date();
    const dateObject = this.datePipe.transform(today, 'full');
    let todayLabel = this.viewType === ChartViewType.WEEKLY ?
      `${dateObject.week} ${dateObject.year}` :
      `${dateObject.month} ${dateObject.year}`;
    const firstDayOfPeriod = segments[segments.length - 1].firstDayOfPeriod;
    const strDate = firstDayOfPeriod.toString().slice(0, firstDayOfPeriod.toString().indexOf('+')) + '+00:00';
    const lastDay = new Date(strDate);
    const todayIndex = this.fullLabels.indexOf(todayLabel);
    if (todayIndex >= 0) {
      return todayIndex;
    } else if (today > lastDay) {
      return this.fullLabels.length;
    }
    return 0;
  }
}
