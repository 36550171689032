import { Pipe, PipeTransform } from '@angular/core';
import { PDocumentsService } from '../providers/plansee/p-documents-service';

@Pipe({
  name: 'documentLink'
})
export class DocumentLinkPipe implements PipeTransform {
  constructor(private documentsService: PDocumentsService) {
  }

  transform(id: string, isPreview= false): string {
    return this.documentsService.getDocumentLink(id, isPreview);
  }
}
