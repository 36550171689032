import { Pipe, PipeTransform } from '@angular/core';
import { MediaWsDTO } from '../providers/types/planseeoccaddon';

@Pipe({
  name: 'combineMedias'
})
export class CombineMediasPipe implements PipeTransform {
  transform(medias: MediaWsDTO[], additionalMedia: MediaWsDTO, documentNumber?: string): MediaWsDTO[] {
    return [
      ...(medias || []),
      additionalMedia
    ].map(media => ({
      ...media,
      documentNumber
    }));
  }
}
