import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableParams, DataTableRowEvent } from 'ngx-datatable-bootstrap4';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PClaimStatus, PClaimWsDTO, PProductWsDTO } from '../../providers/types/planseeoccaddon';
import { PClaimsService } from '../../providers/plansee/p-claims-service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PDataTable } from '../p-data-table';
import { LoadingService } from '../../providers/plansee/loading-service';
import { take } from 'rxjs/operators';

export enum ClaimsListStatus {
  IN_PROGRESS = 1,
  COMPLETED
}

const statusOpen = [PClaimStatus.IN_PROGRESS];
const statusCompleted = [PClaimStatus.PARTIALLY_APPROVED, PClaimStatus.CANCELLED, PClaimStatus.NOT_APPROVED, PClaimStatus.APPROVED, PClaimStatus.NO_VALUE];

@Component({
  selector: 'claims-list',
  template: require('./claims-list.component.html'),
  styles: [require('./claims-list.component.scss')]
})
export class ClaimsListComponent extends PDataTable<PClaimWsDTO> {

  defaultSort = 'dateSubmitted-desc';
  items: PClaimWsDTO[] = [];
  @Input() filter: ClaimsListStatus;
  isSearching: boolean;

  constructor(private claimsService: PClaimsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService,
              loadingService: LoadingService) {
    super(i18nService, authorizationService, loadingService);
    this._query.pageSize = 10;
  }

  /* tslint:disable:cognitive-complexity */
  reloadItems(params: DataTableParams) {
    if (!this.filter) {
      return;
    }
    this.defaultSort = this.filter === ClaimsListStatus.COMPLETED ? 'd8SubmittedDate-desc' : 'dateSubmitted-desc';
    this._query.query += this._query.query.indexOf(':') >= 0 ? '' : ':';
    let compiledQuery = this._query.query;

    if (compiledQuery.indexOf(':') === 0) {
      this.isSearching = false;
    } else {
      this.isSearching = true;
    }

    if (compiledQuery.indexOf('status') < 0) {
      // check relevance tag
      if (compiledQuery.split(':').length !== compiledQuery.indexOf('relevance') + 1) {
        this.areFacetsApplied = true;
      } else {
        this.areFacetsApplied = false;
      }
      // no facet applied
      for (let status of !this.isCompleted() ? statusOpen : statusCompleted) {
        compiledQuery += `:status:${status}`;
      }
    } else {
      // remove statuses from query based on the list type
      for (let status of this.isCompleted() ? statusOpen : statusCompleted) {
        const value = `:status:${status}`;
        if (compiledQuery.indexOf(value) >= 0) {
          compiledQuery = compiledQuery.replace(value, '');
        }
      }

      if (this.isFilterApplied(compiledQuery)) {
        this.areFacetsApplied = true;
      } else {
        this.areFacetsApplied = false;
      }
    }
    if (compiledQuery.indexOf('status') < 0) {
      this.items = [];
      // reset but do not alter the query since reloadItems will be triggered again cuz of pagination changed programmatically
      this.refreshPagination({totalResults: 0});
      return;
    } else {
      this._query.query = compiledQuery;
    }

    this.updateQuery(params);

    if (!this.shouldReload()) {
      return;
    }

    this.oldQuery = Object.assign({}, this._query);

    this.claimsService.search(this._query)
      .pipe(take(1))
      .subscribe(searchResult => {
        // Update pagination with the counts comming from BE
        this.refreshPagination(searchResult.pagination);
        this.items = searchResult.claims;
      });
  }

  /* tslint:enable:cognitive-complexity */

  showTooltip(item: PProductWsDTO, t: NgbTooltip) {
    if (item.code === item.customerMaterialNumber || !item.customerMaterialNumber) {
      t.open();
    }
  }

  isFilterApplied(query: string) {
    return query.split(':').filter(item => item !== '').some(item => {
      if (this.isCompleted()) {
        if (item === 'status' || statusCompleted.find(status => status === item)) {
          return false;
        } else {
          return true;
        }
      } else {
        if (item === 'status' || statusOpen.find(status => status === item)) {
          return false;
        } else {
          return true;
        }
      }
    });
  }

  hideTooltip(t: NgbTooltip) {
    t.close();
  }

  isCompleted() {
    return ClaimsListStatus.COMPLETED === this.filter;
  }

  rowClick(event: DataTableRowEvent<PClaimWsDTO>) {
    const item: {
      detailsExpanded?: boolean
    } & PClaimWsDTO = event.row.item;
    event.row.expanded = !event.row.expanded;
    item.detailsExpanded = event.row.expanded;
  }
}
