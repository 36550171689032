import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionStatusEnum, PInternetConnectionService } from '../../providers/plansee/p-internet-connection-service';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { CustomNgxOauthService } from '../../providers/plansee/custom-ngx-oauth.service';

@Component({
  selector: 'home-page',
  template: require('./home.page.html'),
  styles: [require('./home.page.scss')]
})
export class HomePageComponent extends SubscriptionManager implements OnInit {

  @ViewChild('t')
  internetConnectionTooltip: NgbTooltip;
  @ViewChild('tooltipContainer')
  tooltipContainer: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private internetConnectionService: PInternetConnectionService,
    private translateService: TranslateService,
    private oAuthService: CustomNgxOauthService,
    private authorizationService: AuthorizationService
  ) {
    super();
  }

  private status: ConnectionStatusEnum = ConnectionStatusEnum.Online;

  ngOnInit(): void {
    this.addSubscriptions(
      this.translateService.onLangChange
        .pipe(
          switchMap(() => this.authorizationService.getCompanies())
        )
        .subscribe(() => {
          this.oAuthService.init();
        }),
      this.internetConnectionService.offline.subscribe(e => {
        this.status = ConnectionStatusEnum.Offline;
        this.triggerInternetConnectionOverlay();
      }),
      this.internetConnectionService.online.subscribe(e => {
        this.status = ConnectionStatusEnum.Online;
        this.triggerInternetConnectionOverlay();
      })
    );
  }

  private triggerInternetConnectionOverlay() {
    if (this.internetConnectionTooltip.isOpen()) {
      this.internetConnectionTooltip.close();
    }
    const scrollOffset = window.pageYOffset || 0;
    this.tooltipContainer.nativeElement.style.top = scrollOffset + 50 + 'px';
    this.internetConnectionTooltip.ngbTooltip = 'Internet Connection status changed: ' + this.status;
    this.internetConnectionTooltip.open();
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.internetConnectionTooltip.close();
      this.changeDetectorRef.detectChanges();
    }, 4000);
  }
}
