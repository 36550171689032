import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PB2bUnitWsDTO, PUserWsDTO} from '../../../providers/types/planseeoccaddon';
import {AuthorizationService} from '../../../providers/plansee/authorization-service';

@Component({
  selector: 'dashboard-basic-data',
  template: require('./dashboard-basic-data.component.html'),
  styles: [require('./dashboard-basic-data.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardBasicDataComponent {

  @Input() set basicData(data: PB2bUnitWsDTO) {
    this._basicData = data;
    this.user = this.authorizationService.getUserProfile();
  }

  get basicData() {
    return this._basicData;
  }

  user: PUserWsDTO;
  private _basicData: PB2bUnitWsDTO;

  constructor(
    public authorizationService: AuthorizationService
  ) {
  }
}
