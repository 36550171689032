import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PClaimForm} from '../../providers/types/planseeoccaddon';
import {PClaimsService} from '../../providers/plansee/p-claims-service';

@Component({
  selector: 'claim-page',
  template: require('./claim.page.html'),
  styles: [require('./claim.page.scss')]
})
export class ClaimPageComponent implements OnInit {

  today = new Date();
  newClaimForm: PClaimForm;

  constructor(private claimsService: PClaimsService,
              private router: Router) {
    this.claimsService.onClaimFormChange.subscribe(form => {
      this.newClaimForm = form;
    });
  }

  ngOnInit() {
    this.newClaimForm = this.claimsService.getForm();
    if (!this.newClaimForm.stepNumber) {
      this.newClaimForm.stepNumber = 1;
    }
    if (!this.newClaimForm.freeTextSearch) {
      this.router.navigate(['/menu/claims/new']);
    }
  }
}
