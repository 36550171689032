import {Pipe, PipeTransform} from '@angular/core';
import {ConfigService} from '../providers/plansee/config-service';

@Pipe({
  name: 'apiHost'
})
export class ApiHostPipe implements PipeTransform {

  constructor(private configService: ConfigService) {
  }

  transform(value: string, ...args: any[]): any {
    const apiHost = this.configService.config.apiHost;
    if (apiHost) {
      return `${apiHost}/${value}`;
    }
    return value;
  }
}
