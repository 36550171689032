import {Component, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'confirmation-modal',
  template: require('./confirmation-modal.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [require('./confirmation-modal.component.scss')]
})
export class ConfirmationModalComponent {

  @Input()
  text: string;

  constructor() {}
}
