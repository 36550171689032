export const I18N_WEEKDAY_KEYS = [
  'general.weeksShort.monday',
  'general.weeksShort.tuesday',
  'general.weeksShort.wednesday',
  'general.weeksShort.thursday',
  'general.weeksShort.friday',
  'general.weeksShort.saturday',
  'general.weeksShort.sunday'
];

export const I18N_MONTH_KEYS = [
  'general.monthsShort.january',
  'general.monthsShort.february',
  'general.monthsShort.march',
  'general.monthsShort.april',
  'general.monthsShort.may',
  'general.monthsShort.june',
  'general.monthsShort.july',
  'general.monthsShort.august',
  'general.monthsShort.september',
  'general.monthsShort.october',
  'general.monthsShort.november',
  'general.monthsShort.december'
];
