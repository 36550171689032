import { Pipe, PipeTransform } from '@angular/core';
import { LanguageWsDTO } from '../providers/types/ycommercewebservices';

@Pipe({
  name: 'takeLang'
})
export class TakeLangPipe implements PipeTransform {

  transform(langCode: string, langs: LanguageWsDTO[]): LanguageWsDTO {
    const defaultLang = { nativeName: langCode } as LanguageWsDTO;
    if (langs && langs.length) {
      const currentLang = langs.find(l => l.isocode === langCode);

      return currentLang ? currentLang : defaultLang;
    }


    return defaultLang;
  }
}
