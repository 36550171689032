import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BreadcrumbWsDTO} from '../../providers/types/ycommercewebservices';

@Component({
  selector: 'facet-applied',
  template: require('./facet-applied.component.html'),
  styles: [require('./facet-applied.component.scss')]
})
export class FacetAppliedComponent {

  @Input()
  breadcrumbs: BreadcrumbWsDTO[];
  @Output()
  submit: EventEmitter<string> = new EventEmitter();

  removeBreadcrumb(value) {
    this.submit.emit(decodeURIComponent(value.replace(/\+/g, ' ')));
  }

  sanitizeValue(val: string): string {
    const timeRegx = /T[0-9]{2}:[0-9]{2}:[0-9]{2,3}Z/;
    while (val.match(timeRegx)) {
      val = val.replace(val.match(timeRegx)[0], '');
    }
    return val.replace(/\[/g, '').replace(/\]/g, '').replace(/TO/g, '-');
  }
}
