import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PProductWsDTO, PRequestWsDTO} from '../../providers/types/planseeoccaddon';
import {PProductsService} from '../../providers/plansee/p-products-service';
import {Router} from '@angular/router';
import {QueryRequestWsDTO} from '../../providers/types/ycommercewebservices';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'inter-module-links',
  template: require('./inter-module-links.component.html'),
  styles: [require('./inter-module-links.component.scss')]
})
export class InterModuleLinksComponent extends SubscriptionManager implements OnInit {

  modules = [{
    key: 'menuPanel.documents',
    name: 'Documents',
    image: 'link-documents.svg',
    disabled: false,
    path: '/menu/documents/',
    link: 'Document',
    count: 0
  }, {
    key: 'menuPanel.orders',
    name: 'Purchase<br/>Orders',
    image: 'link-purchase-orders.svg',
    disabled: false,
    path: '/menu/orders/',
    link: 'OrderEntry',
    count: 0
  }, {
    key: 'menuPanel.shipments',
    name: 'Shipments',
    image: 'link-shipments.svg',
    disabled: false,
    path: '/menu/shipments/',
    link: 'Consignment',
    count: 0
  }, {
    key: 'menuPanel.invoices',
    name: 'Invoices',
    image: 'link-invoices.svg',
    disabled: false,
    path: '/menu/invoices/',
    link: 'Invoice',
    count: 0
  }, {
    key: 'menuPanel.claims',
    name: 'Claims',
    image: 'link-claims.svg',
    disabled: false,
    path: '/menu/claims/',
    link: 'Claim',
    count: 0
  }, {
    key: 'menuPanel.rfqs',
    name: 'RFQs',
    image: 'link-rfqs.svg',
    disabled: false,
    path: '/menu/rfqs/',
    link: 'RFQ',
    count: 0
  }];

  @Input()
  product: PProductWsDTO;

  constructor(private i18nService: TranslateService,
              private authorizationService: AuthorizationService,
              private productsService: PProductsService,
              private router: Router) {
    super();
  }
  private query: QueryRequestWsDTO & PRequestWsDTO = {};

  ngOnInit(): void {
    this.addSubscriptions(
      this.i18nService.stream(this.modules.map(obj => obj.key)).subscribe(i18n => {
        this.modules = this.modules.map(obj => {
          obj.name = i18n[obj.key];
          return obj;
        });
      }),
      this.authorizationService.permissionsChanged
        .subscribe(permissions => {
        this.processBlacklist(permissions.blacklist);
      }),
      this.productsService.getProductExternalLinksCount(this.product.code, this.query).subscribe(result => {
        for (let item of result.items) {
          let moduleItem = this.modules.find(i => i.link === item.name);
          if (moduleItem) {
            moduleItem.count = item.count;
          }
        }
      })
    );

    let currentModule = this.modules.find(i => this.router.url && this.router.url.includes(i.path));
    if (currentModule) {
      currentModule.disabled = true;
    }
  }

  processBlacklist(blacklist: string[]) {
    for (let module of this.modules) {
      module.disabled = false;
      for (let path of blacklist) {
        if (module.path.includes(path)) {
          module.disabled = true;
        }
      }
    }
  }

  getModules() {
    return this.modules.filter(val => !val.disabled && val.count > 0);
  }
}


