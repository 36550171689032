import { Component, Input } from '@angular/core';
import { PShipmentWsDTO } from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'shipment-info-inline',
  template: require('./shipment-info-inline.component.html')
})
export class ShipmentInfoInlineComponent {

  @Input()
  shipment: PShipmentWsDTO;

}
