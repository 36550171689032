import { PInvoiceWsDTO } from '../planseeoccaddon';

export class InvoiceSet {

  constructor() {
    this.items = [];
  }
  private items: PInvoiceWsDTO[] = [];

  has(item: PInvoiceWsDTO): boolean {
    return this.takeIndexOf(item) !== -1;
  }

  add(item: PInvoiceWsDTO) {
    if (!this.has(item)) {
      this.items.push(item);
    }
  }

  delete(item: PInvoiceWsDTO) {
    if (this.has(item)) {
      const index = this.takeIndexOf(item);
      this.items.splice(index, 1);
    }
  }

  toArray() {
    return this.items;
  }

  clear() {
    this.items = [];
  }

  private takeIndexOf(item: PInvoiceWsDTO): number {
    return this.items.map(i => i.invoiceNumber).indexOf(item.invoiceNumber);
  }
}
