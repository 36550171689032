import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationService } from './plansee/authorization-service';
import { defaultTo } from 'lodash';

export interface RequestOptions {
  responseType?: string;
  params?: Object;
  headers?: Object;
  observe?: any;
}

interface HTTPClientRequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
  observe?: any;
}

class HttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export abstract class RestService {

  protected basePath = '/rest/v2/electronics';

  protected constructor(protected http: HttpClient, protected authorizationService: AuthorizationService) {
  }

  abstract getEndpoint(): string;

  query<T>(options?: RequestOptions): Observable<T> {
    return this.http.get<T>(this.getEndpoint(), this.toRequestOptions(options));
  }

  get<T>(id: number | string, options?: RequestOptions): Observable<T> {
    return this.http.get<T>(`${this.getEndpoint()}/${id}`, this.toRequestOptions(options));
  }

  head<T>(id: number | string, options?: RequestOptions): Observable<T> {
    return this.http.head<T>(`${this.getEndpoint()}/${id}`, this.toRequestOptions(options));
  }

  postAt<T>(id: number | string, body: any | HttpParams, options?: RequestOptions): Observable<T> {
    let _options = this.fixHeaders(body, options);
    return this.http.post<T>(`${this.getEndpoint()}/${id}`, body, this.toRequestOptions(_options));
  }

  post<T>(body: any | HttpParams, options?: RequestOptions): Observable<T> {
    let _options = this.fixHeaders(body, options);
    return this.http.post<T>(this.getEndpoint(), body, this.toRequestOptions(_options));
  }

  put<T>(id: number | string, body: any, options?: RequestOptions): Observable<T> {
    let _options = this.fixHeaders(body, options);
    return this.http.put<T>(`${this.getEndpoint()}/${id}`, body, this.toRequestOptions(_options));
  }

  patch<T>(id: number | string, body: any, options?: RequestOptions): Observable<T> {
    let _options = this.fixHeaders(body, options);
    return this.http.patch<T>(`${this.getEndpoint()}/${id}`, body, this.toRequestOptions(_options));
  }

  delete(id: number | string): Observable<void> {
    return this.http.delete<void>(`${this.getEndpoint()}/${id}`);
  }

  protected toHttpParams(data: Object): HttpParams {
    let httpParams = new HttpParams({encoder: new HttpParamEncoder()});
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key, data[key]);
    });
    return httpParams;
  }

  protected toHttpHeaders(data: Object): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    Object.keys(data).forEach(function (key) {
      httpHeaders = httpHeaders.append(key, data[key]);
    });
    return httpHeaders;
  }

  private toRequestOptions(options: RequestOptions): HTTPClientRequestOptions {
    let result = {};

    // setting options.params (companyId, customerView)
    options = this.setRequestParams(options);

    if (options && options.params) {
      result['params'] = this.toHttpParams(options.params);
    }
    if (options && options.headers) {
      result['headers'] = this.toHttpHeaders(options.headers);
    }
    if (options && options.observe) {
      result['observe'] = options.observe;
    }
    if (options && options.responseType) {
      result['responseType'] = options.responseType;
    }
    return result;
  }

  private fixHeaders(body: any, options?: RequestOptions): RequestOptions {
    let _options = options || {};
    if (body instanceof HttpParams) {
      _options.headers = _options.headers || {};
      _options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return _options;
  }

  /**
   * add auth-specific params to url
   */
  private setRequestParams(options: any): any {
    // create options.params when undefined
    if (!options || !options.params) {
      options = {...defaultTo(options, {}), params: {}};
    }

    if (this.authorizationService && this.authorizationService.companyId) {

      // companyId
      options.params['companyId'] = this.authorizationService.companyId;

      // customerView
      options.params['customerView'] = this.authorizationService.customerView;
    }

    return options;
  }
}
