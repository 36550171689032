import { filter } from 'rxjs/operators';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  BreadcrumbWsDTO,
  FacetClearEvent,
  FacetDate,
  FacetMode,
  FacetWsDTO,
} from '../../providers/types/ycommercewebservices';
import { StripTimeStringPipe } from '../../filters/stripTimeString.pipe';
import { FormControl, FormGroup } from '@angular/forms';
import { PFacet } from '../p-facet';
import { PDatePipe } from '../../filters/pDate.pipe';
import { CustomDatepickerI18nService } from '../../providers/plansee/custom-datepicker-i18n.service';
import { defaultTo } from 'lodash';

@Component({
  selector: 'facet-date',
  template: require('./facet-date.component.html'),
  styles: [require('./facet-date.component.scss')],
  providers: [
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}
  ]
})
export class FacetDateComponent extends PFacet<FacetDate> implements OnInit {
  @Input() set defaultDates(value: FacetDate) {
    this._defaultDates = value;
    this.init();
  }
  get defaultDates(): FacetDate {
    return this._defaultDates;
  }
  @Input() facet: FacetWsDTO;
  @Input() breadcrumbs: BreadcrumbWsDTO[];
  @Input() type: FacetMode = FacetMode.BREADCRUMB;
  @Input() set clear(event: FacetClearEvent) {
    if (event && event.clearTime) {
      this.init();
    }
  }
  @ViewChild('fromInput') fromInput: ElementRef;
  @ViewChild('untilInput') untilInput: ElementRef;

  form: FormGroup;
  fromCollapse = false;
  untilCollapse = false;
  ngbFrom: NgbDateStruct;
  ngbUntil: NgbDateStruct;

  private _defaultDates: FacetDate = {};
  private datePipe = new PDatePipe;
  private stripTimeString = new StripTimeStringPipe();

  @HostListener('window:keyup', ['$event'])
  keyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 46 || event.keyCode === 8) {
      if (this.fromInput.nativeElement.contains(event.target)) {
        this.form.controls['from'].setValue(null);
      } else if (this.untilInput.nativeElement.contains(event.target)) {
        this.form.controls['until'].setValue(null);
      }
    }
  }

  ngOnInit(): void {
    this.init();
  }

  submitQuery(value: any) {
    if (!value.from && !value.until) {
      return;
    }

    if (this.type === FacetMode.BREADCRUMB) {
      let query = this.facet.values[0].query.query.value.replace(/\+/g, ' ');
      const words = this.prepareQuery(query, value);
      query = words.join(':');
      query = query.replace(/\"/g, '');
      query = this.stripTimeFromDateString(query);
      this.submit.emit(query);
    } else if (this.type === FacetMode.REGULAR) {
      this.ngbFrom = value.from || null;
      this.ngbUntil = value.until || null;
      this.createForm();
      this.submit.emit({
        from: this.toISO8601(value.from),
        until: this.toISO8601(value.until)
      });
    }
  }
  /* tslint:enable:cognitive-complexity */

  keyPress(event: KeyboardEvent) {
    event.preventDefault();
  }

  private init() {
    this.initDates();
    this.createForm();
  }

  private initDates() {
    const selectedDates = this.type === FacetMode.BREADCRUMB ?
      this.getFacetDates() :
      {
        from: defaultTo(this._defaultDates.from, null),
        until: defaultTo(this._defaultDates.until, null)
      };
    if (selectedDates) {
      const {from, until} = selectedDates;
      this.ngbFrom = from === null ? null : this.toNgbDateStruct(new Date(from));
      this.ngbUntil = until === null ? null : this.toNgbDateStruct(new Date(until));
    }
  }

  private createForm() {
    this.form = new FormGroup({
      from: new FormControl(this.ngbFrom),
      until: new FormControl(this.ngbUntil)
    }, (formGroup: FormGroup) => {
      const invalid = {validateForm: {valid: false}};
      return formGroup.valid ? null : invalid;
    });
    this.form.valueChanges
      .pipe(filter((value) => {
        return this.type === FacetMode.BREADCRUMB ? this.form.valid : true;
      }))
      .subscribe(value => {
        this.fromCollapse = false;
        this.untilCollapse = false;
        this.submitQuery(value);
      });
  }

  /* tslint:disable:cognitive-complexity */
  private prepareQuery(query: string, value: any) {
    const words = query.split(':');
    let found = false;
    const regx = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/;
    for (let i = 0; i < words.length; i++) {
      if (words[i] === this.facet.code) {
        if (!found) {
          found = true;
          if (value.from && value.until) {
            if (!this.isFromOlderThanUntil(value.from, value.until)) {
              let aux = Object.assign(value.until);
              value.until = Object.assign(value.from);
              value.from = aux;
            }
            words[i + 1] = `[${this.toISO8601(value.from).match(regx)[0]} TO ${this.toISO8601(value.until).match(regx)[0]}]`;
          } else if (value.from) {
            words[i + 1] = `[${this.toISO8601(value.from).match(regx)[0]} TO *]`;
          } else if (value.until) {
            words[i + 1] = `[* TO ${this.toISO8601(value.until).match(regx)[0]}]`;
          }
        } else {
          words.splice(i, 2);
          break;
        }
      }
    }
    return words;
  }

  private getFacetDates(): any {
    if (this.breadcrumbs) {
      return this.breadcrumbs.filter(value => value.facetCode === this.facet.code).map(value => {
        let str = value.facetValueCode;
        str = str.slice(1, str.length - 1);
        const values = str.split(' ');
        return {
          from: values[0] !== '*' ? new Date(this.stripTimeString.transform(values[0])) : null,
          until: values[2] !== '*' ? new Date(this.stripTimeString.transform(values[2])) : null
        };
      })[0];
    }
  }

  private toISO8601(value: NgbDateStruct): string {
    if (value && value.day >= 0 && value.year >= 0 && value.month >= 0) {
      return this.datePipe.transform(`${value.year}-${value.month}-${value.day}`, 'yyyy-MM-dd');
    }

    return '';
  }

  private toNgbDateStruct(value: Date): NgbDateStruct {
    return {year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate()};
  }

  private isFromOlderThanUntil(from: NgbDateStruct, until: NgbDateStruct) {
    if (from.year < until.year) {
      return true;
    } else if (from.year === until.year && from.month < until.month) {
      return true;
    } else if (from.year === until.year && from.month === until.month && from.day <= until.day) {
      return true;
    }
    return false;
  }
}
