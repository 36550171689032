import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ConfigService} from './config-service';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {
  PClaimForm,
  PClaimFormEntryWsDTO,
  PClaimsSearchPageWsDTO,
  PRequestWsDTO,
  PResponse
} from '../types/planseeoccaddon';
import {QueryRequestWsDTO, SuggestionListWsDTO} from '../types/ycommercewebservices';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

const CLAIM_FORM_STORAGE = 'claimForm';

@Injectable()
export class PClaimsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';
  onClaimFormChange = new Subject<PClaimForm>();

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PClaimsSearchPageWsDTO> {
    this.currentQueryParams = Object.assign({}, queryParams);
    return this.get<PClaimsSearchPageWsDTO>(`claims/search?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
      {params: this.stripQueryStringFromQueryObject(queryParams)});
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`claims/suggestions`, {params: queryParams});
  }

  submitFile(entityID: string, formData?: FormData, queryParams?: PRequestWsDTO): Observable<FormData & PResponse> {
    return this.postAt<FormData>(`documents/claim/${entityID}`, formData, {params: queryParams});
  }

  submitClaim(form: PClaimForm): Observable<PClaimFormEntryWsDTO & PResponse> {
    return this.postAt<PClaimFormEntryWsDTO>(`claim`, {claim: form.claim});
  }

  getForm(): PClaimForm {
    const claimForm = JSON.parse(sessionStorage.getItem(CLAIM_FORM_STORAGE));
    return claimForm !== null ? claimForm : {};
  }

  setForm(form: PClaimForm) {
    sessionStorage.setItem(CLAIM_FORM_STORAGE, JSON.stringify(form));
    this.onClaimFormChange.next(form);
  }

  fileClaim(form: PClaimForm, queryParams?: PRequestWsDTO): Observable<PResponse> {
    return this.submitClaim(form).pipe(map(response => {
      if (response) {
        const files = this.buildFileUploadForms(form);
        for (let file of files) {
          this.submitFile(response.infos[0].value, file, queryParams).subscribe();
        }
        sessionStorage.removeItem(CLAIM_FORM_STORAGE);
      }
      return response;
    }));
  }

  private buildFileUploadForms(form: PClaimForm): FormData[] {
    let files = [];
    if (form.claimMedias) {
      files = files.concat(form.claimMedias);
    }
    if (form.return8Dmedias) {
      files = files.concat(form.return8Dmedias);
    }
    const result = [];
    for (let file of files) {
      const formData = new FormData();
      formData.append('fileName', file.name);
      formData.append('document', file);
      result.push(formData);
    }
    return result;
  }

}
