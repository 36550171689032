import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[pClickOutside]'
})
export class PClickOutsideDirective {
  @Output()
  pClickOutside: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(private elementRef: ElementRef) {

  }

  @HostListener('window:click', ['$event'])
  clickEvent(event: MouseEvent) {
    if (!this.isEventFrom(event)) {
      this.pClickOutside.emit(event);
    }
  }

  private isEventFrom($event) {
    return this.elementRef.nativeElement.contains($event.target);
  }
}
