import {Directive, ElementRef, HostListener, Input} from '@angular/core';

export interface SvgOptions {
  selector: string;
  attributes: SvgAttribute[];
}

export interface SvgAttribute {
  key: string;
  value: string;
}

@Directive({
  selector: '[pSvg]'
})
export class PSvgDirective {

  svgDocument: Document;
  options: SvgOptions[];

  @Input('pSvg')
  set pSvg(options: SvgOptions[]) {
    this.options = options;
    this.applySvgOption(this.options);
  }

  constructor(private el: ElementRef) {
  }

  @HostListener('load')
  onLoad() {
    this.svgDocument = this.el.nativeElement.getSVGDocument();
    this.applySvgOption(this.options);
  }

  applySvgOption(options: SvgOptions[]) {
    if (this.svgDocument) {
      for (let option of options) {
        const element = this.svgDocument.getElementById(option.selector);
        for (let attribute of option.attributes) {
          element.setAttribute(attribute.key, attribute.value);
        }
      }
    }
  }
}
