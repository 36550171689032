import {Component, Input} from '@angular/core';
import { FacetClearEvent, FacetMode, FacetWsDTO } from '../../providers/types/ycommercewebservices'
import {PFacet} from '../p-facet';

@Component({
  selector: 'facet-default',
  template: require('./facet-default.component.html'),
  styles: [require('./facet-default.component.scss')]
})
export class FacetDefaultComponent extends PFacet<string[]> {

  private _facet: FacetWsDTO;

  get facet() {
    return this._facet;
  }

  @Input()
  set facet(facet: FacetWsDTO) {
    this._facet = facet;
    this._facet.values.sort((a, b) => {
      return (a.selected === b.selected) ? 0 : a.selected ? -1 : 1;
    });
  }
  @Input() type: FacetMode = FacetMode.BREADCRUMB;
  @Input() set clear(event: FacetClearEvent) {
    if (event && event.clearTime) {
      this.facet.values = this.facet.values.map(v => {
        return {
          ...v,
          selected: false
        };
      });
    }
  }

  click(value: string) {
    if (this.type === FacetMode.BREADCRUMB) {
      let query = value.replace(/\+/g, ' ').replace(/"/g, '');
      query = this.stripTimeFromDateString(query);
      this.submit.emit(query);
    } else if (this.type === FacetMode.REGULAR) {
      this.facet.values = this.facet.values.map(v => {
        return {
          ...v,
          selected: v.code === value ? !v.selected : v.selected
        };
      });
      this.submit.emit(this.facet.values.filter(v => v.selected).map(v => v.code));
    }
  }
}
