import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config-service';
import {Observable} from 'rxjs';
import {PEventsListWsDTO, PKpiListWsDTO, PNewsListWsDTO, PRequestWsDTO, VideoWidgetWsDTO} from '../types/planseeoccaddon';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PWidgetsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee/widgets';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  getNews(queryParams?: PRequestWsDTO): Observable<PNewsListWsDTO> {
    return this.get<PNewsListWsDTO>(`recentNews`, {params: queryParams});
  }

  getEvents(queryParams?: PRequestWsDTO): Observable<PEventsListWsDTO> {
    return this.get<PEventsListWsDTO>(`events`, {params: queryParams});
  }

  getKpis(queryParams?: PRequestWsDTO): Observable<PKpiListWsDTO> {
    return this.get<PKpiListWsDTO>(`kpis`, {params: queryParams});
  }

  getVideo(queryParams?: PRequestWsDTO): Observable<VideoWidgetWsDTO> {
    return this.get<VideoWidgetWsDTO>(`video`, {params: queryParams});
  }
}
