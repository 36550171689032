import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import {FileDropModule} from 'ngx-file-drop';
import {DragAndDropUploadComponent} from './drag-and-drop-upload/drag-and-drop-upload.component';
import {PSvgDirective} from './p-svg/p-svg.directive';
import {PADirective} from './p-a/p-a.directive';
import {PDownloadDirective} from './p-download/p-download.directive';
import {PSwitchComponent} from './p-switch/p-switch.component';
import {PDateComponent} from './p-date/p-date.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PClickOutsideDirective} from './p-click-outside/p-click-outside.directive';
import {PSelectComponent} from './p-select/p-select.component';
import {PNumberDirective} from './p-number/p-number.directive';
import {TooltipIfEllipsisDirective} from './tooltip-if-ellipsis/tooltip-if-ellipsis.directive';
import { PasswordInputComponent } from './password-input/password-input.component';
import { FiltersModule } from '../filters/filters.module';
import {TermConditionsCheckboxComponent} from './term-conditions-checkbox/term-conditions-checkbox.component';
import { NgSelectModule } from '@ng-select/ng-select';

const components = [
  PSwitchComponent,
  DragAndDropUploadComponent,
  PDateComponent,
  PSvgDirective,
  PADirective,
  PDownloadDirective,
  PClickOutsideDirective,
  PSelectComponent,
  PNumberDirective,
  TooltipIfEllipsisDirective,
  PasswordInputComponent,
  TermConditionsCheckboxComponent
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    TranslateModule,
    FileDropModule,
    NgbModule,
    FiltersModule,
    NgSelectModule
  ],
  declarations: components,
  exports: components
})
export class FormComponentsModule {
}
