import { map, take } from 'rxjs/operators';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { PQuotationsService } from '../../providers/plansee/p-quotations-service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  PRequestedQuotationEntryWSDTO,
  PRequestedQuotationWSDTO,
  PResponse
} from '../../providers/types/planseeoccaddon';
import { Observable } from 'rxjs';
import { ConnectionStatusEnum, PInternetConnectionService } from '../../providers/plansee/p-internet-connection-service';
import { PDatePipe } from '../../filters/pDate.pipe';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'quotation-form',
  template: require('./quotation-form.component.html'),
  styles: [require('./quotation-form.component.scss')]
})
export class QuotationFormComponent extends SubscriptionManager implements OnInit {
  triggerErrorMessages = false;
  rfqForm: FormGroup;
  readonly minDate = new Date();

  constructor(
    private quotationsService: PQuotationsService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private internetConnectionService: PInternetConnectionService
  ) {
    super();
  }

  private datePipe = new PDatePipe();
  private status: ConnectionStatusEnum = ConnectionStatusEnum.Online;

  @HostListener('window:keyup', ['$event'])
  keyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 46 || event.keyCode === 8) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.rfqForm = new FormGroup({
      title: new FormControl(null, [Validators.required, Validators.nullValidator]),
      entries: this.formBuilder.array([new FormGroup({})], Validators.required),
      dueDate: new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        [Validators.required, Validators.nullValidator, (formControl: FormControl) => {
          // check if has proper format
          const regx = new RegExp('^([0-9]{4}-[0-9]{1,2}-[0-9]{1,2})$');
          return regx.test(formControl.value) ? null : {valid: false};
        }])
    }, (formGroup: FormGroup) => {
      return formGroup.valid && this.status === ConnectionStatusEnum.Online ? null : {validateForm: {valid: false}};
    });

    this.addSubscriptions(
      this.internetConnectionService.offline.subscribe(e => {
        this.status = ConnectionStatusEnum.Offline;
        this.rfqForm.updateValueAndValidity();
      }),
      this.internetConnectionService.online.subscribe(e => {
        this.status = ConnectionStatusEnum.Online;
        this.rfqForm.updateValueAndValidity();
      })
    );
  }

  addMaterial() {
    let entries = this.rfqForm.get('entries') as FormArray;
    entries.push(new FormGroup({}));
    this.cdr.detectChanges();
  }

  removeMaterial(material: FormControl) {
    const controls = this.rfqForm.get('entries') as FormArray;
    const materialIndex = controls.controls.indexOf(material);
    controls.controls.splice(materialIndex, 1);
    this.cdr.detectChanges();
  }

  submit(e) {
    e.preventDefault();
    if (!this.rfqForm.valid) {
      this.triggerErrorMessages = true;
      if (this.rfqForm.get('title').invalid) {
        this.rfqForm.get('title').markAsTouched();
        this.rfqForm.get('title').markAsDirty();
      }
      if (this.rfqForm.get('dueDate').invalid) {
        this.rfqForm.get('dueDate').markAsTouched();
        this.rfqForm.get('dueDate').markAsDirty();
      }

      this.cdr.detectChanges();
      let target = document.querySelector('.form-group-danger');
      target = target ? target.querySelector('.p-input:not(.not-danger)') : null;
      if (target) {
        (target as any).focus();
      }
      this.triggerErrorMessages = false;
      return;
    }

    this.submitForm(this.rfqForm.value)
      .pipe(take(1))
      .subscribe(response => {
        this.router.navigate(['/menu/rfqs/confirmation']);
      });
  }

  submitForm(form: any): Observable<PResponse> {
    const quotation = this.toPRequestedQuotationWSDTO(form);
    return this.quotationsService.submitQuote(quotation).pipe(map(response => {
      if (response) {
        // queryParams
        let data: any = {};
        const files = this.buildFileUploadForms(form.entries);
        for (let file of files) {
          this.quotationsService.submitFile(response.infos[0].value, file, data)
            .pipe(take(1))
            .subscribe();
        }
      }
      return response;
    }));
  }

  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }

  private toPRequestedQuotationWSDTO(form): PRequestedQuotationWSDTO {
    const result: PRequestedQuotationWSDTO = {
      title: form.title,
      dueDate: form.dueDate,
      entries: []
    };
    let position = 0;
    for (let entry of form.entries) {
      const additionalServices: string[] = [];
      Object.keys(entry.additionalServices).forEach(key => {
        // if additional get the enum key
        if (entry.additionalServices[key]) {
          additionalServices.push(key);
        }
      });
      position = Math.floor(position / 10) * 10 + 10;
      // construct main entry
      this.constructEntries(entry, additionalServices, position, result);
    }
    return result;
  }

  private constructEntries(entry, additionalServices, position, result) {
    const mainEntry: PRequestedQuotationEntryWSDTO = {
      customerMaterialNumber: entry.product.customerMaterialNumber,
      materialType: entry.product.materialType,
      planseeMaterialNumber: entry.product.planseeMaterialNumber,
      additionalServices: additionalServices,
      quantity: {
        quantity: +entry.quantity.quantity,
        unitOfMeasure: entry.quantity.unitOfMeasure
      },
      tolerances: entry.technical.tolerances ? entry.technical.tolerances : undefined,
      additionalInformation: entry.additionalInformation ? entry.additionalInformation : undefined,
      customUnitOfMeasure: entry.quantity.customUnitOfMeasure ? entry.quantity.customUnitOfMeasure : undefined,
      position: this.createSixLetterStringPosition(position)
    };
    result.entries.push(mainEntry);
    // construct the additional quantities entries
    for (let quantityGroup of entry.additionalQuantities) {
      const additionalEntry: PRequestedQuotationEntryWSDTO = {
        customerMaterialNumber: entry.product.customerMaterialNumber,
        materialType: entry.product.materialType,
        planseeMaterialNumber: entry.product.planseeMaterialNumber,
        additionalServices: additionalServices,
        quantity: {
          quantity: +quantityGroup.quantity,
          unitOfMeasure: entry.quantity.unitOfMeasure
        },
        tolerances: entry.technical.tolerances ? entry.technical.tolerances : undefined,
        additionalInformation: entry.additionalInformation ? entry.additionalInformation : undefined,
        customUnitOfMeasure: entry.quantity.customUnitOfMeasure ? entry.quantity.customUnitOfMeasure : undefined,
        parentPosition: mainEntry.position,
        position: this.createSixLetterStringPosition(++position)
      };
      result.entries.push(additionalEntry);
    }
  }

  private buildFileUploadForms(entries: any[]): FormData[] {
    const result = [];
    let parentPosition = 0;
    for (let entry of entries) {
      parentPosition = Math.floor(parentPosition / 10) * 10 + 10;
      if (entry.technical.medias) {
        for (let file of entry.technical.medias) {
          const formData = new FormData();
          formData.append('fileName', file.name);
          formData.append('document', file);
          formData.append('position', this.createSixLetterStringPosition(parentPosition));
          result.push(formData);
        }
      }
    }
    return result;
  }

  private createSixLetterStringPosition(position: number): string {
    let positionString = position.toString();
    while (positionString.length < 6) {
      positionString = '0' + positionString;
    }
    return positionString;
  }
}
