import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ConfigService} from './config-service';
import {
  PDocumentsSearchPageWsDTO,
  PRequestWsDTO,
  Translation,
} from '../types/planseeoccaddon';
import {FacetValueWsDTO, QueryRequestWsDTO, RequestWsDTO, SuggestionListWsDTO} from '../types/ycommercewebservices';
import {HttpClient} from '@angular/common/http';
import {PService} from './p-service';
import {AuthorizationService} from './authorization-service';
import {Router} from '@angular/router';
import {saveAs} from 'file-saver';
import { CustomNgxOauthService } from './custom-ngx-oauth.service';

@Injectable()
export class PDocumentsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';
  currentQueryParams: QueryRequestWsDTO & PRequestWsDTO = {};
  _bookmarksFacet: FacetValueWsDTO = null;

  constructor(http: HttpClient,
              configService: ConfigService,
              authorizationService: AuthorizationService,
              private router: Router,
              private oauthService: CustomNgxOauthService) {
    super(http, authorizationService);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
    const user = authorizationService.getUserProfile();
    this._bookmarksFacet = {code: user.uid, count: 0, selected: false};
  }

  search(queryParams?: QueryRequestWsDTO, onlyBookmarks = false): Observable<PDocumentsSearchPageWsDTO> {
    this.currentQueryParams = Object.assign({}, queryParams);

    if (onlyBookmarks) {
      return this.get<PDocumentsSearchPageWsDTO>('documents/bookmarkedDocuments?query=' +
        this.encodeLimitsForDateRange(queryParams.query), {params: this.stripQueryStringFromQueryObject(queryParams)});
    }

    return this.get<PDocumentsSearchPageWsDTO>('documents/search?query=' +
      this.encodeLimitsForDateRange(queryParams.query), {params: this.stripQueryStringFromQueryObject(queryParams)});
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & RequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`documents/suggestDocumentAccessUnit`, {params: queryParams});
  }

  getDocumentLink(documentId, isPreview = false) {
    let companyId = this.authorizationService.companyId || '';
    return `${this.getEndpoint()}/documents/${this.urlEncode.transform(documentId)}` +
           `${isPreview ? '&type=view' : ''}` +
           `${companyId !== '' ? '&companyId=' + encodeURIComponent(companyId) : ''}`;
  }

  getListCsvContent(translations: Translation[]): Observable<any> {
    return this.getCsvContent(`documents/download`, translations);
  }

  updateBookmark(documentId: string, selected: boolean): Observable<string> {
    return selected ? this.addBookmark(documentId) : this.removeBookmark(documentId);
  }

  addBookmark(documentId: string, queryParams?: PRequestWsDTO): Observable<string> {
    return this.get(`documents/bookmark/add/${this.urlEncode.transform(documentId)}`, {params: queryParams});
  }

  removeBookmark(documentId: string, queryParams?: PRequestWsDTO): Observable<string> {
    return this.get(`documents/bookmark/remove/${this.urlEncode.transform(documentId)}`, {params: queryParams});
  }

  checkLinkAndHandleDownload(event, document, isPreview: boolean) {

    // first stop the default of opening/downloading file
    event.preventDefault();
    event.stopPropagation();

    const downloadLink = (document && document.id) ? `documents/${this.urlEncode.transform(document.id)}` : null;
    if (!downloadLink) {
      this.router.navigate(['/error'], { skipLocationChange: false });
      return;
    }
    const requestOptions: any = {
      responseType: 'blob'
    };
    this.get<Blob>(downloadLink, requestOptions).subscribe(result => {
      if (result) {
        if (isPreview) {
          const companyId = this.authorizationService.companyId || '';
          const params = '&companyId=' + encodeURIComponent(companyId) + '&customerView=' + this.authorizationService.customerView;
          const viewUrl = `${this.getEndpoint()}/${downloadLink}?access_token=${this.oauthService.token.access_token}`
              + `${companyId !== '' ? params : ''}`
              + `&type=view`;
          window.open(viewUrl, '_blank');
          return;
        } else {
          saveAs(result, document.fileName);
          return;
        }
      } else {
        this.router.navigate(['/error'], { skipLocationChange: false });
      }
    },
    error => {
      this.router.navigate(['/error'], { skipLocationChange: false });
    });
  }
}
