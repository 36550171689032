import {Pipe, PipeTransform} from '@angular/core';
import {PDatePipe} from './pDate.pipe';
import {PForecastEntryValueTypeWsDTO} from '../providers/types/planseeoccaddon';

@Pipe({name: 'isNormalForecast'})
export class IsNormalForecastPipe implements PipeTransform {
    private datePipe = new PDatePipe();
    transform(entries, code, filter) {
      if (!Array.isArray(entries)) {
          return '';
      }
      let segment = entries.find(entry => this.datePipe.transform(entry.firstDayOfPeriod, filter, true) === code);
      return segment && segment.forecastQuantity && segment.forecastQuantityType ?
        segment.forecastQuantityType === PForecastEntryValueTypeWsDTO.NORMAL : true;
    }
}
