import { Injectable } from '@angular/core';
import { OidcSecurityService, ValidationResult } from 'angular-auth-oidc-client';
import { CustomNgxOauthService } from './custom-ngx-oauth.service';
import { ResourceOAuthSettings } from '../../settings/resource-oauth-settings';
import { ConfigService } from './config-service';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from './loading-service';

@Injectable()
export class AzureAuthorizationService {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private oauthService: CustomNgxOauthService,
    private configService: ConfigService,
    private loadingService: LoadingService,
  ) {
  }

  init() {
    if (this.oidcSecurityService.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidcSecurityService.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    }
    this.observeAuthorizationStatus();
  }

  private observeAuthorizationStatus() {
    this.oidcSecurityService.onAuthorizationResult
      .pipe(switchMap((result) => {
        if (result.validationResult === ValidationResult.Ok) {
          return this.oidcSecurityService.getUserData().pipe(take(1));
        } else {
          return of(null);
        }
      }))
      .subscribe(userData => {
        if (userData === null || !userData || !userData.email) {
          this.clearSessionStorage();
          this.oauthService.showAzureError();
        } else {
          this.loginViaIdToken(this.oidcSecurityService.getIdToken(), userData.email);
        }
      });
  }

  private loginViaIdToken(token: string, username: string) {
    this.clearSessionStorage();
    this.loadingService.loading.next(true);
    this.configureOAuthService();
    this.oauthService.loginViaIdToken(token, username);
  }

  private clearSessionStorage() {
    // we need to call it to remove session storage - it will not be logged out from azure because end_session_endpoint is not set
    this.oidcSecurityService.logoff();
  }

  private configureOAuthService(): void {
    const config = this.configService.config;
    if (config) {
      const oauthConfig = new ResourceOAuthSettings()
        .buildWithConfig(config, true);
      this.oauthService.configure(oauthConfig);
    }
  }

  private doCallbackLogicIfRequired() {
    if (window.location.hash) {
      this.oidcSecurityService.authorizedImplicitFlowCallback();
    }
  }
}
