import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PConsignmentsService} from '../../providers/plansee/p-consignments-service';
import {Animations} from '../../animations/animations';
import {PSearch} from '../p-search';
import {TranslateService} from '@ngx-translate/core';
import {PStockLevelSearchPageWsDTO, PDownloadModalType} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'consignments-search',
  template: require('./consignments-search.component.html'),
  animations: [
    Animations.expand()
  ]
})
export class ConsignmentsSearchComponent extends PSearch<PStockLevelSearchPageWsDTO> implements OnInit {
  modalType = PDownloadModalType.CONSIGNMENT;
  showModal = false;
  _stockDevelopment = false;
  _forecast = false;
  _plannedShipments = false;
  _fromDate: string;
  _toDate: string;
  _toDateFromSegment: string;

  constructor(private consignmentsService: PConsignmentsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
  }

  ngOnInit(): void {
    this.setInitialDates();
  }

  get stockDevelopment(): boolean {
    return this._stockDevelopment;
  }
  set stockDevelopment(value: boolean) {
    this._stockDevelopment = value;
  }

  get forecast(): boolean {
    return this._forecast;
  }

  set forecast(value: boolean) {
    this._forecast = value;
  }

  get plannedShipments(): boolean {
    return this._plannedShipments;
  }

  set plannedShipments(value: boolean) {
    this._plannedShipments = value;
  }

  get fromDate() {
    return this._fromDate;
  }

  set fromDate(strDate: string) {
    this._fromDate = strDate;
    this.checkTimePeriod();
  }

  get toDate() {
    return this._toDate;
  }

  set toDate(strDate: string) {
    this._toDate = strDate;
    this.checkTimePeriod();
  }

  setStockDevelopment(event) {
    this.stockDevelopment = event.target.checked;
  }

  setForecast(event) {
    this.forecast = event.target.checked;
  }

  setPlannedShipments(event) {
    this.plannedShipments = event.target.checked;
  }

  setInitialDates() {
    const today = new Date();
    this.fromDate = this.toISO8601(new Date(today.setMonth(today.getMonth() - 1)));
    if (this._toDateFromSegment !== '' && typeof this._toDateFromSegment !== 'undefined') {
      this.toDate = this._toDateFromSegment;
    } else {
      this.toDate = this.toISO8601(new Date());
    }
  }

  updateSearchResult(searchResult: PStockLevelSearchPageWsDTO) {
    this.state = searchResult;
    this.setToDateFromSegment(searchResult.stockLevels);
    // override query since it comes broken out of backend
    this.state.freeTextSearch = this._query && this._query.query ? this.decodeSearchQuery(this._query.query.split(':')[0]) : '';
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }

  search(query: string, emit = false) {
    // remove time from query cuz of :
    query = this.stripTimeFromDateString(query);
    let data = this.checkAndUpdateQuery(query);
    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;
      if (emit) {
        // trigger search in list component
        this.submit();
      } else {
        this.consignmentsService.search(data).subscribe(val => {
          this.state = val;
          this.state.freeTextSearch = this.decodeSearchQuery(query.split(':')[0]);
          // override query since it comes broken out of backend
          this.state.currentQuery.query.value = query;
        });
      }
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.consignmentsService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getCurrentSelectionDocument() {
    const keys = [
      'consignment.customerMaterialNumber',
      'consignment.description',
      'consignment.planseeMaterialNumber',
      'consignment.currentStockLevel',
      'consignment.inTransit',
      'consignment.lastShipment',
      'consignment.segments',
      'consignment.deliveryMode',
      'consignment.trackingUrl',
      'consignment.code',
      'consignment.goodsIssueDate',
      'consignment.namedDeliveryDate',
      'consignment.purchaseNumber',
      'consignment.product.code',
    ];
    let optionalFields = '';
    if (this.stockDevelopment) {
      optionalFields += 'entryStockLevel:';
    }
    if (this.plannedShipments) {
      optionalFields += 'entryShipments:';
    }
    if (this.forecast) {
      optionalFields += 'entryForecasted:';
    }
    const dates = this.formatDownloadDates(this.fromDate, this.toDate, this.toISO8601(new Date()));
    optionalFields += `timeRange=${dates.fromDate}TO${dates.toDate}`;

    this.consignmentsService.getListCsvContent(this.createTranslations(keys), optionalFields).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
      this.showModal = false;
    });
  }

  openDownloadModal() {
    this.showModal = true;
    this.stockDevelopment = false;
    this.forecast = false;
    this.plannedShipments = false;
    this.setInitialDates();
  }

  closeDownloadModal() {
    this.showModal = false;
  }

  /*
    params: date
    return string of type 'yyyy-MM-dd';
  */
  private toISO8601(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    let monthStr = month < 10 ? `0${month}` : `${month}`;
    let dayStr = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${monthStr}-${dayStr}`;
  }

  private checkTimePeriod() {
     if (new Date(this.fromDate) > new Date(this.toDate)) {
      this.toDate = this.fromDate;
    }
  }

  /*
    params: stockLelvels got from server
    action: set TO date either today or last stock level date
    whatever is later;
  */
  private setToDateFromSegment(stockLevels = []) {
    if (stockLevels.length > 0) {
      const lastStockLevel = stockLevels[stockLevels.length - 1];
      if (lastStockLevel.segments && lastStockLevel.segments.length > 0) {
        const lastSegmentOfLastStockLevel = lastStockLevel.segments[lastStockLevel.segments.length - 1];
        if (lastSegmentOfLastStockLevel && lastSegmentOfLastStockLevel.entryUpdateDate) {
          const lastDate = new Date(lastSegmentOfLastStockLevel.entryUpdateDate);
          const today = new Date();
          if (lastDate > today) {
            this.toDate = this.toISO8601(lastDate);
            // the toDateFromSegment will be used later to reset dates
            // when user changed the dates to something else;
            this._toDateFromSegment = this.toDate;
          }
        }
        return;
      }
      return;
    }
    return;
  }
}
