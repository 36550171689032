import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LoadingService } from '../../providers/plansee/loading-service';
import { PCustomerUserWsDTO } from '../../providers/types/planseeoccaddon';
import { PUsersService } from '../../providers/plansee/p-users-service';
import { P_MODULES_NAMES } from '../users-list/users-list.component';
import { GlobalsService } from '../../providers/types/globals.service';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PSelectValue } from '../../form-components/p-select/p-select-value';
import { TranslateService } from '@ngx-translate/core';
import { PageRights } from '../../rights/page-rights';
import { PageRightsService } from '../../rights/page-rights.service';
import { UserPermissions } from '../../rights/user-rights';
import { defaultTo } from 'lodash';
import { CountryLocation } from '../../providers/types/ycommercewebservices';
import { generateLocationList } from '../../shared/utils/country-location-utils';
import { ValidationErrors } from '@angular/forms/src/directives/validators';
import { AbstractControl } from '@angular/forms/src/model';
import { findSelectedLocations } from './user-form-utils';

export enum UserAction {
  POST = 'create',
  PUT = 'update'
}

@Component({
  selector: 'user-form',
  template: require('./user-form.component.html'),
  styles: [require('./user-form.component.scss')]
})
export class UserFormComponent implements OnInit, OnDestroy {
  userForm: FormGroup;

  functions: PSelectValue[] = [];

  modules = [
    {id: PageRights.RFQS, name: P_MODULES_NAMES[PageRights.RFQS]},
    {id: PageRights.HOT_ZONE_CONFIGURATOR, name: P_MODULES_NAMES[PageRights.HOT_ZONE_CONFIGURATOR]},
    {id: PageRights.PRODUCTS, name: P_MODULES_NAMES[PageRights.PRODUCTS]},
    {id: PageRights.ORDERS, name: P_MODULES_NAMES[PageRights.ORDERS]},
    {id: PageRights.SHIPMENTS, name: P_MODULES_NAMES[PageRights.SHIPMENTS]},
    {id: PageRights.FORECASTS, name: P_MODULES_NAMES[PageRights.FORECASTS]},
    {id: PageRights.CONSIGNMENTS, name: P_MODULES_NAMES[PageRights.CONSIGNMENTS]},
    {id: PageRights.INVOICES, name: P_MODULES_NAMES[PageRights.INVOICES]},
    {id: PageRights.CLAIMS, name: P_MODULES_NAMES[PageRights.CLAIMS]},
    {id: PageRights.DOCUMENTS, name: P_MODULES_NAMES[PageRights.DOCUMENTS]}
  ];
  modulesAvailable = [];

  get isConfirmed(): boolean {
    return this._isConfirmed;
  }

  get titles(): PSelectValue[] {
    return this._titles;
  }

  set titles(value: PSelectValue[]) {
    this._titles = value;
  }

  get locations() {
    return this._locations;
  }

  get types(): PSelectValue[] {
    return this._types;
  }

  set types(value: PSelectValue[]) {
    this._types = value;
  }

  get USERTYPE() {
    return this.globals.DEFAULT_USERTYPE;
  }

  get __userType() {
    return this._userType;
  }

  get Readonly() {
    return this.readonly;
  }

  get ReadonlyUserType() {
    return this.readonlyUserType;
  }

  @Input()
  action: UserAction;

  @Input()
  user: PCustomerUserWsDTO;

  @Input() set userPermissions(userPermissions: UserPermissions) {
    this._allowedModules = this.calculateAllowedModules(userPermissions);
  }

  @Output()
  closeOverlayEmitter = new EventEmitter<boolean>();

  @Output()
  reloadUsersList = new EventEmitter<boolean>();

  addedUser = {
    fullName: ''
  };

  error: number = null;
  errorType: string = null;
  errorMessage: string = null;

  constructor(
    public authorizationService: AuthorizationService,
    private pUsersService: PUsersService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private globals: GlobalsService,
    private renderer: Renderer,
    private translateService: TranslateService,
    private pageRightsService: PageRightsService,
  ) {
    this.renderer.setElementClass(document.body, 'noscroll', true);
  }

  private _isConfirmed = false;
  private _isOverlayOpen = false;
  private _titles: PSelectValue[] = [];
  private userId: string = null;
  private _allowedModules = [];
  private _isLoading = false;
  private _locations: CountryLocation[] = [];
  private _types: PSelectValue[] = [];
  private _userType: string = null;
  private readonly = false;
  private readonlyUserType = false;

  get allowedModules() {
    return this._allowedModules;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  ngOnInit(): void {
    // Subscribe to loading server;
    this.loadingService.loading.subscribe(value => this.isLoading = value);

    this._titles = [...this.globals.getTitles(), {value: null, label: 'delimiter'}];
    this._locations = this.authorizationService.noWildcardLocations;
    this._types = this.globals.getUserTypesExcludingSUM();

    // Position
    this.functions = this.globals.getJobPositions().map(pos => ({value: pos.value, label: pos.label}));

    // some properties cannot be changed when editing an existing user
    this.readonly = (this.action === UserAction.PUT);
    this.readonlyUserType = (!this.authorizationService.isSuperUserMaster);

    //
    this.initUser();

    //
    this.initFormGroup();

    this._userType = (this.user.userType || '');

    // add checkboxes for each available module
    this.addModuleCheckboxes();

    // init location controls
    this.initFormValidationForLocations();
    this.initControlsForLocations();
  }

  get typeFormControl() {
    return this.userForm.get('userType') as FormControl;
  }

  get locationsFormControl() {
    return this.userForm.get('locations') as FormControl;
  }

  get locationFormControl() {
    return this.userForm.get('location') as FormControl;
  }

  ngOnDestroy(): void {
    this.renderer.setElementClass(document.body, 'noscroll', false);
  }


  closeOverlay(e: Event, overlayElement: Element) {
    if (e.target === overlayElement) {
      this.closeOverlayEmitter.emit(this._isOverlayOpen);
    }
  }

  close(event: Event) {
    this.closeOverlayEmitter.emit(this._isOverlayOpen);
  }

  takeCountryLocationValue(country: string, city: string, company?: string): string {
    return `${country}|${city}${company ? `|${company}` : ''}`.toLowerCase();
  }

  submit(e) {
    e.preventDefault();

    // reset error
    this.error = null;
    this.errorType = null;
    this.errorMessage = null;
    this.user.userType = (this.authorizationService.isSuperUserMaster) ? this.userForm.get('userType').value : this.USERTYPE.REGULAR;

    if (!this.userForm.valid) {
      this.userForm.get('titleCode').markAsTouched();
      this.userForm.get('firstName').markAsTouched();
      this.userForm.get('lastName').markAsTouched();
      this.userForm.get('email').markAsTouched();
      this.userForm.get('phone').markAsTouched();
      this.userForm.get('function').markAsTouched();
      this.userForm.get('userType').markAsTouched();
      this.userForm.get('locations').markAsTouched();
      this.userForm.get('location').markAsTouched();
      this.userForm.get('modules').markAsTouched();

      this.cdr.detectChanges();

      if (this.action === UserAction.PUT) {
        // FormValidationError when updating an user
        this.handleFormValidationError();
      }

      return;
    }

    // get selected modules
    const selectedModules = this.userForm.value.modules
      .map((v, i) => v ? this.allowedModules[i].id : null)
      .filter(v => v !== null);

    // get values to save from form
    if (this.action === UserAction.POST) {
      this.user.titleCode = this.userForm.get('titleCode').value;
      this.user.firstName = this.userForm.get('firstName').value;
      this.user.lastName = this.userForm.get('lastName').value;
      this.user.email = this.userForm.get('email').value;
      this.user.address.phone = this.userForm.get('phone').value;
      this.user.function = this.userForm.get('function').value;
    }

    // usertype
    this.user.userType = (this.authorizationService.isSuperUserMaster) ? this.userForm.get('userType').value : this.USERTYPE.REGULAR;
    this.user.superuser = (this.user.userType !== this.USERTYPE.REGULAR);

    // get selected locations
    this.user.locationList = this.getSelectedLocations();

    // module permissions
    this.user.modules = selectedModules;

    if (this.action === UserAction.PUT /*&& this.userId*/) {
      // update
      return this.pUsersService.editUser(this.userId, this.user).subscribe(response => {
        // close form + refresh UsersList
        this._isOverlayOpen = false;
        this.closeOverlayEmitter.emit(this._isOverlayOpen);
        this.refreshUsersList();
      }, error => {
        this.serverError(error);
      }, () => {
        // HTTP request completed
      });
    } else {
      // add
      return this.pUsersService.addUser(this.user).subscribe(response => {
        // show confirmation and auto-close + refresh UsersList
        this.addedUser.fullName = `${this.user.firstName} ${this.user.lastName}`;
        this._isConfirmed = true;
        setTimeout(() => {
          this.closeOverlayEmitter.emit(this._isOverlayOpen);
          this.refreshUsersList();
        }, 3000);
      }, error => {
        this.serverError(error);
      }, () => {
        // HTTP request completed
      });
    }
  }

  private initUser() {
    if (this.user === null) {
      this.user = {
        uid: '',
        titleCode: '',
        firstName: '',
        lastName: '',
        email: '',
        address: {phone: ''},
        function: '',
        modules: [],
        userType: '',
        locationList: [],
        superuser: null,
        creationDate: null
      };
    } else {
      this.userId = this.user.uid;
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  private initFormGroup() {
    let _user = this.user;
    let functionLabel = '';
    const functionEntry = this.functions.find(f => f.value === this.user.function);

    if (functionEntry) {
      functionLabel = this.translateService.instant(functionEntry.label);
    }

    let FORM_GROUP: any = {
      titleCode: new FormControl(
        {
          value:
            (this.readonly) ?
              (
                (this.globals.DEFAULT_TITLES.filter(title => title.code === _user.titleCode).length === 1) ?
                  (this.translateService.instant(this.globals.DEFAULT_TITLES.filter(title => title.code === _user.titleCode)[0].name))
                  : (_user.titleCode || '')
              )
              : (_user.titleCode || ''),
          disabled: false
        },
        [Validators.required, Validators.nullValidator]
      ),
      firstName: new FormControl(
        {value: (_user.firstName || ''), disabled: false},
        [Validators.required, Validators.nullValidator]
      ),
      lastName: new FormControl(
        {value: (_user.lastName || ''), disabled: false},
        [Validators.required, Validators.nullValidator]
      ),
      email: new FormControl(
        {value: (_user.email || ''), disabled: false},
        [Validators.required, Validators.nullValidator]
      ),
      phone: new FormControl(
        {value: (_user.address.phone || ''), disabled: false},
        [Validators.required, Validators.nullValidator]
      ),
      function: new FormControl(
        {value: functionLabel || '', disabled: false},
        [Validators.required, Validators.nullValidator]
      ),
      userType: new FormControl(
        {
          value: (this.readonlyUserType) ?
            (
              (this.globals.DEFAULT_USERTYPES.filter(userType => userType.value === _user.userType).length === 1) ?
                this.translateService.instant((this.globals.DEFAULT_USERTYPES.filter(userType => userType.value === _user.userType)[0].name))
                : (_user.userType ||
                (this.authorizationService.isSuperUser ?
                  this.translateService.instant(this.globals.DEFAULT_USERTYPES.filter(userType => userType.value === this.globals.DEFAULT_USERTYPE.REGULAR)[0].name)
                  : ''))
            )
            : (_user.userType || ''), disabled: false
        },
        [Validators.required, Validators.nullValidator]
      ),
      locations: new FormArray([], [minOneLocationSelected]),
      location: new FormControl(null, [Validators.required]),
      modules: new FormArray([], [minSelectedCheckboxes(1)])
    };

    this.userForm = this.fb.group(FORM_GROUP);
  }

  private initFormValidationForLocations() {
    // deactivate initial validation when opening form
    if (this.action === UserAction.POST) {
      // add new user
      this.locationFormControl.setValidators(null);
      this.locationsFormControl.setValidators(null);
      this.locationFormControl.updateValueAndValidity();
      this.locationsFormControl.updateValueAndValidity();
    } else {
      // edit existing user
      if (this.user.userType === this.USERTYPE.REGULAR) {
        this.locationsFormControl.setValidators(null);
        this.locationsFormControl.updateValueAndValidity();
      } else {
        this.locationFormControl.setValidators(null);
        this.locationFormControl.updateValueAndValidity();
      }
    }

    // change validation according to selected (user) type
    this.typeFormControl.valueChanges.subscribe(selected => {
      if (selected === this.USERTYPE.SUPERUSER) {
        this.locationFormControl.setValidators(null);
        this.locationsFormControl.setValidators(minOneLocationSelected);
      } else if (selected === this.USERTYPE.REGULAR) {
        this.locationFormControl.setValidators(Validators.required);
        this.locationsFormControl.setValidators(null);
      } else {
        this.locationFormControl.setValidators(null);
        this.locationsFormControl.setValidators(null);
      }
      this.locationFormControl.updateValueAndValidity();
      this.locationsFormControl.updateValueAndValidity();

      //
      this._userType = selected;
    });
  }

  private initControlsForLocations() {
    // add checkboxes/radio buttons for each location
    if (this.authorizationService.isSuperUserMaster) {
      // SUM
      this.addLocationCheckboxes();
    }
    // SUM + SU
    this.setupLocationRadioGroup();
  }

  private calculateAllowedModules(userPermissions: UserPermissions) {
    return defaultTo(this.modules, []).filter(module => {
      const isBlackRightOnBlackList = this.pageRightsService.isPageOnBlackList(module.id, userPermissions.blacklist);
      const hasUserCorrectRights = this.pageRightsService.hasUserCorrectRights(module.id, userPermissions.userRights);
      const isSomeOfWhiteRightOnWhiteList = this.pageRightsService.isPageOnWhiteList(module.id, userPermissions.whitelist);

      return !isBlackRightOnBlackList && hasUserCorrectRights && isSomeOfWhiteRightOnWhiteList;
    });
  }

  private addModuleCheckboxes() {
    let first = (this.action === UserAction.POST);
    this.allowedModules.forEach((module, index) => {
      const checked = (first) ? (index === 0) : ((this.user.modules).indexOf(module.id) >= 0);
      const control = new FormControl(checked);
      (this.userForm.controls.modules as FormArray).push(control);
    });
  }

  private addLocationCheckboxes() {
    let adminLocations = generateLocationList(this._locations, this.user.locationList);
    adminLocations.forEach((location) => {
      const control = new FormControl(location);
      (this.userForm.controls.locations as FormArray).push(control);
    });
  }

  private setupLocationRadioGroup() {
    const locations = this.user.locationList;
    if (locations && locations.length) {
      const selectedLocation = locations[0] || { isoCode: '', cities: []};
      const cities = selectedLocation.cities || [];

      if (cities.length && selectedLocation.isoCode) {
        const selectedCity = cities[0] || { city: '', companies: []};
        const companies = selectedCity.companies || [];
        const selectedCompany = companies[0] || '';

        if (selectedCity.city) {
          const locationSelected = this.takeCountryLocationValue(selectedLocation.isoCode, selectedCity.city, selectedCompany);
          this.userForm.controls.location = new FormControl(locationSelected, [Validators.required]);
          this.locationFormControl.updateValueAndValidity();
        }
      }
    }
  }

  private getSelectedLocations(): CountryLocation[] {
    if (this.authorizationService.isSuperUserMaster) {
      // SUM
      if (this.user.userType !== this.USERTYPE.REGULAR) {
        return this.filterSelectedLocations();
      } else {
        return this.findSelectedLocation();
      }
    } else {
      // SU
      return this.findSelectedLocation();
    }
  }

  private findSelectedLocation(): CountryLocation[] {
    const splitLocation = this.locationFormControl.value.split('|') || [];
    const country = splitLocation.length > 0  ? splitLocation[0] : '';
    const city = splitLocation.length > 1  ? splitLocation[1] : '';
    const company = splitLocation.length > 2  ? splitLocation[2] : '';
    const selectedCountry = this.locations.find(c => c.isoCode.toLowerCase() === country);

    if (selectedCountry) {
      if (city && selectedCountry.cities && selectedCountry.cities.length) {
        const selectedCity = selectedCountry.cities.find(c => c.city.toLowerCase() === city);

        if (selectedCity) {
          if (company && selectedCity.companies && selectedCity.companies.length) {
            const selectedCompany = selectedCity.companies.find(c => c.toLowerCase() === company);

            if (selectedCompany) {
              return [{
                ...selectedCountry,
                cities: [{
                  ...selectedCity,
                  companies: [selectedCompany]
                }]
              }];
            }
          }

          return [{
            ...selectedCountry,
            cities: [selectedCity]
          }];
        }
      }

      return [selectedCountry];
    }

    return [];
  }

  private filterSelectedLocations(): CountryLocation[] {
    return findSelectedLocations(this.locationsFormControl.value);
  }

  private handleFormValidationError(): void {
    console.log('FormValidationError');

    let readonly = ['titleCode', 'firstName', 'lastName', 'email', 'phone', 'function'];
    if (!this.authorizationService.isSuperUserMaster) {
      readonly.push('userType');
    }

    let invalid = [];
    Object.keys(this.userForm.controls).forEach(field => {
      if (!(this.userForm.get(field).valid) && readonly.includes(field)) {
        // invalid readonly control
        invalid.push(field);
      }
    });

    if (invalid.length > 0) {
      this.errorMessage = `Empty/Invalid Readonly Controls`;
      this.error = 400;
      console.log(`${this.errorMessage}: ${invalid.join()}`);
    }
  }

  private serverError(httpErrorResponse): void {
    console.log('HTTP error', httpErrorResponse);
    if (httpErrorResponse.error.errors) {
      (httpErrorResponse.error.errors).forEach(error => {
        // console output
        console.log((error.subject || (error.type || 'Error')), (error.message || 'undefined'));

        // handling specific errors
        if ((error.type).toLowerCase() === ('PlanseeEmailAddressAlreadyRegisteredError').toLowerCase()) {
          this.errorType = 'PlanseeEmailAddressAlreadyRegisteredError';
          return false;
        }
      });
    }
    this.error = 400;
  }

  private refreshUsersList(): void {
    this.reloadUsersList.emit(true);
  }
}

/**
 * Min Checked Checkbox Validation
 * @param min
 */

/* tslint:disable */
function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : {required: true};
  };

  return validator;
}
/* tslint:enable */

function minOneLocationSelected(control: AbstractControl): ValidationErrors | null {
  if (findSelectedLocations(control.value).length) {
    return null;
  }

  return { required: true };
}
