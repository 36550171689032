import { NgModule } from '@angular/core';
import { GeneralConditionsOfSaleDeComponent } from './de/general-conditions-of-sale-de.component';
import { GeneralConditionsOfSaleJaComponent } from './ja/general-conditions-of-sale-ja.component';
import { GeneralConditionsOfSaleEnComponent } from './en/general-conditions-of-sale-en.component';
import { GeneralConditionsOfSaleZhComponent } from './zh/general-conditions-of-sale-zh.component';

const components = [
  GeneralConditionsOfSaleDeComponent,
  GeneralConditionsOfSaleJaComponent,
  GeneralConditionsOfSaleEnComponent,
  GeneralConditionsOfSaleZhComponent
];

@NgModule({
  declarations: components,
  exports: components
})
export class GeneralConditionsOfSaleLangModule {
}
