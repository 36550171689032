import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableCellEvent, DataTableParams } from 'ngx-datatable-bootstrap4';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import {
  ChartViewType,
  PForecastWsDTO
} from '../../providers/types/planseeoccaddon';
import { PForecastService } from '../../providers/plansee/p-forecast-service';
import { StripTimeStringPipe } from '../../filters/stripTimeString.pipe';
import { PDataTable } from '../p-data-table';
import { PForecastGraphService } from '../../providers/plansee/p-forecast-graph-service';
import { LoadingService } from '../../providers/plansee/loading-service';
import { PDatePipe } from '../../filters/pDate.pipe';
import { take } from 'rxjs/operators';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

interface CalendarStructure {
  title?: {
    code?: string
    name?: string
    colspan?: number
  }[];
  header?: {
    code?: string
    name?: string
    isCurrent?: boolean
  }[];
  filter?: string;
}

const WEEK_COLUMNS = 8;
const MONTH_COLUMNS = 4;
const DAY_MS = 1000 * 3600 * 24;
const WEEK_MS = DAY_MS * 7;
const MONTH_MS = WEEK_MS * 4;

const MONTHS_BEFORE = 3;
const MONTHS_AFTER = 6;

@Component({
  selector: 'forecasts-list',
  template: require('./forecasts-list.component.html'),
  styles: [require('./forecasts-list.component.scss')]
})
export class ForecastsListComponent extends PDataTable<PForecastWsDTO> {

  @Output() selectRow: EventEmitter<PForecastWsDTO> = new EventEmitter();

  viewType: ChartViewType;
  defaultSort = 'materialNumber-asc';
  items: PForecastWsDTO[] = [];
  calendarStructure: CalendarStructure = {
    title: [],
    header: []
  };

  constructor(
    private forecastService: PForecastService,
              private forecastGraphService: PForecastGraphService,
              private planseeTranslateService: PlanseeTranslateService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService,
              loadingService: LoadingService
  ) {
    super(i18nService, authorizationService, loadingService);

    this.addSubscriptions(
      this.authorizationService.permissionsChanged.subscribe(() => {
        this.pageMinDate = new Date();
        this.pageMaxDate = new Date();
        this.reloadItems({limit: 10});
      })
    );

    this._query.pageSize = 10;
  }

  private datePipe = new PDatePipe();
  private stripTimeString = new StripTimeStringPipe();
  private pageMinDate: Date = new Date();
  private pageMaxDate: Date = new Date();

  reloadItems(params: DataTableParams) {
    if (JSON.stringify(this.latestTableParams) !== JSON.stringify(params)) {
      this.latestTableParams = params;
    }
    this.updateQuery(params);
    if (JSON.stringify(this._query) === JSON.stringify(this.oldQuery)) {
      return;
    }

    this.areFacetsApplied = !!this._query.query;
    this.oldQuery = Object.assign({}, this._query);
    this.forecastService.search(this._query)
      .pipe(take(1))
      .subscribe(searchResult => {
        // Update pagination with the counts comming from BE
        this.refreshPagination(searchResult.pagination);

        // save current pagination information for using it in csv download
        this.forecastService.setPaginationInformations(searchResult.pagination);

        this.viewType = searchResult.historyViewType ? searchResult.historyViewType :
          this.getViewType(searchResult.productHistoryForecastRecords);

        // BE results might contain double data for the same segment or there are different approaches for negatives numbers
        // next method creates an array with no double segments & the current config for negative numbers(config could change if Client asks)
        this.items = this.createItems(searchResult.productHistoryForecastRecords);

        // All the graphs & tables are allowed to navigate back/forward in time until:
        // the maximum/minimum date available for all the entries on the page or
        // the ones defined by the default number of columns desired by the client (ex: 2 months before today and 6 months after today Date)
        this.setMinAndMaxDatesOfAllEntries(this.items);

        this.setStaticDataForAllGraphComponents();

        // Construct table's header (Year, month/ month+week) for the current data displayed
        this.constructDates();

        // send response to search components
        this.searchResultChange.emit(searchResult);
      });
  }

  cellClick(event: DataTableCellEvent<PForecastWsDTO>) {
    const item: {
      dropdown?: boolean,
      graphLoaded?: boolean,
      detailsExpanded?: boolean
    } & PForecastWsDTO = event.row.item;
    if (item.dropdown) {
      delete item.dropdown;
    } else {
      item.dropdown = true;
      item.graphLoaded = true;
    }
    event.row.expanded = !event.row.expanded;
    item.detailsExpanded = event.row.expanded;
    this.selectRow.emit(event.row.item);
  }

  incDate() {
    this.forecastGraphService.incDate();
    this.constructDates();
  }

  decDate() {
    this.forecastGraphService.decDate();
    this.constructDates();
  }

  hasPrevious() {
    return this.forecastGraphService.pos > 0;
  }

  hasNext() {
    if (this.viewType === ChartViewType.WEEKLY) {
      return this.forecastGraphService.pos < (this.forecastGraphService.labels.length - 1) - WEEK_COLUMNS;
    } else {
      return this.forecastGraphService.pos < (this.forecastGraphService.labels.length - 1) - MONTH_COLUMNS;
    }
  }

  private constructDates() {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.generateWeekViewCalendarStructure();
      return;
    }
    this.generateMonthlyViewCalendarStructure();
  }

  private generateWeekViewCalendarStructure() {
    const calendarStructure: CalendarStructure = {
      title: [],
      header: []
    };
    calendarStructure.filter = 'yyyy-ww';
    let firstDateIndex = this.forecastGraphService.pos;
    for (let i = 0; i < WEEK_COLUMNS; i++) {
      if (this.forecastGraphService.dateSegments.length <= firstDateIndex) {
        firstDateIndex++;
        continue;
      }
      const dateObject = this.datePipe.transform(this.forecastGraphService.dateSegments[firstDateIndex], 'full');
      let entry = {
        week: dateObject.week,
        month: this.datePipe.transform(this.forecastGraphService.dateSegments[firstDateIndex], 'MMMM'),
        year: dateObject.year
      };
      calendarStructure.header.push({
        code: `${entry.year}-${entry.week}`,
        name: `<div>${this.i18nService.instant('forecastsList.week', { number: entry.week})}</div>`,
        isCurrent: `${entry.year}-${entry.week}` === this.datePipe.transform(new Date(), calendarStructure.filter, true)
      });
      const index = calendarStructure.title.findIndex(val => val.code === entry.month);
      if (index < 0) {
        calendarStructure.title.push({
          code: entry.month,
          name: this.i18nService.currentLang === 'ja_JP' || this.i18nService.currentLang === 'zh_CN' ?
            `${entry.year}<br/><strong>${this.planseeTranslateService.translateMonth(entry.month)}</strong>` :
            `<strong>${this.planseeTranslateService.translateMonth(entry.month)}</strong><br/>${entry.year}`,
          colspan: 1
        });
      } else {
        calendarStructure.title[index].colspan++;
      }
      firstDateIndex++;
    }
    this.calendarStructure = calendarStructure;
  }

  private generateMonthlyViewCalendarStructure() {
    const calendarStructure: CalendarStructure = {
      title: [],
      header: []
    };
    calendarStructure.filter = 'yyyy-MMM';
    let firstDateIndex = this.forecastGraphService.pos;
    for (let i = 0; i < MONTH_COLUMNS; i++) {
      if (this.forecastGraphService.dateSegments.length <= firstDateIndex) {
        firstDateIndex++;
        continue;
      }
      let entry = {
        month: this.datePipe.transform(this.forecastGraphService.dateSegments[firstDateIndex], 'MMM'),
        year: this.datePipe.transform(this.forecastGraphService.dateSegments[firstDateIndex], 'yyyy')
      };
      calendarStructure.header.push({
        code: `${entry.year}-${entry.month}`,
        name: entry.month,
        isCurrent: `${entry.year}-${entry.month}` === this.datePipe.transform(new Date(), calendarStructure.filter)
      });
      const index = calendarStructure.title.findIndex(val => val.code === entry.year);
      if (index < 0) {
        calendarStructure.title.push({
          code: entry.year,
          name: `<strong>${entry.year}</strong>`,
          colspan: 1
        });
      } else {
        calendarStructure.title[index].colspan++;
      }
      firstDateIndex++;
    }
    this.calendarStructure = calendarStructure;
  }

  private getViewType(items: PForecastWsDTO[]) {
    if (items && items[0] && items[0].segments && items[0].segments.length >= 2) {
      const segments = items[0].segments;
      const firstDateString = this.stripTimeString.transform(segments[0].firstDayOfPeriod);
      const secondDateString = this.stripTimeString.transform(segments[1].firstDayOfPeriod);
      const val = new Date(secondDateString).getTime() - new Date(firstDateString).getTime();
      const days = Math.abs(val) / 86400000;
      if (days > 360) {
        return ChartViewType.YEARLY;
      }
      if (days > 27) {
        return ChartViewType.MONTHLY;
      }
    }
    return ChartViewType.WEEKLY;
  }

  private setMinAndMaxDatesOfAllEntries(items: PForecastWsDTO[]) {
    if (items) {
      for (let item of items) {
        let minDate: Date;
        let maxDate: Date;
        if (item.segments.length === 0) {
          minDate = new Date();
          maxDate = new Date();
        } else {
          minDate = new Date(this.stripTimeString.transform(item.segments[0].firstDayOfPeriod));
          maxDate = new Date(this.stripTimeString.transform(item.segments[item.segments.length - 1].firstDayOfPeriod));
        }
        this.pageMinDate = minDate < this.pageMinDate ? minDate : this.pageMinDate;
        this.pageMaxDate = maxDate > this.pageMaxDate ? maxDate : this.pageMaxDate;
      }
    }
    this.setMonthlyWeeklyMinMaxdates();
  }

  private setMonthlyWeeklyMinMaxdates() {
    const today = new Date();
    let firstShownDate = new Date();
    let lastShownDate = new Date();
    if (this.viewType === ChartViewType.WEEKLY) {
      firstShownDate.setTime(today.getTime() - MONTH_MS);
      lastShownDate.setTime(today.getTime() + MONTH_MS * 1.5);
      this.pageMinDate = this.pageMinDate >= firstShownDate ? firstShownDate : this.pageMinDate;
      this.pageMaxDate = this.pageMaxDate <= lastShownDate ? lastShownDate : this.pageMaxDate;
    } else {
      firstShownDate.setMonth(today.getMonth() - MONTHS_BEFORE);
      lastShownDate.setMonth(today.getMonth() + MONTHS_AFTER);
      this.pageMinDate = this.pageMinDate >= firstShownDate ? firstShownDate : this.pageMinDate;
      this.pageMaxDate = this.pageMaxDate <= lastShownDate ? lastShownDate : this.pageMaxDate;
    }
  }

  private createItems(productHistoryForecastRecords: PForecastWsDTO[] | undefined) {
    let newProductHistoryForecastRecords: any[] = [];
    if (productHistoryForecastRecords) {
      productHistoryForecastRecords.forEach(forecastRecord => {
        if (!forecastRecord.segments || forecastRecord.segments.length === 0) {
          forecastRecord.segments = [];
          newProductHistoryForecastRecords.push(forecastRecord);
          return;
        }
        newProductHistoryForecastRecords.push(this.generateStockLevel(forecastRecord));
      });
    }
    return newProductHistoryForecastRecords;
  }

  /* tslint:disable:cognitive-complexity */
  private generateStockLevel(forecastRecord) {
    let newStockLevel: any = Object.assign({}, forecastRecord);
    newStockLevel.segments = [];
    for (let segment of forecastRecord.segments) {
      let newSegment: any = {};
      newSegment.firstDayOfPeriod = segment.firstDayOfPeriod;
      newSegment.forecastQuantity = segment.forecastQuantity && Number(segment.forecastQuantity) > 0 ?
        Number(segment.forecastQuantity) : 0;
      newSegment.quantityDeviation = segment.quantityDeviation ? Number(segment.quantityDeviation) : 0;
      newSegment.orderedQuantity = segment.orderedQuantity
      && Number(segment.orderedQuantity) > 0 ? Number(segment.orderedQuantity) : 0;
      newSegment.forecastQuantityType = segment.forecastQuantityType;
      newStockLevel.segments.push(newSegment);
    }
    return newStockLevel;
  }

  /* tslint:enable:cognitive-complexity */

  private setStaticDataForAllGraphComponents() {
    this.forecastGraphService.viewType = this.viewType;
    this.forecastGraphService.maxDate = this.pageMaxDate;
    this.forecastGraphService.minDate = this.pageMinDate;
    this.forecastGraphService.monthsBefore = MONTHS_BEFORE;
    // initialize all posible date segments between min and max dates, handle correctly w53 case
    this.forecastGraphService.createAllDateSegments();
    this.forecastGraphService.initPosition();
  }
}
