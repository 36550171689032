import { Component, OnInit, ViewChild } from '@angular/core';
import { PUsersService } from '../../providers/plansee/p-users-service';
import { UserAction } from '../../components/user-form/user-form.component';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { UsersListComponent } from '../../components/users-list/users-list.component';
import { take } from 'rxjs/operators';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { TranslateService } from '@ngx-translate/core';
import { UserPermissions } from '../../rights/user-rights';

@Component({
  selector: 'user-administration-page',
  template: require('./userAdministration.page.html'),
  styles: [require('./userAdministration.page.scss')]
})

export class UserAdministrationPageComponent extends SubscriptionManager implements OnInit {

  @ViewChild(UsersListComponent)
  usersList: UsersListComponent;

  get showUserForm(): boolean {
    return this._showUserForm;
  }

  showDeleteConfirmation = false;
  showConfirmDeletion = false;

  addedUser = {
    fullName: 'Max Mustermann'
  };

  deletedUser = {
    fullName: 'Max Mustermann'
  };

  _user = {
    uid: null,
    email: ''
  };

  userPermissions: UserPermissions = {
    userRights: [],
    whitelist: [],
    blacklist: []
  };

  user = null;
  action = UserAction.POST;

  constructor(
    private userService: PUsersService,
    private authorizationService: AuthorizationService,
    private translateService: TranslateService
  ) {
    super();
  }

  private _showUserForm = false;

  ngOnInit(): void {
    this.addSubscriptions(
      this.authorizationService.permissionsChanged
        .subscribe((permissions) => {
          this.userPermissions = permissions;
        }),
      this.translateService.onLangChange.subscribe(() => {
        this.onReloadUsersList(true);
      })
    );
  }

  addUser() {
    this.onEditUser(null);
  }

  onEditUser(user) {
    if (!this._showUserForm) {
      this.action = (user !== null) ? UserAction.PUT : UserAction.POST;
      this.user = user;
      this.toggleUserFormOverlay();
    }
  }

  toggleUserFormOverlay() {
    this._showUserForm = !this._showUserForm;
    if (!this._showUserForm) {
      this.resetUserFormParameters();
    }
  }

  onReloadUsersList(refresh: boolean) {
    if (refresh) {
      this.usersList.reloadItems(this.usersList._query);
    }
  }

  closeDeletionModal() {
    this.showConfirmDeletion = false;
  }

  deleteUser() {
    this.showConfirmDeletion = false;
    this.userService.deleteUser(this._user.uid)
      .pipe(take(1))
      .subscribe(data => {
        this.showDeleteConfirmation = true;
        this.onReloadUsersList(true);
      }, error => {
        console.log(error);
      });
    setTimeout(() => {
      this.showDeleteConfirmation = false;
    }, 3000);
  }

  onDeleteUser(user) {
    this._user = user;
    this.showConfirmDeletion = true;
    this.deletedUser.fullName = user.firstName + ' ' + user.lastName;
  }

  private resetUserFormParameters(): void {
    this.user = null;
    this.action = UserAction.POST;
  }
}
