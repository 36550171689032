import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatLangCode'
})
export class LangCodeFormatter implements PipeTransform {

    transform(value: string): string {
        const langCodeWithUnderscore = value.indexOf('_') !== -1;

        if (langCodeWithUnderscore) {
            return value.split('_')[0];
        }

        return value;
    }
}
