import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { CityLocation, CountryLocation } from '../providers/types/ycommercewebservices';
import { TreeItem } from '../shared/components/tree/tree-model';
import { checkSelection } from '../shared/utils/country-location-utils';

@Pipe({
  name: 'countryLocationsToTreeItems'
})
export class CountryLocationsToTreeItemsPipe implements PipeTransform {
  transform(locations: CountryLocation[], selectedLocations?: CountryLocation[]): TreeItem[] {
    return (locations || []).map((country) => {
      const countryIsoCode = get(country, 'isoCode', '');
      const cities = get(country, 'cities', []) || [];
      return {
        id: countryIsoCode,
        label: get(country, 'countryName', ''),
        selected: checkSelection(selectedLocations || [], cities.length, countryIsoCode),
        children: this.toTreeItems(selectedLocations || [], countryIsoCode, cities),
        hideChildren: false,
      }  as TreeItem;
    });
  }

  private toTreeItems(selectedLocations: CountryLocation[], country: string, cities?: CityLocation[]): TreeItem[] {
    return (cities || []).map(city => {
      const companies = (get(city, 'companies', []) || []) || [];
      return {
        label: get(city, 'city', ''),
        selected: checkSelection(selectedLocations, companies.length, country, get(city, 'city', '')),
        hideChildren: companies.length <= 1,
        children: companies.map(company => ({
          label: company,
          selected: checkSelection(selectedLocations, 0, country, get(city, 'city', ''), company),
        })),
      };
    });
  }
}
