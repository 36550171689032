import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bookmark-indicator',
  template: require('./bookmark-indicator.component.html'),
  styles: [require('./bookmark-indicator.component.scss')]
})
export class BookmarkIndicatorComponent {
  @Input() selected!: boolean;
  @Output() change = new EventEmitter<boolean>();

  onClick(event: MouseEvent) {
    this.change.emit(!this.selected);
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
  }
}
