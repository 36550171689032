import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { PlanseeTableTab } from './plansee-table-model';

@Pipe({
  name: 'takeSelectedTabId'
})
export class TakeSelectedTabIdPipe implements PipeTransform {
  transform(selectedTabId: string | undefined, allTabs: PlanseeTableTab[] | undefined): string {
    return !!selectedTabId ? selectedTabId : get(allTabs, '0.id', '');
  }
}
