import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addProtocol'
})
export class AddProtocolPipe implements PipeTransform {

  transform(url: string): any {
    if (url.indexOf('http') === -1) {
      return 'http://' + url;
    }
    return url;
  }
}
