import { PB2bUnitWsDTO } from '../../providers/types/planseeoccaddon';
import { CountryLocation } from '../../providers/types/ycommercewebservices';
import { ALL_LOCATION_VALUE } from '../../providers/types/globals.service';

export const COMPANY = 'company';
export const LOCATION = 'location';
export const CUSTOMER_VIEW = 'customerView';
export const AUTH_TOKEN = 'token';

export const takeLocationsFromStorage = (): CountryLocation[] | string => {
  const location = localStorage[LOCATION];

  if (location === ALL_LOCATION_VALUE) {
    return location;
  }

  return location ? JSON.parse(location) : '';
};

export const setLocationsInStorage = (location: CountryLocation[] | string) => {
  if (location) {
    localStorage[LOCATION] = location;
  }
};


export const takeCompanyFromStorage = (): PB2bUnitWsDTO => {
  const company = localStorage[COMPANY];
  return company ? JSON.parse(company) : '';
};

export const takeCompanyIdFromStorage = (): string => {
  const company = takeCompanyFromStorage();
  return company ? company.customerNumber : '';
};

export const setCompanyInStorage = (company: PB2bUnitWsDTO) => {
  if (company) {
    localStorage[COMPANY] = JSON.stringify(company);
  }
};

export const takeCustomerViewFromStorage = (): boolean => {
  const customerView = localStorage[CUSTOMER_VIEW];
  return customerView ? JSON.parse(customerView) : false;
};

export const setCustomerViewInStorage = (customerView: boolean) => {
    localStorage[CUSTOMER_VIEW] = JSON.stringify(customerView);
};
