import {Component} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {POrdersService} from '../../providers/plansee/p-orders-service';
import {FacetWidgetType, PFacetWsDTO, POrderEntrySearchPageWsDTO} from '../../providers/types/planseeoccaddon';
import {Animations} from '../../animations/animations';
import {TranslateService} from '@ngx-translate/core';
import {PSearch} from '../p-search';

@Component({
  selector: 'orders-search',
  template: require('./orders-search.component.html'),
  animations: [
    Animations.expand()
  ]
})
export class OrdersSearchComponent extends PSearch {

  i18n: TranslateService;

  constructor(private ordersService: POrdersService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
    i18nService.stream([
      'ordersSearch.pieces'
    ]).subscribe(i18n => {
      this.i18n = i18n;
    });
  }

  updateSearchResult(searchResult: POrderEntrySearchPageWsDTO) {
    this.state = searchResult;
    // override query since it comes broken out of backend
    this.state.freeTextSearch = this._query && this._query.query ? this.decodeSearchQuery(this._query.query.split(':')[0]) : '';
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }


  search(query: string, emit= false) {
    // remove time from query cuz of :
    query = this.stripTimeFromDateString(query);
    let data = this.checkAndUpdateQuery(query);
    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;
      if (emit) {
        // trigger search in list component
        this.submit();
      } else {
        this.ordersService.search(data).subscribe(val => {
          this.state = val;
          this.state.freeTextSearch = this.decodeSearchQuery(query.split(':')[0]);
          // override query since it comes broken out of backend
          this.state.currentQuery.query.value = query;
        });
      }
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.ordersService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getSliderFacets(): PFacetWsDTO[] {
    return this.state.facets ? this.state.facets.filter(facet => facet.widgetType === FacetWidgetType.SLIDER).map(facet => {
      if (facet.code.toLowerCase().indexOf('quantity') >= 0) {
        facet.unit = this.i18n['ordersSearch.pieces'];
      }
      return facet;
    }) : null;
  }

  getCurrentSelectionDocument() {
    const keys = [
      'order.purchaseOrderNumber',
      'order.customerMaterialNumber',
      'order.description',
      'order.requestedDeliveryDate',
      'order.requestedQuantity',
      'order.confirmedDeliveryDate',
      'order.confirmedQuantity',
      'order.changeHistoryDate',
      'order.changeHistoryQuantity',
      'order.planseeOrderNumber',
      'order.planseeMaterialNumber',
    ];

    return this.ordersService.getListCsvContent(this.createTranslations(keys)).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
    });
  }
}
