import { Pipe, PipeTransform } from '@angular/core';
import { PaginationWsDTO } from '../../providers/types/ycommercewebservices';
import { defaultTo, get } from 'lodash';

@Pipe({
  name: 'takeMainPagination'
})
export class TakeMainPaginationPipe implements PipeTransform {
  transform(mainPagination: PaginationWsDTO | null, secondTableTotalCount: number | undefined): PaginationWsDTO | null {
    if (!mainPagination) {
      return null;
    }

    const totalCount = get(mainPagination, 'totalCount', 0) + defaultTo(secondTableTotalCount, 0);
    return { ...(mainPagination || {}), totalCount };
  }
}
