import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from './providers/plansee/loading-service';
import { PLanguagesService } from './providers/plansee/p-languages-service';
import { PGoogleAnalytics } from './providers/plansee/p-google-analytics';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import '../styles/app.scss';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { SubscriptionManager } from './shared/components/subscriptions-manager';
import { PCookiePanelService } from './providers/plansee/p-cookie-panel.service';
import { PAdobeTmService } from './providers/plansee/p-adobe-tm.service';
import { myPlanseeLang } from '../utils';
import { LanguageWsDTO } from './providers/types/ycommercewebservices';
import { combineLatest } from 'rxjs';
import { Params } from '@angular/router/src/shared';
import { AzureAuthorizationService } from './providers/plansee/azure-authorization.service';

registerLocaleData(localeDe);

@Component({
  selector: 'my-app',
  template: require('./app.component.html')
})
export class AppComponent extends SubscriptionManager implements OnInit {

  loading = false;

  constructor(private i18nService: TranslateService,
              private languagesService: PLanguagesService,
              private loadingService: LoadingService,
              private googleAnalytics: PGoogleAnalytics,
              private cookiePanelService: PCookiePanelService,
              private adobeTmService: PAdobeTmService,
              private router: Router,
              private route: ActivatedRoute,
              private azureAuthorizationService: AzureAuthorizationService,
  ) {
    super();
    this.azureAuthorizationService.init();
  }

  ngOnInit(): void {
    console.log('Application Initialisation');

    this.addSubscriptions(
      combineLatest(
        this.route.queryParams,
        this.languagesService.getLanguages()
      ).subscribe(([params, langs]: [Params, LanguageWsDTO[]]) => {
          const langFromUrl = params.currentLang;
          const defaultLang = langFromUrl || localStorage.getItem(myPlanseeLang) || this.i18nService.getBrowserLang();
          const isoCodes = langs.map(l => l.isocode);
          const defaultIsoCode = isoCodes.find(iso => iso.indexOf(defaultLang) !== -1);

          const currentLang = defaultIsoCode ? defaultIsoCode : 'en_GB';

          if (langFromUrl === currentLang) {
            localStorage.setItem(myPlanseeLang, currentLang);
          }

          this.i18nService.addLangs(isoCodes);
          this.i18nService.use(currentLang);
        })
    );

    this.loadingService.ignorePaths.push(/rights/);
    this.loadingService.ignorePaths.push(/alerts/);
    this.addSubscriptions(
      this.loadingService.loading.subscribe((value) => {
        setTimeout(() => {
          this.loading = value;
        });
      })
    );

    this.cookiePanelService.init();

    this.googleAnalytics.init();
    this.addSubscriptions(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.googleAnalytics.track(event.urlAfterRedirects);
          window.scrollTo(0, 0);
        }
      })
    );

    this.adobeTmService.init();
  }
}
