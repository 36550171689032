import {Component, Input} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import 'chartjs-plugin-annotation';
import {PForecastWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'frame-contract',
  template: require('./frame-contract.component.html'),
  styles: [require('./frame-contract.component.scss')]
})
export class FrameContractComponent {

  get contract() {
    return this._contract;
  }

  @Input()
  set contract(contract: PForecastWsDTO) {
    this._contract = contract;
    this.addChartText();
  }

  get pieChartText() {
    return this._pieChartText;
  }

  @Input()
  set pieChartText(pieChartText: string) {
    this._pieChartText = pieChartText;
  }

  donutData: ChartData = {
    labels: [
      'Red',
      'Blue'
    ],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#FFF',
          '#0973bb'
        ],
        borderColor: 'transparent',
        borderWidth: 0,
        hoverBorderWidth: 0
      }]
  };
  donutOptions: ChartOptions = {
    animation: {
      duration: 0
    },
    cutoutPercentage: 80,
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    }
  };

  constructor() {
  }
  private donutChart: Chart;
  private _pieChartText: string = null;
  private _contract: PForecastWsDTO;

  createDonutChart($event) {
    this.donutChart = $event.chart;
    if (this._contract && this._contract.contractCurrentQuantity >= 0 && this._contract.contractMaximumQuantity >= 0) {
      let remainingValueToFill = this._contract.contractCurrentQuantity > this._contract.contractMaximumQuantity ? 0 :
        this._contract.contractMaximumQuantity - this._contract.contractCurrentQuantity;
      remainingValueToFill = this._contract.contractMaximumQuantity === 0 ? 100 : remainingValueToFill;
      this.donutData.datasets[0].data = [this._contract.contractCurrentQuantity, remainingValueToFill];
      this.donutChart.update();
    }
  }

  private addChartText() {
    this.pieChartText = this._contract.contractMaximumQuantity === 0 ? '0' :
      Math.round((this.contract.contractCurrentQuantity * 100) / this.contract.contractMaximumQuantity) + '';
  }
}
