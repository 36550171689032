import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {ConfigService} from '../../providers/plansee/config-service';
import {PPage} from '../p-page';
import {ActivatedRoute} from '@angular/router';
import {PAuthenticationService} from '../../providers/plansee/p-authentication-service';
import {ResourceOAuthSettings} from '../../settings/resource-oauth-settings';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { CustomNgxOauthService } from '../../providers/plansee/custom-ngx-oauth.service';

@Component({
  selector: 'forgotten-password-page',
  template: require('./forgotten-password.page.html'),
  styles: [require('./forgotten-password.page.scss')]
})
export class ForgottenPasswordPageComponent extends PPage implements OnInit {
  isFailed = false;
  isPlanseeEmail = false;

  get isConfirmed(): boolean {
    return this._isConfirmed;
  }

  get email(): string {
    return this._email;
  }

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private authenticationService: PAuthenticationService,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    private oauthService: CustomNgxOauthService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  private forgottenPasswordForm: FormGroup;
  private _isConfirmed = false;
  private _email = '';
  private readonly PLANSEE_EMAILS = ['plansee.com', 'plansee-group.com', 'ceratizit.com'];

  ngOnInit(): void {
    this.forgottenPasswordForm = new FormGroup({
      email: new FormControl(null, [
        Validators.required, Validators.nullValidator
      ])
    });
    this.fetchToken();
  }

  forgottenPassword(e, isResend: boolean) {
    e.preventDefault();

    if (!this.forgottenPasswordForm.valid && !isResend) {
      this.forgottenPasswordForm.get('email').markAsTouched();
      this.cdr.detectChanges();

      let target = document.querySelector('.form-group-danger');
      target = target ? target.querySelector('.p-input') : null;

      if (target) {
        (target as any).focus();
      }

      return;
    }

    const email = this._email || this.forgottenPasswordForm.value.email;
    const planseeEmailRegExp = new RegExp(`${this.PLANSEE_EMAILS.map(e => `@${e}`).join('|')}`);
    this.isPlanseeEmail = email.match(planseeEmailRegExp);

    if (this.isPlanseeEmail) {
      this.isFailed = false;
      return;
    }

    return this.authenticationService.resetPassword(this._email || this.forgottenPasswordForm.value.email).subscribe(response => {
      this._isConfirmed = true;
      this._email = this.forgottenPasswordForm.value.email;
    }, (error) => {
      this.isFailed = true;
    });
  }

  resendEmail(e) {
    this.forgottenPassword(e, true);
  }

  fetchToken() {
    const config = this.configService.config;

    if (config) {
      const oauthConfig = new ResourceOAuthSettings()
        .buildWithConfig(config);
      this.oauthService.configure(oauthConfig);
    }

    this.oauthService.login();
  }

  isAuthorized() {
    return this.authorizationService.isAuthorized();
  }

  close(event) {
    window.history.back();
  }

  goToLogin() {
    this.authorizationService.logout();
  }
}
