import {Component, Input} from '@angular/core';
import { MediaWsDTO, PQuotationEntry } from '../../providers/types/planseeoccaddon';
import { DownloadDocumentType } from '../../providers/types/download';
import { AppMode } from '../../providers/types/app-mode';

@Component({
  selector: 'quotation-info',
  template: require('./quotation-info.component.html'),
  styles: [require('./quotation-info.component.scss')]
})
export class QuotationInfoComponent {

  @Input()
  entry: PQuotationEntry;
  @Input()
  code: string;
  @Input()
  position: number;
  @Input()
  hotzoneMedia: MediaWsDTO;
  @Input()
  downloadDocumentType = DownloadDocumentType.QUOTATION;

  AppMode = AppMode;
}
