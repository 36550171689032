import { Injectable } from '@angular/core';
import { PService } from './p-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { AuthorizationService } from './authorization-service';
import { QueryRequestWsDTO } from '../types/ycommercewebservices';
import { PQuotationWsDTO, PRequestWsDTO } from '../types/planseeoccaddon';
import { Observable, of } from 'rxjs';
import { defaultTo } from 'lodash';
import { Quotations } from '../../components/quotations-v2/quotations-model';

@Injectable()
export class PlanseeQuotationsService extends PService {
  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO, onlyBookmarks = false): Observable<Quotations> {
    const params = defaultTo(queryParams, { query: ''});

    if (this.paramsIncludeNone(params)) {
      return of({ ...this.emptyDataSyncV2Response, quotations: [] });
    }

    this.currentQueryParams = Object.assign({}, queryParams);
    return this.get<Quotations>(`rfqs/${onlyBookmarks ? 'bookmarkedRFQsV2' : 'searchV2'}?query=${this.encodeLimitsForDateRange(params.query)}`,
      {params: this.stripQueryStringFromQueryObject(params)})
      .map(data => ({...data, items: defaultTo(data.quotations, [])}));
  }

  searchEntries(code: string, queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PQuotationWsDTO> {
    return this.get<PQuotationWsDTO>(`rfqs/rfqV2/${code}`, {params: queryParams});
  }

  updateBookmark(rfqId: string, sapId: string, selected: boolean): Observable<string> {
    return selected ? this.addBookmark(rfqId, sapId) : this.removeBookmark(rfqId, sapId);
  }

  private addBookmark(rfqId: string, sapId: string): Observable<string> {
    return this.get<string>('rfqs/bookmark/add', {params: { rfqId, sapId }});
  }

  private removeBookmark(rfqId: string, sapId: string): Observable<string> {
    return this.get<string>('rfqs/bookmark/remove', {params: { rfqId, sapId }});
  }
}
