import { Pipe, PipeTransform } from '@angular/core';
import { CountryLocation } from '../providers/types/ycommercewebservices';
import { toCountryLocationCheckboxItem } from '../shared/utils/country-location-utils';

@Pipe({
  name: 'toCountryLocationStringList'
})
export class ToCountryLocationStringListPipe implements PipeTransform {
  transform(locations: CountryLocation[]): string[] {
    return toCountryLocationCheckboxItem(locations);
  }
}
