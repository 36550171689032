import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config-service';
import {Observable} from 'rxjs';
import {PForecastingSearchPageWsDTO, PRequestWsDTO, Translation} from '../types/planseeoccaddon'
import {QueryRequestWsDTO, SuggestionListWsDTO} from '../types/ycommercewebservices';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PForecastService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee/catalog/products';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PForecastingSearchPageWsDTO> {
    this.currentQueryParams = Object.assign({}, queryParams);
    return this.get<PForecastingSearchPageWsDTO>(`search/volumeHistory?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
      {params: this.stripQueryStringFromQueryObject(queryParams)});
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`volumeHistory/suggestions`, {params: queryParams});
  }

  getListCsvContent(translations: Translation[], optionalFields?: string): Observable<any> {
    return this.getCsvContent(`volumeHistory/download`, translations, optionalFields);
  }
}
