import {Component} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PShipmentsService} from '../../providers/plansee/p-shipments-service';
import {Animations} from '../../animations/animations';
import {PSearch} from '../p-search';
import {TranslateService} from '@ngx-translate/core';
import {PShipmentEntrySearchPageWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'shipments-search',
  template: require('./shipments-search.component.html'),
  animations: [
    Animations.expand()
  ]
})
export class ShipmentsSearchComponent extends PSearch {

  constructor(private shipmentsService: PShipmentsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
  }

  updateSearchResult(searchResult: PShipmentEntrySearchPageWsDTO) {
    this.state = searchResult;
    // override query since it comes broken out of backend
    this.state.freeTextSearch = this._query && this._query.query ? this.decodeSearchQuery(this._query.query.split(':')[0]) : '';
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }


  search(query: string, emit= false) {
    /*
     Since date filters are with time exntended in BE, we have to remove it
     Otherwise BE cannot parse it (what ???)
     TODO: clarify with BE where is the problem, Solr maybe ???
    */
    query = this.stripTimeFromDateString(query);
    let data = this.checkAndUpdateQuery(query);
    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;
      if (emit) {
        // trigger search in list component
        this.submit();
      } else {
        this.shipmentsService.search(this._query).subscribe(val => {
          this.state = val;
          this.state.freeTextSearch = this.decodeSearchQuery(query.split(':')[0]);
          // override query since it comes broken out of backend
          this.state.currentQuery.query.value = query;
        });
      }
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.shipmentsService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getCurrentSelectionDocument() {
    const keys = [
      'consignment.customerMaterialNumber',
      'consignment.description',
      'consignment.planseeMaterialNumber',
      'consignment.currentStockLevel',
      'consignment.inTransit',
      'consignment.lastShipment',
      'consignment.segments',
      'consignment.deliveryMode',
      'consignment.trackingUrl',
      'consignment.code',
      'consignment.goodsIssueDate',
      'consignment.namedDeliveryDate',
      'consignment.purchaseNumber',
      'consignment.orderCodePosition',
      'consignment.planseeQuantity',
      'consignment.product.code',
    ];

    this.shipmentsService.getListCsvContent(this.createTranslations(keys)).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
    });
  }
}
