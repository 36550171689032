// tslint:disable-next-line: max-line-length
import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy, ContentChild, TemplateRef, Renderer, OnDestroy } from '@angular/core';

@Component({
  selector: 'download-modal',
  template: require('./download-modal.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadModalComponent implements OnDestroy {

  @Output()
  closeOverlayEmitter = new EventEmitter();
  @Output()
  startDownloadEmitter = new EventEmitter();
  @Input()
  title: string;
  @Input()
  btnDisabled = false;

  @Input()
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  constructor(private renderer: Renderer) {
    this.renderer.setElementClass(document.body, 'noscroll', true);
  }

  closeOverlay(event: Event, overlayElement: Element) {
    if (event.target === overlayElement) {
      this.closeOverlayEmitter.emit();
    }
  }

  startDownload() {
    this.startDownloadEmitter.emit();
  }

  close(event: Event) {
    this.closeOverlayEmitter.emit();
  }

  ngOnDestroy(): void {
    this.renderer.setElementClass(document.body, 'noscroll', false);
  }
}
