export const invoicesHeaderTranslationKeys = [
  'invoice.paymentStatus',
  'invoice.invoiceNumber',
  'invoice.poNumber',
  'invoice.paymentDueDate',
  'invoice.invoiceDate',
  'invoice.amount',
  'invoice.dunningNote',
  'invoice.invoiceType',
  'invoice.currency',
];
