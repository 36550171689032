import {Component, OnInit} from '@angular/core';
import {PUserWsDTO} from '../../providers/types/planseeoccaddon';
import {AuthorizationService} from '../../providers/plansee/authorization-service';

@Component({
  selector: 'quotation-page',
  template: require('./quotation-confirmation.page.html'),
  styles: [require('./quotation-confirmation.page.scss')]
})
export class QuotationConfirmationPageComponent implements OnInit {

  user: PUserWsDTO;

  constructor(private authorizationService: AuthorizationService) {
  }

  ngOnInit() {
    this.user = this.authorizationService.getUserProfile();
  }
}
