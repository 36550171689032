import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FacetWidgetType, PDocumentsSearchPageWsDTO, PFacetWsDTO} from '../../providers/types/planseeoccaddon';
import {QueryRequestWsDTO} from '../../providers/types/ycommercewebservices';
import {PDocumentsService} from '../../providers/plansee/p-documents-service';

@Component({
  selector: 'documents-navigation',
  template: require('./documents-navigation.component.html'),
  styles: [require('./documents-navigation.component.scss')]
})
export class DocumentsNavigationComponent implements OnInit {
  @Input()
  set query(val: QueryRequestWsDTO) {
    val = val ? val : {query: ''};
    let data: any = {
      query: val.query
    };
    const langChanged = this.prevLang !== val.lang;
    const prevLangIsSet = !!this.prevLang;
    const currentLangIsSet = !!val.lang;

    if (langChanged && prevLangIsSet && currentLangIsSet) {
      this.resetQuery();
    }

    if (JSON.stringify(data) === JSON.stringify(this.oldQuery) && ((!langChanged && prevLangIsSet) || !currentLangIsSet)) {
      return;
    }

    this.oldQuery = Object.assign({}, data);
    if (currentLangIsSet) {
      this.prevLang = val.lang;
    }
    this.documentsService.search(data).subscribe(searchResult => {
      if (searchResult) {
        if (val.query === '') {
          this.state = searchResult;
        }
        this.selected = searchResult;
      }
    });
  }

  state: PDocumentsSearchPageWsDTO = {};
  selected: PDocumentsSearchPageWsDTO = {};
  @Output()
  queryChange: EventEmitter<QueryRequestWsDTO> = new EventEmitter();

  constructor(private documentsService: PDocumentsService) {
  }

  private oldQuery: any = {};
  private prevLang: string;

  ngOnInit() {
    // initial call empty query to get all facets for folder structure
    this.query = {query: ''};
  }

  click(query) {
    this.queryChange.emit({
      query: decodeURIComponent(query),
      currentPage: 0
    });
  }

  getFolderFacets(): PFacetWsDTO[] {
    return this.state.facets ? this.state.facets.filter(facet => facet.widgetType === FacetWidgetType.FOLDER) : null;
  }

  isActive(name: string, value: string): boolean {
    return this.selected.breadcrumbs &&
      this.selected.breadcrumbs.filter(breadcrumb => breadcrumb.facetName === name && breadcrumb.facetValueName === value).length > 0;
  }


  private resetQuery() {
    this.queryChange.emit({
      query: '',
      currentPage: 0
    });
  }
}
