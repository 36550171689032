import { NgModule } from '@angular/core';
import { SearchTabsComponent } from './search-tabs.component';
import { CommonModule } from '@angular/common';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SearchTabsComponent],
  imports: [CommonModule, NgbTabsetModule, TranslateModule],
  exports: [SearchTabsComponent]
})
export class SearchTabsModule {}
