import { combineLatest, interval as observableInterval, Subscription } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';
import { Component, ElementRef, HostListener, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { PNotificationsService } from '../../providers/plansee/p-notifications-service';
import {
  PModuleType,
  PNotificationsListWsDTO,
  PNotificationWsDTO
} from '../../providers/types/planseeoccaddon';
import { Animations } from '../../animations/animations';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { from as observableFrom } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

const POLL_TIMEOUT = 60000;

@Component({
  selector: 'notifications-dropdown',
  template: require('./notifications-dropdown.component.html'),
  styles: [ require('./notifications-dropdown.component.scss') ],
  animations: [ Animations.expand() ]
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
  collapse = false;
  newNotifications: PNotificationWsDTO[] = [];
  oldNotifications: PNotificationWsDTO[] = [];
  unread = 0;
  @ViewChild('notificationButton')
  notificationButton: ElementRef;
  finishedExpansion = false;
  subscription: Subscription = null;

  constructor(
    private notificationsService: PNotificationsService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private planseeTranslateService: PlanseeTranslateService
  ) {
  }

  ngOnInit() {
    // subscribe to changed company event (choose customer)
    combineLatest(
      this.authorizationService.changedCompany.pipe(startWith('')),
      this.authorizationService.changedLocation.pipe(startWith('')),
      this.planseeTranslateService.onLangChange()
    ).subscribe(() => {
      this.startAlertSubscription();
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    if (this.subscription) {
      // unsubscribe ...
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.collapse = false;
    }
  }

  getNotificationIcon(type: PModuleType) {
    return `/img/navigation-${type.toLocaleLowerCase()}.svg`;
  }

  remove(
    notification: PNotificationWsDTO,
    notifications: PNotificationWsDTO[]
  ) {
    this.notificationsService.delete(notification.code).subscribe(() => {
      this.updateNotificationIndicator(notification);
      notifications.splice(notifications.indexOf(notification), 1);
    });
  }

  view(notification: PNotificationWsDTO, isDocument: boolean) {
    this.notificationsService.put(notification.code, {}).subscribe(() => {
      this.updateNotificationIndicator(notification);
      this.collapse = false;
      if (!isDocument) {
        this.router.navigate([
          `/menu/${this.getModulePath(notification.linkedEntityType)}/${
            encodeURIComponent(notification.linkedEntityId)
          }`
        ]);
      }
    });
  }

  handleExpandDone(event) {
    if (event.toState === 'active') {
      this.finishedExpansion = true;
    } else {
      this.finishedExpansion = false;
    }
  }

  startAlertSubscription() {
    this.unsubscribe();

    // ... and subscribe (again)
    const source =  observableInterval(POLL_TIMEOUT);
    this.subscription = source.pipe(
      startWith(1),
      mergeMap(() => {
        if (this.authorizationService.isAuthorized()) {
          return this.notificationsService.search(
            this.authorizationService.companyId
              ? {
                  companyId: this.authorizationService.companyId
                }
              : null
          );
        }
        return observableFrom([]);
      })
    )
    .subscribe(result => {
      if (result && (result as PNotificationsListWsDTO).alerts) {
        this.newNotifications = (result as PNotificationsListWsDTO).alerts.filter(
          value => !value.isOld
        );
        this.oldNotifications = (result as PNotificationsListWsDTO).alerts.filter(
          value => value.isOld
        );
        this.unread = (result as PNotificationsListWsDTO).alerts.filter(
          value => !value.isRead
        ).length;
      } else {
        this.newNotifications = [];
        this.oldNotifications = [];
        this.unread = 0;
      }

      this.notificationButton.nativeElement.style.setProperty(
        '--unread',
        `'${this.unread}'`
      );
    });
  }

  private getModulePath(module: PModuleType) {
    switch (module) {
      case PModuleType.CLAIMS:
        return 'claims';
      case PModuleType.CONSIGNMENTS:
        return 'consignments';
      case PModuleType.DOCUMENTS:
        return 'documents';
      case PModuleType.FORECASTS:
        return 'forecasts';
      case PModuleType.INVOICES:
        return 'invoices';
      case PModuleType.ORDERS:
        return 'orders';
      case PModuleType.QUOTATIONS:
        return 'rfqs';
      case PModuleType.SHIPMENTS:
        return 'shipments';
    }
  }

  private updateNotificationIndicator(notification: PNotificationWsDTO) {
    if (!notification.isRead) {
      notification.isRead = true;
      this.unread--;
      this.notificationButton.nativeElement.style.setProperty(
        '--unread',
        `'${this.unread}'`
      );
    }
  }
}
