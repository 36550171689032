import {Injectable} from '@angular/core';
import {ConfigService} from './config-service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  PQuotationSearchPageWsDTO,
  PQuotationWsDTO,
  PRequestedQuotationWSDTO,
  PRequestWsDTO,
  PResponse
} from '../types/planseeoccaddon';
import {QueryRequestWsDTO, SuggestionListWsDTO} from '../types/ycommercewebservices';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PQuotationsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO, onlyBookmarks = false): Observable<PQuotationSearchPageWsDTO> {
    this.currentQueryParams = Object.assign({}, queryParams);
    if (onlyBookmarks) {
      return this.get<PQuotationSearchPageWsDTO>(`rfqs/bookmarkedRFQs?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
        {params: this.stripQueryStringFromQueryObject(queryParams)});
    }

    return this.get<PQuotationSearchPageWsDTO>(`rfqs/search?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
      {params: this.stripQueryStringFromQueryObject(queryParams)});
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`rfqs/suggestions`, {params: queryParams});
  }

  searchEntries(code: string, queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PQuotationWsDTO> {
    return this.get<PQuotationWsDTO>(`rfqs/rfq/${code}`, {params: queryParams});
  }

  submitFile(entityID: string, formData?: FormData, queryParams?: PRequestWsDTO): Observable<FormData & PResponse> {
    return this.postAt<FormData>(`documents/rfq/${entityID}`, formData, {params: queryParams});
  }

  submitQuote(newQuote: PRequestedQuotationWSDTO): Observable<PRequestedQuotationWSDTO & PResponse> {
    return this.postAt<PRequestedQuotationWSDTO>(`rfq`, newQuote);
  }

  updateBookmark(rfqId: string, sapId: string, selected: boolean): Observable<string> {
    return selected ? this.addBookmark(rfqId, sapId) : this.removeBookmark(rfqId, sapId);
  }

  private addBookmark(rfqId: string, sapId: string): Observable<string> {
    return this.get<string>('rfqs/bookmark/add', {params: { rfqId, sapId }});
  }

  private removeBookmark(rfqId: string, sapId: string): Observable<string> {
    return this.get<string>('rfqs/bookmark/remove', {params: { rfqId, sapId }});
  }
}
