import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PPage } from '../p-page';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

@Component({
  selector: 'documents-page',
  template: require('./documents.page.html'),
  styles: []
})
export class DocumentsPageComponent extends PPage {

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }
}
