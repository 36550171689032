import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { hideMainScroll, showMainScroll, validateAllFields } from '../../../utils';
import { PTermConditionsService } from '../../providers/plansee/p-term-conditions.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'term-conditions',
  template: require('./term-conditions.component.html'),
  styles: [require('./term-conditions.component.scss')]
})
export class TermConditionsComponent extends SubscriptionManager implements OnInit {
  form: FormGroup;
  isPrivacyPolicy = false;
  updateTCError = false;

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private termConditionsService: PTermConditionsService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      termConditions: [false, Validators.requiredTrue]
    });
  }

  onSubmit(): void {
    this.updateTCError = false;
    if (this.form.invalid) {
      validateAllFields(this.form);
      return;
    } else {
      this.termConditionsService.updateTC()
        .pipe(take(1))
        .subscribe(() => {
          // navigate to login because over there is a logic to open modals for customer and location selection
          this.router.navigate(['/login'], {
            queryParams: {
              returnUrl: '/menu/dashboard'
            }
          });
        }, () => {
          this.updateTCError = true;
        });
    }
  }

  onExit() {
    this.authorizationService.logout(false);
    this.router.navigate(['/login']);
  }

  showPrivacyPolicy() {
    this.isPrivacyPolicy = true;
    hideMainScroll();
  }

  hidePrivacyPolicy() {
    this.isPrivacyPolicy = false;
    showMainScroll();
  }
}
