import {Component} from '@angular/core';
import {ClaimsListStatus} from '../../components/claims-list/claims-list.component';
import {PPage} from '../p-page';
import {ActivatedRoute} from '@angular/router';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

@Component({
  selector: 'claims-page',
  template: require('./claims.page.html'),
  styles: [require('./claims.page.scss')]
})
export class ClaimsPageComponent extends PPage {

  filterInProgress = ClaimsListStatus.IN_PROGRESS;
  filterCompleted = ClaimsListStatus.COMPLETED;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }
}
