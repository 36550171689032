import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { TranslateService } from '@ngx-translate/core';
import { myPlanseeLang } from '../../../utils';

@Component({
  selector: 'header-panel',
  template: require('./header-panel.component.html'),
  styles: [require('./header-panel.component.scss')],
})
export class HeaderPanelComponent extends SubscriptionManager implements OnInit {

  isBlacklisted = false;

  constructor(private authorizationService: AuthorizationService, public translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.authorizationService.permissionsChanged
        .subscribe(permissions => {
          this.authorizeRFQ(permissions.blacklist);
        })
    );
  }

  onLangChange(isocode: string) {
    // All svg flags should have the same name like translation json files
    localStorage.setItem(myPlanseeLang, isocode);
    this.translate.use(isocode);
  }

  private authorizeRFQ(blacklist: string[]) {
    let currentRfqBlacklisted = false;
    if (blacklist) {
      for (let path of blacklist) {
        if (path.includes('rfqs')) {
          currentRfqBlacklisted = true;
        }
      }
    }
    this.isBlacklisted = currentRfqBlacklisted;
  }
}
