import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

export class SubscriptionManager implements OnDestroy {
  private subs: Subscription[] = [];

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  addSubscriptions(...subscriptions: Subscription[]): void {
    this.subs.push(...subscriptions);
  }
}
