import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { myPlanseeLang } from '../../../utils';

@Component({
  selector: 'public-header',
  template: require('./public-header.component.html'),
  styles: [require('./public-header.component.scss')]
})
export class PublicHeaderComponent {

  constructor(public translate: TranslateService) {
  }

  onLangChange(isocode: string) {
    // All svg flags should have the same name like translation json files
    localStorage.setItem(myPlanseeLang, isocode);
    this.translate.use(isocode);
  }
}
