import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PDocumentsService} from '../../providers/plansee/p-documents-service';
import {Animations} from '../../animations/animations';
import {PSearch} from '../p-search';
import {TranslateService} from '@ngx-translate/core';
import {PDocumentsSearchPageWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'documents-search',
  template: require('./documents-search.component.html'),
  styles: [require('./documents-search.component.scss')],
  animations: [
    Animations.expand()
  ]
})
export class DocumentsSearchComponent extends PSearch implements OnInit {

  constructor(private documentsService: PDocumentsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
  }

  updateSearchResult(searchResult: PDocumentsSearchPageWsDTO) {
    this.state = searchResult;
    this.state.freeTextSearch = this._query && this._query.query ?
      this.decodeSearchQuery(this._query.query.split(':')[0]) : '';
    // override query since it comes broken out of backend
    if (!this.state.currentQuery) {
      this.state.currentQuery = {query: {value: ''}};
    }
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }

  search(query: string, emit = false, forceUpdate = false) {
    // remove time from query cuz of :
    query = this.stripTimeFromDateString(query);
    // forceUpdate = true => trigger search
    let data = this.checkAndUpdateQuery(query, forceUpdate);

    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;

      if (emit) {
        // trigger search in list component
        this.submit();
      }
      this.documentsService.search(data).subscribe(val => {
        if (val) {
          this.setState(val, query);
        }
      });
    }
  }

  setState(value, query) {
    if (value) {
      this.state = value;
      this.state.freeTextSearch = query ? this.decodeSearchQuery(query.split(':')[0]) : '';
      // override query since it comes broken out of backend
      if (!this.state.currentQuery) {
        this.state.currentQuery = {query: {value: ''}};
      }
      this.state.currentQuery.query.value = query;
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.documentsService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getCurrentSelectionDocument() {
    const keys = [
      'document.filename',
      'document.size',
      'document.location',
      'document.lastModified',
      'document.type',
    ];

    this.documentsService.getListCsvContent(this.createTranslations(keys)).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
    });
  }

  ngOnInit(): void {
    let searchQuery = '';
    let forceUpdate = false;

    // handle search query by url param?
    if ((window.location.pathname).indexOf('documents') >= 0) {
      let urlsplit = (window.location.pathname).split('/');
      let lastparam = urlsplit[urlsplit.length - 1];
      searchQuery = lastparam !== 'documents' ? lastparam : '' ;
      forceUpdate = true;

      if (searchQuery) {
        // remove searchQuery from url
        window.history.pushState('', '', window.location.pathname.replace(`/${searchQuery}`, ``) );
      }
    }
    // trigger initial search
    this.search(searchQuery, false, forceUpdate);
  }

  /**
   * Extendet version for super.checkAndUpdateQuery()
   * @param query
   * @param forceUpdate
   */
  checkAndUpdateQuery(query: string, forceUpdate = false) {
    let data: any = {
      query: query
    };
    if (!forceUpdate) {
      return super.checkAndUpdateQuery(query);
    }
    return data;
  }
}
