import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {icons} from '../../public/icon/icons';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(value: string): SafeHtml {
    const icon = icons[value];

    return icon ? this.domSanitizer.bypassSecurityTrustHtml(icon) : value;
  }
}
