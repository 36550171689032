import {Pipe, PipeTransform} from '@angular/core';
import {ConfigService} from '../providers/plansee/config-service';

@Pipe({
  name: 'urlToHybris'
})
export class UrlToHybrisPipe implements PipeTransform {

  constructor(private configService: ConfigService) {
  }

  transform(value: URL): string {
    const apiHost = this.configService.config.apiHost;
    if (value && value[0] === '/') {
      return `${apiHost}${value}`;
    }
    return `${apiHost}/${value}`;
  }
}
