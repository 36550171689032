import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryLocationCheckboxItem } from './country-location-checkbox';

@Component({
  selector: 'country-location-checkbox',
  template: require('./country-location-checkbox.component.html'),
  styles: [require('./country-location-checkbox.component.scss')],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CountryLocationCheckboxComponent), multi: true}
  ]
})
export class CountryLocationCheckboxComponent implements ControlValueAccessor {
  value: CountryLocationCheckboxItem | null = null;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
  }

  writeValue(value: CountryLocationCheckboxItem) {
    this.value = value || null;
  }

  onSelect(type: 'city' | 'company', cityIndex: number) {
    if (type === 'city') {
      this.onCitySelect(cityIndex);
    }
    if (type === 'company') {
      this.onCompanySelect(cityIndex);
    }
    this.onChange(this.value);
  }

  private onCitySelect(index: number) {
    const selected = this.value.cities[index].selected;

    if (selected) {
      this.value.cities[index].companies.forEach(company => company.selected = true);
    } else {
      this.value.cities[index].companies.forEach(company => company.selected = false);

    }
  }

  private onCompanySelect(cityIndex: number) {
    const allSelected = this.value.cities[cityIndex].companies.every(company => company.selected);
    this.value.cities[cityIndex].selected = allSelected;
  }
}
