import {Component, Input} from '@angular/core';
import 'chartjs-plugin-annotation';
import {PStockLevelWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'stock-levels',
  template: require('./stock-levels.component.html'),
  styles: [require('./stock-levels.component.scss')]
})
export class StockLevelsComponent {

  @Input()
  item: PStockLevelWsDTO;

  constructor() {
  }
}
