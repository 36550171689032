import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UserWsDTO} from '../../../providers/types/ycommercewebservices';

const HEADQUARTERS_NUMBER = '+43 5672 600 0';

@Component({
  selector: 'dashboard-contact',
  template: require('./dashboard-contact.component.html'),
  styles: [require('./dashboard-contact.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardContactComponent {

  @Input() contacts: UserWsDTO[];

  headquartersNumber = HEADQUARTERS_NUMBER;
}
