import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomDatePipe, NEW_APP_MODE } from './custom-date.pipe';

@Pipe({
  name: 'newAppModeDate'
})
export class NewAppModeDatePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService,
  ) {
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    const regularDate = new CustomDatePipe(this.locale, this.translateService);
    return regularDate.transform(
      value,
      format,
      NEW_APP_MODE,
      this.translateService.currentLang
    );
  }
}
