import { from as observableFrom, Observable, BehaviorSubject } from 'rxjs';

import { mergeMap, map, take } from 'rxjs/operators';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { OAuthEvent } from 'ngx-oauth';
import { PB2bUnitListWsDTO, PB2bUnitWsDTO, PUserWsDTO, PRequestWsDTO } from '../types/planseeoccaddon';
import { UserWsDTO, UserGroupListWsDTO, CountryLocation } from '../types/ycommercewebservices';
import { PUsersService } from './p-users-service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ALL_LOCATION_VALUE, GlobalsService } from '../types/globals.service';
import { UserPermissions } from '../../rights/user-rights';
import { PageRightsService } from '../../rights/page-rights.service';
import { AppModeService } from './app-mode.service';
import {
  AUTH_TOKEN, COMPANY, CUSTOMER_VIEW, LOCATION,
  setCompanyInStorage, setCustomerViewInStorage, setLocationsInStorage,
  takeCompanyFromStorage, takeCompanyIdFromStorage, takeCustomerViewFromStorage, takeLocationsFromStorage,
} from '../../shared/utils/authorization-storage-utils';
import { CustomNgxOauthService } from './custom-ngx-oauth.service';

export interface UserCompanies extends PB2bUnitListWsDTO {
  isAdmin: boolean;
  disabled: boolean;
}

@Injectable()
export class AuthorizationService {

  get selectedLocations(): CountryLocation[] {
    const locationFromStorage = takeLocationsFromStorage();

    if (locationFromStorage) {
      // if 'all locations' checkbox is selected in location modal then only 'all' string is set in cookies
      if (typeof locationFromStorage === 'string' && locationFromStorage.toLowerCase() === ALL_LOCATION_VALUE) {
        return [{
          isoCode: ALL_LOCATION_VALUE,
        }];
      } else {
        return locationFromStorage as CountryLocation[] || [];
      }
    }
    return [];
  }

  set selectedLocations(locations: CountryLocation[]) {
    if (locations && locations.length > 0) {
      // if 'all locations' checkbox is selected in location modal then only 'all' string is set in cookies
      const includesAll = locations.map(l => l.isoCode || '').includes(ALL_LOCATION_VALUE);
      setLocationsInStorage(includesAll ? ALL_LOCATION_VALUE : JSON.stringify(locations));
      // trigger changed location
      this.changedLocation.emit(locations);
    }
    this.refreshRights();
  }

  get noWildcardLocations(): CountryLocation[] {
    // exclude wildcard locations
    return this.globals.getLocationsWithoutWildcards(this.countryLocations);
  }

  get companyId(): string {
    return takeCompanyIdFromStorage();
  }

  get company() {
    return takeCompanyFromStorage();
  }

  get isSuperUser() {
    return this._isSuperUser;
  }

  set isSuperUser(value: boolean) {
    this._isSuperUser = value;
  }

  get isSuperUserMaster() {
    return this._isSuperUserMaster;
  }

  set isSuperUserMaster(value: boolean) {
    this._isSuperUserMaster = value;
  }

  set company(company: PB2bUnitWsDTO) {
    if (company) {
      const profile: UserWsDTO = this.oauthService.profile;
      this.userService.setB2BUnit(profile.uid, company.customerNumber).subscribe();
      setCompanyInStorage(company);
      // trigger changed company
      this.changedCompany.emit(this.companyId);
    }
    this.refreshRights();
  }

  get customerView(): boolean {
    return takeCustomerViewFromStorage();
  }

  set customerView(customerView: boolean) {
    setCustomerViewInStorage(customerView);
  }

  companies: PB2bUnitWsDTO[];
  countryLocations: CountryLocation[];
  SUWithAllLocation = false;

  permissions: UserPermissions = {
    userRights: null,
    whitelist: null,
    blacklist: null
  };
  permissionsChanged: BehaviorSubject<UserPermissions> = new BehaviorSubject(this.permissions);

  useAzureButton = new BehaviorSubject(false);
  wrongPassword = new BehaviorSubject(false);
  wrongId = new BehaviorSubject(false);

  // event emitter for changed company
  @Output() changedCompany: EventEmitter<string> = new EventEmitter();

  // event emitter for changed location
  @Output() changedLocation: EventEmitter<CountryLocation[]> = new EventEmitter();

  constructor(
    private oauthService: CustomNgxOauthService,
    private userService: PUsersService,
    private router: Router,
    private route: ActivatedRoute,
    private globals: GlobalsService,
    private pageRightsService: PageRightsService,
    private appModeService: AppModeService,
  ) {
    window.addEventListener('storage', this.handleStorageEvent);
  }

  private _isSuperUser = false;
  private _isSuperUserMaster = false;


  isCustomerAdmin(withMaster = true) {
    return (!withMaster) ? (this._isSuperUser) : (this._isSuperUser || this._isSuperUserMaster);
  }

  isCustomerAdminWithAllLocation() {
    return (this.isCustomerAdmin()) && this.selectedLocations && this.selectedLocations.length === 1 && this.selectedLocations[0].isoCode === ALL_LOCATION_VALUE;
  }

  isCustomerAdminWithMultipleLocations() {
    // SU/SUM with more than one (non-wildcard) location
    return (this.isCustomerAdmin()) && this.selectedLocations && (this.noWildcardLocations && (!this.isOnlyOneNoWildcardLocation()));
  }

  isAuthorized(): boolean {
    return this.oauthService.status === OAuthEvent.AUTHORIZED && this.oauthService.clientId !== 'plansee-anonymous';
  }

  /* tslint:disable:cognitive-complexity */
  getCompanies(): Observable<UserCompanies> {
    const profile: UserWsDTO = this.oauthService.profile;
    if (profile) {
      return this.userService.getCustomerGroups(profile.uid).pipe(
        map(value => {
          if (!value.userGroups) {
            throw new Error('User group should exist');
          }
          const userGroups = value.userGroups;

          this.isSuperUser = userGroups.some(group => group.uid === 'myplansee_superuser');
          this.isSuperUserMaster = userGroups.some(group => group.uid === 'myplansee_superuser_master');

          return {
            disabled: !userGroups.some(group => group.uid === 'myplansee_whitelist_frontend'),
            isAdmin: userGroups.some(group => group.uid === 'myplansee_employee'),
            isSuperUser: this.isSuperUser,
            isSuperUserMaster: this.isSuperUserMaster
          };
        }),
        mergeMap(value => {
          // get the b2b units since customergroups endpoint is not the truth
          if (value.isAdmin && !value.disabled) {
            return this.userService.getB2BUnits(profile.uid).pipe(map(returnData => {
              const b2bUnits = Array.isArray(returnData.b2bUnits) ? returnData.b2bUnits : [];

              this.companies = b2bUnits;

              return {
                disabled: false,
                isAdmin: true,
                b2bUnits
              };
            }));
          } else if (value.isSuperUser || value.isSuperUserMaster) {
            return this.userService.getCustomerLocations(profile.uid).pipe(map(returnData => {
              this.countryLocations = returnData;

              return {
                disabled: false,
                isAdmin: false,
                isSuperUser: value.isSuperUser,
                isSuperUserMaster: value.isSuperUserMaster,
                locations: this.countryLocations
              };
            }));
          } else {
            return observableFrom([value]);
          }
        }));
    } else {
      return observableFrom([{
        disabled: true,
        isAdmin: false
      }]);
    }
  }

  getUserProfile(): PUserWsDTO {
    return this.oauthService.profile;
  }

  logout(redirectToLogin = true) {
    this.oauthService.logout();
    delete localStorage[COMPANY];
    delete localStorage[CUSTOMER_VIEW];
    delete localStorage[LOCATION];
    sessionStorage.clear();
    delete this.companies;
    delete this.permissions;
    this.appModeService.removeCurrentMode();
    if (redirectToLogin === true) {
      this.router.navigate(['/login']);
    }
  }

  handleStorageEvent(event) {
    if (event.key !== 'token') {
      return;
    }

    if ((event.newValue !== event.oldValue) && (event.newValue === '' || event.newValue === null)) {
      // need to clean out the token in IE11, since
      // it has been removed;
      delete localStorage[COMPANY];
      delete localStorage[LOCATION];
      delete localStorage[CUSTOMER_VIEW];
      delete localStorage[AUTH_TOKEN];
      this.appModeService.removeCurrentMode();
      sessionStorage.clear();
    }
  }

  loadPermissions(): Observable<UserPermissions> {
    return this.getRights()
      .pipe(
        map(ugl => {
          if (ugl && ugl.userGroups && ugl.userGroups.length) {
            const userGroups = ugl.userGroups;
            const blacklist = userGroups
              .filter(value => value.uid.startsWith('myplansee_blacklist'))
              .map(value => value.uid.replace(/myplansee_blacklist_/, ''));
            const userRights = userGroups
              .filter(value => !value.uid.startsWith('myplansee_blacklist') && !value.uid.startsWith('myplansee_whitelist'))
              .map(value => value.uid);
            const whitelist = userGroups
              .filter(value => value.uid.startsWith('myplansee_whitelist'))
              .map(value => value.uid.replace(/myplansee_whitelist_/, ''));

            return {
              blacklist,
              userRights,
              whitelist,
            };
          }

          return {
            blacklist: [],
            userRights: [],
            whitelist: [],
          };
        })
      );
  }

  checkAccessToCurrentPage(permissions: UserPermissions, route: ActivatedRouteSnapshot, currentUrl: string): boolean {
    const blackRights = permissions.blacklist;
    const userRights = permissions.userRights;
    const whiteRights = permissions.whitelist;
    const isUrlOnBlackList = blackRights.filter(value => currentUrl.includes(value)).length !== 0;
    let isBlackRightOnBlackList = false;
    let hasUserCorrectRights = true;
    let isSomeOfWhiteRightOnWhiteList = true;
    const key = route.data && route.data.pageRightsKey ? route.data.pageRightsKey : '';

    if (key) {
      isBlackRightOnBlackList = this.pageRightsService.isPageOnBlackList(key, blackRights);
      hasUserCorrectRights = this.pageRightsService.hasUserCorrectRights(key, userRights);
      isSomeOfWhiteRightOnWhiteList = this.pageRightsService.isPageOnWhiteList(key, whiteRights);
    }

    const hasAccess = !isUrlOnBlackList && !isBlackRightOnBlackList && hasUserCorrectRights && isSomeOfWhiteRightOnWhiteList;

    if (!hasAccess) {
      this.router.navigate(['/', 'error']);
    }

    return hasAccess;
  }

  isOnlyOneNoWildcardLocation(): boolean {
    return this.isOnlyOneLocation(this.noWildcardLocations || []);
  }

  private isOnlyOneLocation(locations: CountryLocation[]): boolean {
    if (locations && locations.length === 1) {
      const cities = (locations[0] || {cities: []}).cities || [];
      const isOnlyOneCity = cities.length === 1;

      if (isOnlyOneCity) {
        const companies = (cities[0] || {companies: []}).companies || [];
        const isMaxOneCompany = companies && companies.length <= 1;
        return isMaxOneCompany;
      }
    }

    return false;
  }

  private refreshRights() {
    this.loadPermissions()
      .pipe(take(1))
      .subscribe((permissions) => {
        this.permissions = permissions;
        this.permissionsChanged.next(this.permissions);
        this.checkAccessToCurrentPage(permissions, this.route.snapshot, this.router.url);
      });
  }

  private getRights(): Observable<UserGroupListWsDTO> {
    const profile: UserWsDTO = this.oauthService.profile;
    const params: PRequestWsDTO = {};
    if (this.companyId) {
      params.companyId = this.companyId;
      params.customerView = this.customerView;
    }
    if (profile && this.isAuthorized()) {
      return this.userService.getCompanyGroups(profile.uid, params);
    } else {
      return observableFrom([]);
    }
  }
}
