import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataTableParams} from 'ngx-datatable-bootstrap4';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import { PDocumentsWsDTO } from '../../providers/types/planseeoccaddon';
import {BreadcrumbWsDTO} from '../../providers/types/ycommercewebservices';
import {PDocumentsService} from '../../providers/plansee/p-documents-service';
import {PDataTable} from '../p-data-table';
import {LoadingService} from '../../providers/plansee/loading-service';
import { defaultTo } from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'documents-list',
  template: require('./documents-list.component.html'),
  styles: [require('./documents-list.component.scss')]
})
export class DocumentsListComponent extends PDataTable<PDocumentsWsDTO> {

  defaultSort = 'lastModified-desc';
  isListView = true;
  items: PDocumentsWsDTO[] = [];
  breadcrumbs: BreadcrumbWsDTO[];

  constructor(protected documentsService: PDocumentsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService,
              loadingService: LoadingService) {
    super(i18nService, authorizationService, loadingService);
  }

  reloadItems(params: DataTableParams, forceReload = false) {
    this.updateQuery(params);
    if (JSON.stringify(this._query) === JSON.stringify(this.oldQuery) && !forceReload) {
      return;
    }
    this.areFacetsApplied = !!this._query.query;
    this.oldQuery = Object.assign({}, this._query);
    this.documentsService.search(this._query, this.onlyBookmarks).subscribe(searchResult => {
      if (searchResult) {
        this.items = searchResult.documents;

        // Update pagination with the counts comming from BE
        this.refreshPagination(searchResult.pagination);

        // save current pagination information for using it in csv download
        this.documentsService.setPaginationInformations(searchResult.pagination);
        this.breadcrumbs = searchResult.breadcrumbs;
      }
    });
  }

  getFileExtension(filename) {
    const extension = filename.split('.').pop();
    return extension && ['pdf', 'xls', 'doc'].indexOf(extension.toLowerCase()) > -1 ? extension : 'other';
  }

  hasPreview(file) {
    const acceptedImageFormats = ['.png', '.jpg', '.jpeg', '.pdf'];
    const fileExtension = file && file.fileName ? '.' + file.fileName.split('.').pop() : '';
    return file && (acceptedImageFormats.includes(fileExtension.toLowerCase()) ||
      (file.format && acceptedImageFormats.includes('.' + file.format.toLowerCase())));
  }

  onBookmarkChange(selected: boolean, entry: PDocumentsWsDTO) {
    const documentId = defaultTo(entry.id, '');
    if (documentId) {
      this.documentsService.updateBookmark(documentId, selected)
        .pipe(take(1))
        .subscribe(() => this.reloadItems(this._query, true));
    }
  }
}
