import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http/src/response';
import { PService } from './p-service';
import { take } from 'rxjs/operators';
import { downloadBlob } from '../../../utils';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { AuthorizationService } from './authorization-service';
import { DownloadDocuments } from '../types/download';

@Injectable()
export class DownloadService extends PService {
  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  downloadBlob(blob: Blob, name?: string) {
    downloadBlob(blob, name);
  }

  downloadMultipleDocumentsV2(documents: DownloadDocuments) {
    this.takeMultipleDocuments(documents, true)
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  downloadMultipleDocuments(codes: string[]) {
    this.takeMultipleDocuments(codes)
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  takeMultipleDocuments(body: string[] | DownloadDocuments, version2 = false): Observable<HttpResponse<Blob>> {
    const requestOptions: any = {
      headers: {
        'Content-Type': 'application/json',
      },
      observe: 'response',
      responseType: 'blob' as 'json'
    };
    return this.postAt<HttpResponse<Blob>>(`documents/${version2 ? 'downloadMultipleDocumentsV2' : 'downloadMultipleDocuments'}`, body, requestOptions);
  }
}
