import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PSelectValue } from './p-select-value';

@Component({
  selector: 'p-select',
  template: require('./p-select.component.html'),
  styles: [require('./p-select.component.scss')],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: PSelectComponent, multi: true}]
})
export class PSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('select') select: NgSelectComponent;

  @Input() items: PSelectValue[];
  @Input() name = '';
  @Input() danger = false;
  @Input() placeholder = '';
  @Input() translateLabels = true;
  @Input() appendToBody = true;

  value: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    const test = this.value ? this.value : null;
    if (obj !== test && obj) {
      this.value = obj;
    }
  }

  setOption(option: PSelectValue) {
    this.value = option.value;
    this.onChange(this.value);
  }

  private onScroll = (event: any) => {
    const select = this.select;
    if (select && select.isOpen && this.appendToBody) {
      const target = event.target;
      const isScrollingInScrollHost = (target.className as string).indexOf('ng-dropdown-panel-items') > -1;

      if (isScrollingInScrollHost) {
        return;
      }

      const topPositionOfScroll = target.scrollTop;
      const bottomPositionOfScroll = target.offsetHeight + target.scrollTop;
      const offsetOfParentOfSelect = select.element.offsetParent as any;
      const bottomPositionOfSelect = offsetOfParentOfSelect.offsetTop + offsetOfParentOfSelect.offsetHeight;

      if (topPositionOfScroll > bottomPositionOfSelect || bottomPositionOfScroll < bottomPositionOfSelect) {
        select.close(); // hide dropdown if select is outside of viewport
      } else {
        select.dropdownPanel.adjustPosition();
      }
    }
  };
}
