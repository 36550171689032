import { NgModule } from '@angular/core';
import { CountryLocationCheckboxComponent } from './country-location-checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [CountryLocationCheckboxComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  exports: [CountryLocationCheckboxComponent]
})
export class CountryLocationCheckboxModule {}
