import {Injectable} from '@angular/core';
import {PService} from './p-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config-service';
import {AuthorizationService} from './authorization-service';
import {Observable} from 'rxjs';
import {Faqs} from '../types/faq';

@Injectable()
export class PLoginFaqsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee/widgets/faq';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  getFaqs(): Observable<Faqs> {
    return this.query<Faqs>();
  }
}
