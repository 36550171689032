import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { Animations } from '../../animations/animations';
import { ConfigService } from '../../providers/plansee/config-service';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'profile-dropdown',
  template: require('./profile-dropdown.component.html'),
  styles: [require('./profile-dropdown.component.scss')],
  animations: [
    Animations.expand()
  ]
})
export class ProfileDropdownComponent extends SubscriptionManager implements OnInit {

  collapse = false;
  customImage: string | URL;
  currentAppMode: AppMode;

  constructor(
    public authorizationService: AuthorizationService,
    private configService: ConfigService,
    private appModeService: AppModeService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    // get the profile and check if there is custom profile image;
    // if there is one, load it instead of default image;
    const profile = this.authorizationService.getUserProfile();
    const profileImage = profile.profileImage;
    if (profileImage && profileImage.url) {
      const apiHost = this.configService.config.apiHost;
      this.customImage = apiHost ? `${apiHost}/${profileImage.url}` : profileImage.url;
    }
    this.addSubscriptions(
      this.appModeService.currentMode$.subscribe(mode => this.currentAppMode = mode)
    );
  }

  setCustomerView(customerView: boolean) {
    this.authorizationService.customerView = customerView;
  }

  setAppMode(isNewMode: boolean) {
    if (isNewMode) {
      this.appModeService.setNewMode();
    } else {
      this.appModeService.setOldMode();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.collapse = false;
    }
  }

  modalCustomerSelector(content) {
    this.modalService.open(content, {size: 'sm'}).result.then((result) => {
      this.authorizationService.company = result;
    }, (reason) => {
    });
  }

  modalLocationSelector(content) {
    this.modalService.open(content, {size: 'sm'}).result.then((result) => {
      this.authorizationService.selectedLocations = result;
    }, (reason) => {
    });
  }

  logout() {
    this.authorizationService.logout();
  }
}
