import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthEvent } from 'ngx-oauth';
import { AuthorizationService, UserCompanies } from '../../providers/plansee/authorization-service';
import { ConfigService } from '../../providers/plansee/config-service';
import { PB2bUnitWsDTO } from '../../providers/types/planseeoccaddon';
import { ResourceOAuthSettings } from '../../settings/resource-oauth-settings';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { switchMap, take } from 'rxjs/operators';
import { PAuthenticationService } from '../../providers/plansee/p-authentication-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { CountryLocation } from '../../providers/types/ycommercewebservices';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PWidgetsService } from '../../providers/plansee/p-widgets-service';
import { hideMainScroll, showMainScroll } from '../../../utils';
import { PLoginFaqsService } from '../../providers/plansee/p-login-faqs-service';
import { combineLatest } from 'rxjs';
import { Faq } from '../../providers/types/faq';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CustomNgxOauthService } from '../../providers/plansee/custom-ngx-oauth.service';

@Component({
  selector: 'login-page',
  template: require('./login.page.html'),
  styles: [require('./login.page.scss')]
})
export class LoginPageComponent extends SubscriptionManager implements OnInit {
  set wrongId(wrongId: boolean) {
    this._wrongId = wrongId;
  }

  set wrongPassword(wrongPassword: boolean) {
    this._wrongPassword = wrongPassword;
  }

  get wrongPassword() {
    return this._wrongPassword;
  }

  get wrongId() {
    return this._wrongId;
  }

  returnUrl: string;
  isDenied = false;
  isDisabled = false;
  debugMode = false;
  isAdmin = false;
  noB2BAssigned = false;
  noB2BAssignedError = false;
  isSuperUser = false;
  noLocationAssigned = false;
  noLocationAssignedError = false;
  useAzureButtonError = false;
  faqs: Faq[] = [];
  images = [];
  safeVideo: SafeResourceUrl;

  isVideoModal = false;
  currentAppMode: AppMode;

  constructor(
    public oauthService: CustomNgxOauthService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    public authorizationService: AuthorizationService,
    private cdr: ChangeDetectorRef,
    private authenticationService: PAuthenticationService,
    private planseeTranslateService: PlanseeTranslateService,
    public carouselConfig: NgbCarouselConfig,
    private domSanitizer: DomSanitizer,
    private widgetsService: PWidgetsService,
    private appModeService: AppModeService,
    private loginFaqsService: PLoginFaqsService,
    private oidcSecurityService: OidcSecurityService
  ) {
    super();
  }

  private _wrongId: boolean;
  private _wrongPassword: boolean;
  // tslint:disable-next-line: max-line-length
  private bannerImages = ['business-data-24-7.jpg', 'global-overview-locations.jpg', 'receive-notifications.jpg', 'tracking-information.jpg', 'updates-delivery-times.jpg'];

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.subscribeToOAuthService();

    this.addSubscriptions(
      this.authorizationService.wrongId.subscribe(value => {
        this.wrongId = value;
        this.triggerFocus();
      }),
      this.authorizationService.wrongPassword.subscribe(value => {
        this.wrongPassword = value;
        this.triggerFocus();
      }),
      this.authorizationService.useAzureButton.subscribe(value => {
        this.useAzureButtonError = value;
        this.triggerFocus();
      }),
      this.appModeService.currentMode$.subscribe(mode => this.currentAppMode = mode)
    );

    this.subscribeToLanguageService();

    this.carouselConfig.interval = 3000;
    this.carouselConfig.keyboard = false;
    this.carouselConfig.pauseOnHover = false;
    this.carouselConfig.showNavigationArrows = false;
    this.carouselConfig.showNavigationIndicators = false;
    this.carouselConfig.wrap = true;
  }

  showVideoModal() {
    this.isVideoModal = true;
    hideMainScroll();
  }

  hideVideoModal() {
    this.isVideoModal = false;
    showMainScroll();
  }

  setAppMode(isNewMode: boolean) {
    if (isNewMode) {
      this.appModeService.setNewMode();
    } else {
      this.appModeService.setOldMode();
    }
  }

  setCompany(company: PB2bUnitWsDTO) {
    this.authorizationService.company = company;
    this.router.navigate([this.returnUrl]);
  }

  setLocation(locations: CountryLocation[]) {
    this.authorizationService.selectedLocations = locations;
    this.router.navigate([this.returnUrl]);
  }

  setCustomerView(customerView: boolean) {
    this.authorizationService.customerView = customerView;
  }

  login() {
    this.resetAuthorizationErrors();
    this.oauthService.login();
  }

  loginViaAzure() {
    this.resetAuthorizationErrors();
    this.oidcSecurityService.authorize();
  }

  isNotAuthorized() {
    if (this.isSuperUser) {
      if (this.noLocationAssigned) {
        return true;
      }
      return !this.authorizationService.isAuthorized() ||
        (this.authorizationService.countryLocations.length && (!this.authorizationService.selectedLocations || !this.authorizationService.selectedLocations.length));
    }

    if (this.noB2BAssigned) {
      return true;
    }

    return !this.authorizationService.isAuthorized() ||
      (this.authorizationService.companies && this.authorizationService.companies.length && this.authorizationService.companyId === '');
  }

  dismissNoB2bAssignedError() {
    this.noB2BAssignedError = false;
  }

  dismissNoLocationAssignedError() {
    this.noLocationAssignedError = false;
  }

  dismissUseAzureButtonErrorError() {
    this.authorizationService.useAzureButton.next(false);
  }

  private resetAuthorizationErrors() {
    this.oauthService.closeAzureError();
    this.authorizationService.wrongId.next(false);
    this.authorizationService.wrongPassword.next(false);
    this.authorizationService.useAzureButton.next(false);
  }

  private setDefaultFlags() {
    this.isDenied = false;
    this.isDisabled = false;
    this.debugMode = false;
    this.isAdmin = false;
    this.noB2BAssigned = false;
    this.noB2BAssignedError = false;
    this.isSuperUser = false;
    this.noLocationAssigned = false;
    this.noLocationAssignedError = false;
    this.useAzureButtonError = false;
  }

  private configureOAuthService(): void {
    const config = this.configService.config;
    if (config) {
      const oauthConfig = new ResourceOAuthSettings()
        .buildWithConfig(config, true);
      this.oauthService.configure(oauthConfig);
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  private subscribeToOAuthService(): void {
    this.configureOAuthService();
    this.addSubscriptions(
      this.oauthService.onStatus.subscribe(status => {
        switch (status) {
          case OAuthEvent.PROFILE: {
            const profile = this.oauthService.profile;

            // check if there is a token under profile: param firstLoginToken or changePasswordToken
            // when it is not empty, navigate to setPassword
            // else check if user has accepted terms and conditions
            // when no, navigate to termConditions page
            // else proceed as usual
            const shouldChangePassword = profile && !profile.internalAccount &&
              (profile.firstLoginToken || profile.changePasswordToken);
            const shouldAcceptTermsAndConditions = profile && !profile.termsAndConditionsAccepted;

            if (shouldChangePassword) {
              const isFirstLoginToken = profile.firstLoginToken;
              const token = encodeURIComponent(profile.firstLoginToken || profile.changePasswordToken);

              this.authorizationService.logout(false);

              if (isFirstLoginToken) {
                this.router.navigate([`/setPassword/${token}`]);
              } else {
                this.router.navigate([`/updatePassword/${token}`]);
              }
            } else if (shouldAcceptTermsAndConditions) {
              this.router.navigate(['/termConditions']);
            } else {
              this.authenticationService.sendCountingRequest(profile.uid)
                .pipe(take(1))
                .subscribe();
              this.downloadCompanies();
            }
            break;
          }
          case OAuthEvent.LOGOUT: {
            console.log('Logout');
            break;
          }
          case OAuthEvent.DENIED: {
            this.isDenied = true;
            this.isDisabled = false;
            break;
          }
        }
      })
    );
  }

  private downloadCompanies() {
    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .pipe(
          switchMap(() => this.authorizationService.getCompanies())
        )
        .subscribe(userCompanies => this.handleProfile(userCompanies))
    );
  }

  private subscribeToLanguageService(): void {
    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .pipe(
          switchMap((langChange) => {
            this.images = this.bannerImages.map((i) => `/img/login/${langChange.lang || 'en_GB'}/${i}`);
            return combineLatest([
              this.widgetsService.getVideo(),
              this.loginFaqsService.getFaqs()
            ]);
          })
        )
        .subscribe(([video, faqData]) => {
          this.safeVideo = this.domSanitizer.bypassSecurityTrustResourceUrl(video.videoUrl);
          this.faqs = faqData ? faqData.faq : [];
        })
    );
  }

  // tslint:disable-next-line: cognitive-complexity
  private handleProfile(userCompanies: UserCompanies) {
    this.setDefaultFlags();
    if (userCompanies.disabled) {
      this.isDisabled = true;
      this.isDenied = false;
      return;
    }
    this.noB2BAssigned = false;
    this.noB2BAssignedError = false;
    this.noLocationAssigned = false;
    this.noLocationAssignedError = false;
    this.authorizationService.companies = userCompanies.b2bUnits;
    this.authorizationService.countryLocations = userCompanies.locations;

    if (
      (!this.authorizationService.selectedLocations || !this.authorizationService.selectedLocations.length)
      && (userCompanies.isSuperUser || userCompanies.isSuperUserMaster)
    ) {
      this.isSuperUser = true;
      if (this.authorizationService.countryLocations && (this.authorizationService.countryLocations).length > 0) {
        // check if there is only one location
        if (this.authorizationService.isOnlyOneNoWildcardLocation()) {
          this.setLocation([this.authorizationService.noWildcardLocations[0]]);
          // check if there is only all added
        } else if ((this.authorizationService.countryLocations).length === 1) {
          this.setLocation([this.authorizationService.countryLocations[0]]);
        }
      } else {
        this.noLocationAssigned = true;
        this.noLocationAssignedError = true;
      }
    } else if (!this.authorizationService.companyId && userCompanies.isAdmin) {
      this.isAdmin = true;
      if (userCompanies.b2bUnits.length === 0) {
        this.noB2BAssigned = true;
        this.noB2BAssignedError = true;
      }
    } else {
      this.setCompany(null);
    }
  }

  private triggerFocus() {
    this.cdr.detectChanges();
    let target = document.querySelector('.wrong-input');
    if (target) {
      (target as any).focus();
    }
  }
}
