
import {tap} from 'rxjs/operators';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LoadingService {
  loading = new BehaviorSubject(false);
  ignorePaths: RegExp[] = [];
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(loadingService: LoadingService) {
    this.loadingService = loadingService;
  }

  private loadingService: LoadingService;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isPathExcepted(req)) {
      return next.handle(req);
    }
    this.loadingService.loading.next(true);
    return next.handle(req).pipe(tap((response) => {
      if (response instanceof HttpResponse) {
        this.loadingService.loading.next(false);
      }
    }, (error) => {
      this.loadingService.loading.next(false);
    }));
  }

  isPathExcepted(req: HttpRequest<any>) {
    for (let ignorePath of this.loadingService.ignorePaths) {
      try {
        if (req.url.match(ignorePath)) {
          return true;
        }
      } catch (err) {
      }
    }
    return false;
  }
}
