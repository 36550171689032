import { NgModule } from '@angular/core';
import { DataProtectionDeComponent } from './de/data-protection-de.component';
import { DataProtectionEnComponent } from './en/data-protection-en.component';
import { DataProtectionJaComponent } from './ja/data-protection-ja.component';
import { DataProtectionZhComponent } from './zh/data-protection-zh.component';

const components = [
  DataProtectionDeComponent,
  DataProtectionEnComponent,
  DataProtectionJaComponent,
  DataProtectionZhComponent
];

@NgModule({
  declarations: components,
  exports: components
}) export class DataProtectionLangModule { }
