import { CountryLocation } from '../../providers/types/ycommercewebservices';

export const findSelectedLocations = (locations?: any[]): CountryLocation[] => {
  return (locations || []).map(country => {
    const selectedCities = country.cities.map(city => {
      const someCompaniesSelected = city.companies && city.companies.length > 1 && city.companies.some(company => company.selected);
      if (someCompaniesSelected) {
        const companies = city.companies.filter(company => company.selected);
        return {
          city: city.city,
          companies: companies.map(company => company.name)
        };
      } else if (city.selected) {
        return {
          city: city.city,
          companies: []
        };
      }
      return null;
    }).filter(Boolean);

    if (selectedCities.length) {
      return {
        isoCode: country.isoCode,
        cities: selectedCities.map(city => {
          return {
            city: city.city,
            companies: city.companies
          };
        })
      };
    }

    return null;
  }).filter(Boolean);
};
