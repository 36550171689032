import { Component, Input } from '@angular/core';
import { PProductWsDTO } from '../../providers/types/planseeoccaddon';
import { UserLocation } from '../../providers/types/ycommercewebservices';

@Component({
  selector: 'product-info',
  template: require('./product-info.component.html'),
  styles: [require('./product-info.component.scss')]
})
export class ProductInfoComponent {

  @Input()
  product: PProductWsDTO;
  @Input()
  location: UserLocation;
}


