export enum PageRights {
  DASHBOARD = 'dashboard',
  RFQS = 'rfqs',
  PRODUCTS = 'products',
  ORDERS = 'orders',
  SHIPMENTS = 'shipments',
  FORECASTS = 'forecasts',
  CONSIGNMENTS = 'consignments',
  INVOICES = 'invoices',
  CLAIMS = 'claims',
  HOT_ZONE_CONFIGURATOR = 'hotzone',
  DOCUMENTS = 'documents',
  SHOP = 'shop',
}

export interface PageRightItem {
  blackRight?: string;
  userRights?: string[];
  whiteRights?: string[];
}
