import { Component, OnInit } from '@angular/core';
import { Animations } from '../../animations/animations';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { DataTableParams, DataTableTranslations, defaultTranslations } from 'ngx-datatable-bootstrap4';
import { Observable } from 'rxjs';
import { Shipments } from './shipments-model';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { TranslateService } from '@ngx-translate/core';
import {
  PlanseeTableDateFilter,
  PlanseeTableSearchEvent,
} from '../plansee-table/plansee-table-model';
import { PlanseeShipmentsService } from '../../providers/plansee/plansee-shipments.service';
import { defaultTo, get } from 'lodash';
import { DownloadService } from '../../providers/plansee/download.service';
import { PRequestWsDTO } from '../../providers/types/planseeoccaddon';
import { PlanseeDataTable } from '../plansee-data-table';
import { take } from 'rxjs/operators';
import { PlanseeDocumentsService } from '../../providers/plansee/plansee-documents.service';
import { DownloadDocumentType } from '../../providers/types/download';

@Component({
  selector: 'shipments',
  template: require('./shipments.component.html'),
  styles: [require('./shipments.component.scss')],
  animations: [Animations.expand()]
})
export class ShipmentsComponent extends PlanseeDataTable implements OnInit {

  dataTableParams: DataTableParams = {};
  dateFilters: PlanseeTableDateFilter[];
  filterDeliveryDateParamKey = 'del';

  i18n: DataTableTranslations = defaultTranslations;

  constructor(
    private planseeShipmentsService: PlanseeShipmentsService,
    private planseeTranslateService: PlanseeTranslateService,
    private planseeDocumentsService: PlanseeDocumentsService,
    private downloadService: DownloadService,
    private translateService: TranslateService,
  ) {
      super();
    }

    ngOnInit() {
      this.addSubscriptions(
        this.planseeTranslateService.onLangChange()
          .subscribe(() => {
            this.dateFilters = [
              {
                key: this.filterDeliveryDateParamKey,
                label: this.translateService.instant('shipmentsList.deliveryDateFilter')
              }
            ];
          })
      );
    }

  searchFn = (event: PlanseeTableSearchEvent): Observable<Shipments> => {
    return this.planseeShipmentsService.search(this.prepareParams(event));
  }

  onReloadItems(options: DataTableParams) {
    this.dataTableParams = options;
  }

  onDownload(event: PlanseeTableSearchEvent) {
    const keys = [
      'consignment.customerMaterialNumber',
      'consignment.description',
      'consignment.planseeMaterialNumber',
      'consignment.currentStockLevel',
      'consignment.inTransit',
      'consignment.lastShipment',
      'consignment.segments',
      'consignment.deliveryMode',
      'consignment.trackingUrl',
      'consignment.code',
      'consignment.goodsIssueDate',
      'consignment.namedDeliveryDate',
      'consignment.deliveryDate',
      'consignment.purchaseNumber',
      'consignment.orderCodePosition',
      'consignment.planseeQuantity',
      'consignment.product.code',
    ];

    this.planseeShipmentsService
      .getListCsvContent(this.planseeTranslateService.createTranslations(keys), this.prepareParams(event, get(event, 'totalResults', 0)))
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadService.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  downloadFile(itemNumber: string, documentIdOrDunningNoteId: string, documentType: DownloadDocumentType) {
    this.planseeDocumentsService.takeFileAsBlob(itemNumber, documentIdOrDunningNoteId, documentType);
  }

  private prepareParams(event?: PlanseeTableSearchEvent, totalResults?: number): QueryRequestWsDTO & PRequestWsDTO {
    const params = this.prepareBasicParams(event);
    const dates = get(event, 'filters.dates', {}) || {};

    const deliveryDates = dates[this.filterDeliveryDateParamKey];
    if (deliveryDates) {
      params[`${this.filterDeliveryDateParamKey}From`] = defaultTo(deliveryDates.from, '');
      params[`${this.filterDeliveryDateParamKey}To`] = defaultTo(deliveryDates.until, '');
    }
    params['pageSize'] = totalResults || get(event, 'dataTableParams.limit', '');

    return params;
  }
}
