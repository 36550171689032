import {NgModule} from '@angular/core';
import {OccModule} from './occ/occ.module';
import {PlanseeModule} from './plansee/plansee.module';

@NgModule({
  imports: [
    OccModule,
    PlanseeModule
  ],
})
export class ProvidersModule {
}
