import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FiltersModule} from '../../filters/filters.module';
import {NgbCarouselModule, NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import {PlanseeCarouselComponent} from './plansee-carousel.component';

@NgModule({
  imports: [
    CommonModule,
    FiltersModule,
    NgbCarouselModule
  ],
  declarations: [
    PlanseeCarouselComponent
  ],
  exports: [
    PlanseeCarouselComponent,
    NgbSlide
  ]
})
export class PlanseeCarouselModule {}
