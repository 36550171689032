import { PlanseeTable } from '../plansee-table/plansee-table-model';
import {
  DeliveryModeWsDTO,
  PointOfServiceWsDTO,
  PriceWsDTO,
  ProductWsDTO,
} from '../../providers/types/ycommercewebservices';
import {
  PConsignmentEntryWsDTO, PConsignmentScheduleWsDTO,
  POrderMinimalDataWsDTO,
  PShipmentStatus,
} from '../../providers/types/planseeoccaddon';

export interface Orders extends PlanseeTable {
  orderEntries?: OrderEntry[];
}

export interface OrderEntry {
  basePrice?: PriceWsDTO;
  consignmentEntries?: PConsignmentEntryWsDTO[];
  consignmentSchedules?: PConsignmentScheduleWsDTO[];
  customPONumber?: string;
  deliveryMode?: DeliveryModeWsDTO;
  deliveryPointOfService?: PointOfServiceWsDTO;
  entryNumber?: number;
  hasChangedShipmentInfo?: boolean;
  hasSchedules?: boolean;
  hasShipments?: boolean;
  order?: POrderMinimalDataWsDTO;
  planseeQuantity?: number;
  product?: ProductWsDTO;
  quantity?: number;
  rejected?: boolean;
  requestedDate?: string;
  shipmentInfoExpanded?: boolean;
  shipmentStatus?: PShipmentStatus;
  totalPrice?: PriceWsDTO;
  unit?: string;
  unitCode?: string;
  updateable?: boolean;
}

export enum OrderStatus {
  OPEN = 'OPEN',
  PARTIALLY_DONE = 'PARTIALLY_DONE',
  DONE = 'DONE',
}
