import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {Animations} from '../../animations/animations';
import {PQuotationsService} from '../../providers/plansee/p-quotations-service';
import {PSearch} from '../p-search';
import {TranslateService} from '@ngx-translate/core';
import {PSearchPageWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'quotations-search',
  template: require('./quotations-search.component.html'),
  animations: [
    Animations.expand()
  ]
})
export class QuotationsSearchComponent extends PSearch  implements OnInit {

  constructor(private quotationsService: PQuotationsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
  }

  search(query: string, emit= false) {
    // remove time from query cuz of :
    query = this.stripTimeFromDateString(query);
    let data = this.checkAndUpdateQuery(query);
    if (data) {
      this.quotationsService.search(data).subscribe(val => {
        if (val) {
          this.state = val;
          this.state.freeTextSearch = this.decodeSearchQuery(query.split(':')[0]);
          // override query since it comes broken out of backend
          this.state.currentQuery.query.value = query;
          if (emit) {
            // also update list after suggestion search
            this.submit();
          }
        }
      });
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.quotationsService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  updateSearchResult(searchResult: PSearchPageWsDTO) {
  }

  ngOnInit(): void {
    this.search('', false);
  }
}
