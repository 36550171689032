import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortModules'
})
export class SortModulesPipe implements PipeTransform {
  transform(modules: string[], sortedModules?: string[]): string[] {
    if (sortedModules && sortedModules.length) {
      const filteredItems = sortedModules.filter(m => modules.indexOf(m) !== -1);
      return Array.from(new Set([...filteredItems, ...modules]));
    }

    return modules;
  }
}
