import { Component, Input } from '@angular/core';

export const TODAY_PLACEHOLDER = 'today';

@Component({
  selector: 'fixed-empty-table',
  template: require('./fixed-empty-table.component.html'),
  styles: [require('./fixed-empty-table.component.scss')]
})
export class FixedEmptyTableComponent {
  @Input()
  elements: string[] = [];
  @Input()
  height: string = '3rem';
  @Input()
  marginBottom = 0; // px
  @Input()
  paddingLeft = 0; // px
  // if you want to addToday class then this property has to be true and elements needs to contains one element with string value === TODAY_PLACEHOLDER
  @Input()
  addTodayClass = true;

  TODAY_PLACEHOLDER = TODAY_PLACEHOLDER;
}
