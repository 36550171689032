
import {tap} from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthorizationService} from './authorization-service';
import {Router} from '@angular/router';
import {CustomNgxOauthService} from './custom-ngx-oauth.service';

const LOCATION_HEADER = 'Plansee-Location';

enum ERROR_MESSAGE {
  WRONG_ID = 'wrong_id',
  BAD_CREDENTIALS_OLD = 'bad credentials',
  BAD_CREDENTIALS = 'bad_credentials',
  AZURE_LOGIN_REQUIRED = 'azure_login_required',
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(injector: Injector, private router: Router) {
    setTimeout(() => {
      this.oauth = injector.get(CustomNgxOauthService);
      this.authorizationService = injector.get(AuthorizationService);
    });
  }
  private oauth: CustomNgxOauthService;
  private authorizationService: AuthorizationService;

  // intercepting requests and force performing logout or redirect

   /* tslint:disable:cognitive-complexity */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oauth && this.oauth.token && this.oauth.token.access_token) {
      if (!localStorage['token']) {
        this.authorizationService.logout();
      } else {
        const location = localStorage['location'] || '';
        const headers = {
          Authorization: `Bearer ${this.oauth.token.access_token}`,
        };

        if (location) {
          headers[LOCATION_HEADER] = encodeURIComponent(location);
        }

        req = req.clone({
          setHeaders: headers,
        });
      }
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse && this.authorizationService) {
        // err.error can be either Object or Array
        const errorMessage = (err.error.error_description
          || err.error.errors && err.error.errors[0] && err.error.errors[0].message
          || err.error.errors && err.error.errors[0] && err.error.errors[0].type
          || '').toLowerCase();
        if (err.status === 401 || err.status === 403) {
          this.authorizationService.logout();
          return;
        }
        if (err.status === 400 && errorMessage === ERROR_MESSAGE.WRONG_ID) {
          this.authorizationService.wrongId.next(true);
          return;
        }
        if (err.status === 400 && (errorMessage === ERROR_MESSAGE.BAD_CREDENTIALS || errorMessage === ERROR_MESSAGE.BAD_CREDENTIALS_OLD)) {
          this.authorizationService.wrongPassword.next(true);
          return;
        }
        if (err.status === 400 && errorMessage === ERROR_MESSAGE.AZURE_LOGIN_REQUIRED) {
          this.authorizationService.useAzureButton.next(true);
          return;
        }
        if (err.status === 404) {
          this.router.navigate(['/error'], { skipLocationChange: true });
        }
      }
    }));
  }
  /* tslint:enable:cognitive-complexity */
}
