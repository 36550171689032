import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { DataTableParams, DataTableRow } from 'ngx-datatable-bootstrap4';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PInvoicesService} from '../../providers/plansee/p-invoices-service';
import {PInvoiceWsDTO, PPaymentStatus} from '../../providers/types/planseeoccaddon';
import {PDataTable} from '../p-data-table';
import {LoadingService} from '../../providers/plansee/loading-service';
import {InvoiceType} from '../../providers/types/invoices/invoice-type.enum';
import {take} from 'rxjs/operators';
import { defaultTo } from 'lodash';
import { DataTable } from 'ngx-datatable-bootstrap4';
import { Animations } from '../../animations/animations';
import { DownloadType } from '../../providers/types/download';
import { InvoiceSet } from '../../providers/types/invoices/invoice-set';

@Component({
  selector: 'invoices-list',
  template: require('./invoices-list.component.html'),
  styles: [require('./invoices-list.component.scss')],
  animations: [Animations.expand()]
})
export class InvoicesListComponent extends PDataTable<PInvoiceWsDTO> {
  @ViewChild('invoicesTable') table!: DataTable<PInvoiceWsDTO>;

  @Output() selectedItemsChange = new EventEmitter<PInvoiceWsDTO[]>();
  @Output() download = new EventEmitter<DownloadType>();

  // enum
  invoiceType = InvoiceType;
  DownloadType = DownloadType;

  _invoice: any;

  defaultSort = 'overdue-desc,paymentDueDate-desc';
  items: PInvoiceWsDTO[] = [];
  selectedDocumentsIds = new InvoiceSet();

  //
  isSearching: boolean;
  dropdownShow = false;

  constructor(
    private invoicesService: PInvoicesService,
    authorizationService: AuthorizationService,
    i18nService: TranslateService,
    loadingService: LoadingService
  ) {
    super(i18nService, authorizationService, loadingService);
  }

  onRowClick(row: DataTableRow<PInvoiceWsDTO>) {
    this.table.onRowSelectChanged(row);
    const invoice = defaultTo(row.item, {});
    this.addOrRemoveItemFromSelectedItems(invoice);
  }

  reloadItems(params: DataTableParams) {
    try {
      this.updateQuery(params);

      if (!this._query && !this._query.query) {
        this._query = { query: '' };
      }

      this.areFacetsApplied = !!this._query.query;
      this.oldQuery = Object.assign({}, this._query);

      this._query.query += this._query.query.indexOf(':') >= 0 ? '' : ':';
      let compiledQuery = this._query.query;
      this.isSearching = !(compiledQuery.indexOf(':') === 0 && compiledQuery.length <= 1);
      this._query.query = compiledQuery;

      if (JSON.stringify(this._query) === JSON.stringify(this.oldQuery)) {
        // TODO find better solution to prevent 2nd call
        // Problem: following uncommented line prevents changing page
        // return;
      }

      this.invoicesService.search(this._query)
        .pipe(take(1))
        .subscribe(searchResult => {
          // check if this._query points to current invoiceCategory
          let _queryCode = searchResult.currentQuery.query.value.split(':invoiceCategory:')[1] || '';
          _queryCode = _queryCode.indexOf(':') < 0 ? _queryCode : _queryCode.split(':')[0];

          this.items = searchResult.invoices;

          // Update pagination with the counts comming from BE
          this.refreshPagination(searchResult.pagination);

          // save current pagination information for using it in csv download
          this.invoicesService.setPaginationInformations(searchResult.pagination);

          // update checkboxes from current page. SetTimeout because we need to wait until table will be rendered
          this.clearSelectedItemsList();

          // send response to search components
          this.searchResultChange.emit(searchResult);
        });
    } catch (error) {
    }
  }

  getPaymentStatus(item: PInvoiceWsDTO): boolean {
    return item.paymentStatus === PPaymentStatus.PAID || new Date(item.paymentDueDate).getTime() - new Date().getTime() > 0;
  }

  private addOrRemoveItemFromSelectedItems(invoice: PInvoiceWsDTO) {
    if (invoice && this.selectedDocumentsIds.has(invoice)) {
      this.removeItemFromSelectedItems(invoice);
    } else if (invoice && !this.selectedDocumentsIds.has(invoice)) {
      this.addItemToSelectedItems(invoice);
    }
  }

  private addItemToSelectedItems(invoice: PInvoiceWsDTO) {
    if (invoice) {
      this.selectedDocumentsIds.add(invoice);
      this.selectedItemsChange.emit(this.selectedDocumentsIds.toArray());
    }
  }

  private removeItemFromSelectedItems(invoice: PInvoiceWsDTO) {
    if (invoice) {
      this.selectedDocumentsIds.delete(invoice);
      this.selectedItemsChange.emit(this.selectedDocumentsIds.toArray());
    }
  }

  private clearSelectedItemsList() {
    this.selectedDocumentsIds.clear();
    this.selectedItemsChange.emit([]);
  }
}
