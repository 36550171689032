import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stripTimeString'
})
export class StripTimeStringPipe implements PipeTransform {

  transform(value: Date | string): string {
    const regx = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/;
    return value.toString().match(regx)[0];
  }
}
