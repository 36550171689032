import {Pipe, PipeTransform} from '@angular/core';
import {PDatePipe} from './pDate.pipe';
import {PStockLevelWsDTO} from '../providers/types/planseeoccaddon';

@Pipe({name: 'isNormalConsignment'})
export class IsNormalConsignmentPipe implements PipeTransform {
    private datePipe = new PDatePipe();
    transform(stockLevel: PStockLevelWsDTO, code, filter) {
      if (!Array.isArray(stockLevel.segments)) {
          return '';
      }
      let segment = stockLevel.segments.find(entry =>
        this.datePipe.transform(entry.entryUpdateDate, filter, true) === code);
      return segment && segment.entryStockLevel && segment.entryStockLevel >= stockLevel.minimumStockLevel &&
        segment.entryStockLevel <= stockLevel.maximumStockLevel;
    }
}
