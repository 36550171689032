import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { isNil } from 'lodash';

@Injectable()
export class MenuGuard implements CanActivateChild {

  constructor(
    private authorizationService: AuthorizationService,
  ) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.authorizationService.permissionsChanged
      .pipe(
        switchMap(permissions => {
          if (isNil(permissions.whitelist) || isNil(permissions.userRights) || isNil(permissions.blacklist)) {
            return this.authorizationService.loadPermissions();
          }

          return of(permissions);
        }),
        map((permissions) => {
          return this.authorizationService.checkAccessToCurrentPage(permissions, route, state.url);
        })
      );
  }
}
