import {
  FacetWidgetType,
  PFacetWsDTO,
  PRequestWsDTO,
  PSearchPageWsDTO,
  Translation,
} from '../providers/types/planseeoccaddon';
import { ElementRef, EventEmitter, HostListener, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { StripHtmlPipe } from '../filters/stripHtml.pipe';
import { CountryLocation, QueryRequestWsDTO } from '../providers/types/ycommercewebservices'
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../providers/plansee/authorization-service';
import { SubscriptionManager } from '../shared/components/subscriptions-manager';
import { SearchTab } from '../shared/components/search-tabs/search-tab';
import { downloadBlob } from '../../utils';
import { isEqual } from 'lodash';

export abstract class PSearch<T extends PSearchPageWsDTO = PSearchPageWsDTO> extends SubscriptionManager {
  protected _query: QueryRequestWsDTO & PRequestWsDTO = {query: ''};

  @ViewChildren('suggestionItem')
  set suggestionItems(content: QueryList<ElementRef>) {
    this._suggestionItems = content;
    this.suggestionItemsArray = this._suggestionItems.toArray();
  }

  @Input()
  set query(val: QueryRequestWsDTO) {
    if (val) {
      this.search(val.query, false);
      this.prevLang = val.lang;
      this.prevLocations = this.authorizationService.selectedLocations;
    }
  }

  @Input()
  set searchResult(searchResult: T) {
    // get response from the list component
    if (searchResult) {
      this.updateSearchResult(searchResult);
    }
  }

  @Input() set selectedTab(tab: SearchTab) {
    if (tab) {
      this._selectedSearchTab = tab;
      this.selectedTabKey = tab.facetCode;
      this.search(this._query.query || '', false);
    }
  }

  @Input() onlyBookmarks!: boolean;

  get selectedTab(): SearchTab {
    return this._selectedSearchTab;
  }

  @Output() filterChanged = new EventEmitter<void>();
  @Output() queryChange: EventEmitter<QueryRequestWsDTO> = new EventEmitter();
  @Output() onlyBookmarksChange: EventEmitter<boolean> = new EventEmitter();

  state: T | PSearchPageWsDTO = {
    freeTextSearch: '',
    currentQuery: {
      query: {
        value: ''
      }
    }
  };
  collapse = false;
  suggestions: string[];
  showBreadcrumbs = false;
  stripHtml = new StripHtmlPipe();
  downloadContent = '';
  oldQuery: QueryRequestWsDTO & PRequestWsDTO = {};
  prevLang: string;
  prevLocations: CountryLocation[];
  selectedTabKey!: string;

  constructor(
    protected i18nService: TranslateService,
    protected authorizationService: AuthorizationService
  ) {
    super();
  }

  private suggestionItemsArray: any[];
  private highlightedSuggestionIndex = -1;
  private _suggestionItems: QueryList<ElementRef>;
  private _selectedSearchTab: SearchTab;

  abstract updateSearchResult(searchResult: T);

  /* tslint:disable */
  abstract search(query: string, emit?: boolean);

  /* tslint:enable */

  abstract suggestion(query: string);

  @HostListener('window:keydown', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.collapse = false;
      this.highlightedSuggestionIndex = -1;
      delete this.suggestions;
    } else if (event.keyCode === 13) {
      this.collapse = false;
      if (this.highlightedSuggestionIndex >= 0) {
        this.state.freeTextSearch = this.suggestions[this.highlightedSuggestionIndex];
        this.highlightedSuggestionIndex = -1;
      }
      this.setSuggestion(this.state.freeTextSearch);
    } else if (event.keyCode === 40 && this.suggestions && this.suggestions.length > 0) {
      event.preventDefault();
      if (this.highlightedSuggestionIndex < this.suggestions.length - 1) {
        this.highlightedSuggestionIndex = this.highlightedSuggestionIndex + 1;
        this.suggestionItemsArray[this.highlightedSuggestionIndex].nativeElement.focus();
      } else if (this.highlightedSuggestionIndex === this.suggestions.length - 1) {
        this.highlightedSuggestionIndex = 0;
        this.suggestionItemsArray[0].nativeElement.focus();
      }
    } else if (event.keyCode === 38 && this.suggestions && this.suggestions.length > 0) {
      event.preventDefault();
      if (this.highlightedSuggestionIndex > 0) {
        this.highlightedSuggestionIndex = this.highlightedSuggestionIndex - 1;
        this.suggestionItemsArray[this.highlightedSuggestionIndex].nativeElement.focus();
      } else {
        this.highlightedSuggestionIndex = this.suggestions.length - 1;
        this.suggestionItemsArray[this.highlightedSuggestionIndex].nativeElement.focus();
      }
    }
  }

  onApply() {
    this.filterChanged.emit();
    this.submit();
  }

  submit() {
    this.queryChange.emit({
      query: this.state.currentQuery.query.value
    });
  }

  setSuggestion(suggestion, emit = false) {
    if (emit || !suggestion) {
      this.filterChanged.emit();
    }
    this.collapse = false;
    this.state.freeTextSearch = this.stripHtml.transform(suggestion);
    if (this.state.freeTextSearch.length < 3 && this.state.freeTextSearch !== '') {
      return;
    }
    let query = this.state.currentQuery.query.value;
    if (query.split(':')[0] === '') {
      query = this.encodeSearchQuery(this.state.freeTextSearch) + query;
    } else {
      query = query.replace(query.split(':')[0], this.encodeSearchQuery(this.state.freeTextSearch));
    }
    this.search(query, true);
    delete this.suggestions;
  }

  onInput(event: KeyboardEvent) {
    if (this.state.freeTextSearch === '') {
      this.setSuggestion('');
    } else if (this.state.freeTextSearch.length > 2) {
      this.suggestion(this.state.freeTextSearch);
    }
  }

  hasFacets() {
    return this.state.breadcrumbs && this.state.breadcrumbs.length > 0;
  }

  getDefaultFacets(): PFacetWsDTO[] {
    return this.state.facets ? this.state.facets.filter(
      facet => facet.widgetType === FacetWidgetType.SINGLE_SELECT || facet.widgetType === FacetWidgetType.MULTI_SELECT
    ) : null;
  }

  getSliderFacets(): PFacetWsDTO[] {
    return this.state.facets ? this.state.facets.filter(facet => facet.widgetType === FacetWidgetType.SLIDER) : null;
  }

  getDateFacets(): PFacetWsDTO[] {
    return this.state.facets ? this.state.facets.filter(facet => facet.widgetType === FacetWidgetType.DATE) : null;
  }

  createTranslations(keys: string[]): Translation[] {
    return keys.reduce((acc: Translation[], curr) => {
      if (curr) {
        acc[curr] = this.i18nService.instant(`download.${curr.toLowerCase()}`) || curr;
      }
      return acc;
    }, {} as Translation[]);
  }

  downloadBlob(blob: Blob, name?: string) {
    downloadBlob(blob, name);
  }

  checkAndUpdateQuery(query: string) {
    let data: any = {
      query: query
    };
    const langChanged = this.i18nService.currentLang !== this.prevLang;
    const locationChanged = !isEqual(this.prevLocations, this.authorizationService.selectedLocations);
    if (JSON.stringify(data) === JSON.stringify(this.oldQuery) && !langChanged && !locationChanged) {
      return null;
    }
    this.oldQuery = Object.assign({}, data);
    return data;
  }

  stripTimeFromDateString(dateString: string) {
    const regx = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/;
    const timeRegx = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T[0-9]{2}(:|%3A)[0-9]{2}(:|%3A)[0-9]{2,3}Z?/;
    let match = dateString.match(timeRegx) ? dateString.match(timeRegx)[0] : null;
    while (match) {
      dateString = dateString.replace(match, match.match(regx)[0]);
      match = dateString.match(timeRegx) ? dateString.match(timeRegx)[0] : null;
    }
    return dateString;
  }

  onSearchIconClick() {
    this.search(this.state.freeTextSearch, true);
  }

  protected encodeSearchQuery(query: string): string {
    if (query) {
      return encodeURIComponent(
        query
        .replace(/:/g, '\\:')
      );
    }

    return '';
  }

  protected decodeSearchQuery(query: string): string {
    if (query) {
      return decodeURIComponent(query)
        .replace(/\\:/g, ':');
    }

    return '';
  }

  protected formatDownloadDates(fromDate: string, toDate: string, today: string): {fromDate: string, toDate: string} {
    return {
      fromDate: fromDate || toDate || today,
      toDate: toDate || fromDate || today,
    };
  }
}
