import { NgModule } from '@angular/core';
import { TreeComponent } from './tree.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FiltersModule } from '../../../filters/filters.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TreeComponent],
  imports: [CommonModule, FormsModule, FiltersModule, TranslateModule],
  exports: [TreeComponent]
})
export class TreeModule {}
