import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap/tabset/tabset';
import { SearchTab } from './search-tab';
import { SubscriptionManager } from '../subscriptions-manager';
import { AuthorizationService } from '../../../providers/plansee/authorization-service';

@Component({
  selector: 'search-tabs',
  template: require('./search-tabs.component.html'),
  styles: [require('./search-tabs.component.scss')]
})
export class SearchTabsComponent extends SubscriptionManager implements OnInit {
  @Input() searchTabs: SearchTab[] = [];
  @Input() selectedSearchTab: SearchTab;
  @Output() selectedTabChange = new EventEmitter<SearchTab | null>();

  constructor(private authorizationService: AuthorizationService) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.authorizationService.permissionsChanged
        .subscribe(() => {
          setTimeout(() => this.selectedTabChange.emit(null));
        })
    );
  }

  onTabChange(event: NgbTabChangeEvent) {
    const selectedTab = this.searchTabs.find(t => t.code === event.nextId);
    if (selectedTab) {
      this.selectedTabChange.emit(selectedTab);
    }
  }
}
