import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CityLocation, CountryLocation } from '../../providers/types/ycommercewebservices';
import { TreeItem } from '../../shared/components/tree/tree-model';
import { defaultTo, get } from 'lodash';

@Component({
  selector: 'location-selection',
  template: require('./location-selection.component.html'),
  styles: [require('./location-selection.component.scss')]
})
export class LocationSelectionComponent {


  @Input()
  set selected(selected: CountryLocation[]) {
    // decouple
    if (selected && !this._selected) {
      this._selected = selected;
      this.tempSelected = selected;
    }
  }

  get selected(): CountryLocation[] {
    return this._selected;
  }

  @Input() newLocations: CountryLocation[];

  @Input()
  showDismiss = false;
  @Output()
  submit: EventEmitter<CountryLocation[]> = new EventEmitter();
  @Output()
  dismiss: EventEmitter<void> = new EventEmitter();

  tempSelected: CountryLocation[];
  private _selected: CountryLocation[];

  onLocationsChange(items: TreeItem[]) {
    this.tempSelected = this.toCountryLocations(items);
  }

  private toCountryLocations(items: TreeItem[]): CountryLocation[] {
    return (items || []).filter(i => i && (i.selected || i.indeterminate)).map(i => {
      return {
        isoCode: i.id,
        countryName: i.label,
        cities: this.toCityLocations(get(i, 'children', []))
      };
    });
  }

  private toCityLocations(items?: TreeItem[]): CityLocation[] | null {
    const elements = defaultTo(items, []);
    if (elements.length) {
      return elements.filter(e => e && (e.selected || e.indeterminate)).map(e => {
        return {
          city: e.label,
          companies: (get(e, 'children', []) || []).filter(c => c && c.selected).map(c => c.label)
        };
      });
    }

    return null;
  }
}
