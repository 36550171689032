import {Injectable} from '@angular/core';
import {ConfigService} from './config-service';
import {HttpClient} from '@angular/common/http';
import {QueryRequestWsDTO} from '../types/ycommercewebservices';
import {PNotificationsListWsDTO, PRequestWsDTO} from '../types/planseeoccaddon';
import {Observable} from 'rxjs';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PNotificationsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee/alerts';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PNotificationsListWsDTO> {
    return this.query<PNotificationsListWsDTO>({params: queryParams});
  }
}
