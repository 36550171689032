import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { SubscriptionManager } from '../components/subscriptions-manager';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'footer-panel',
  template: require('./footer-panel.component.html'),
  styles: [require('./footer-panel.component.scss')]
})
export class FooterPanelComponent extends SubscriptionManager implements OnInit {

  /* tslint:disable */
  followUs = [
    {
      icon: 'fas fa-2x fa-linkedin',
      link: 'https://www.linkedin.com/company/130943/',
      name: 'LinkedIn',
      image: 'social-linkedin.svg',
      svg: `<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>svg:hover #footer{
        fill: #062039 !important;
    }</style>
    <g id="Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="footer" transform="translate(-550.000000, -916.000000)" fill="#418fde">
            <g id="Linkedin-Icon" transform="translate(550.000000, 916.000000)">
                <path d="M12.4565,0.2465 C15.87675,0.2465 18.96775,1.63125 21.20975,3.87275 C23.45125,6.11425 24.83575,9.2055 24.83575,12.626 C24.83575,16.0465 23.45125,19.138 21.20975,21.37925 C18.96775,23.621 15.87675,25.00575 12.4565,25.00575 C9.03575,25.00575 5.9445,23.621 3.70325,21.37925 C1.46125,19.138 0.07675,16.0465 0.07675,12.626 C0.07675,9.2055 1.46125,6.11425 3.70325,3.87275 C5.9445,1.63125 9.03575,0.2465 12.4565,0.2465 M20.52425,4.55825 C18.46075,2.488 15.60975,1.21325 12.4565,1.21325 C9.30275,1.21325 6.45175,2.488 4.38825,4.55825 C2.3185,6.6215 1.04325,9.473 1.04325,12.626 C1.04325,15.77925 2.3185,18.631 4.38825,20.69375 C6.45175,22.764 9.30275,24.039 12.4565,24.039 C15.60975,24.039 18.46075,22.764 20.52425,20.69375 C22.59425,18.631 23.86925,15.77925 23.86925,12.626 C23.86925,9.47325 22.59425,6.6215 20.52425,4.55825" id="Shape" fill-rule="nonzero"></path>
                <path d="M6.86975,11.7425 L6.86975,17.2125 L9.4745,17.2125 L9.4745,11.297 L9.4745,9.76175 L6.86975,9.76175 L6.86975,11.7425 Z M17.981,12.051 C17.8095,10.639 17.1655,9.76175 15.25975,9.76175 C14.14225,9.76175 13.3885,10.173 13.07325,10.76925 L13.03875,10.76925 L13.03875,9.76175 L10.9615,9.76175 L10.9615,11.19425 L10.9615,17.2125 L13.1345,17.2125 L13.1345,13.51825 C13.1345,12.5445 13.3265,11.60575 14.51975,11.60575 C15.7185,11.60575 15.8075,12.716 15.8075,13.5865 L15.8075,17.2125 L18.0420089,17.2125 L18.042,13.1205 C18.0425,12.7365 18.022,12.38025 17.981,12.051 Z M8.172,6.0395 C7.45225,6.0395 6.8695,6.62225 6.8695,7.342 C6.8695,8.06175 7.45225,8.64425 8.172,8.64425 C8.89175,8.64425 9.47425,8.06175 9.47425,7.342 C9.47425,6.62225 8.89175,6.0395 8.172,6.0395 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>`
    },
    {
      icon: 'fas fa-2x fa-youtube-play',
      link: 'http://www.youtube.com/user/planseevideos',
      name: 'YouTube',
      image: 'social-youtube.svg',
      svg: `<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>svg:hover #footer{
        fill: #062039 !important;
    }</style>
    <g id="Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="footer" transform="translate(-581.000000, -916.000000)" fill="#418fde">
            <g id="Youtube-Icon" transform="translate(581.000000, 916.000000)">
                <path d="M12.45625,0.10875 C15.87675,0.10875 18.968,1.5 21.20925,3.73475 C23.45075,5.97625 24.83575,9.0745 24.83575,12.495 C24.83575,15.9085 23.45075,19.007 21.20925,21.24825 C18.968,23.48275 15.87675,24.87425 12.45625,24.87425 C9.0355,24.87425 5.94425,23.48275 3.70275,21.24825 C1.4615,19.007 0.07675,15.9085 0.07675,12.495 C0.07675,9.0745 1.4615,5.97625 3.70275,3.73475 C5.944,1.5 9.0355,0.10875 12.45625,0.10875 M20.52425,4.42025 C18.46075,2.35675 15.60925,1.082 12.45625,1.082 C9.303,1.082 6.4515,2.35675 4.388,4.42025 C2.3185,6.4835 1.04325,9.342 1.04325,12.495 C1.04325,15.64125 2.3185,18.49975 4.388,20.563 C6.4515,22.626 9.303,23.908 12.45625,23.908 C15.60925,23.908 18.46075,22.626 20.52425,20.563 C22.594,18.49975 23.86875,15.64125 23.86875,12.495 C23.86875,9.342 22.594,6.4835 20.52425,4.42025" id="Shape" fill-rule="nonzero"></path>
                <path d="M18.6525,9.85575 C18.6525,9.85575 18.5225,8.94425 18.14525,8.5465 C17.66575,8.02575 17.12425,8.01875 16.8845,7.98425 C15.1155,7.85425 12.463,7.85425 12.463,7.85425 L12.44875,7.85425 C12.44875,7.85425 9.80325,7.85425 8.03425,7.98425 C7.781,8.01875 7.2465,8.02575 6.76675,8.5465 C6.3895,8.94425 6.25975,9.85575 6.25975,9.85575 C6.25975,9.85575 6.129,10.925 6.129,11.98075 L6.129,12.11775 L6.129,12.98825 C6.129,14.0575 6.25975,15.11325 6.25975,15.11325 C6.25975,15.11325 6.3895,16.025 6.76675,16.42925 C7.2465,16.957 7.87725,16.93675 8.16525,16.9915 C9.17275,17.09425 12.463,17.1285 12.463,17.1285 C12.463,17.1285 15.11525,17.115 16.8845,16.9915 C17.12425,16.957 17.66575,16.957 18.14525,16.42925 C18.52275,16.025 18.6525,15.11325 18.6525,15.11325 C18.6525,15.11325 18.77575,14.0575 18.77575,12.98825 L18.77575,12.17275 L18.77575,11.98075 C18.776,10.925 18.6525,9.85575 18.6525,9.85575 Z M14.5675,12.351 L11.14675,14.19475 L11.14675,11.35675 L11.14675,10.49325 L12.69625,11.3365 L14.5675,12.351 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>`
    },
    {
      icon: 'fas fa-2x fa-facebook-f',
      link: 'https://www.facebook.com/Plansee',
      name: 'Facebook',
      image: 'social-facebook.svg',
      svg: `<svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>svg:hover #footer{
        fill: #062039 !important;
    }</style>
    <g id="Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="footer" transform="translate(-613.000000, -916.000000)" fill="#418fde">
            <g id="facebook-icon" transform="translate(613.000000, 916.000000)">
                <path d="M12.402,10.09675 L12.402,9.08225 C12.402,8.59575 12.72425,8.47925 12.9575,8.47925 L14.37625,8.47925 L14.37625,6.313 L12.4225,6.313 C10.24975,6.313 9.76275,7.917 9.76275,8.959 L9.76275,10.09675 L8.5015,10.09675 L8.5015,11.62525 L8.5015,12.62625 L9.7765,12.62625 L9.7765,18.94625 L12.306,18.94625 L12.306,12.62625 L14.17725,12.62625 L14.2595,11.63225 L14.41025,10.09675 L12.402,10.09675 Z" id="Shape"></path>
                <path d="M11.9177547,0.708490675 C15.2104321,0.708490675 18.1868623,2.04134994 20.3445155,4.19924381 C22.5026501,6.35737835 23.83575,9.33332722 23.83575,12.6260047 C23.83575,15.9189228 22.5026501,18.8951123 20.3445155,21.0530062 C18.1868623,23.2109001 15.2104321,24.5437593 11.9177547,24.5437593 C8.62483655,24.5437593 5.648647,23.2109001 3.49075313,21.0530062 C1.33309994,18.8951123 0,15.9189228 0,12.6260047 C0,9.33332722 1.33309994,6.35737835 3.49075313,4.19924381 C5.648647,2.04134994 8.62507722,0.708490675 11.9177547,0.708490675 M19.6915637,4.85917521 C17.6985323,2.87288277 14.9531503,1.63870033 11.9177547,1.63870033 C8.88235901,1.63870033 6.13697698,2.87288277 4.15068454,4.85917521 C2.16439209,6.84546766 0.930450332,9.59060901 0.930450332,12.6260047 C0.930450332,15.661641 2.16439209,18.4067823 4.15068454,20.399573 C6.13697698,22.3861061 8.88235901,23.613309 11.9177547,23.613309 C14.9531503,23.613309 17.6985323,22.3861061 19.6915637,20.399573 C21.6776155,18.4067823 22.9052997,15.661641 22.9052997,12.6260047 C22.9052997,9.59084969 21.6776155,6.84546766 19.6915637,4.85917521" id="Shape" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>`
    },
    {
      icon: 'fas fa-2x fa-twitter',
      link: 'https://twitter.com/planseese',
      name: 'Twitter',
      image: 'social-twitter.svg',
      svg: `<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style>svg:hover #footer{
        fill: #062039 !important;
    }</style>
    <g id="Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="footer" transform="translate(-643.000000, -916.000000)" fill="#418fde">
            <g id="Twitter-Icon" transform="translate(643.000000, 916.000000)">
                <path d="M12.49225,0.034 C15.9125,0.034 19.0035,1.42525 21.2455,3.66 C23.487,5.9015 24.8715,8.99975 24.8715,12.42025 C24.8715,15.83375 23.487,18.93225 21.2455,21.1735 C19.0035,23.408 15.9125,24.7995 12.49225,24.7995 C9.0715,24.7995 5.98025,23.40825 3.739,21.1735 C1.497,18.93225 0.1125,15.83375 0.1125,12.42025 C0.1125,8.99975 1.497,5.9015 3.739,3.66 C5.98025,1.42525 9.0715,0.034 12.49225,0.034 M20.56,4.3455 C18.4965,2.282 15.6455,1.00725 12.49225,1.00725 C9.3385,1.00725 6.4875,2.282 4.424,4.3455 C2.35425,6.40875 1.079,9.26725 1.079,12.42025 C1.079,15.5665 2.35425,18.425 4.424,20.48825 C6.4875,22.55125 9.3385,23.83325 12.49225,23.83325 C15.6455,23.83325 18.4965,22.55125 20.56,20.48825 C22.63,18.42475 23.905,15.56625 23.905,12.42025 C23.905,9.26725 22.63025,6.40875 20.56,4.3455" id="Shape" fill-rule="nonzero"></path>
                <path d="M19.812,9.561 C19.3525,9.76 18.8525,9.897 18.32475,9.9655 C18.866,9.6435 19.27725,9.14975 19.4695,8.5465 C18.969,8.84125 18.407,9.05375 17.81775,9.17025 C17.351,8.67025 16.67275,8.3615 15.91875,8.3615 C14.493,8.3615 13.3275,9.50625 13.3275,10.91125 C13.3275,11.117 13.362,11.309 13.403,11.49425 C11.244,11.39125 9.33125,10.37675 8.05625,8.8275 C7.837,9.2115 7.7,9.6435 7.7,10.1095 C7.7,11.0005 8.166,11.782 8.85125,12.2345 C8.4265,12.22775 8.02875,12.111 7.6795,11.919 L7.6795,11.95325 C7.6795,12.28225 7.74125,12.584 7.8575,12.87175 C8.173,13.674 8.89275,14.277 9.7635,14.4485 C9.53675,14.51025 9.3175,14.54425 9.07775,14.54425 C8.91325,14.54425 8.74875,14.52375 8.59075,14.4895 C8.927,15.504 9.87975,16.24425 11.011,16.2715 C10.1195,16.957 9.00925,17.36175 7.796,17.36175 C7.58325,17.36175 7.38475,17.3475 7.17225,17.327 C8.32375,18.047 9.68775,18.472 11.148,18.472 C15.199,18.472 17.70075,15.66825 18.35175,12.755 C18.46875,12.23425 18.52325,11.72 18.52325,11.21275 L18.52325,10.88375 C19.02375,10.52075 19.4695,10.06825 19.812,9.561" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>`
    }
  ];
  contactLink = 'https://www.plansee.com/en/contact.html';
  /* tslint:enable */

  isAuthorized = false;

  constructor(
    public router: Router,
    public authorizationService: AuthorizationService
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          const isInTheMenuRoute = this.router.url.indexOf('menu') !== -1;
          this.isAuthorized = isInTheMenuRoute && this.authorizationService.isAuthorized();
        })
    );
  }
}
