import { Pipe, PipeTransform } from '@angular/core';
import { PlanseeTableDateFilter, PlanseeTableDefaultFilter, PlanseeTableFilters } from '../plansee-table-model';
import { BreadcrumbWsDTO, FacetDate } from '../../../providers/types/ycommercewebservices';
import { defaultTo, get } from 'lodash';

@Pipe({
  name: 'calculateBreadcrumbs'
})
export class CalculateBreadcrumbsPipe implements PipeTransform {
  transform(filters: PlanseeTableFilters, dateFilters: PlanseeTableDateFilter[], defaultFilters: PlanseeTableDefaultFilter[]): BreadcrumbWsDTO[] {
      return [...this.calculateDefaultFilters(filters, defaultFilters), ...this.calculateDatesFilters(filters, dateFilters)];
  }

  private calculateDefaultFilters(filters: PlanseeTableFilters, defaultFilters: PlanseeTableDefaultFilter[]): BreadcrumbWsDTO[] {
    return defaultTo(defaultFilters, [])
      .filter(f => f && f.key ? get(filters, 'defaults', {})[f.key] : false)
      .map(f => {
        const values = get(filters, 'defaults', {})[f.key] || [];
        return {
          facetName: f.label || '',
          facetValueName: f.options
            .filter(o => values.includes(o.key))
            .map(o => get(o, 'label', '')).join(',') || '',
          removeQuery: {
            query: {
              value: ''
            }
          }
        };
      });
  }

  private calculateDatesFilters(filters: PlanseeTableFilters, dateFilters: PlanseeTableDateFilter[]): BreadcrumbWsDTO[] {
    return defaultTo(dateFilters, [])
      .filter(f => {
        return this.takeFrom(filters, f.key) || this.takeUntil(filters, f.key);
      })
      .map(f => {
        return {
          facetName: f.label || '',
          facetValueName: `[${this.takeFrom(filters, f.key) || '*'} - ${this.takeUntil(filters, f.key) || '*'}]`,
          removeQuery: {
            query: {
              value: ''
            }
          }
        };
      });
  }

  private takeFrom(filters: PlanseeTableFilters, key: string): string {
    const dates = this.takeDates(filters, key);
    return dates && dates.from ? dates.from : '';
  }

  private takeUntil(filters: PlanseeTableFilters, key: string): string {
    const dates = this.takeDates(filters, key);
    return dates && dates.until ? dates.until : '';
  }

  private takeDates(filters: PlanseeTableFilters, key: string): FacetDate {
    return get(filters, 'dates', {[key]: {from: '', until: ''}})[key];
  }
}
