import { Injectable } from '@angular/core';
import { PageRightItem, PageRights } from './page-rights';

@Injectable()
export class PageRightsService {
  private readonly PAGE_RIGHTS_MAP: { [key: string]: PageRightItem} = {
    [PageRights.DASHBOARD]: {
      blackRight: 'dashboard',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.RFQS]: {
      blackRight: 'rfqs',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.PRODUCTS]: {
      blackRight: 'products',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.ORDERS]: {
      blackRight: 'orders',
      whiteRights: [],
    },
    [PageRights.SHIPMENTS]: {
      blackRight: 'shipments',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.FORECASTS]: {
      blackRight: 'forecasts',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.CONSIGNMENTS]: {
      blackRight: 'consignments',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.INVOICES]: {
      blackRight: 'invoices',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.CLAIMS]: {
      blackRight: 'claims',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.HOT_ZONE_CONFIGURATOR]: {
      blackRight: '',
      userRights: ['hotzoneRequest', 'hotzoneConfigurator', 'hotzoneConfiguratorAdmin', 'hotzonePlansee', 'hotzoneAdmin'],
      whiteRights: ['hotzone'],
    },
    [PageRights.DOCUMENTS]: {
      blackRight: 'documents',
      userRights: [],
      whiteRights: [],
    },
    [PageRights.SHOP]: {
      blackRight: 'shop',
      userRights: [],
      whiteRights: [],
    },
  };

  isPageOnBlackList(page: PageRights, blacklist: string[]): boolean {
    const pageRights = this.PAGE_RIGHTS_MAP[page];

    if (pageRights && pageRights.blackRight && blacklist && blacklist.length) {
      return blacklist.includes(pageRights.blackRight);
    }

    return false;
  }

  hasUserCorrectRights(page: PageRights, userRights: string[]): boolean {
    const pageRights = this.PAGE_RIGHTS_MAP[page];
    const pageRequiredRights = pageRights && pageRights.userRights && pageRights.userRights.length;

    if (pageRequiredRights && userRights && userRights.length) {
      return userRights.some(right => pageRights.userRights.indexOf(right) !== -1);
    } else if (pageRequiredRights) {
      return false;
    }

    return true;
  }

  isPageOnWhiteList(page: PageRights, whitelist: string[]): boolean {
    const pageRights = this.PAGE_RIGHTS_MAP[page];
    const pageRequiredWhiteList = pageRights && pageRights.whiteRights && pageRights.whiteRights.length;

    if (pageRequiredWhiteList && whitelist && whitelist.length) {
      return whitelist.some(right => pageRights.whiteRights.indexOf(right) !== -1);
    } else if (pageRequiredWhiteList) {
      return false;
    }

    return true;
  }
}
