/**
 * P-Number-Directive (PNumberDirective)
 * based on https://codeburst.io/digit-only-directive-in-angular-3db8a94d80c3
 *
 * replacing HTML5's <input type="number"> because it's not working for MS IE11, Edge even though it should.
 * e.g.
 *  - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/110895/
 */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[pNumber]'
})
export class PNumberDirective {
  inputElement: HTMLTextAreaElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  // var representing value from input element (setter with validation & auto-correction + getter)
  private _currentValue: string;
  set currentValue(newValue: string) {
    this._currentValue = this.replaceIllegalChars(newValue.toLowerCase());
  }
  get currentValue(): string {
    return this._currentValue;
  }

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  /* tslint:disable:cognitive-complexity */
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress: input is a digit [0-9], an e, or a .
    if (!(!isNaN(parseInt(e.key, 10)) || e.key === 'e' || e.key === '.')) {
      e.preventDefault();
    }
  }
  /* tslint:disable:cognitive-complexity */

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    let target = (e.target as HTMLTextAreaElement);
    // 'validate' input value
    this.currentValue = target.value;
    // set 'corrected' value back to input element
    this.inputElement.value = this.currentValue;
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.currentValue = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, this.currentValue);
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.currentValue = e.dataTransfer.getData('text');
    this.inputElement.focus();
    document.execCommand('insertText', false, this.currentValue);
  }

  /**
   * Replaces illegal chars.
   * @param data
   */
  private replaceIllegalChars(data: string): string {
    data = data.replace(/[^0-9eE.]/g, '').toLowerCase(); // get valid chars

    if (data !== '' && data.match(/^[0-9eE\.]*$/g)) {
      if (data.indexOf('.') >= 0 && data.indexOf('e') >= 0 ) {
        // includes both non-digit separators
        if (data.indexOf('.') < data.indexOf('e')) {
          // '.' is first 'separator'
          data = this.cleanup(data, '.');
        } else {
          // 'e' is first 'separator'
          data = this.cleanup(data, 'e');
        }
      } else if (data.indexOf('.') >= 0) {
        // '.' is 'separator'
        data = this.cleanup(data, '.');
      } else if (data.indexOf('e') >= 0) {
        // 'e' is 'separator'
        data = this.cleanup(data, 'e');
      }
    }
    return data;
  }

  /**
   * Replaces duplicated separator chars.
   * @param value
   * @param separator
   */
  private cleanup(value: string, separator: string): string {
    let strBefore = value.substr(0, value.indexOf(separator));
    let strAfter = value.substr(value.indexOf(separator) + 1, value.length);
    return strBefore.concat(separator, (strAfter.replace(/\./gi, '')).replace(/e/gi, ''));
  }
}
