import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardWsDTO} from '../../providers/types/dashboard/dashboard';
import {UserWsDTO} from '../../providers/types/ycommercewebservices';
import {defaultTo} from 'lodash';

@Component({
  selector: 'plansee-dashboard',
  template: require('./plansee-dashboard.component.html'),
  styles: [require('./plansee-dashboard.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanseeDashboardComponent {

  @Input() set dashboardData(data: DashboardWsDTO) {
    this._dashboardData = data;
    if (data && data.profile && data.profile.company) {
      const outsideSales = defaultTo(data.profile.company.outsideSalesContacts, [])
        .map(contact => ({
          ...contact,
          type: 'dashboardPage.outsideSales'
        }));
      const insideSales = defaultTo(data.profile.company.insideSalesContacts, [])
        .map(contact => ({
          ...contact,
          type: 'dashboardPage.insideSales'
        }));

      this.contacts = [
        ...outsideSales,
        ...insideSales,
      ];
    }
  }

  get dashboardData() {
    return this._dashboardData;
  }

  contacts: UserWsDTO[] = [];
  private _dashboardData: DashboardWsDTO;
}
