import {Component, ElementRef, ViewChild} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PProductsService} from '../../providers/plansee/p-products-service';
import {Animations} from '../../animations/animations';
import {PSearch} from '../p-search';
import {TranslateService} from '@ngx-translate/core';
import {PProductSearchPageWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'products-search',
  template: require('./products-search.component.html'),
  animations: [
    Animations.expand()
  ]
})
export class ProductsSearchComponent extends PSearch {

  @ViewChild('a')
  downloadButton: ElementRef;

  constructor(private productsService: PProductsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
  }

  updateSearchResult(searchResult: PProductSearchPageWsDTO) {
    this.state = searchResult;
    // override query since it comes broken out of backend
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }

  search(query: string, emit= false) {
    let data = this.checkAndUpdateQuery(query);
    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;
      if (emit) {
        // trigger search in list component
        this.submit();
      } else {
        this.productsService.search(this._query).subscribe(val => {
          this.state = val;
          // override query since it comes broken out of backend
          this.state.currentQuery.query.value = query;
        });
      }
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.productsService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getCurrentSelectionDocument() {
    const keys = [
      'product.customerMaterialNumber',
      'product.description',
      'product.material',
      'product.type',
      'product.unit',
      'product.code',
    ];

    this.productsService.getListCsvContent(this.createTranslations(keys)).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
    });
  }
}
