import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductWsDTO } from '../../providers/types/ycommercewebservices';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PPage } from '../p-page';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

@Component({
  selector: 'products-page',
  template: require('./products.page.html'),
  styles: []
})
export class ProductsPageComponent extends PPage {

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private router: Router
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  showProductDetail(event: ProductWsDTO) {
    this.router.navigate(['/menu/products', event.code, event.b2bUnit || '']);
  }
}
