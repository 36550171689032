import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeItem } from './tree-model';
import { get, isEqual } from 'lodash';
import { ALL_LOCATION_VALUE } from '../../../providers/types/globals.service';

@Component({
  selector: 'tree',
  template: require('./tree.component.html'),
  styles: [require('./tree.component.scss')],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TreeComponent), multi: true}
  ]
})
export class TreeComponent implements ControlValueAccessor {
  items: TreeItem[];
  filteredItems: TreeItem[];
  freeTextSearch = '';

  ALL_LOCATION_VALUE = ALL_LOCATION_VALUE;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
  }

  writeValue(items: TreeItem[]) {
    if (items && items.length) {
      this.items = items.length > 5 ? items : items.map(item => {
        item.open = true;
        return item;
      });
      this.items.forEach(item => item && item.children.forEach(child => this.checkIndeterminate(child)));
      const allItem = this.items.find(item => item.id === ALL_LOCATION_VALUE);
      if (allItem && allItem.selected) {
        this.checkIfAllShouldBeSelected(allItem, true);
      }
      this.filteredItems = [...this.items];
    }
  }

  onSelect(item: TreeItem, parent?: TreeItem) {
    const newState = !(item.selected);
    this.select(item, newState, parent);
    this.checkIndeterminate(item, parent);
    this.checkIfAllShouldBeSelected(item, newState);
    this.onChange(this.items);
  }

  toggle(item: TreeItem) {
    item.open = !item.open;
  }

  onInput(term: string) {
    const filterItems = (results: TreeItem[], item: TreeItem) => {
      const label = get(item, 'label', '').toLowerCase();
      if (label.includes(term.toLowerCase())) {
        results.push(item);
        return results;
      }
      if (item && item.children && Array.isArray(item.children)) {
        const children = item.children.reduce(filterItems, []);
        if (children.length) {
          results.push({...item, children});
        }
      }
      return results;
    };
    this.filteredItems = this.items.reduce(filterItems, []);
  }

  private select(item: TreeItem, state: boolean, parent?: TreeItem) {
    const children: TreeItem[] = get(item, 'children', []);
    if (children.length) {
      children.forEach(child => this.select(child, state, item));
    } else {
      item.selected = state;
    }

    this.isParentSelected(item, parent);
  }

  private isParentSelected(item: TreeItem, parentItem?: TreeItem) {
    const parent: TreeItem = parentItem || this.takeParent(this.items, item);
    if (parent) {
      parent.selected = this.areAllSelected(parent.children);
      this.isParentSelected(parent);
    }
  }

  private isSelected(item?: TreeItem): boolean {
    const children: TreeItem[] = get(item, 'children', []);
    return children.length ? this.areAllSelected(children) : !!(item && item.selected);
  }

  private checkIndeterminate(item: TreeItem, parentItem?: TreeItem) {
    item.indeterminate = this.areSomeSelectedOrIndeterminate(item.children) && !item.selected;
    const parent: TreeItem = parentItem || this.takeParent(this.items, item);
    if (parent) {
      this.checkIndeterminate(parent);
    }
  }

  private takeParent(root: TreeItem[], item: TreeItem) {
    let parent = null;

    root.some((child: TreeItem) => {
      if (child.children && child.children.length) {
        if (child.children.some(c => isEqual(c, item))) {
          return parent = child;
        } else {
          return parent = this.takeParent(child.children, item);
        }
      }
    });

    return parent;
  }

  private areAllSelected(children?: TreeItem[]) {
    return children.every(c => this.isSelected(c));
  }

  private areSomeSelectedOrIndeterminate(children?: TreeItem[]) {
    return (children || []).some(c => this.isSelected(c) || (c && c.indeterminate));
  }

  private checkIfAllShouldBeSelected(clickedItem?: TreeItem, status?: boolean) {
    if (clickedItem && clickedItem.id === ALL_LOCATION_VALUE) {
      this.items.forEach(item => {
        if (item.id !== ALL_LOCATION_VALUE) {
          this.select(item, !!status);
        }
      });
    } else {
      setTimeout(() => {
        const allItemsSelected = this.items.filter((item => item.id !== ALL_LOCATION_VALUE)).every(item => this.isSelected(item));
        const allItem = this.items.find(item => item.id === ALL_LOCATION_VALUE);

        if (allItem) {
          this.select(allItem, allItemsSelected);
          this.onChange(this.items);
        }
      });
    }
  }
}
