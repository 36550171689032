import {RestService} from '../rest-service';
import {PaginationWsDTO, QueryRequestWsDTO} from '../types/ycommercewebservices';
import { PRequestWsDTO, Translation } from '../types/planseeoccaddon';
import {Observable} from 'rxjs';
import {UrlEncodePipe} from '../../filters/urlEncode.pipe';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from './authorization-service';
import { PlanseeTable } from '../../components/plansee-table/plansee-table-model';

export const NONE_PARAM = 'none';

export abstract class PService extends RestService {

  currentQueryParams: QueryRequestWsDTO & PRequestWsDTO = {};
  basePath: string;
  emptyDataSyncV2Response: PlanseeTable = {
    tabs: [],
    pagination: {
      totalCount: 0,
      totalPages: 0,
      count: 0,
      pageSize: 0,
      page: 0,
      totalResults : 0,
      currentPage: 0,
    },
    items: [],
  };

  //
  urlEncode = new UrlEncodePipe();

  constructor (http: HttpClient, authorizationService: AuthorizationService) {
    super(http, authorizationService);
  }

  encodeLimitsForDateRange(query?: string): string {
    // url encode box brackets
    query = query.replace(/\[/g, '%5B').replace(/\]/g, '%5D');
    return query;
  }

  stripQueryStringFromQueryObject(queryParams?: QueryRequestWsDTO & PRequestWsDTO): QueryRequestWsDTO & PRequestWsDTO {
    let newQueryParams = Object.assign({}, queryParams);
    delete newQueryParams.query;
    return newQueryParams;
  }

  getCsvContent(downloadModuleID: string, translations: Translation[], optionalFields?: string): Observable<any> {
    let params = this.stripQueryStringFromQueryObject(this.currentQueryParams);
    if (optionalFields) {
      params.optionals = optionalFields;
    }
    return this.postAt(`${downloadModuleID}?query=${this.encodeLimitsForDateRange(this.currentQueryParams.query)}`, translations, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.ms-excel'
      },
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  getEndpoint(): string {
    return this.basePath;
  }

  getCurrentParams() {
    return this.currentQueryParams;
  }

  setPaginationInformations(pagination: PaginationWsDTO) {
    this.currentQueryParams.pageSize = pagination.totalResults;
  }

  protected paramsIncludeNone(queryParams?: QueryRequestWsDTO & PRequestWsDTO): boolean {
    return Object.values(queryParams).some(value => {
      if (value && Array.isArray(value) && value.length > 0) {
        return value[0] === NONE_PARAM;
      }

      return value === NONE_PARAM;
    });
  }
}
