import {Component, OnInit} from '@angular/core';
import {PUserWsDTO} from '../../providers/types/planseeoccaddon';
import {AuthorizationService} from '../../providers/plansee/authorization-service';

@Component({
  selector: 'claim-page',
  template: require('./claim-confirmation.page.html'),
  styles: [require('./claim-confirmation.page.scss')]
})
export class ClaimConfirmationPageComponent implements OnInit {

  user: PUserWsDTO;

  constructor(private authorizationService: AuthorizationService) {
  }

  ngOnInit() {
    this.user = this.authorizationService.getUserProfile();
  }
}
