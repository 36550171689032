import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export const NEW_APP_MODE = 'NEW';

@Pipe({
  name: 'date'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private translateService: TranslateService
  ) {
    super(locale);
  }

  private readonly DEFAULT_TIMEZONE = 'UTC';

  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    const newTimezone = timezone === NEW_APP_MODE ? '' : (!timezone && !format ? this.DEFAULT_TIMEZONE : timezone)
    return super.transform(
      value,
      format ? format : this.takeFormat(),
      newTimezone,
      this.translateService.currentLang
    );
  }

  private takeFormat() {
    if (this.translateService.currentLang === 'ja_JP') {
      return 'yyyy/MM/dd';
    } else if (this.translateService.currentLang === 'zh_CN') {
      return 'yyyy年MM月dd日';
    } else {
      return 'dd/MM/yyyy';
    }
  }
}
