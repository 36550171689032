import {Component} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const noop = () => {
};

@Component({
  selector: 'p-switch',
  template: require('./p-switch.component.html'),
  styles: [require('./p-switch.component.scss')],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PSwitchComponent,
    multi: true
  }]
})
export class PSwitchComponent implements ControlValueAccessor {

  private _checked: boolean;
  private onChangeCallback: (_: any) => void = noop;

  get checked() {
    return this._checked;
  }

  set checked(checked) {
    if (checked !== this._checked) {
      this._checked = checked;
      this.onChangeCallback(checked);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    if (value !== this._checked) {
      this._checked = value;
    }
  }
}
