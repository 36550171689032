import {Component, Input} from '@angular/core';
import {PProductWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'product-classifications',
  template: require('./product-classifications.component.html'),
  styles: [require('./product-classifications.component.scss')]
})
export class ProductClassificationsComponent {

  @Input()
  product: PProductWsDTO[];
}
