import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { Animations } from '../../animations/animations';
import { LanguageWsDTO } from '../../providers/types/ycommercewebservices';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { PLanguagesService } from '../../providers/plansee/p-languages-service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum LanguageSelectorType {
  HEADER = 'HEADER',
  USER_SETTING = 'USER_SETTING'
}

@Component({
  selector: 'language-selector',
  template: require('./language-selector.component.html'),
  styles: [require('./language-selector.component.scss')],
  animations: [
    Animations.expand()
  ],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LanguageSelectorComponent), multi: true}
  ]
})
export class LanguageSelectorComponent extends SubscriptionManager implements OnInit, ControlValueAccessor {
  @Input() type: LanguageSelectorType = LanguageSelectorType.HEADER;

  value = '';
  LanguageSelectorType = LanguageSelectorType;
  collapse = false;
  langs: LanguageWsDTO[] = [];

  constructor(
    private languagesService: PLanguagesService
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.languagesService.getLanguages()
        .subscribe(langs => {
          this.langs = langs;
        })
    );
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    // do nothing
  }

  writeValue(value: string) {
    if (value) {
      this.value = value;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.collapse = false;
    }
  }

  useLanguage(lang: string) {
    this.value = lang;
    this.onChange(this.value);
  }
}
