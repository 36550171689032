import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationWsDTO } from '../../../providers/types/ycommercewebservices';
import { QuotationBookmarkChangeEvent, Quotation } from '../quotations-model';
import { DataTableParams, DataTableRowEvent, defaultTranslations } from 'ngx-datatable-bootstrap4';
import { PQuotationWsDTO } from '../../../providers/types/planseeoccaddon';
import { PlanseeQuotationsService } from '../../../providers/plansee/plansee-quotations.service';
import { get } from 'lodash';
import { take } from 'rxjs/operators';
import { PlanseeDocumentsService } from '../../../providers/plansee/plansee-documents.service';
import { DownloadDocumentType } from '../../../providers/types/download';

@Component({
  selector: 'quotations-table',
  template: require('./quotations-table.component.html'),
  styles: [require('./quotations-table.component.scss')]
})
export class QuotationsTableComponent {
  @Input() pagination: PaginationWsDTO;
  @Input() items: Quotation[];
  @Input() selectedTabId: string;
  @Input() isCompleted: boolean;
  @Output() onReloadItems = new EventEmitter<DataTableParams>();
  @Output() bookmarkChange = new EventEmitter<QuotationBookmarkChangeEvent>();

  i18n = defaultTranslations;
  DownloadDocumentType = DownloadDocumentType;

  constructor(
    public documentsService: PlanseeDocumentsService,
    private planseeQuotationsService: PlanseeQuotationsService,
  ) {
  }

  onBookmarkChange(selected: boolean, quotation: Quotation) {
    if (quotation && (quotation.id || quotation.sapId)) {
      this.bookmarkChange.emit({ selected, quotation });
    }
  }


  rowClick(event: DataTableRowEvent<PQuotationWsDTO>) {
    const item: {
      detailsExpanded?: boolean
    } & PQuotationWsDTO = event.row.item;

    if (!this.isCompleted) {
      if (!event.row.item.entries) {
        this.searchEntries(event, item);
      } else {
        event.row.expanded = !event.row.expanded;
        event.row.item.entries = null;
      }
    }
    item.detailsExpanded = event.row.expanded;
  }

  rowExpand(event: DataTableRowEvent<PQuotationWsDTO>) {
    const item: {
      detailsExpanded?: boolean
    } & PQuotationWsDTO = event.row.item;

    let expanded = event.row.expanded;
    event.row.expanded = false;
    if (!event.row.item.entries) {
      this.searchEntries(event, item);
    } else {
      event.row.expanded = expanded;
      event.row.item.entries = null;
    }
    item.detailsExpanded = event.row.expanded;
  }

  private searchEntries(event: DataTableRowEvent<PQuotationWsDTO>, item) {
    const id = get(event, 'row.item.sapId', get(event, 'row.item.is', ''));
    if (id) {
      this.planseeQuotationsService.searchEntries(id, {})
        .pipe(take(1))
        .subscribe(searchResult => {
          event.row.item.entries = searchResult.entries;
          event.row.expanded = searchResult.entries && searchResult.entries.length > 0;
          item.detailsExpanded = event.row.expanded;
        });
    }
  }
}
