import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { NgbDateStruct, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import {PDatePipe} from '../../filters/pDate.pipe';
import { CustomDatepickerI18nService } from '../../providers/plansee/custom-datepicker-i18n.service';

const noop = () => {
};

@Component({
  selector: 'p-date',
  template: require('./p-date.component.html'),
  styles: [require('./p-date.component.scss')],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: PDateComponent, multi: true},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}
  ]
})
export class PDateComponent implements ControlValueAccessor, OnInit {
  @Input()
  readonly = false;
  @Input()
  required = false;
  @Input()
  disabled = false;
  @Input()
  name = '';
  @Input()
  danger = false;
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Output()
  onDateSelect: EventEmitter<NgbDate> = new EventEmitter();
  today: NgbDateStruct = this.toNgbDateStruct(new Date());
  ngbMinDate: NgbDateStruct;
  ngbMaxDate: NgbDateStruct;
  private onChangeCallback: (_: any) => void = noop;
  private datePipe = new PDatePipe();

  private _value: NgbDateStruct;

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    this.ngbMinDate = this.minDate ? this.toNgbDateStruct(this.minDate) : undefined;
    this.ngbMaxDate  = this.maxDate ? this.toNgbDateStruct(this.maxDate) : undefined;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    if (value && value.year && value !== this._value) {
      this._value = value;
      this.onChangeCallback(this.toISO8601(value));
    } else {
      this._value = null;
      this.onChangeCallback(this._value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    const test = this._value ? this.toISO8601(this._value) : null;
    if (value !== test) {
      this._value = this.toNgbDateStruct(new Date(value));
    }
  }

  keyPress(event: KeyboardEvent) {
    if (event.keyCode === 46 || event.keyCode === 8) {
      event.preventDefault();
      this.value = null;
    }
  }

  private toISO8601(value: NgbDateStruct): string {
    return this.datePipe.transform(`${value.year}-${value.month}-${value.day}`, 'yyyy-MM-dd');
  }

  private toNgbDateStruct(value: Date): NgbDateStruct {
    return {year: value.getFullYear(), month: value.getMonth() + 1, day: value.getDate()};
  }
}
