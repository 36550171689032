import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfigService} from './config-service';
import {QueryRequestWsDTO} from '../types/ycommercewebservices';
import {PRequestWsDTO, PUnitsWsDTO} from '../types/planseeoccaddon';
import {Observable} from 'rxjs';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PUnitsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PUnitsWsDTO> {
    return this.get<PUnitsWsDTO>(`uom`, {params: queryParams});
  }
}
