import {ChartViewType} from '../types/planseeoccaddon';
import {StripTimeStringPipe} from '../../filters/stripTimeString.pipe';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {PDatePipe} from '../../filters/pDate.pipe';

// general number of weeks considered in one month
const MONTH_WEEKS = 4;

export abstract class PGraphService {
  pos = 0;
  bufferedPosition = null;
  labels: string[] = [];
  viewType: ChartViewType;
  todayIndex = 0;
  positionSubject = new BehaviorSubject(this.pos);
  maxDate: Date;
  minDate: Date;
  datePipe = new PDatePipe;
  stripTimeString = new StripTimeStringPipe();
  todayLabel: string;
  dateSegments: Date[];
  monthsBefore = 2;
  // number of columns displayed in each view type for Forecasts module (It could change in time) + 1.
  // The (+1) is needed because graphs have 1 additional value added to that the labels are aligned with the table on it's edges
  WEEK_POINTS: number;
  MONTH_POINTS: number;

  incDate() {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.bufferedPosition = this.pos = this.bufferedPosition + MONTH_WEEKS;
      if (this.bufferedPosition >= this.labels.length - this.WEEK_POINTS) {
        this.pos = this.labels.length - this.WEEK_POINTS;
      } else if (this.bufferedPosition <= 0) {
        this.pos = 0;
      }
    } else {
      this.pos = this.pos + 1;
      if (this.pos >= this.labels.length - this.WEEK_POINTS) {
        this.pos = this.labels.length - this.WEEK_POINTS;
      } else if (this.pos <= 0) {
        this.pos = 0;
      }
    }
    this.positionSubject.next(this.pos);
  }

  decDate() {
    if (this.viewType === ChartViewType.WEEKLY) {
      this.bufferedPosition = this.pos = this.bufferedPosition - MONTH_WEEKS;
      if (this.bufferedPosition <= 0) {
        this.pos = 0;
      } else if (this.bufferedPosition >= this.labels.length - this.WEEK_POINTS) {
        this.pos = this.labels.length - this.WEEK_POINTS;
      }
    } else {
      this.pos = this.pos - 1;
      if (this.pos <= 0) {
        this.pos = 0;
      } else if (this.pos >= this.labels.length - this.MONTH_POINTS) {
        this.pos = this.labels.length - this.MONTH_POINTS;
      }
    }
    this.positionSubject.next(this.pos);
  }

  createAndInitLabels() {
    this.labels = [];
    if (this.viewType === ChartViewType.WEEKLY) {
      this.labels = this.dateSegments.map(date => {
        return `${this.datePipe.transform(date, 'ww', true)} ${this.datePipe.transform(date, 'yyyy')}`;
      });
    } else {
      this.labels = this.dateSegments.map(date => {
        return `${this.datePipe.transform(date, 'MM')} ${this.datePipe.transform(date, 'yyyy')}`;
      });
    }
  }

  compareMonths(firstDate, lastDate) {
    return firstDate.getFullYear() < lastDate.getFullYear() ||
      (firstDate.getMonth() <= lastDate.getMonth() && firstDate.getFullYear() === lastDate.getFullYear());
  }

  compareWeeks(firstDate, lastDate) {
    return firstDate.getFullYear() < lastDate.getFullYear() ||
      (firstDate.getMonth() < lastDate.getMonth() && firstDate.getFullYear() === lastDate.getFullYear()) ||
      (this.datePipe.transform(firstDate, 'ww', true) <= this.datePipe.transform(lastDate, 'ww', true) &&
        firstDate.getFullYear() === lastDate.getFullYear());
  }

  getTodayIndex() {
    const today = new Date();
    this.todayLabel = this.viewType === ChartViewType.WEEKLY ?
      `${this.datePipe.transform(today, 'ww', true)} ${this.datePipe.transform(today, 'yyyy')}` :
      `${this.datePipe.transform(today, 'MM')} ${this.datePipe.transform(today, 'yyyy')}`;
    const lastDay = this.maxDate;
    const todayIndex = this.labels.indexOf(this.todayLabel);
    if (todayIndex >= 0 && todayIndex < this.labels.length - 1) {
      return todayIndex;
    } else if (today > lastDay) {
      return this.labels.length;
    }
    return 0;
  }

  createAllDateSegments() {
    this.dateSegments = [];
    if (this.viewType === ChartViewType.WEEKLY) {
      this.createWeeklyDateSegments();
    } else {
      this.createMonthlyDateSegments();
    }
  }

  createWeeklyDateSegments() {
    let firstDate = new Date(this.minDate);
    const lastDate = new Date(this.maxDate);
    while (this.compareWeeks(firstDate, lastDate)) {
      this.dateSegments.push(new Date(firstDate));
      firstDate.setDate(firstDate.getDate() + 7);
    }
  }

  createMonthlyDateSegments() {
    let firstDate = new Date(this.minDate);
    const lastDate = new Date(this.maxDate);
    firstDate.setDate(1);
    lastDate.setDate(1);
    lastDate.setMonth(lastDate.getMonth() + 1);
    while (this.compareMonths(firstDate, lastDate)) {
      this.dateSegments.push(new Date(firstDate));
      firstDate.setMonth(firstDate.getMonth() + 1);
    }
  }

  initPosition() {
    this.createAndInitLabels();
    this.todayIndex = this.getTodayIndex();
    if (this.viewType === ChartViewType.WEEKLY) {
      this.bufferedPosition = this.pos = this.todayIndex > MONTH_WEEKS ? this.todayIndex - MONTH_WEEKS + 2 : this.pos;
    } else {
      this.pos = this.todayIndex > 1 ? this.todayIndex - this.monthsBefore : this.pos;
    }
  }
}
