import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'atLeastTwoTabsHaveData'
})
export class AtLeastTwoTabsHaveData implements PipeTransform {

  transform(invoiceTabs: any[]): boolean {
    const twoGreaterThanZero = invoiceTabs
      .map(tab => tab.count)
      .filter(count => count > 0);
    return twoGreaterThanZero.length > 1;
  }
}
