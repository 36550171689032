import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLangName'
})
export class LangNameFormatter implements PipeTransform {

  transform(value: string): string {
    const langNameWithBrackets = value.indexOf('(') !== -1;

    if (langNameWithBrackets) {
      return value.split('(')[0].trim();
    }

    return value;
  }
}
