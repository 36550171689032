const arrowDown = require('!!raw-loader!../img/arrow-down.svg') as string;
const arrowLeft = require('!!raw-loader!../img/arrow-left.svg') as string;
const arrowRight = require('!!raw-loader!../img/arrow-right.svg') as string;
const carouselArrowLeft = require('!!raw-loader!../img/carousel-arrow-left.svg') as string;
const carouselArrowRight = require('!!raw-loader!../img/carousel-arrow-right.svg') as string;
const de_AT = require('!!raw-loader!../img/flags/de_AT.svg') as string;
const de_DE = require('!!raw-loader!../img/flags/de_DE.svg') as string;
const email = require('!!raw-loader!../img/email.svg') as string;
const en_GB = require('!!raw-loader!../img/flags/en_GB.svg') as string;
const es_ES = require('!!raw-loader!../img/flags/es_ES.svg') as string;
const eye = require('!!raw-loader!../img/icon-eye.svg') as string;
const fileDownload = require('!!raw-loader!../img/file-download.svg') as string;
const fr_FR = require('!!raw-loader!../img/flags/fr_FR.svg') as string;
const globe = require('!!raw-loader!../img/icon-globe.svg') as string;
const headerNewRFQs = require('!!raw-loader!../img/header_newRFQs.svg') as string;
const headerNotification = require('!!raw-loader!../img/header_notification.svg') as string;
const headerProfile = require('!!raw-loader!../img/header_profile.svg') as string;
const iconBell = require('!!raw-loader!../img/icon-bell.svg') as string;
const ja_JP = require('!!raw-loader!../img/flags/ja_JP.svg') as string;
const linkInvoices = require('!!raw-loader!../img/link-invoices.svg') as string;
const linkPurchaseOrders = require('!!raw-loader!../img/link-purchase-orders.svg') as string;
const linkShipments = require('!!raw-loader!../img/link-shipments.svg') as string;
const longArrowRight = require('!!raw-loader!../img/long-arrow-right.svg') as string;
const phone = require('!!raw-loader!../img/phone.svg') as string;
const ru_RU = require('!!raw-loader!../img/flags/ru_RU.svg') as string;
const star = require('!!raw-loader!../img/star.svg') as string;
const starFilled = require('!!raw-loader!../img/star-filled.svg') as string;
const zh_CN = require('!!raw-loader!../img/flags/zh_CN.svg') as string;
const microsoft = require('!!raw-loader!../img/microsoft.svg') as string;

export const icons = {
  arrowDown,
  arrowLeft,
  arrowRight,
  carouselArrowLeft,
  carouselArrowRight,
  de_AT,
  de_DE,
  email,
  en_GB,
  es_ES,
  eye,
  fileDownload,
  fr_FR,
  globe,
  headerNewRFQs,
  headerNotification,
  headerProfile,
  iconBell,
  ja_JP,
  linkInvoices,
  linkPurchaseOrders,
  linkShipments,
  longArrowRight,
  phone,
  ru_RU,
  star,
  starFilled,
  zh_CN,
  microsoft
};
