import { Component } from '@angular/core';
import { PForecastingSearchPageWsDTO, PForecastWsDTO, PSearchPageWsDTO } from '../../providers/types/planseeoccaddon';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { defaultTo } from 'lodash';
import { ForecastsType } from '../../providers/types/forecasts/forecasts-type.enum';
import { FORECASTS_ACTIVE_FACET_CODE, ForecastsBaseQuery } from '../../providers/types/forecasts/forecasts-base-query';
import { SearchTab } from '../../shared/components/search-tabs/search-tab';
import { PTabsPage } from '../p-tabs-page';

const DefaultForecastsTabs: SearchTab<ForecastsType, ForecastsBaseQuery>[] = [
  {
    type: ForecastsType.ACTIVE,
    count: 0,
    facetCode: FORECASTS_ACTIVE_FACET_CODE,
    code: 'active',
    query: ForecastsBaseQuery.ACTIVE,
    translationKey: 'forecastsTabs.active'
  }
];

@Component({
  selector: 'forecasting-page',
  template: require('./forecasting.page.html'),
  styles: [require('./forecasting.page.scss')]
})
export class ForecastingPageComponent extends PTabsPage<ForecastsType, ForecastsBaseQuery> {
  productForecast: PForecastWsDTO;
  tabs = DefaultForecastsTabs;
  selectedTab = this.tabs[0];
  selectedTabQuery = ForecastsBaseQuery;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  onSearchResult(result: PSearchPageWsDTO) {
    this.searchResult = result;
    const tempResult = defaultTo(result, {facets: []});
    const queryContainsActiveCode = defaultTo(result.currentQuery.query.value, '').indexOf(`:${FORECASTS_ACTIVE_FACET_CODE}:`) !== -1;

    if (tempResult.facets.length && !queryContainsActiveCode) {
      const activeFacet = tempResult.facets.find(f => f.code === FORECASTS_ACTIVE_FACET_CODE);
      const areValuesForFacet = defaultTo(activeFacet, {values: []}).values.length;
      const activeFacetTrue = areValuesForFacet ? activeFacet.values.find(f => f.code === 'true') : null;

      this.tabs[0].count = defaultTo(activeFacetTrue, {count: 0}).count;
      this.setDefaultTab();
    } else if (!queryContainsActiveCode) {
      this.tabs[0].count = 0;
      this.setDefaultTab();
    }
  }

  setQuery(searchResult: PForecastingSearchPageWsDTO) {
    this.query = {
      query: decodeURIComponent(searchResult.currentQuery.query.value.replace(/\+/g, ' ')),
      pageSize: 10
    };
  }

  protected calculateQuery(langEvent: LangChangeEvent): QueryRequestWsDTO {
    return {
      query: defaultTo(this.query, { query: '' }).query,
      lang: langEvent.lang,
      pageSize: 10,
    };
  }
}
