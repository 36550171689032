import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Animations } from '../../../animations/animations';
import {
  FacetClearEvent,
  FacetDate,
  FacetMode,
  PaginationWsDTO,
} from '../../../providers/types/ycommercewebservices';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  PlanseeTable,
  PlanseeTableDateFilter, PlanseeTableDefaultFilter,
  PlanseeTableFilters,
  PlanseeTableSearchEvent,
} from '../plansee-table-model';
import { DataTableParams } from 'ngx-datatable-bootstrap4';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';

@Component({
  selector: 'plansee-table-filters',
  template: require('./plansee-table-filters.component.html'),
  animations: [Animations.expand()]
})
export class PlanseeTableFiltersComponent implements OnInit {
  @Input() dateFilters: PlanseeTableDateFilter[];
  @Input() defaultFilters: PlanseeTableDefaultFilter[];
  @Input() searchFn: (event: PlanseeTableSearchEvent) => Observable<PlanseeTable>;
  @Input() mainPagination: PaginationWsDTO;
  @Input() selectedTabId: string;
  @Input() dataTableParams: DataTableParams;
  @Input() searchTranslationKey: string;
  @Input() filterPanelTitleKey: string;
  @Input() selectedBookmark: boolean;
  @Input() showDownload = true;
  @Input() showBookmark = false;
  @Input() numberOfTables: 1 | 2 = 1;
  @Output() filtersChange = new EventEmitter<PlanseeTableFilters>();
  @Output() download = new EventEmitter<number | undefined>();
  @Output() bookmarkChange = new EventEmitter<boolean>();

  filters: PlanseeTableFilters = {
    dates: {
    },
    defaults: {
    },
    searchTerm: ''
  };
  collapse = false;
  showBreadcrumbs = false;
  facetClearEvent: FacetClearEvent = {};
  filterPagination: PaginationWsDTO;
  FacetMode = FacetMode;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.setSearchTermFromUrl();
  }

  onFilterSummarySubmit() {
    this.showBreadcrumbs = true;
    this.emitSearchTermChange(this.filters);
  }

  onFilterSummaryClear() {
    this.showBreadcrumbs = false;
    this.filters.dates = {
      until: null,
      from: null
    };
    this.filters.defaults = {};
    this.filterPagination = {};
    this.facetClearEvent = {
      clearTime: new Date().getTime()
    };
    this.emitSearchTermChange(this.filters);
    this.checkMatches();
  }

  onSearchIconClick() {
    this.emitSearchTermChange(this.filters);
  }

  onDateChange(event: FacetDate, key: string) {
    this.filters.dates[key] = event;
    this.checkMatches();
  }

  onDefaultFilterChange(value: string | string[], key: string) {
    this.filters.defaults[key] = Array.isArray(value) ? value : [value];
    this.checkMatches();
  }

  setSuggestion(suggestion: string) {
    this.filters.searchTerm = suggestion;
    this.filtersChange.emit(this.filters);
  }

  private emitSearchTermChange(filters: PlanseeTableFilters) {
    this.collapse = false;
    this.filtersChange.emit(filters);
  }

  private checkMatches() {
    if (this.searchFn) {
      if (this.numberOfTables === 1) {
        this.searchFn({ currentTab: this.selectedTabId, filters: this.filters, dataTableParams: this.dataTableParams })
          .pipe(take(1))
          .subscribe(data => {
            if (data && data.pagination) {
              this.filterPagination = data.pagination;
            }
          });
      } else {
        combineLatest(
          this.searchFn({ currentTab: this.selectedTabId, filters: this.filters, dataTableParams: this.dataTableParams }),
          this.searchFn({ currentTab: this.selectedTabId, filters: this.filters, dataTableParams: this.dataTableParams, secondTable: true })
        )
          .pipe(take(1))
          .subscribe(([firstData, secondData]) => {
            const firstPagination = get(firstData, 'pagination', {});
            const secondPagination = get(secondData, 'pagination', {});
            const firstTotalCount = get(firstPagination, 'totalCount', 0);
            const secondTotalCount = get(secondPagination, 'totalCount', 0);

            this.filterPagination = {
              ...firstPagination,
              totalCount: firstTotalCount + secondTotalCount
            };
          });
      }
    }
  }

  private setSearchTermFromUrl() {
    this.route.paramMap
      .pipe(
        take(1),
      )
      .subscribe((params) => {
        const id = params ? params.get('id') || '' : '';
        if (id) {
          this.setSuggestion(id);
        }
      });
  }
}
