import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PB2bUnitWsDTO, PUserWsDTO, PB2UnitContactInfo, PB2UnitContactAddress } from '../../providers/types/planseeoccaddon';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PSettingsService } from '../../providers/plansee/p-settings-service';
import { take } from 'rxjs/operators';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { combineLatest } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { defaultTo } from 'lodash';

@Component({
  selector: 'user-details',
  template: require('./user-details.html'),
  styles: [require('./user-details.scss')]
})
export class UserDetailsComponent extends SubscriptionManager implements OnInit {

  user: PUserWsDTO;
  company: PB2bUnitWsDTO;
  contactInfo: PB2UnitContactInfo;
  contactAddress: PB2UnitContactAddress;
  rootCompany: PB2bUnitWsDTO;

  constructor(
    public authorizationService: AuthorizationService,
    private settingsService: PSettingsService,
    private route: ActivatedRoute,
    private planseeTranslateService: PlanseeTranslateService
  ) {
    super();
  }

  private query: QueryRequestWsDTO;

  ngOnInit() {
    this.addSubscriptions(
      combineLatest(
        this.authorizationService.permissionsChanged,
        this.planseeTranslateService.onLangChange()
      ).subscribe(() => {
        this.setQueryFromParam();
      })
    );
  }

  setQueryFromParam() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      this.query = {
        query: id ? id : ''
      };
    });
    this.user = this.authorizationService.getUserProfile();
    this.settingsService.getCustomerProfile(this.query)
      .pipe(take(1))
      .subscribe(profile => {
        this.company = profile.company || null;
        this.contactInfo = profile.contactInfo;
        this.contactAddress = defaultTo(profile.company, {contactAddress: null}).contactAddress;
        this.rootCompany = profile.rootCompany || null;
      });
  }
}
