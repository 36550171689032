import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'privacy-policy-modal',
  template: require('./privacy-policy-modal.component.html'),
  styles: [require('./privacy-policy-modal.component.scss')]
})
export class PrivacyPolicyModalComponent {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  closePrivacyPolicyOverlay(event: Event, overlayElement: Element) {
    if (event.target === overlayElement) {
      this.closePrivacyPolicy();
    }
  }

  closePrivacyPolicy() {
    this.onClose.emit(true);
  }
}
