import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableCellEvent, DataTableParams } from 'ngx-datatable-bootstrap4';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PProductsService } from '../../providers/plansee/p-products-service';
import { ProductWsDTO } from '../../providers/types/ycommercewebservices';
import { PDataTable } from '../p-data-table';
import { LoadingService } from '../../providers/plansee/loading-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'products-list',
  template: require('./products-list.component.html'),
  styles: [require('./products-list.component.scss')]
})
export class ProductsListComponent extends PDataTable<ProductWsDTO> implements OnInit {

  items: ProductWsDTO[] = [];
  @Output()
  selectRow: EventEmitter<ProductWsDTO> = new EventEmitter();

  constructor(
    private productService: PProductsService,
    authorizationService: AuthorizationService,
    i18nService: TranslateService,
    loadingService: LoadingService
  ) {
    super(i18nService, authorizationService, loadingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this._query = {query: ''};
  }

  reloadItems(params: DataTableParams) {
    this.updateQuery(params);
    if (JSON.stringify(this._query) === JSON.stringify(this.oldQuery)) {
      return;
    }
    this.areFacetsApplied = !!this._query.query;
    this.oldQuery = Object.assign({}, this._query);
    this.productService.search(this._query)
      .pipe(take(1))
      .subscribe(searchResult => {
        this.items = searchResult.products;

        // Update pagination with the counts comming from BE
        this.refreshPagination(searchResult.pagination);

        // save current pagination information for using it in csv download
        this.productService.setPaginationInformations(searchResult.pagination);
        // send response to search components
        this.searchResultChange.emit(searchResult);
      });
  }

  cellClick(event: DataTableCellEvent<ProductWsDTO>) {
    this.selectRow.emit(event.row.item);
  }
}
