import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {FileSystemFileEntry, UploadEvent} from 'ngx-file-drop';
import {isNumber} from '../../../utils';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

const noop = () => {
};

@Component({
  selector: 'drag-and-drop-upload',
  template: require('./drag-and-drop-upload.component.html'),
  styles: [require('./drag-and-drop-upload.component.scss')],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DragAndDropUploadComponent,
    multi: true
  }]
})
export class DragAndDropUploadComponent implements ControlValueAccessor {
  @Input()
  set fileErrorMessageOpen(errorMessageOpen: boolean) {
    this._fileErrorMessageOpen = errorMessageOpen;
    }
  get fileErrorMessageOpen() {
    return this._fileErrorMessageOpen;
  }

  @Input()
  set accept(accept: string[]) {
    if (accept && accept.length) {
      this._accept = accept;
    }
  }

  @Input()
  set maxFiles(maxFiles: number) {
    if (isNumber(maxFiles)) {
      this._maxFiles = maxFiles;
    }
  }

  get maxSize() {
    return this._maxSize;
  }

  @Input()
  set maxSize(maxSize: number) {
    if (isNumber(maxSize)) {
      this._maxSize = maxSize;
    }
  }

  @Input()
  required = false;
  @Input()
  disabled = false;
  @Input()
  name = '';
  @Input()
  viewExtensions = true;
  @Input()
  invalidContainer = false;
  @Output()
  fileErrorMessageOpenChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('t')
  fileUploadTooltip: NgbTooltip;
  wrongSizeLimit = false;
  wrongFormat = false;
  exceededMaxNumber = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }
  private onChangeCallback: (_: any) => void = noop;
  @ViewChild('fileLoader')
  private fileLoader: ElementRef;
  private files: File[] = [];
  private _maxFiles = 10;
  private _accept = ['.jpg', '.jpeg', '.png', '.tif', '.tiff', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
  private _maxSize = 10;
  private _fileErrorMessageOpen: boolean;

  getFormats() {
    return this._accept.join(',');
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value && value !== this.files) {
      this.files = value;
    }
  }

  removeFile(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
    this.onChangeCallback(this.files);
    // Workaround for detecting changes in the file list on Chrome
    this.changeDetectorRef.detectChanges();
    this.exceededMaxNumber = false;
    this.wrongFormat = false;
    this.wrongSizeLimit = false;
    this._fileErrorMessageOpen = false;
    this.fileErrorMessageOpenChange.emit(this._fileErrorMessageOpen);
  }

  openDialog() {
    if (this.files.length < this._maxFiles) {
      this.fileLoader.nativeElement.click();
    } else {
      this.triggerExeededMaxNumberErrorMessage();
    }
  }

  @HostListener('change', ['$event.target.files'])
  onChange(files: File[]) {
    for (let file of files) {
      this.addFile(file);
    }
    // Clean the value of the input after every upload in order for upload the same file after deleting it
    this.fileLoader.nativeElement.value = '';
  }

  onFileDrop(event: UploadEvent) {
    for (let droppedFile of event.files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.addFile(file);
      });
    }
  }

  private addFile(file: File) {
    for (let f of this.files) {
      if (f.name === file.name && f.size === file.size && f.lastModified === file.lastModified) {
        return;
      }
    }
    const fileExtension = '.' + file.name.split('.').pop();
    if (!this._accept.includes(fileExtension.toLowerCase())) {
      this.triggerInvalidFileFormatErrorMessage();
      this.changeDetectorRef.detectChanges();
      this.onChangeCallback(this.files);
      return;
    }
    if (file.size > this._maxSize * Math.pow(2, 20)) {
      this.triggerLargeFileErrorMessage();
      this.changeDetectorRef.detectChanges();
      this.onChangeCallback(this.files);
      return;
    }
    if (this.files.length < this._maxFiles) {
      this.files.push(file);
      // Workaround for detecting changes in the file list on Chrome
      this.changeDetectorRef.detectChanges();
      this.onChangeCallback(this.files);
      this.wrongFormat = false;
      this.wrongSizeLimit = false;
      this.fileErrorMessageOpen = this.exceededMaxNumber;
      this.fileErrorMessageOpenChange.emit(this._fileErrorMessageOpen);
    } else {
      this.triggerExeededMaxNumberErrorMessage();
    }
  }

  private triggerInvalidFileFormatErrorMessage() {
    this.wrongFormat = true;
    this.fileErrorMessageOpen = true;
    this.fileErrorMessageOpenChange.emit(this._fileErrorMessageOpen);
  }

  private triggerLargeFileErrorMessage() {
    this.wrongSizeLimit = true;
    this.fileErrorMessageOpen = true;
    this.fileErrorMessageOpenChange.emit(this._fileErrorMessageOpen);
  }

  private triggerExeededMaxNumberErrorMessage() {
    this.exceededMaxNumber = true;
    this.fileErrorMessageOpen = true;
    this.fileErrorMessageOpenChange.emit(this._fileErrorMessageOpen);
  }
}
