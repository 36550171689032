import { Pipe, PipeTransform } from '@angular/core';
import { FacetWsDTO } from '../../../providers/types/ycommercewebservices';

@Pipe({
  name: 'makeDateFacet'
})
export class MakeDateFacetPipe implements PipeTransform {
  transform(label: string, key: string): FacetWsDTO {
    return {
      category: false,
      code: key || '',
      multiSelect: true,
      name: label || '',
      priority: 100,
      values: [],
      visible: true,
    };
  }
}
