import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {BreadcrumbWsDTO, FacetValueWsDTO, FacetWsDTO} from '../../providers/types/ycommercewebservices';
import {PFacet} from '../p-facet';

@Component({
  selector: 'facet-slider',
  template: require('./facet-slider.component.html'),
  styles: [require('./facet-slider.component.scss')]
})
export class FacetSliderComponent extends PFacet {

  @Input()
  unit: string;
  @Input()
  breadcrumbs: BreadcrumbWsDTO[];
  @ViewChild('rangeSlider')
  rangeSlider: ElementRef;

  private _facet: FacetWsDTO;

  get facet() {
    return this._facet;
  }

  @Input()
  set facet(facet: FacetWsDTO) {
    this._facet = facet;
  }

  // listen for maxValue and minValue changes and manually trigger rangeChange with these values

  // because rangeChange is not triggered when mouseDrop is made outside the component
  @HostListener('change', ['$event'])
  changeEvent(event: MouseEvent) {
    let value = {
      minValue: (this.rangeSlider as any).maxValue,
      maxValue: (this.rangeSlider as any).minValue,
    };
    this.rangeChange(this.facet, value);
  }

  getFacetValues(values: FacetValueWsDTO[]) {
    return values.map(val => val.name);
  }

  getLimitValues(values: FacetValueWsDTO[]) {
    const initialValue = values && values.length ? values[0].name : '0';
    let minValue = initialValue;
    let maxValue = initialValue;
    for (let value of values) {
      minValue = +value.name < +minValue ? value.name : minValue;
      maxValue = +value.name > +maxValue ? value.name : maxValue;
    }
    return {min: minValue, max: maxValue};
  }

  rangeChange(facet: FacetWsDTO, value: {
    minValue: number
    maxValue: number
  }) {
    if (value.minValue > value.maxValue) {
      const aux = value.minValue;
      value.minValue = value.maxValue;
      value.maxValue = aux;
    }
    let query = facet.values[0].query.query.value.replace(/\"/g, '');

    const words = query.split(':');
    let found = false;
    for (let i = 0; i < words.length; i++) {
      if (words[i] === facet.code) {
        if (!found) {
          found = true;
          words[i + 1] = `[${value.minValue} TO ${value.maxValue}]`;
        } else {
          words.splice(i, 2);
          break;
        }
      }
    }

    query = words.join(':');
    query = this.stripTimeFromDateString(query);
    this.submit.emit(query);
  }

  getMinValue(facetCode: string) {
    const dimensions = this.getSelectedDimensions(facetCode);
    return dimensions ? dimensions.min : null;
  }

  getMaxValue(facetCode: string) {
    const dimensions = this.getSelectedDimensions(facetCode);
    return dimensions ? dimensions.max : null;
  }

  getStep(values: FacetValueWsDTO[]) {
    // set the step to 1 / 10 * biggest number of decimals
    const decimalsNumberOfMax = this.getLimitValues(values).max.split('.')[1] ? this.getLimitValues(values).max.split('.')[1].length : 0;
    const decimalsNumberOfMin = this.getLimitValues(values).min.split('.')[1] ? this.getLimitValues(values).min.split('.')[1].length : 0;
    return decimalsNumberOfMax >= decimalsNumberOfMin ? 1 / (10 ** decimalsNumberOfMax) : 1 / (10 ** decimalsNumberOfMin);
  }

  private getSelectedDimensions(facetName: string): any {
    if (this.breadcrumbs) {
      return this.breadcrumbs.filter(value => value.facetCode === facetName).map(value => {
        let str = value.facetValueCode;
        str = str.slice(1, str.length - 1);
        const values = str.split(' ');
        return {
          min: values[0],
          max: values[2]
        };
      })[0];
    }
  }
}
