import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PUserSignUpWsDTO} from '../../providers/types/planseeoccaddon';
import {PAuthenticationService} from '../../providers/plansee/p-authentication-service';
import {PDeliveryCountriesService} from '../../providers/plansee/p-delivery-countries-service';
import {GlobalsService} from '../../providers/types/globals.service';
import {Router} from '@angular/router';
import {hideMainScroll, showMainScroll} from '../../../utils';
import {PSelectValue} from '../../form-components/p-select/p-select-value';
import {take} from 'rxjs/operators';
import {RegisterErrorEnum} from './register-error.enum';
import {SubscriptionManager} from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'register-page',
  template: require('./register.page.html'),
  styles: [require('./register.page.scss')]
})
export class RegisterPageComponent extends SubscriptionManager implements OnInit {
  registerForm: FormGroup;
  functions: PSelectValue[] = [];
  countries: PSelectValue[] = [];
  titles: PSelectValue[] = [];
  error: number = null;
  errorType: string | RegisterErrorEnum = null;
  isConfirmed = false;
  isPrivacyPolicyShown = false;

  RegisterErrorEnum = RegisterErrorEnum;

  constructor(
    private authorizationService: AuthorizationService,
    private authenticationService: PAuthenticationService,
    private deliveryCountriesService: PDeliveryCountriesService,
    private cdr: ChangeDetectorRef,
    private globals: GlobalsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    // prepare Country & Title selectors
    this.countries = this.globals.getCountries();
    this.titles = this.globals.getTitles();

    // Country
    this.deliveryCountriesService.getDeliveryCountries(null)
      .pipe(take(1))
      .subscribe(result => {
        if (result && result.countries && result.countries.length > 0) {
          this.countries = this.countries.concat(result.countries.map(c => ({value: c.isocode, label: c.name})));
        }
      });

    // Position
    this.functions = this.globals.getJobPositions();

    // prepare form
    this.buildForm();
  }

  register(e: Event) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    this.error = null; // reset error
    this.errorType = null; // reset custom errorType

    if (!this.registerForm.valid) {
      this.markAsTouchedForm();
      return;
    }

    return this.authenticationService.registerUser(
      this.transformFormValueToUserSignUpDTO(this.registerForm.value),
      {myplansee: true}
    )
      .pipe(take(1))
      .subscribe(response => {
        this.isConfirmed = true;
      }, error => {
        this.serverError(error);
      });
  }

  isRequired(control: AbstractControl) {
    const validator = control.validator ? control.validator({} as AbstractControl) : {};
    return validator && validator.required;
  }

  isAuthorized() {
    return this.authorizationService.isAuthorized();
  }

  showPrivacyPolicy() {
    this.isPrivacyPolicyShown = true;
    showMainScroll();
  }

  closePrivacyPolicy() {
    this.isPrivacyPolicyShown = false;
    hideMainScroll();
  }

  close(event) {
    // back to login;
    this.router.navigate(['/login']);
  }

  private buildForm() {
    this.registerForm = new FormGroup({
      company: new FormControl(null, [Validators.required, Validators.nullValidator]),
      streetName: new FormControl(null, [Validators.required, Validators.nullValidator]),
      streetNumber: new FormControl(null, [Validators.required, Validators.nullValidator]),
      postalCode: new FormControl(null, [Validators.required, Validators.nullValidator]),
      city: new FormControl(null, [Validators.required, Validators.nullValidator]),
      country: new FormControl(null, [Validators.required, Validators.nullValidator]),
      customerNumber: new FormControl(null),
      vatNumber: new FormControl(null),
      titleCode: new FormControl(null, [Validators.required, Validators.nullValidator]),
      firstName: new FormControl(null, [Validators.required, Validators.nullValidator]),
      lastName: new FormControl(null, [Validators.required, Validators.nullValidator]),
      phone: new FormGroup({
        countryCode: new FormControl(null),
        nationalNumber: new FormControl(null)
      }),
      email: new FormControl(null, [Validators.required, Validators.nullValidator]),
      function: new FormControl(null),
      termsAndConditionsAccepted: new FormControl(false, [Validators.requiredTrue])
    });

    this.addSubscriptions(
      // value change event handler for Country to set value for Phone's country code
      this.registerForm.controls['country'].valueChanges.subscribe(value => {
        let info = this.globals.DEFAULT_PHONE_COUNTRY_CODES.find(code => code.isocode === value);
        this.registerForm.controls['phone'].get('countryCode').setValue(`+${info.countrycode}`);
      })
    );
  }

  private markAsTouchedForm() {
    this.registerForm.get('company').markAsTouched();
    this.registerForm.get('streetName').markAsTouched();
    this.registerForm.get('streetNumber').markAsTouched();
    this.registerForm.get('postalCode').markAsTouched();
    this.registerForm.get('city').markAsTouched();
    this.registerForm.get('country').markAsTouched();
    this.registerForm.get('customerNumber').markAsTouched();
    this.registerForm.get('vatNumber').markAsTouched();
    this.registerForm.get('titleCode').markAsTouched();
    this.registerForm.get('firstName').markAsTouched();
    this.registerForm.get('lastName').markAsTouched();
    this.registerForm.get('email').markAsTouched();
    this.registerForm.get('phone').get('countryCode').markAsTouched();
    this.registerForm.get('phone').get('nationalNumber').markAsTouched();
    this.registerForm.get('function').markAsTouched();
    this.registerForm.get('termsAndConditionsAccepted').markAsTouched();
    this.cdr.detectChanges();
    let target = document.querySelector('.form-group-danger');
    target = target ? target.querySelector('.p-input') : null;
    if (target) {
      (target as HTMLElement).focus();
    }
  }

  private transformFormValueToUserSignUpDTO(value: { [key: string]: any }): PUserSignUpWsDTO {
    const userSignUpData: PUserSignUpWsDTO = Object.assign({address: {}}, this.clearEmptyValues(value));

    if (value.phone && value.phone.nationalNumber) {
      const countryCode = value.phone.countryCode || '';
      const nationalNumber = value.phone.nationalNumber || '';
      userSignUpData.address.phone = (countryCode && nationalNumber)
        ? `${countryCode} ${(nationalNumber).trim().replace(/\s\s+/g, ' ')}` : '';
    }
    delete userSignUpData['phone'];

    userSignUpData.address.companyName = value.company;
    userSignUpData.address.country = {isocode: value.country};
    userSignUpData.address.streetname = value.streetName;
    userSignUpData.address.streetnumber = value.streetNumber;
    userSignUpData.address.postalCode = value.postalCode;
    userSignUpData.address.town = value.city;
    userSignUpData.address.formattedAddress = `${(value.streetName).trim()} ${(value.streetNumber).trim()}`;

    if (value.customerNumber) {
      userSignUpData.address.customerNumber = value.customerNumber;
    }
    if (value.vatNumber) {
      userSignUpData.address.vatNumber = value.vatNumber;
    }
    delete userSignUpData['customerNumber'];
    delete userSignUpData['vatNumber'];

    return userSignUpData;
  }

  private clearEmptyValues(value: { [key: string]: any }) {
    return Object.entries(value)
      .filter(([_, val]) => val)
      .reduce((acc, [key, val]) => ({...acc, [key]: val}), {});
  }

  private serverError(httpErrorResponse): void {
    console.log('HTTP error', httpErrorResponse);
    if (httpErrorResponse.error.errors) {
      (httpErrorResponse.error.errors).forEach(error => {
        // console output
        console.log((error.subject || (error.type || 'Error')), (error.message || 'undefined'));

        // handling specific errors
        if ((error.type).toLowerCase() === RegisterErrorEnum.EMAIL_ALREADY_REGISTERED.toLowerCase()) {
          this.errorType = RegisterErrorEnum.EMAIL_ALREADY_REGISTERED;
          return false;
        }
      });
    }
    this.error = 400;
  }
}
