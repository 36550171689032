import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthorizationService } from '../providers/plansee/authorization-service';
import { QueryRequestWsDTO } from '../providers/types/ycommercewebservices';
import { PSearchPageWsDTO } from '../providers/types/planseeoccaddon';
import { SubscriptionManager } from '../shared/components/subscriptions-manager';
import { PlanseeTranslateService } from '../providers/plansee/p-translate-service';
import { combineLatest } from 'rxjs';
import { defaultTo } from 'lodash';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';

export abstract class PPage extends SubscriptionManager {
  protected searchResult: PSearchPageWsDTO = null;

  query: QueryRequestWsDTO = null;
  onlyBookmarks = false;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService
  ) {
    super();
    this.setQueryFromParam();
    this.addSubscriptions(
      combineLatest(
        this.planseeTranslateService.onLangChange(),
        this.authorizationService.permissionsChanged
      ).subscribe(([langEvent]) => {
        this.query = this.calculateQuery(langEvent);
      })
    );
  }

  setQueryFromParam() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      if (id) {
        this.query = {
          query: this.hasWhiteSpace(id) ? `"${id}"` : id
        };
      }
    });
  }

  protected calculateQuery(langEvent: LangChangeEvent): QueryRequestWsDTO {
    return {
      query: defaultTo(this.query, { query: '' }).query,
      lang: langEvent.lang
    };
  }

  private hasWhiteSpace(value: string): boolean {
    if (!value) {
      return false;
    }

    return /\s/g.test(value);
  }
}
