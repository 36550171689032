import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { AuthorizationService } from './authorization-service';
import { QueryRequestWsDTO, SuggestionListWsDTO } from '../types/ycommercewebservices';
import { PRequestWsDTO, Translation } from '../types/planseeoccaddon';
import { Observable } from 'rxjs';
import { PService } from './p-service';
import { defaultTo } from 'lodash';
import { Invoices } from '../../components/invoices-v2/invoices-model';

// new version of p-invoice-service. The old one can be removed when whole app will switch to new version
@Injectable()
export class PlanseeInvoicesService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<Invoices> {
    const params = defaultTo(queryParams, { query: ''});
    this.currentQueryParams = Object.assign({}, queryParams);
    return this.get<Invoices>(`invoices/searchV2?query=${this.encodeLimitsForDateRange(params.query)}`,
      {params: this.stripQueryStringFromQueryObject(params)})
      .map(data => ({...data, items: defaultTo(data.invoices, [])}));
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`invoices/suggestions`, {params: queryParams});
  }

  // overwritten
  getCsvContent(downloadModuleID: string, translations: Translation[], params?: any): Observable<any> {
    if (params) {
      // update pageSize to get all found invoices of any category
      this.currentQueryParams.pageSize = params.pageSize;
    }
    // remove all invoiceCategory's from query to get all
    let _query = this.currentQueryParams.query;
    _query = _query.replace(':invoiceCategory:invoice', '')
      .replace(':invoiceCategory:credit_debit_notes', '')
      .replace(':invoiceCategory:advanced_payments', '');
    this.currentQueryParams.query = _query;

    return this.postAt(`${downloadModuleID}?query=${this.encodeLimitsForDateRange(this.currentQueryParams.query)}`, translations, {
      params: this.stripQueryStringFromQueryObject(this.currentQueryParams),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.ms-excel'
      },
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  getListCsvContent(translations: Translation[], params?: any, version2 = false): Observable<any> {
    return this.getCsvContent(`invoices/${version2 ? 'downloadV2' : 'download'}`, translations, params);
  }

  getSelectedAsXls(translations: Translation[], selectedCodes: string[], version2 = false): Observable<any> {
    return this.postAt(`invoices/${version2 ? 'downloadSelectedV2' : 'downloadSelected'}`,
      {
        codes: {
          code: selectedCodes
        },
        localizedHeaders: {
          entry: Object.entries(translations).map(([key, value]) => ({key, value}))
        }
      },
      {
        params: this.stripQueryStringFromQueryObject(this.currentQueryParams),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.ms-excel'
        },
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    );
  }
}
