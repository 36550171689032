import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  PProductExternalLinksWsDTO,
  PProductSearchPageWsDTO,
  PProductWsDTO,
  PRequestWsDTO,
  PVolumeHistoryWsDTO, Translation,
} from '../types/planseeoccaddon';
import {ProductWsDTO, QueryRequestWsDTO, SuggestionListWsDTO} from '../types/ycommercewebservices';
import {ConfigService} from './config-service';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PProductsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee/catalog/products';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<PProductSearchPageWsDTO> {
    this.currentQueryParams = Object.assign({}, queryParams);
    return this.get<PProductSearchPageWsDTO>(`search?fields=FULL&query=${this.encodeLimitsForDateRange(queryParams.query)}`,
      {params: this.stripQueryStringFromQueryObject(queryParams)});
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`suggestions`, {params: queryParams});
  }

  getProduct(productCode: string, b2bUnit?: string, queryParams?: PRequestWsDTO): Observable<PProductWsDTO> {
    const params = {
      ...(queryParams || {})
    };

    if (b2bUnit) {
      params['b2bUnit'] = b2bUnit;
    }

    return this.get<ProductWsDTO>(productCode, {params});
  }

  getVolumeHistory(productCode: string, queryParams?: PRequestWsDTO): Observable<PVolumeHistoryWsDTO> {
    return this.get<PVolumeHistoryWsDTO>(`${productCode}/volumeHistory`, {params: queryParams});
  }

  getListCsvContent(translations: Translation[]): Observable<any> {
    return this.getCsvContent(`download`, translations);
  }

  getProductExternalLinksCount(productCode: string, queryParams?: PRequestWsDTO): Observable<PProductExternalLinksWsDTO> {
    return this.get<PProductExternalLinksWsDTO>(`${productCode}/relatedItems`, {params: queryParams});
  }
}
