import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PClaimsService } from '../../providers/plansee/p-claims-service';
import { PClaimForm, PClaimReportAction } from '../../providers/types/planseeoccaddon';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionStatusEnum, PInternetConnectionService } from '../../providers/plansee/p-internet-connection-service';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'claim-form-step-4',
  template: require('./claim-form-step-4.component.html'),
  styles: [require('./claim-form-step-4.component.scss')]
})
export class ClaimFormStep4Component extends SubscriptionManager implements OnInit {

  formGroup: FormGroup;
  errorMessageOpen = false;

  constructor(
    private claimsService: PClaimsService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private internetConnectionService: PInternetConnectionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  private form: PClaimForm;
  private status: ConnectionStatusEnum = ConnectionStatusEnum.Online;
  private productPlaceholderLoaded = false;

  ngOnInit() {
    this.form = this.claimsService.getForm();
    if (this.form.claim) {
      this.form.claim.contactPerson = this.authorizationService.getUserProfile().name;
    }
    this.formGroup = new FormGroup({
      claimHeadline: new FormControl(null, [Validators.required, Validators.nullValidator]),
      customerReferenceNumber: new FormControl(null),
      problemDescription: new FormControl(null, [Validators.required, Validators.nullValidator]),
      claimMedias: new FormControl([]),
      alternativeContactEmail: new FormControl(null),
      preferredAction: new FormControl(),
      reportFormGroup: new FormGroup({
        reportActions: new FormControl('NONE', [Validators.required]),
        return8Dmedias: new FormControl()
      }, [(formGroup: FormGroup) => {
        const fields = formGroup.value;
        let isValid = true;
        if (fields.reportActions !== PClaimReportAction.RETURN_MY_8D) {
          if (fields.return8Dmedias !== null) {
            // setting the value from fields does not work
            formGroup.controls['return8Dmedias'].setValue(null);
          }
        } else {
          isValid = fields.return8Dmedias !== null;
        }
        return isValid ? null : {validateForm: {valid: false}};
      }])
    }, (formGroup: FormGroup) => {
      return formGroup.valid && this.status === ConnectionStatusEnum.Online ? null : {validateForm: {valid: false}};
    });

    this.addSubscriptions(
      this.internetConnectionService.offline.subscribe(e => {
        this.status = ConnectionStatusEnum.Offline;
        this.formGroup.updateValueAndValidity();
      }),
      this.internetConnectionService.online.subscribe(e => {
        this.status = ConnectionStatusEnum.Online;
        this.formGroup.updateValueAndValidity();
      })
    );
  }

  previous() {
    this.updateForm();
    this.form.stepNumber = 3;
    this.form.claim.shipmentPosition = '';
    this.claimsService.setForm(this.form);
  }

  /* tslint:disable:cognitive-complexity */
  fileClaim() {
    this.updateForm();
    this.claimsService.setForm(this.form);
    if (!this.formGroup.valid) {
      if (this.formGroup.get('claimHeadline').invalid) {
        this.formGroup.get('claimHeadline').markAsTouched();
        this.formGroup.get('claimHeadline').markAsDirty();
      }
      if (!this.formGroup.get('problemDescription').valid) {
        this.formGroup.get('problemDescription').markAsTouched();
        this.formGroup.get('problemDescription').markAsDirty();
      }
      if (!this.formGroup.get('alternativeContactEmail').valid) {
        this.formGroup.get('alternativeContactEmail').markAsDirty();
        this.formGroup.get('alternativeContactEmail').markAsTouched();
      }
      if (!this.formGroup.get('reportFormGroup').valid) {
        this.formGroup.get('reportFormGroup').markAsDirty();
        this.formGroup.get('reportFormGroup').markAsTouched();
      }
      this.changeDetectorRef.detectChanges();
      let target = document.querySelector('.form-group-danger');
      target = target ? target.querySelector('.p-input') : null;
      if (target) {
        (target as any).focus();
      }
      return;
    }

    // queryParams
    let data: any = {};

    this.addSubscriptions(
      this.claimsService.fileClaim(this.form, data).subscribe(response => {
        if (response) {
          this.router.navigate(['/menu/claims/confirmation']);
        } else {
          this.router.navigate(['/menu/claims']);
        }
      })
    );
  }

  /* tslint:enable:cognitive-complexity */

  fileErrorMessageOpen(event) {
    this.errorMessageOpen = event;
    this.changeDetectorRef.detectChanges();
  }

  inputProductPlaceholder() {
    if (!this.productPlaceholderLoaded) {
      this.formGroup.get('problemDescription').markAsPristine();
      this.formGroup.get('problemDescription').markAsUntouched();
      this.productPlaceholderLoaded = true;
    }
  }

  private updateForm() {
    this.form.claim.headline = this.formGroup.value.claimHeadline;
    this.form.claim.customerReferenceNumber = this.formGroup.value.customerReferenceNumber;
    this.form.claim.problemDescription = this.formGroup.value.problemDescription;
    this.form.claim.alternativeContactEmail = this.formGroup.value.alternativeContactEmail;
    this.form.claim.preferredAction = this.formGroup.value.preferredAction;
    this.form.claimMedias = this.formGroup.value.claimMedias;
    this.form.claim.reportAction = this.formGroup.value.reportFormGroup.reportActions;
    this.form.return8Dmedias = this.formGroup.value.reportFormGroup.return8Dmedias;
  }
}
