import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'ngx-oauth';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

export interface NewTokenStructure {
  accessToken?: string;
  baseSites?: string[];
  expiresIn?: number;
  refreshToken?: string;
  userGroups: string[];
}

@Injectable()
export class CustomNgxOauthService extends OAuthService {

  azureDeniedError = false;

  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    // we have to override private method because structure is different, library use access_token, but we receive accessToken
    this['setToken'] = this.customSetToken;
  }

  /*
   * Unfortunately, the ngx-oauth library does not provide a method to add custom params to login method, so I needed to
   * build this 'loginViaIdToken' method based on the 'login' method from ngx-oauth library - that's why I used private
   * method here.
   * It is not perfect solution, but in a few months we will start working on completely new app which will replace
   * this one, so it doesn’t make sense to replace current library completely in this app.
   * In new app we will use only 'angular-auth-oidc-client' library to manage whole authorization flow
   * (now this library is used only to build proper connection to azure).
   */
  loginViaIdToken(idToken: string, username: string) {
    this.closeAzureError();
    let body = new HttpParams().set('client_id', this.clientId);
    body = body.set('client_secret', this.clientSecret);
    body = body.set('grant_type', 'custom');
    body = body.set('username', username);
    body = body.set('token', idToken);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post(this.tokenPath, body, {headers}).pipe(catchError( error => {
      this.status = OAuthEvent.DENIED;
      this.onStatus.emit(OAuthEvent.DENIED);
      this.showAzureError();
      return EMPTY;
    }))
      .pipe(take(1))
      .subscribe(params => {
        this.closeAzureError();
        // @ts-ignore
        this.customSetToken(params);
      });
  }

  showAzureError() {
    this.azureDeniedError = true;
  }

  closeAzureError() {
    this.azureDeniedError = false;
  }

  private customSetToken(params: NewTokenStructure) {
    const oldTokenStructure = {
      access_token: params.accessToken || params['access_token'],
      refresh_token: params.refreshToken || params['refresh_token'],
      token_type: 'bearer',
      state: '',
      error: '',
      expires_in: params.expiresIn || params['expires_in'],
    };
    super['setToken'](oldTokenStructure);
  }
}
