import {Pipe, PipeTransform} from '@angular/core';
import { PSelectValue } from '../form-components/p-select/p-select-value';

@Pipe({
  name: 'takeTitleName'
})
export class TakeTitleNamePipe implements PipeTransform {

  transform(titles: PSelectValue[], selectedTitle: string): string {
    const title = titles.find(t => t.value === selectedTitle);

    return title ? title.label : '';
  }
}
