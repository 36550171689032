import { Injectable } from '@angular/core';
import { ConfigService } from './config-service';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class PAdobeTmService {

  constructor(
    private configService: ConfigService
  ) {
  }

  init() {
    this.configService.onConfigSet
      .pipe(
        filter((isSet: boolean) => isSet),
        take(1)
      )
      .subscribe(() => {
        const tmUrl = this.configService.config.adobeTmUrl;

        this.addScriptToHeader(tmUrl);
        this.addScriptToBody();
      });

  }

  private addScriptToBody() {
    const bodyElement = document.getElementsByTagName('body')[0];

    const adobePageBottomScript = document.createElement('script');
    adobePageBottomScript.type = 'text/javascript';
    adobePageBottomScript.text = 'window._satellite ? _satellite.pageBottom() : null;';

    bodyElement.appendChild(adobePageBottomScript);
  }

  private addScriptToHeader(url: string) {
    const headElement = document.getElementsByTagName('head')[0];

    const adobeLunchScript = document.createElement('script');
    adobeLunchScript.src = url;
    adobeLunchScript.type = 'text/javascript';

    headElement.appendChild(adobeLunchScript);
  }
}
