import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { I18N_MONTH_KEYS, I18N_WEEKDAY_KEYS } from '../../form-components/p-date/p-date-utils';

@Injectable()
export class CustomDatepickerI18nService extends NgbDatepickerI18n {
  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translateService.instant(I18N_WEEKDAY_KEYS[weekday - 1]);
  }

  getWeekLabel(): string {
    return '';
  }

  getMonthShortName(month: number): string {
    return this.translateService.instant(I18N_MONTH_KEYS[month - 1]);
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
