import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardShipment} from '../../../../providers/types/dashboard/dashboard-shipment';
import {stringToDate} from '../../../../../utils';

@Component({
  selector: 'dashboard-shipments-table',
  template: require('./dashboard-shipments-table.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardShipmentsTableComponent {

  @Input()
  set shipments(items: DashboardShipment[]) {
    this.items = items
      .sort((s1, s2) =>
        stringToDate(s2.namedDeliveryDate).getTime() - stringToDate(s1.namedDeliveryDate).getTime());
  }

  items: DashboardShipment[] = [];
}
