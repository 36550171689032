
import {fromEvent as observableFromEvent, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

export enum ConnectionStatusEnum {
  Online = 'Online',
  Offline = 'Offline'
}

@Injectable()
export class PInternetConnectionService {

  online: Observable<Event> = observableFromEvent(window, 'online');
  offline: Observable<Event> = observableFromEvent(window, 'offline');

}
