import {Injectable} from '@angular/core';
import {PGraphService} from './p-graph.service';

@Injectable()
export class PForecastGraphService extends PGraphService {

  // number of columns displayed in each view type for Forecasts module (It could change in time) + 1.
  // The (+1) is needed because graphs have 1 additional value added to that the labels are aligned with the table on it's edges
  MONTH_POINTS = 4;
  WEEK_POINTS = 8;
}
