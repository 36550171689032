import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PB2bUnitWsDTO } from '../../providers/types/planseeoccaddon';
import { AppMode } from '../../providers/types/app-mode';

@Component({
  selector: 'customer-selection',
  template: require('./customer-selection.component.html'),
  styles: [require('./customer-selection.component.scss')]
})
export class CustomerSelectionComponent {

  @Input()
  showDismiss = false;
  @Input()
  companies: PB2bUnitWsDTO[];
  @Output()
  submit: EventEmitter<PB2bUnitWsDTO> = new EventEmitter();
  @Output()
  setCustomerView: EventEmitter<boolean> = new EventEmitter();
  @Output()
  setNewAppMode: EventEmitter<boolean> = new EventEmitter();
  @Output()
  dismiss: EventEmitter<void> = new EventEmitter();
  @Input()
  current: PB2bUnitWsDTO;
  @Input()
  customerView: boolean;

  @Input()
  set selected(selected: PB2bUnitWsDTO) {
    // decouple
    if (selected && !this._selected) {
      this._selected = selected;
    }
  }

  @Input()
  set currentAppMode(appMode: AppMode) {
    this.newAppMode = appMode === AppMode.NEW_VERSION;
  }

  newAppMode: boolean;

  private _selected: PB2bUnitWsDTO;
  private freeTextSearch = '';
  private filteredCompanies = null;

  getBtnBackground(company) {
    return JSON.stringify(company) === JSON.stringify(this._selected) ? 'btn-primary' : 'btn-light';
  }

  getBtnColor(company) {
    return JSON.stringify(company) !== JSON.stringify(this._selected) &&
      JSON.stringify(company) === JSON.stringify(this.current) ? 'text-primary' : '';
  }

  onInput(event: KeyboardEvent) {
    const searchPhrase = this.freeTextSearch.trim().toLocaleLowerCase();
    const filter = company => (company.companyName && company.companyName.toLocaleLowerCase().indexOf(searchPhrase) !== -1) ||
                              (company.customerNumber && company.customerNumber.toLocaleLowerCase().indexOf(searchPhrase) !== -1);

    if (searchPhrase.length > 1) {
      this.filteredCompanies = this.companies.filter(filter);
    } else {
      this.filteredCompanies = null;
    }

    // autoselect customer if only 1 is provided
    if (this.filteredCompanies && this.filteredCompanies.length === 1) {
      this._selected = this.filteredCompanies[0];
    }
  }
}
