import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  PConsignmentEntryListWsDTO,
  PConsignmentScheduleListWsDTO,
  POrderEntrySearchPageWsDTO,
  PRequestWsDTO,
  PConsignmentEntryDetailsWsDTO, Translation,
} from '../types/planseeoccaddon';
import {QueryRequestWsDTO, SuggestionListWsDTO} from '../types/ycommercewebservices';
import {ConfigService} from './config-service';
import {PService} from './p-service';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class POrdersService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO, onlyBookmarks = false): Observable<POrderEntrySearchPageWsDTO> {
    this.currentQueryParams = Object.assign({}, queryParams);

    if (onlyBookmarks) {
      return this.get<POrderEntrySearchPageWsDTO>(
        `orderentries/bookmarkedOrderEntries?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
        {params: this.stripQueryStringFromQueryObject(queryParams)}
      );
    }

    return this.get<POrderEntrySearchPageWsDTO>(`orderentries/search?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
      {params: this.stripQueryStringFromQueryObject(queryParams)});
  }

  suggestions(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`orderentries/suggestions`, {params: queryParams});
  }

  getConsignmentEntries(orderCode: string,
                        entryNumber: number,
                        queryParams?: PRequestWsDTO): Observable<PConsignmentEntryListWsDTO> {
    return this.get<PConsignmentEntryListWsDTO>(`orderentries/consignments/${orderCode}/${entryNumber}`, {params: queryParams});
  }

  getConsignmentEntryDetails(orderCode: string,
    entryNumber: number,
    queryParams?: PRequestWsDTO): Observable<PConsignmentEntryDetailsWsDTO> {
      return this.get<PConsignmentEntryDetailsWsDTO>(`orderentries/${orderCode}/${entryNumber}/shipmentsummary`, {params: queryParams});
  }

  getConsignmentSchedules(orderCode: string,
                        entryNumber: number,
                        queryParams?: PRequestWsDTO): Observable<PConsignmentScheduleListWsDTO> {
    return this.get<PConsignmentScheduleListWsDTO>(`orderentries/schedules/${orderCode}/${entryNumber}`, {params: queryParams});
  }

  suggestPurchaseOrderNrField(queryParams?: {
    max?: number
    term?: string
  } & PRequestWsDTO): Observable<SuggestionListWsDTO> {
    return this.get<SuggestionListWsDTO>(`orderentries/suggestPurchaseOrderNrField`, {params: queryParams});
  }

  getListCsvContent(translations: Translation[]): Observable<any> {
    return this.getCsvContent(`orderentries/download`, translations);
  }

  updateBookmark(orderCode: string, entryNumber: number, selected: boolean): Observable<string> {
    return selected ? this.addBookmark(orderCode, entryNumber) : this.removeBookmark(orderCode, entryNumber);
  }

  private addBookmark(orderCode: string, entryNumber: number): Observable<string> {
    return this.get<string>(`orderentries/bookmark/add/${orderCode}/${entryNumber}`);
  }

  private removeBookmark(orderCode: string, entryNumber: number): Observable<string> {
    return this.get<string>(`orderentries/bookmark/remove/${orderCode}/${entryNumber}`);
  }
}
