import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardOrder} from '../../../../providers/types/dashboard/dashboard-order';
import {stringToDate} from '../../../../../utils';
import {defaultTo} from 'lodash';

@Component({
  selector: 'dashboard-orders-table',
  template: require('./dashboard-orders-table.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardOrdersTableComponent {

  @Input()
  set orders(items: DashboardOrder[]) {
    const itemsWithSortedRequestedDeliveries: DashboardOrder[] = items.map(order => {
      const requestedDeliveries = defaultTo(order.requestedDeliveries, []);
      const sortedRequestedDeliveries = !requestedDeliveries.length ? [] : requestedDeliveries
        .sort((d1, d2) => stringToDate(d2.date).getTime() - stringToDate(d1.date).getTime());

      return {
        ...order,
        requestedDeliveries: sortedRequestedDeliveries
      };
    });

    this.items = itemsWithSortedRequestedDeliveries
      .sort((o1, o2) => {
        const defaultRequestedDelivery = {date: ''};
        const order1Date = defaultTo(o1.requestedDeliveries[0], defaultRequestedDelivery).date;
        const order2Date = defaultTo(o2.requestedDeliveries[0], defaultRequestedDelivery).date;

        return stringToDate(order2Date).getTime() - stringToDate(order1Date).getTime();
      });
  }

  items: DashboardOrder[] = [];
}
