import {Component} from '@angular/core';
import {DataTableParams} from 'ngx-datatable-bootstrap4';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PShipmentsService} from '../../providers/plansee/p-shipments-service';
import {PShipmentWsDTO} from '../../providers/types/planseeoccaddon';
import {PDataTable} from '../p-data-table';
import {TranslateService} from '@ngx-translate/core';
import {LoadingService} from '../../providers/plansee/loading-service';

@Component({
  selector: 'shipments-list',
  template: require('./shipments-list.component.html'),
  styles: [require('./shipments-list.component.scss')]
})
export class ShipmentsListComponent extends PDataTable<PShipmentWsDTO> {
  items: PShipmentWsDTO[] = [];
  defaultSort = 'namedDeliveryDate-desc';

  constructor(private shipmentService: PShipmentsService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService,
              loadingService: LoadingService) {
    super(i18nService, authorizationService, loadingService);
  }

  reloadItems(params: DataTableParams) {
    this.updateQuery(params);
    if (JSON.stringify(this._query) === JSON.stringify(this.oldQuery)) {
      return;
    }
    this.areFacetsApplied = !!this._query.query;
    this.oldQuery = Object.assign({}, this._query);
    this.shipmentService.search(this._query).subscribe(searchResult => {
      this.items = searchResult.consignments;

      // Update pagination with the counts comming from BE
      this.refreshPagination(searchResult.pagination);

      // save current pagination information for using it in csv download
      this.shipmentService.setPaginationInformations(searchResult.pagination);

      // send response to search components
      this.searchResultChange.emit(searchResult);
    });
  }
}
