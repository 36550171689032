import {Injectable} from '@angular/core';
import {ConfigService} from './config-service';

@Injectable()
export class PGoogleAnalytics {
  //
  containerId: string;
  dataLayer: any;

  constructor(configService: ConfigService) {
    // GTM dataLayer
    window['dataLayer'] = window['dataLayer'] || [];
    this.dataLayer = window['dataLayer'];
    this.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    // GTM containerId
    this.containerId = configService.config.gtmContainerId;
  }

  init() {
    // create GTM snippet and add it into head
    let head = document.getElementsByTagName('head')[0];
    let j = document.createElement('script');
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${this.containerId}`;
    head.appendChild(j);
  }

  track(page: string | URL) {
    if (this.dataLayer) {
      this.dataLayer.push({
        'originalLocation': document.location.href
      });
    }
  }
}
