import { Component, OnInit } from '@angular/core';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { startWith, switchMap } from 'rxjs/operators';
import { DashboardWsDTO } from '../../providers/types/dashboard/dashboard';
import { combineLatest } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { PlanseeDashboardService } from '../../providers/plansee/plansee-dashboard.service';

@Component({
  selector: 'dashboard-page',
  template: require('./dashboard.page.html')
})
export class DashboardPageComponent extends SubscriptionManager implements OnInit {

  dashboardData: DashboardWsDTO;

  constructor(
    private authorizationService: AuthorizationService,
    private planseeDashboardService: PlanseeDashboardService,
    private planseeTranslateService: PlanseeTranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      combineLatest(
        this.planseeTranslateService.onLangChange(),
        this.authorizationService.changedCompany.pipe(startWith('')),
        this.authorizationService.changedLocation.pipe(startWith(''))
      ).pipe(
        switchMap(() => this.planseeDashboardService.getDashboardData())
      ).subscribe(dashboardData => {
        this.dashboardData = dashboardData;
      })
    );
  }
}
