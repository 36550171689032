import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardOrder} from '../../../providers/types/dashboard/dashboard-order';

@Component({
  selector: 'dashboard-orders',
  template: require('./dashboard-orders.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardOrdersComponent {

  @Input() orders: DashboardOrder[];
}
