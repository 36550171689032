import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from './config-service';
import {PService} from './p-service';
import {RequestWsDTO, UserSignUpWsDTO, UserWsDTO} from '../types/ycommercewebservices';
import { AuthorizationService } from './authorization-service';

@Injectable()
export class PAuthenticationService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  // this service is reserved for authentication methods like: reset, update password, register, etc.

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  resetPassword(userId: string): Observable<void> {
    return this.postAt('forgottenpasswordtokens', this.toHttpParams({userId: userId}));
  }

  updatePassword(params: {token: string, newPassword: string, termsAndConditionsAccepted: boolean}): Observable<void> {
    return this.postAt('updatepassword', this.toHttpParams(params));
  }

  changePassword(userId: string, params: {old: string, new: string}): Observable<void> {
    return this.put(`users/${userId}/password`, this.toHttpParams(params));
  }

  registerUser(user: UserSignUpWsDTO, queryParams: RequestWsDTO & {myplansee: boolean}): Observable<UserWsDTO> {
    return this.postAt<UserWsDTO>('users', user, {params: queryParams});
  }

  sendCountingRequest(userId: string): Observable<void> {
    return this.patch(`users/${userId}/setLoginDate`, this.toHttpParams({}));
  }
}
