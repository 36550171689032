import {Component, Input} from '@angular/core';
import {Faq} from '../../providers/types/faq';

@Component({
  selector: 'login-faqs',
  template: require('./login-faqs.component.html'),
})
export class LoginFaqsComponent {

  @Input() faqs: Faq[] = [];
}
