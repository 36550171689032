import {Component, ContentChildren, QueryList} from '@angular/core';
import {NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import {NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap/carousel/carousel';

@Component({
  selector: 'plansee-carousel',
  template: require('./plansee-carousel.component.html'),
  styles: [require('./plansee-carousel.component.scss')]
})
export class PlanseeCarouselComponent {

  @ContentChildren(NgbSlide, {descendants: true})
  set items(slides: QueryList<NgbSlide>) {
    this.slides = slides;

    if (this.activeSlideIndex >= slides.length) {
      this.activeSlideIndex = 0;
    }
  }

  slides: QueryList<NgbSlide>;
  idPrefix = 'plansee-slide-';
  activeSlideIndex = 0;


  onSlide(event: NgbSlideEvent) {
    this.activeSlideIndex = Number(event.current.split(this.idPrefix)[1]);
  }
}
