import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  i18n: any[];

  constructor(i18nService: TranslateService) {
    i18nService.stream([
      'fileSize.K',
      'fileSize.M',
      'fileSize.G'
    ]).subscribe((value) => {
      this.i18n = value;
    });
  }

  transform(value: number, ...args: any[]): string {
    if (Math.floor(value / 1000000000) > 0) {
      return `${value / 1000000000}${this.i18n['fileSize.G']}`;
    } else if (Math.floor(value / 1000000) > 0) {
      return `${value / 1000000}${this.i18n['fileSize.M']}`;
    } else if (Math.floor(value / 1000) > 0) {
      return `${value / 1000}${this.i18n['fileSize.K']}`;
    }
    return `${value}`;
  }
}
