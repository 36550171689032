import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'urlEncode'
})
export class UrlEncodePipe implements PipeTransform {

  // encodes special characters in a string (e.g. identifier) used in url's
  transform(value: string): string {
    return value ? encodeURIComponent(String(value)) : '';
  }
}
