import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlanseeTableTab } from '../plansee-table-model';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap/tabset/tabset';

@Component({
  selector: 'plansee-table-tabs',
  template: require('./plansee-table-tabs.component.html'),
  styles: [require('./plansee-table-tabs.component.scss')]
})
export class PlanseeTableTabsComponent {
  @Input() set tabs(tabs: PlanseeTableTab[]) {
    this._tabs = tabs || [];
    this.setSelectedTab();
  }

  get tabs(): PlanseeTableTab[] {
    return this._tabs || [];
  }
  @Output() tabChange = new EventEmitter<string>();

  selectedTab: PlanseeTableTab | null;

  private _tabs: PlanseeTableTab[];

  onTabChange(event: NgbTabChangeEvent) {
    const selectedTab = this.tabs.find(t => t.id === event.nextId);
    if (selectedTab) {
      this.tabChange.emit(selectedTab.id);
    }
  }

  private setSelectedTab() {
    if (this.tabs.length) {
      this.selectedTab = this.tabs.find(t => t && t.active);
      if (!this.selectedTab) {
        setTimeout(() => this.tabChange.emit(this.tabs[0].id));
      }
    } else {
      this.selectedTab = null;
    }
  }
}
