import {Component, Input} from '@angular/core';
import {PClaimTimeline, PClaimWsDTO} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'claim-info',
  template: require('./claim-info.component.html'),
  styles: [require('./claim-info.component.scss')]
})
export class ClaimInfoComponent {
  @Input()
  set entry(e: PClaimWsDTO) {
    const timelines: PClaimTimeline[] = [{type: 'D3'}, {type: 'D6'}, {type: 'D8'}];
    this._entry = e;
    if (e.claim && e.claim.claimTimelines) {
      for (let timeline of timelines) {
        Object.assign(timeline, this._entry.claim.claimTimelines.find(value => timeline.type === value.type));
      }
      this._entry.claim.claimTimelines = timelines;
    }
  }

  get entry(): PClaimWsDTO {
    return this._entry;
  }

  private _entry: PClaimWsDTO;

}
