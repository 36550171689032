import {Pipe, PipeTransform} from '@angular/core';
import {PDatePipe} from './pDate.pipe';

@Pipe({name: 'getForecastValue'})
export class GetForecastValuePipe implements PipeTransform {
    private datePipe = new PDatePipe();
    transform(entries, code, filter, prop) {
      if (!Array.isArray(entries)) {
          return '';
      }
      let segment = entries.find(entry => this.datePipe.transform(entry.firstDayOfPeriod, filter, true) === code);
      return segment ? segment[prop] && segment[prop] > 0 ? segment[prop] : '' : '';
    }
}
