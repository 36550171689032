import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {formatNumber} from '@angular/common';
import {ChartViewType, PForecastEntryWsDTO, PForecastWsDTO} from '../../providers/types/planseeoccaddon';
import {ChartData, ChartTooltipItem, LinearTickOptions} from 'chart.js';
import {ChartAnnotatedOptions, LineAnnotationEntry} from '../../providers/types/chartjs-annotations';
import {PForecastGraphService} from '../../providers/plansee/p-forecast-graph-service';
import {Subscription} from 'rxjs';
import Chart = require('chart.js');
import { TranslateService } from '@ngx-translate/core';

interface ChartExtended extends Chart {
  annotation: any;
}

const WEEK_POINTS = 9;
const MONTH_POINTS = 5;

@Component({
  selector: 'forecast-volume-chart',
  template: require('./forecast-volume-chart.component.html'),
  styles: [require('./forecast-volume-chart.component.scss')]
})
export class ForecastVolumeChartComponent implements OnInit, OnDestroy {

  @Input()
  productForecast: PForecastWsDTO;

  constructor(
    private elementRef: ElementRef,
    private forecastGraphService: PForecastGraphService,
    private translateService: TranslateService
  ) {
  }

  private lineData: ChartData = {
    labels: [],
    datasets: [
      {
        hideInLegendAndTooltip: true,
        label: this.translateService.instant('forecastsList.forecastChart.forecasted'),
        type: 'line',
        lineTension: 0.1,
        backgroundColor: 'transparent',
        borderColor: '#3bdbfb',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        pointStyle: 'line',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 8,
        pointHitRadius: 8,
        data: [],
        spanGaps: false
      },
      {
        hideInLegendAndTooltip: true,
        label: this.translateService.instant('forecastsList.forecastChart.confirmedQuantity'),
        fill: true,
        type: 'line',
        lineTension: 0.1,
        backgroundColor: '#0098F0',
        borderColor: '#0973bb',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        pointStyle: 'line',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 8,
        pointHitRadius: 8,
        data: [],
        spanGaps: false
      },
      {
        hideInLegendAndTooltip: true,
        label: this.translateService.instant('forecastsList.forecastChart.futureQuantity'),
        type: 'line',
        lineTension: 0.1,
        backgroundColor: 'transparent',
        borderColor: '#0973bb',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        pointStyle: 'line',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 8,
        pointHitRadius: 8,
        data: [],
        spanGaps: false
      },
      {
        hideInLegendAndTooltip: true,
        label: this.translateService.instant('forecastsList.forecastChart.futureForecasts'),
        fill: true,
        type: 'line',
        lineTension: 0.1,
        backgroundColor: '#43F4FD',
        borderColor: '#6EFDFD',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointStyle: 'line',
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 8,
        pointHitRadius: 8,
        data: [],
        spanGaps: false
      }
    ]
  };
  private lineOptions: ChartAnnotatedOptions = {
    animation: {
      duration: 0
    },
    hover: {
      animationDuration: 0
    },
    maintainAspectRatio: false,
    tooltips: {
      mode: 'single',
      backgroundColor: '#fff',
      bodyFontColor: '#000',
      borderColor: '#97b3ce',
      borderWidth: 1,
      displayColors: false,
      callbacks: {
        title: () => {
          return '';
        },
        label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
          return `${formatNumber(+tooltipItem.yLabel, 'de', '1.0-3')} ${this.productForecast.unitOfMeasurement} / ${tooltipItem.xLabel}`;
        }
      }
    },
    scales: {
      xAxes: [{
        display: false,
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
          offsetGridLines: false,
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0, 0, 0, 0)'
        },
        afterFit: function (scaleInstance) {
          scaleInstance.width = 40; // sets the width of the Ylabels to 40px in order to align graph with table above
        },
        ticks: <LinearTickOptions>{
          min: 0,
          fontSize: 9,
          // this value will be overridden if the scale is greater than this value
          suggestedMax: 10,
          beginAtZero: true,
        }
      }]
    },
    legend: {
      position: 'bottom',
      labels: {
        filter: (legendItem, chartData) => {
          return !(
            legendItem.text === this.translateService.instant('forecastsList.forecastChart.futureQuantity') ||
            legendItem.text === this.translateService.instant('forecastsList.forecastChart.futureForecasts')
          );
        },
        usePointStyle: true
      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: -10
      }
    },
    annotation: {
      annotations: [
        <LineAnnotationEntry>{
          type: 'line',
          mode: 'vertical',
          id: 'todayLine',
          scaleID: 'x-axis-0',
          value: '',
          borderColor: '#22A2EC',
          borderWidth: 2
        }
      ]
    }
  };
  private forecastedPastValues: number[] = [];
  private forecastedFutureValues: number[] = [];
  private confirmedPastQuantity: number[] = [];
  private futureConfirmedQuantity: number[] = [];
  private chart: ChartExtended;
  private subscription: Subscription = null;

  init() {
    this.buildFillGradient();
    this.forecastGraphService.labels.forEach(label => {
      this.forecastedPastValues.push(0);
      this.forecastedFutureValues.push(0);
      this.confirmedPastQuantity.push(0);
      this.futureConfirmedQuantity.push(0);
    });
    for (let i = 0; i < this.productForecast.segments.length; i++) {
      let entry = this.productForecast.segments[i];
      const dateObject = this.forecastGraphService.datePipe.transform(entry.firstDayOfPeriod, 'full');
      let newLabel = this.forecastGraphService.viewType === ChartViewType.WEEKLY ?
        `${dateObject.week} ${dateObject.year}` : `${dateObject.month} ${dateObject.year}`;
      this.createChartData(entry, newLabel);
    }
    this.lineOptions.annotation.annotations[0].value = this.forecastGraphService.todayLabel;
    this.subscription = this.forecastGraphService.positionSubject.subscribe(position => {
      this.setChartData();
    });
  }

  buildFillGradient() {
    const ctx = this.elementRef.nativeElement.querySelector('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(1, '#418fde');
    gradient.addColorStop(0, '#43F4FD');
    this.lineData.datasets[3].backgroundColor = gradient;
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 300);
    gradient1.addColorStop(1, '#0973bb');
    gradient1.addColorStop(0, '#0990ec');
    this.lineData.datasets[1].backgroundColor = gradient1;
  }

  setChartData() {
    const POINTS = this.forecastGraphService.viewType === ChartViewType.WEEKLY ? WEEK_POINTS : MONTH_POINTS;
    this.lineData.labels = this.forecastGraphService.labels.slice(this.forecastGraphService.pos,
      this.forecastGraphService.pos + POINTS);
    this.lineData.datasets[0].data = this.forecastedPastValues.slice(this.forecastGraphService.pos,
      this.forecastGraphService.pos + POINTS);
    this.lineData.datasets[1].data = this.confirmedPastQuantity.slice(this.forecastGraphService.pos,
      this.forecastGraphService.pos + POINTS);
    this.lineData.datasets[3].data = this.forecastedFutureValues.slice(this.forecastGraphService.pos,
      this.forecastGraphService.pos + POINTS);
    this.lineData.datasets[2].data = this.futureConfirmedQuantity.slice(this.forecastGraphService.pos,
      this.forecastGraphService.pos + POINTS);
    if (this.chart) {
      if (this.lineData.labels.indexOf(this.forecastGraphService.todayLabel) < 0) {
        this.chart.annotation.elements['todayLine'].options.type = '';
        this.chart.annotation.elements['todayLine'].options.borderWidth = 0;
        this.chart.annotation.elements['todayLine'].options.borderColor = '#FFF';
        this.chart.annotation.elements['todayLine'].options.value =
              this.forecastGraphService.labels[this.forecastGraphService.pos + POINTS - 1];
      } else {
        this.chart.annotation.elements['todayLine'].options.type = 'line';
        this.chart.annotation.elements['todayLine'].options.borderWidth = 2;
        this.chart.annotation.elements['todayLine'].options.borderColor = '#22A2EC';
        this.chart.annotation.elements['todayLine'].options.value = this.forecastGraphService.todayLabel;
      }
      this.chart.update();
    }
  }

  onChartCreated($event) {
    this.chart = $event.chart;
  }

  ngOnInit() {
    if (this.productForecast && this.productForecast.segments) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private createChartData(entry: PForecastEntryWsDTO, newLabel: string) {
    const existingIndex = this.forecastGraphService.labels.indexOf(newLabel);
    if (existingIndex < 0) {
      return;
    }
    if (existingIndex > this.forecastGraphService.todayIndex) {
      this.forecastedFutureValues[existingIndex] = entry.forecastQuantity ?
        Math.round((this.forecastedFutureValues[existingIndex] + Number(entry.forecastQuantity)) * 1000) / 1000 : 0;
      this.futureConfirmedQuantity[existingIndex] = entry.orderedQuantity ?
        Math.round((this.futureConfirmedQuantity[existingIndex] + Number(entry.orderedQuantity)) * 1000) / 1000 : 0;
      this.forecastedPastValues[existingIndex] = null;
      this.confirmedPastQuantity[existingIndex] = null;
      return;
    } else if (existingIndex < this.forecastGraphService.todayIndex) {
      this.forecastedPastValues[existingIndex] = entry.forecastQuantity ?
        Math.round((this.forecastedPastValues[existingIndex] + Number(entry.forecastQuantity)) * 1000) / 1000 : 0;
      this.confirmedPastQuantity[existingIndex] = entry.orderedQuantity ?
        Math.round((this.confirmedPastQuantity[existingIndex] + Number(entry.orderedQuantity)) * 1000) / 1000 : 0;
      this.forecastedFutureValues[existingIndex] = null;
      this.futureConfirmedQuantity[existingIndex] = null;
      return;
    }
    this.forecastedFutureValues[existingIndex] = entry.forecastQuantity ?
      Math.round((this.forecastedFutureValues[existingIndex] + Number(entry.forecastQuantity)) * 1000) / 1000 : 0;
    this.forecastedPastValues[existingIndex] = entry.forecastQuantity ?
      Math.round((this.forecastedPastValues[existingIndex] + Number(entry.forecastQuantity)) * 1000) / 1000 : 0;
    this.confirmedPastQuantity[existingIndex] = entry.orderedQuantity ?
      Math.round((this.confirmedPastQuantity[existingIndex] + Number(entry.orderedQuantity)) * 1000) / 1000 : 0;
    this.futureConfirmedQuantity[existingIndex] = entry.orderedQuantity ?
      Math.round((this.futureConfirmedQuantity[existingIndex] + Number(entry.orderedQuantity)) * 1000) / 1000 : 0;
  }
}
