import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { ConfigService } from '../../providers/plansee/config-service';
import { PPage } from '../p-page';
import { ActivatedRoute } from '@angular/router';
import { PAuthenticationService } from '../../providers/plansee/p-authentication-service';
import { GlobalsService } from '../../providers/types/globals.service';
import { hideMainScroll, showMainScroll, updatePassword, validateAllFields } from '../../../utils';
import { ResourceOAuthSettings } from '../../settings/resource-oauth-settings';
import { take } from 'rxjs/operators';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { CustomNgxOauthService } from '../../providers/plansee/custom-ngx-oauth.service';

@Component({
  selector: 'set-password-page',
  template: require('./set-password.page.html'),
  styles: [require('./set-password.page.scss')]
})
export class SetPasswordPageComponent extends PPage implements OnInit {

  @ViewChild('cardContent') cardContent: ElementRef;

  error: number = null;
  setPasswordForm: FormGroup;
  displayTC = false;
  isConfirmed = false;
  isPrivacyPolicy = false;
  isUpdatePage = false;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private authenticationService: PAuthenticationService,
    private configService: ConfigService,
    private oauthService: CustomNgxOauthService,
    public globals: GlobalsService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(take(1))
      .subscribe(data => {
        this.isUpdatePage = data[updatePassword];

        this.setPasswordForm = new FormGroup({
          password: new FormControl(null, [
            Validators.required,
            Validators.pattern(this.globals.getRegExpForPW())
          ]),
          passwordRepeat: new FormControl(null, [Validators.required]),
          termConditions: new FormControl(false, [Validators.requiredTrue])
        });

        this.setPasswordForm.validator = this.matchPassword.bind(this);

        this.fetchToken();
      });
  }

  setPassword(e) {
    e.preventDefault();
    this.error = null; // reset error

    if (!this.setPasswordForm.valid) {
      validateAllFields(this.setPasswordForm);

      setTimeout(() => {
        const notValidGroup = this.cardContent.nativeElement.querySelector('.form-group-danger');
        const target: HTMLElement = notValidGroup ? notValidGroup.querySelector('input') : null;

        if (target) {
          target.focus();
        }
      }, 0);

      return;
    }

    return this.authenticationService.updatePassword({
      // will be passed as url parameter
      token: decodeURIComponent(this.query.query),
      newPassword: this.setPasswordForm.value.password,
      termsAndConditionsAccepted: this.setPasswordForm.value.termConditions
    }).subscribe(response => {
      this.isConfirmed = true;
      this.authorizationService.logout(false);
    }, error => {
      this.serverError(error);
    }, () => {
      // HTTP request completed
    });
  }

  goToLogin() {
    this.authorizationService.logout();
  }

  fetchToken() {
    const config = this.configService.config;

    if (config) {
      const oauthConfig = new ResourceOAuthSettings()
        .buildWithConfig(config);
      this.oauthService.configure(oauthConfig);
    }

    this.oauthService.login();
  }

  matchPassword(group: FormGroup) {
    let password = group.controls.password.value || '';
    let passwordRepeat = group.controls.passwordRepeat.value || '';

    if (passwordRepeat.length <= 0) {
      return null;
    }

    if (passwordRepeat !== password) {
      return {
        notEquivalent: true
      };
    }

    return null;
  }

  isAuthorized() {
    return this.authorizationService.isAuthorized();
  }

  showPrivacyPolicy() {
    this.isPrivacyPolicy = true;
    hideMainScroll();
  }

  hidePrivacyPolicy() {
    this.isPrivacyPolicy = false;
    showMainScroll();
  }

  private serverError(httpErrorResponse): void {
    console.log('HTTP error', httpErrorResponse);
    if (httpErrorResponse.error.errors) {
      (httpErrorResponse.error.errors).forEach(error => {
        console.log((error.subject) || 'Error', error.message);
      });
    }
    this.error = 400;
  }
}
