import {Component, Input} from '@angular/core';
import {PB2bUnitWsDTO, PUserWsDTO, PB2UnitContactInfo, PB2UnitContactAddress} from '../../providers/types/planseeoccaddon';

@Component({
  selector: 'user-profile',
  template: require('./user-profile.component.html'),
  styles: [require('./user-profile.component.scss')]
})
export class UserProfileComponent {

  get isChangePasswordOpen(): boolean {
    return this._isChangePasswordOpen;
  }


  @Input()
  user: PUserWsDTO;
  @Input()
  company: PB2bUnitWsDTO;
  @Input()
  contactInfo: PB2UnitContactInfo;
  @Input()
  contactAddress: PB2UnitContactAddress;
  @Input()
  rootCompany: PB2bUnitWsDTO;
  @Input()
  showCompany: boolean;

  private _isChangePasswordOpen = false;

  toggleChangePasswordOverlay() {
    this._isChangePasswordOpen = !this._isChangePasswordOpen;
  }
}
