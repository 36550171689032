import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(items: any[], sortBy: string, order: 'desc' | 'asc' = 'asc'): any[] {
    return orderBy(items, [sortBy], [order]);
  }

}
