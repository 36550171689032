import {ResourceOAuthConfig} from 'ngx-oauth';
import {PConfig} from '../providers/types/planseeoccaddon';

export class ResourceOAuthSettings implements ResourceOAuthConfig {
  tokenPath = 'planseecommercewebservices/v2/plansee/login';
  profileUri = '';
  clientId = '';
  clientSecret = '';
  username = '';
  password = '';
  grantType = 'client_credentials';
  storage = localStorage;

  buildWithConfig(config: PConfig, isLoginPage = false): ResourceOAuthSettings {
    const oauthConfig = new ResourceOAuthSettings();

    if (isLoginPage) {
      oauthConfig.profileUri = 'planseecommercewebservices/v2/plansee/users/current?fields=FULL&checkSUFirstLogin=1';
      oauthConfig.grantType = 'password';
      oauthConfig.clientId = config.clientId;
      oauthConfig.clientSecret = config.clientSecret;
    } else {
      oauthConfig.clientId = config.clientIdAnonymous;
      oauthConfig.clientSecret = config.clientSecretAnonymous;
    }

    oauthConfig.tokenPath = `${config.apiHost}/${oauthConfig.tokenPath}`;
    oauthConfig.profileUri = `${config.apiHost}/${oauthConfig.profileUri}`;

    return oauthConfig;
  }
}
