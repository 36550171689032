import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {PaginationWsDTO} from '../../providers/types/ycommercewebservices';
import {PFacet} from '../p-facet';
import { defaultTo } from 'lodash';

@Component({
  selector: 'facet-summary',
  template: require('./facet-summary.component.html'),
  styles: [require('./facet-summary.component.scss')]
})
export class FacetSummaryComponent extends PFacet {

  @Input()
  set pagination(pagination: PaginationWsDTO) {
    if (pagination) {
      this._pagination = pagination;
    }
  }
  get pagination() {
    return this._pagination;
  }
  @Output()  dismiss: EventEmitter<void> = new EventEmitter();

  get totalNumbers(): number {
    return defaultTo(this.pagination.totalResults, 0) || defaultTo(this.pagination.totalCount, 0);
  }

  private _pagination: PaginationWsDTO = {};

  @HostListener('window:keyup', ['$event'])
  keyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.submit.emit();
    }
  }
}
