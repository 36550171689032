import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { RestService } from '../rest-service';
import { AuthorizationService } from './authorization-service';
import { LanguageListWsDTO, LanguageWsDTO, RequestWsDTO } from '../types/ycommercewebservices';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';

@Injectable()
export class PLanguagesService extends RestService {
  basePath = 'planseecommercewebservices/v2/plansee';

  private cache$: Observable<LanguageWsDTO[]>;

  constructor(
    protected http: HttpClient,
    protected authorizationService: AuthorizationService,
    protected configService: ConfigService
  ) {
    super(http, authorizationService);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  getEndpoint(): string {
    return `${this.basePath}/languages`;
  }

  /**
   * Lists all available languages (all languages used for a particular store).<br/>
   * If the list of languages for a base store is empty, a list of all languages available in the system will be returned.
   * @param {RequestWsDTO} queryParams
   * @returns {Observable<LanguageListWsDTO>}
   */
  getLanguages(queryParams?: RequestWsDTO): Observable<LanguageWsDTO[]> {
    if (!this.cache$) {
      this.cache$ = this.requestLangs(queryParams)
        .pipe(shareReplay(1));
    }

    return this.cache$;
  }

  private requestLangs(queryParams?: RequestWsDTO): Observable<LanguageWsDTO[]> {
    return this.query<LanguageListWsDTO>({params: queryParams})
      .pipe(
        take(1),
        switchMap(list => {
          const langs = list.languages.filter(l => l.active) || [];

          return of(langs);
        })
      );
  }
}
