import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { OAuthModule } from 'ngx-oauth';
import { OccModule } from '../occ/occ.module';
import { AuthorizationService } from './authorization-service';
import { ConfigService } from './config-service';
import { LanguageInterceptor } from './language-interceptor';
import { LoadingInterceptor, LoadingService } from './loading-service';
import { PInvoicesService } from './p-invoices-service';
import { POrdersService } from './p-orders-service';
import { PProductsService } from './p-products-service';
import { PShipmentsService } from './p-shipments-service';
import { PUsersService } from './p-users-service';
import { PForecastService } from './p-forecast-service';
import { PDocumentsService } from './p-documents-service';
import { PConsignmentsService } from './p-consignments-service';
import { PLanguagesService } from './p-languages-service';
import { PClaimsService } from './p-claims-service';
import { PQuotationsService } from './p-quotations-service';
import { PSettingsService } from './p-settings-service';
import { PUnitsService } from './p-units-service';
import { PWidgetsService } from './p-widgets-service';
import { PNotificationsService } from './p-notifications-service';
import { PGoogleAnalytics } from './p-google-analytics';
import { AuthInterceptor } from './p-auth-interceptor';
import { PInternetConnectionService } from './p-internet-connection-service';
import { PForecastGraphService } from './p-forecast-graph-service';
import { PConsignmentsGraphService } from './p-consignments-graph-service';
import { PAuthenticationService } from './p-authentication-service';
import { PDeliveryCountriesService } from './p-delivery-countries-service';
import { GlobalsService } from '../types/globals.service';
import { PCookiePanelService } from './p-cookie-panel.service';
import { PAdobeTmService } from './p-adobe-tm.service';
import { PlanseeTranslateService } from './p-translate-service';
import { CustomDatepickerI18nService } from './custom-datepicker-i18n.service';
import { PTermConditionsService } from './p-term-conditions.service';
import { PLoginFaqsService } from './p-login-faqs-service';
import { AppModeService } from './app-mode.service';
import { PlanseeInvoicesService } from './plansee-invoices.service';
import { DownloadService } from './download.service';
import { PlanseeShipmentsService } from './plansee-shipments.service';
import { PlanseeDocumentsService } from './plansee-documents.service';
import { PlanseeDashboardService } from './plansee-dashboard.service';
import { PlanseeQuotationsService } from './plansee-quotations.service';
import { PlanseeOrdersService } from './plansee-orders.service';
import { CustomNgxOauthService } from './custom-ngx-oauth.service';
import { OAuthService } from 'ngx-oauth';
import { AzureAuthorizationService } from './azure-authorization.service';

@NgModule({
  imports: [
    OAuthModule,
    OccModule
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (src: ConfigService) => () => src.init(),
      deps: [ConfigService, HttpClient],
      multi: true
    },
    AuthorizationService,
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    PLanguagesService,
    PUsersService,
    PProductsService,
    POrdersService,
    PInvoicesService,
    PShipmentsService,
    PForecastService,
    PDocumentsService,
    PConsignmentsService,
    PClaimsService,
    PQuotationsService,
    PSettingsService,
    PUnitsService,
    PWidgetsService,
    PNotificationsService,
    PGoogleAnalytics,
    PInternetConnectionService,
    PForecastGraphService,
    PConsignmentsGraphService,
    PAuthenticationService,
    PDeliveryCountriesService,
    GlobalsService,
    PCookiePanelService,
    PAdobeTmService,
    PlanseeTranslateService,
    CustomDatepickerI18nService,
    PTermConditionsService,
    PLoginFaqsService,
    AppModeService,
    // new services
    PlanseeInvoicesService,
    DownloadService,
    PlanseeShipmentsService,
    PlanseeDocumentsService,
    PlanseeDashboardService,
    PlanseeQuotationsService,
    PlanseeOrdersService,
    AzureAuthorizationService,
    CustomNgxOauthService,
    { provide: OAuthService, useClass: CustomNgxOauthService }
  ]
})
export class PlanseeModule {
}
