import { SubscriptionManager } from '../shared/components/subscriptions-manager';
import { PlanseeTableSearchEvent } from './plansee-table/plansee-table-model';
import { defaultTo, get } from 'lodash';

export class PlanseeDataTable extends SubscriptionManager {

  prepareBasicParams(event: PlanseeTableSearchEvent): { [key: string]: string | number } {
    const params = { query: get(event, 'filters.searchTerm', '') || '' };

    params['tabId'] = defaultTo(event.currentTab, '');
    params['sortMethod'] = this.takeSortMethod(event);
    params['sortField'] = get(event, 'dataTableParams.sortBy', '');
    params['currentPage'] = (get(event, 'dataTableParams.offset', 0) / get(event, 'dataTableParams.limit', 0)) || 0;

    return params;
  }

  private takeSortMethod(event: PlanseeTableSearchEvent): string {
    const sortAsc = get(event, 'dataTableParams.sortAsc', null);

    if (sortAsc === null) {
      return '';
    }

    return sortAsc ? 'ASCENDING' : 'DESCENDING';
  }
}
