import { NgModule } from '@angular/core';
import { BookmarkIndicatorComponent } from './bookmark-indicator/bookmark-indicator.component';
import { BookmarkButtonComponent } from './bookmark-button/bookmark-button.component';
import { FiltersModule } from '../../../filters/filters.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

const components = [BookmarkIndicatorComponent, BookmarkButtonComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FiltersModule,
    TranslateModule,
  ],
  exports: components,
})
export class BookmarkModule {}
