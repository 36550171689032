import { Component, Input, Output, EventEmitter, Renderer, OnDestroy } from '@angular/core';

@Component({
  selector: 'action-modal',
  template: require('./action-modal.component.html'),
  styles: [require('./action-modal.component.scss')]
})

export class ActionModalComponent implements OnDestroy {

  @Input()
  text: string;

  @Input()
  btnText: string;

  @Output()
  onClose = new EventEmitter();

  @Output()
  onBtnClick = new EventEmitter();

  constructor(private renderer: Renderer) {
    this.renderer.setElementClass(document.body, 'noscroll', true);
  }

  closeOverlay(event: Event, overlayElement: Element) {
    if (event.target === overlayElement) {
      this.onClose.emit();
    }
  }

  close(event: Event) {
    this.onClose.emit();
  }

  ngOnDestroy(): void {
    this.renderer.setElementClass(document.body, 'noscroll', false);
  }
}
