import {
  DataTableColumn,
  DataTableHeaderEvent,
  DataTableParams,
  DataTableTranslations,
  defaultTranslations
} from 'ngx-datatable-bootstrap4';
import { PRequestWsDTO, PSearchPageWsDTO } from '../providers/types/planseeoccaddon';
import { PaginationWsDTO, QueryRequestWsDTO } from '../providers/types/ycommercewebservices';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../providers/plansee/authorization-service';
import { LoadingService } from '../providers/plansee/loading-service';
import { SubscriptionManager } from '../shared/components/subscriptions-manager';
import { SearchTab } from '../shared/components/search-tabs/search-tab';
import { defaultTo } from 'lodash';

export interface PDataTableColumn<T> extends DataTableColumn<T> {
  styleClassObject: any;
}

export interface PDataTableHeaderEvent<T> extends DataTableHeaderEvent<T> {
  column: PDataTableColumn<T>;
}

const DEFAULT_PAGE_SIZE = 25;

export abstract class PDataTable<T> extends SubscriptionManager implements OnInit {
  @Input()
  set searchResult(searchResult: PSearchPageWsDTO) {
    this._searchResult = searchResult;
  }
  get searchResult(): PSearchPageWsDTO {
    return this._searchResult;
  }
  @Input()
  set query(val: QueryRequestWsDTO) {
    if (val) {
      if (!this._query) {
        this._query = {};
      }
      this._query = Object.assign(this._query, val);
      this.reloadItems(this._query);
    }
  }
  @Input() set selectedTab(tab: SearchTab) {
    this._selectedTab = tab;
    this.reloadItems({});
  }
  @Input() set onlyBookmarks(selected: boolean) {
    this._query.currentPage = 0;
    this._onlyBookmarks = selected;
    this.reloadItems(this._query, true);
  }

  get selectedTab(): SearchTab {
    return this._selectedTab;
  }

  get onlyBookmarks(): boolean {
    return this._onlyBookmarks;
  }

  get listIsLoading(): boolean {
    return this._listIsLoading;
  }

  set listIsLoading(value: boolean) {
    this._listIsLoading = value;
  }

  protected constructor(
    protected i18nService: TranslateService,
    protected authorizationService: AuthorizationService,
    protected loadingService: LoadingService
  ) {
    super();
    const i18nKeys = Object.keys(this.i18n);
    this.i18nService.stream(i18nKeys.map(val => `datatable.${val}`)).subscribe(i18n => {
      for (let key of i18nKeys) {
        this.i18n[key] = i18n[`datatable.${key}`];
      }
    });
  }
  @Input() selectedTabQuery: any;

  @Output() searchResultChange: EventEmitter<PSearchPageWsDTO> = new EventEmitter();

  latestTableParams: DataTableParams = {};
  defaultSort = 'companyMaterialNr-asc';
  activeSortedColumn: PDataTableColumn<T> = null;
  i18n: DataTableTranslations = defaultTranslations;
  oldQuery: QueryRequestWsDTO & PRequestWsDTO = {};
  _query: QueryRequestWsDTO & PRequestWsDTO & DataTableParams = {query: ''};
  areFacetsApplied = false;
  private pagination: any = {
    itemCount: 0,
    offset: 0,
    limit: 10,
    page: 0
  };
  private _listIsLoading = false;
  private _searchResult: PSearchPageWsDTO;
  private _selectedTab: SearchTab;
  private _onlyBookmarks = false;

  ngOnInit() {
    this.addSubscriptions(
      this.loadingService.loading
        .subscribe(value => this._listIsLoading = value)
    );
  }

  abstract reloadItems(params: DataTableParams, strict?: boolean);

  headerClick(event: PDataTableHeaderEvent<T>) {
    if (event.column.sortable) {
      if (this.activeSortedColumn) {
        if (this.activeSortedColumn.header === event.column.header) {
          this.activeSortedColumn.styleClassObject.sortedDesc = !this.activeSortedColumn.styleClassObject.sortedDesc;
          this.activeSortedColumn.styleClassObject.sortedAsc = !this.activeSortedColumn.styleClassObject.sortedAsc;
        } else {
          this.activeSortedColumn.styleClassObject.sortedAsc = false;
          this.activeSortedColumn.styleClassObject.sortedDesc = false;
          event.column.styleClassObject.sortedAsc = true;
          event.column.styleClassObject.sortedDesc = false;
        }
      } else {
        event.column.styleClassObject.sortedAsc = true;
        event.column.styleClassObject.sortedDesc = false;
      }
      this.activeSortedColumn = event.column;
    }
  }

  updateQuery(params: DataTableParams) {
    this._query.pageSize = this._query.pageSize ? this._query.pageSize : DEFAULT_PAGE_SIZE;
    this._query.sort = this._query.sort ? this._query.sort : this.defaultSort;
    if (params) {
      this._query.pageSize = params.limit > 0 ? params.limit : this._query.pageSize ? this._query.pageSize : DEFAULT_PAGE_SIZE;
      this._query.currentPage = Math.floor(params.offset / params.limit) || (params as PaginationWsDTO).currentPage || 0;
      if (params.sortBy) {
        this._query.sort = `${params.sortBy}-${params.sortAsc ? 'asc' : 'desc'}`;
      }
    }
    this.setCompanyIdToQuery();
    this.setLocationChangedTime();
    this.updateQueryBySelectedTab();
  }

  setCompanyIdToQuery() {
    // When one changes company (Customer selection),
    // we need to set it to query, so that tables will update there data;
    // because the query itself does not change, but the company.
    if (!this.authorizationService.companyId) {
      return;
    }
    this._query.companyId = this.authorizationService.companyId;
  }

  setLocationChangedTime() {
    this._query.locationChangedTime = new Date().getTime();
  }

  updateQueryBySelectedTab() {
    const searchTerm = this.takeSearchTerm();
    let compiledQuery = defaultTo(this._query.query, '');

    if (this.selectedTab && this.selectedTab.facetCode && this.selectedTabQuery) {
      if (compiledQuery.indexOf(this.selectedTab.facetCode) >= 0) {
        compiledQuery = this.removeSelectedTabFromQuery(compiledQuery);
      }

      compiledQuery = this.updateQueryForSearchTermAndSort(compiledQuery);
      compiledQuery += this.selectedTab.query;

      this._query.query = `${searchTerm || ''}:${this._query.sort}${compiledQuery.startsWith(':') ? '' : ':'}${compiledQuery}`;
    } else if (!this.selectedTab && this.selectedTabQuery) {
      compiledQuery = this.removeSelectedTabFromQuery(compiledQuery);
      compiledQuery = this.updateQueryForSearchTermAndSort(compiledQuery);

      this._query.query = `${searchTerm || ''}:${this._query.sort}${compiledQuery.startsWith(':') ? '' : ':'}${compiledQuery}`;
    }
  }

  refreshPagination(pagination: PaginationWsDTO) {
    if (pagination) {
      this.pagination.itemCount = pagination.totalResults;
      if (this.pagination.itemCount > 0) {
        this.pagination.page = pagination.currentPage + 1;
        this.pagination.offset = pagination.currentPage * pagination.pageSize;
      } else {
        this.pagination.page = 1;
        this.pagination.offset = 0;
      }
      // current BE/solr maximum number of results sent is 500
      this.pagination.limit = pagination.pageSize > 500 ? 500 : pagination.pageSize === 0 ? DEFAULT_PAGE_SIZE : pagination.pageSize;
    } else {
      this.pagination.page = 1;
      this.pagination.offset = 0;
    }
  }

  shouldReload(): boolean {
    return JSON.stringify(this._query) !== JSON.stringify(this.oldQuery);
  }

  private removeSelectedTabFromQuery(actualQuery: string): string {
    let tempQuery = actualQuery || '';

    if (this.selectedTabQuery) {
      Object.entries(this.selectedTabQuery).forEach((entry: any) => {
        tempQuery = tempQuery.replace(entry[entry.length - 1] || '', '');
      });
    }

    return tempQuery;
  }

  private updateQueryForSearchTermAndSort(actualQuery: string): string {
    let tempQuery = actualQuery || '';
    tempQuery = this.removeSearchTermFromQuery(tempQuery);
    tempQuery = this.removeSortFromQuery(tempQuery);
    return tempQuery;
  }

  private removeSearchTermFromQuery(actualQuery: string): string {
    let tempQuery = actualQuery || '';
    const searchTerm = this.takeSearchTerm();

    if (tempQuery.indexOf(`${searchTerm}:`) !== -1) {
      tempQuery.replace(`${searchTerm}:`, '');
    }

    return tempQuery;
  }

  private removeSortFromQuery(actualQuery: string): string {
    const splitQuery = (actualQuery || '').split(':') || [];

    return splitQuery.filter(v => v && !v.includes('-desc') && !v.includes('-asc')).join(':');
  }

  private takeSearchTerm() {
    const splitQuery = (this._query.query || '').split(':');
    return  splitQuery[0];
  }
}
