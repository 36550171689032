import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PNotificationWsDTO} from '../../../providers/types/planseeoccaddon';

@Component({
  selector: 'dashboard-notifications',
  template: require('./dashboard-notifications.component.html'),
  styles: [require('./dashboard-notifications.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardNotificationsComponent {

  @Input() notifications: PNotificationWsDTO[];
}
