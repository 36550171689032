import { NgModule } from '@angular/core';
import { ImprintEnComponent } from './en/imprint-en.component';
import { ImprintDeComponent } from './de/imprint-de.component';
import { ImprintJaComponent } from './ja/imprint-ja.component';
import { ImprintZhComponent } from './zh/imprint-zh.component';

const components = [
  ImprintEnComponent,
  ImprintZhComponent,
  ImprintJaComponent,
  ImprintDeComponent
];

@NgModule({
  declarations: components,
  exports: components
}) export class ImprintLangModule { }
