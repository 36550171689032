import {FormGroup} from '@angular/forms';
import { saveAs } from 'file-saver';

export const stringToDate = (date: string): Date => {
  return new Date(date) || new Date();
};

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return element && element.className && element.className.split &&
    element.className.split(/\s+/).indexOf(className) >= 0;
}

export const scrollToNotificationsParam = 'scrollToNotifications';
export const myPlanseeLang = 'my_plansee_current_lang';
export const updatePassword = 'updatePassword';

// FORMS
export const hideMainScroll = () => {
  document.body.classList.remove('noscroll');
};

export const showMainScroll = () => {
  document.body.classList.add('noscroll');
};

export const validateAllFields = (group: FormGroup) => {
  Object.keys(group.controls).forEach(key => {
    const control = group.get(key);
    control.markAsTouched();
  });
};

export const downloadBlob = (blob: Blob, name?: string) => {
  if (name) {
    saveAs(blob, name);
  } else {
    saveAs(blob);
  }
};
