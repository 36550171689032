import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'general-conditions-of-sale',
  template: require('./general-conditions-of-sale.component.html'),
  styles: [require('./general-conditions-of-sale.component.scss')]
})
export class GeneralConditionsOfSaleComponent {
  constructor(
    public translateService: TranslateService
  ) {
  }
}
