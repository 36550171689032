import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxLoadingModule } from 'ngx-loading';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { PagesModule } from './pages/pages.module';
import { ProvidersModule } from './providers/providers.module';
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import localeZh from '@angular/common/locales/zh';
import { FooterPanelModule } from './shared/footer-panel/footer-panel.module';
import { PageRightsModule } from './rights/page-rights.module';
import {
  AuthModule, AuthWellKnownEndpoints,
  OidcSecurityService,
  OpenIdConfiguration,
} from 'angular-auth-oidc-client';

registerLocaleData(localeJa);
registerLocaleData(localeZh);

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, 'i18n/', '.json');
};

@NgModule({
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxLoadingModule,
    ProvidersModule,
    PagesModule,
    FooterPanelModule,
    PageRightsModule,
    AuthModule.forRoot(),
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private oidcSecurityService: OidcSecurityService) {
    const config: OpenIdConfiguration = {
      redirect_url: window.location.origin,
      unauthorized_route: '',
      client_id: '3ac761f0-3597-423d-94b2-0c9f259f8519',
      response_type: 'id_token',
      scope: 'openid',
      disable_iat_offset_validation: true,
    };

    const authWellKnownEndpoints: AuthWellKnownEndpoints = {
      issuer: 'https://login.microsoftonline.com/6e754c03-6c26-480f-a166-ff9598a067ed/v2.0',
      jwks_uri: 'https://login.microsoftonline.com/6e754c03-6c26-480f-a166-ff9598a067ed/discovery/keys?appid=3ac761f0-3597-423d-94b2-0c9f259f8519',
      authorization_endpoint: 'https://login.microsoftonline.com/6e754c03-6c26-480f-a166-ff9598a067ed/oauth2/v2.0/authorize',
    };

    this.oidcSecurityService.setupModule(config, authWellKnownEndpoints);
    this.oidcSecurityService.setCustomRequestParameters({ response_mode: 'fragment'});
  }
}
