import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PProductWsDTO } from '../../providers/types/planseeoccaddon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'inter-module-links-inline',
  template: require('./inter-module-links-inline.component.html'),
  styles: [require('./inter-module-links-inline.component.scss')]
})
export class InterModuleLinksInlineComponent extends SubscriptionManager implements OnInit {

  @Input() product: PProductWsDTO;
  @Input() singleline = false;

  modules = [
    {
      key: 'menuPanel.products',
      name: 'Products',
      image: 'link-inline-products.svg',
      disabled: false,
      path: '/menu/products',
      link: 'Product',
      count: 0
    }, {
      key: 'menuPanel.forecast',
      name: 'PO<br/>Forecasting',
      image: 'link-inline-po-forecasting.svg',
      disabled: false,
      path: '/menu/forecasts',
      link: 'ProductHistoryForecast',
      count: 0
    },
    {
      key: 'menuPanel.documents',
      name: 'Documents',
      image: 'link-inline-documents.svg',
      disabled: false,
      path: '/menu/documents',
      link: 'Document',
      count: 0
    },
    {
      key: 'menuPanel.shipments',
      name: 'Shipments',
      image: 'link-inline-shipments.svg',
      disabled: false,
      path: '/menu/shipments',
      link: 'Consignment',
      count: 0
    }
  ];

  constructor(
    private i18nService: TranslateService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscriptions(
      this.i18nService.stream(this.modules.map(obj => obj.key))
        .subscribe(i18n => {
          this.modules = this.modules.map(obj => {
            obj.name = i18n[obj.key];
            return obj;
          });
        }),
      this.authorizationService.permissionsChanged
        .subscribe(permissions => {
          this.processBlacklist(permissions.blacklist);
        })
    );

    let currentModule = this.modules.find(i => this.router.url && this.router.url.includes(i.path));
    if (currentModule) {
      currentModule.disabled = true;
    }
  }

  processBlacklist(blacklist: string[]) {
    for (let module of this.modules) {
      module.disabled = false;
      for (let path of blacklist) {
        if (module.path.includes(path)) {
          module.disabled = true;
        }
      }
    }
  }

  showTooltip(t: NgbTooltip) {
    if (!this.product.customerMaterialNumber || this.product.code === this.product.customerMaterialNumber) {
      t.open();
    }
  }

  hideTooltip(t: NgbTooltip) {
    t.close();
  }
}
