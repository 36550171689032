import { Component, Input } from '@angular/core';
import { PDocumentsWsDTO } from '../../providers/types/planseeoccaddon';
import { PDocumentsService } from '../../providers/plansee/p-documents-service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { PlanseeDocumentsService } from '../../providers/plansee/plansee-documents.service';
import { AppMode } from '../../providers/types/app-mode';
import { DownloadDocumentType } from '../../providers/types/download';

const MIN_SIZE = 3;

@Component({
  selector: 'file-view',
  template: require('./file-view.component.html'),
  styles: [require('./file-view.component.scss')]
})
export class FileViewComponent {

  @Input() downloadDocumentType = DownloadDocumentType.QUOTATION;
  @Input() appMode = AppMode.OLD_VERSION;
  @Input()
  set medias(medias: PDocumentsWsDTO[]) {
    if (medias && medias.length > 0) {
      this._medias = medias.filter( value => value && value.id) || [];
      this._medias.forEach( media => {
        if (this.isImage(media)) {
          this.getImageThumbnail(media);
        }
      });
      if (this._medias.length < MIN_SIZE && this._medias.length > 0) {
        for (let i = 0; i < MIN_SIZE - this._medias.length + 1; i++) {
          this._medias.push({});
        }
      }
    }
  }

  pos = 0;

  constructor(
    private documentsService: PDocumentsService,
    private planseeDocumentsService: PlanseeDocumentsService,
    private sanitizer: DomSanitizer
  ) {
  }
  private selectedPos = 0;
  private _medias: PDocumentsWsDTO[];

  hasMedias() {
    return this._medias && this._medias.length > 0;
  }

  hasNext() {
    return this.pos < this._medias.length - 3 && this._medias.length > 3;
  }

  hasPrev() {
    return this.pos > 0;
  }

  next() {
    this.pos++;
  }

  prev() {
    this.pos--;
  }

  download(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.appMode === AppMode.NEW_VERSION) {
      this.planseeDocumentsService.checkLinkAndHandleDownload(this.getPreview(), true, this.downloadDocumentType);
    } else {
      this.documentsService.checkLinkAndHandleDownload(event, this.getPreview(), true);
    }
  }

  getPreview(): PDocumentsWsDTO {
    return this._medias[this.selectedPos];
  }

  getCarousel(): PDocumentsWsDTO[] {
    const buf = this._medias.slice(this.pos, this._medias.length);
    return buf.slice(0, MIN_SIZE);
  }

  isImage(media: PDocumentsWsDTO) {
    const acceptedImageFormats = ['.png', '.jpg', '.jpeg'];
    const fileExtension = media.fileName ? '.' + media.fileName.split('.').pop() : '';
    return media && media.thumbnailUrl && media.thumbnailUrl !== '' &&
      ((media.format && media.format.indexOf('image') !== -1) || acceptedImageFormats.includes(fileExtension.toLowerCase()));
  }

  selectPreview(media) {
    this.selectedPos = media.id ? this._medias.indexOf(media) : this.selectedPos;
  }

  showTooltip(t: NgbTooltip, media) {
    if (media.id) {
      t.open();
    }
  }

  hideTooltip(t: NgbTooltip) {
    t.close();
  }

  getImageThumbnail(preview: PDocumentsWsDTO) {
    if (preview && preview.thumbnailUrl) {
      this.documentsService.get<Blob>(preview.thumbnailUrl, { responseType: 'blob' }).subscribe(result => {
        let urlCreator = window.URL;
        (preview as any).src = this.sanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(result));
      });
    }
  }
}
