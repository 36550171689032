import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardInvoice} from '../../../../providers/types/dashboard/dashboard-invoice';
import {PPaymentStatus} from '../../../../providers/types/planseeoccaddon';
import {stringToDate} from '../../../../../utils';

@Component({
  selector: 'dashboard-invoices-table',
  template: require('./dashboard-invoices-table.component.html'),
  styles: [require('./dashboard-invoices-table.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardInvoicesTableComponent {

  @Input()
  set invoices(items: DashboardInvoice[]) {
    this.items = items
      .sort((i1, i2) => {
        const sortedByPaymentStatus = this.getOrderNumberByPaymentStatus(i2.paymentStatus) - this.getOrderNumberByPaymentStatus((i1.paymentStatus));
        const sortedByPaymentDueDate = stringToDate(i2.paymentDueDate).getTime() - stringToDate(i1.paymentDueDate).getTime();

        return sortedByPaymentStatus || sortedByPaymentDueDate;
      });
  }

  items: DashboardInvoice[] = [];

  checkPaymentStatus(paymentStatus: PPaymentStatus, paymentDueDate: string): boolean {
    const isPaid = paymentStatus === PPaymentStatus.PAID;
    const isOverdue = new Date(paymentDueDate).getTime() - new Date().getTime() > 0;

    return isPaid || isOverdue;
  }

  private getOrderNumberByPaymentStatus(paymentStatus: PPaymentStatus): number {
    switch (paymentStatus) {
      case PPaymentStatus.OVERDUE:
        return 3;
      case PPaymentStatus.UNPAID:
        return 2;
      case PPaymentStatus.PAID:
        return 1;
      default:
        return 0;
    }
  }
}
