import { Component } from '@angular/core';
import { PStockLevelWsDTO, PSearchPageWsDTO } from '../../providers/types/planseeoccaddon';
import { PPage } from '../p-page';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';

@Component({
  selector: 'consignments-page',
  template: require('./consignments.page.html'),
  styles: []
})
export class ConsignmentsPageComponent extends PPage {

  stockLevel: PStockLevelWsDTO;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  onSearchResultChange(searchResult: PSearchPageWsDTO) {
    this.searchResult = searchResult;
  }
}
