import {Component} from '@angular/core';

@Component({
  selector: 'quotation-page',
  template: require('./quotation.page.html'),
  styles: [require('./quotation.page.scss')]
})
export class QuotationPageComponent {

}
