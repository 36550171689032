import { CityLocation, CountryLocation } from '../../providers/types/ycommercewebservices';
import { get } from 'lodash';
import {
  CountryLocationCheckboxCityItem,
  CountryLocationCheckboxCompanyItem,
  CountryLocationCheckboxItem,
} from '../components/country-location-selector/country-location-checkbox';

const takeCountryByIsoCode = (selectedLocations: CountryLocation[], country?: string): CountryLocation => {
  return country ? (selectedLocations || []).find(l => l && l.isoCode === country) : null;
};

const takeCityByIsoCode = (selectedLocations: CountryLocation[], country?: string, city?: string): CityLocation | null => {
  const currentCountry = takeCountryByIsoCode(selectedLocations, country);

  if (currentCountry && city) {
    return (currentCountry.cities || []).find(c => c && c.city === city);
  }

  return null;
};

const takeCompanyByName = (selectedLocations: CountryLocation[], country?: string, city?: string, company?: string): string | null => {
  const currentCity = takeCityByIsoCode(selectedLocations, country, city);

  if (currentCity && company) {
    return (currentCity.companies || []).find(c => c === company);
  }

  return null;
};

export const checkSelection = (selectedLocations: CountryLocation[], numberOfChildren: number, country?: string, city?: string, company?: string): boolean => {
  if (company) {
    return !!takeCompanyByName(selectedLocations, country, city, company);
  } else if (city) {
    const cityLocation = takeCityByIsoCode(selectedLocations, country, city);
    return !!cityLocation && numberOfChildren === get(cityLocation, 'companies.length', 0);
  } else if (country) {
    const countryLocation = takeCountryByIsoCode(selectedLocations, country);
    return !!countryLocation && numberOfChildren === get(countryLocation, 'cities.length', 0);
  }

  return false;
};

export const generateLocationList = (locations: CountryLocation[], selectedLocations: CountryLocation[]): CountryLocationCheckboxItem[] => {
  if (!locations || !Array.isArray(locations) || !locations.length) {
    return [];
  }

  const mapCompanies = (country: string, city: string, companies: string[]): CountryLocationCheckboxCompanyItem[] => {
    return companies.map(c => ({
      name: c,
      selected: checkSelection(selectedLocations, 0, country, city, c)
    }));
  };

  const mapCities = (country: string, cities: CityLocation[]): CountryLocationCheckboxCityItem[] => {
    return cities.map(city => ({
      city: city.city,
      selected: checkSelection(selectedLocations, (city.companies || []).length, country, get(city, 'city', ''), ''),
      companies: mapCompanies(country, get(city, 'city', ''), city.companies || [])
    }));
  };

  return locations.map(location => {
    return {
      selected: false,
      isoCode: location.isoCode,
      countryName: location.countryName,
      cities: mapCities(location.isoCode, location.cities || [])
    };
  });
};

export const toCountryLocationCheckboxItem = (locations: CountryLocation[]): string[] => {
  const names = [];

  locations.forEach(location => {
    if (location && location.cities && location.cities.length > 0) {
      location.cities.forEach(city => {
        if (city && city.companies && city.companies.length > 0) {
          city.companies.forEach(company => {
            names.push(`${location.countryName}, ${city.city}, ${company}`);
          });
        } else {
          names.push(`${location.countryName}, ${city.city}`);
        }
      });
    }
  });

  return names;
};
