import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FiltersModule } from '../filters/filters.module';
import { OAuthModule } from 'ngx-oauth';
import { AppRoutingModule } from '../app.routing';
import { ComponentsModule } from '../components/components.module';
import { ClaimsPageComponent } from './claims/claims.page';
import { ConsignmentsPageComponent } from './consignments/consignments.page';
import { DashboardPageComponent } from './dashboard/dashboard.page';
import { DocumentsPageComponent } from './documents/documents.page';
import { ForecastingPageComponent } from './forecasting/forecasting.page';
import { HomePageComponent } from './home/home.page';
import { InvoicesPageComponent } from './invoices/invoices.page';
import { LoginPageComponent } from './login/login.page';
import { OrdersPageComponent } from './orders/orders.page';
import { ProductPageComponent } from './product/product.page';
import { ProductsPageComponent } from './products/products.page';
import { SettingsPageComponent } from './settings/settings.page';
import { ShipmentsPageComponent } from './shipments/shipments.page';
import { ClaimPageComponent } from './claim/claim.page';
import { QuotationPageComponent } from './quotation/quotation.page';
import { QuotationsPageComponent } from './quotations/quotations.page';
import { ImprintPageComponent } from './imprint/imprint.page';
import { ErrorPageComponent } from './error/error.page';
import { ClaimConfirmationPageComponent } from './claim-confirmation/claim-confirmation.page';
import { QuotationConfirmationPageComponent } from './quotation-confirmation/quotation-confirmation.page';
import { ForgottenPasswordPageComponent } from './forgotten-password/forgotten-password.page';
import { FormComponentsModule } from '../form-components/form-components.module';
import { SetPasswordPageComponent } from './set-password/set-password.page';
import { UserAdministrationPageComponent } from './userAdministration/userAdministration.page';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';
import { PublicPageComponent } from './public/public.page';
import { DataProtectionPage } from './data-protection/data-protection.page';
import { GeneralConditionsOfSalePage } from './general-conditions-of-sale/general-conditions-of-sale.page';
import { RegisterPageComponent } from './register/register.page';
import { HotZoneConfiguratorPageComponent } from './hot-zone-configurator/hot-zone-configurator.page';
import { SearchTabsModule } from '../shared/components/search-tabs/search-tabs.module';

const pages = [
  LoginPageComponent,
  HomePageComponent,
  DashboardPageComponent,
  ClaimPageComponent,
  ClaimsPageComponent,
  ConsignmentsPageComponent,
  DocumentsPageComponent,
  ForecastingPageComponent,
  InvoicesPageComponent,
  OrdersPageComponent,
  ProductsPageComponent,
  ShipmentsPageComponent,
  SettingsPageComponent,
  ProductPageComponent,
  QuotationPageComponent,
  QuotationsPageComponent,
  ImprintPageComponent,
  ClaimConfirmationPageComponent,
  QuotationConfirmationPageComponent,
  ErrorPageComponent,
  ForgottenPasswordPageComponent,
  SetPasswordPageComponent,
  UserAdministrationPageComponent,
  TermConditionsComponent,
  PublicPageComponent,
  DataProtectionPage,
  GeneralConditionsOfSalePage,
  RegisterPageComponent,
  HotZoneConfiguratorPageComponent,
];

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    TranslateModule,
    FiltersModule,
    NgbModule,
    ComponentsModule,
    OAuthModule,
    ReactiveFormsModule,
    FormComponentsModule,
    SearchTabsModule,
  ],
  declarations: pages,
  exports: pages
})
export class PagesModule {
}
