import { Pipe, PipeTransform } from '@angular/core';
import { PFacetWsDTO } from '../providers/types/planseeoccaddon';

@Pipe({
  name: 'filterFacetsBySelectedTabKey'
})
export class FilterFacetsBySelectedTabKeyPipe implements PipeTransform {
  transform(value: PFacetWsDTO[], selectedTabKey: string): PFacetWsDTO[] {
    if (selectedTabKey) {
      return (value || []).filter(f => f.code && f.code !== selectedTabKey);
    }

    return value || [];
  }
}
