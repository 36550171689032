import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter, Renderer, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PAuthenticationService } from '../../providers/plansee/p-authentication-service';
import { GlobalsService } from '../../providers/types/globals.service';
import { defaultTo, isEqual } from 'lodash';
import { SetPasswordErrorEnum, SetPasswordErrorKeys } from '../../providers/types/errors/set-password-error';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';

@Component({
  selector: 'change-password',
  template: require('./change-password.component.html'),
  styles: [require('./change-password.component.scss')]
})
export class ChangePasswordComponent extends SubscriptionManager implements OnInit, OnDestroy {

  get isConfirmed(): boolean {
    return this._isConfirmed;
  }

  @Output()
  closeOverlayEmitter = new EventEmitter<boolean>();

  bePasswordOldErrorKey = '';

  constructor(
    private authorizationService: AuthorizationService,
    private authenticationService: PAuthenticationService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer,
    public globals: GlobalsService
  ) {
    super();
    this.renderer.setElementClass(document.body, 'noscroll', true);
  }

  private setPasswordForm: FormGroup;
  private _isConfirmed = false;
  private _isOverlayOpen = false;

  ngOnInit(): void {
    this.setPasswordForm = new FormGroup({
      passwordOld: new FormControl(null, [Validators.required, this.validateOldPassword.bind(this)]),
      passwordNew: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.globals.getRegExpForPW())
      ]),
      passwordNewRepeat: new FormControl(null, [Validators.required]),
    });

    this.setPasswordForm.validator = this.matchPassword.bind(this);

    this.addSubscriptions(
      this.setPasswordForm.valueChanges
        .pipe(distinctUntilChanged((v1, v2) => isEqual(v1, v2)))
        .subscribe(() => {
          if (this.bePasswordOldErrorKey) {
            this.updateBePasswordOldErrorKey('');
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.renderer.setElementClass(document.body, 'noscroll', false);
  }

  closeOverlay(e: Event, overlayElement: Element) {
    if (e.target === overlayElement) {
      this.closeOverlayEmitter.emit(this._isOverlayOpen);
    }
  }

  close(e: Event) {
    this.closeOverlayEmitter.emit(this._isOverlayOpen);
  }

  setPassword(e) {
    e.preventDefault();

    if (!this.setPasswordForm.valid) {
      this.setPasswordForm.get('passwordOld').markAsTouched();
      this.setPasswordForm.get('passwordNew').markAsTouched();
      this.setPasswordForm.get('passwordNewRepeat').markAsTouched();
      this.cdr.detectChanges();

      let target = document.querySelector('.form-group-danger');
      target = target ? target.querySelector('.p-input') : null;

      if (target) {
        (target as any).focus();
      }

      return;
    }

    return this.authenticationService.changePassword(this.authorizationService.getUserProfile().uid, {
      old: this.setPasswordForm.value.passwordOld,
      new: this.setPasswordForm.value.passwordNew,
    }).subscribe(response => {
      this._isConfirmed = true;

      setTimeout(() => {
        this.closeOverlayEmitter.emit(this._isOverlayOpen);
      }, 3000);
    }, ({error}) => {
      const errorMsg = defaultTo(error.errors[0].message, '');

      if (errorMsg === SetPasswordErrorEnum.OLD_PASSWORD_INVALID) {
        this.updateBePasswordOldErrorKey(SetPasswordErrorKeys[SetPasswordErrorEnum.OLD_PASSWORD_INVALID] || '');
      }
    });
  }

  validateOldPassword() {
    if (this.bePasswordOldErrorKey) {
      return { invalidBe: true };
    }
    return null;
  }

  matchPassword(group: FormGroup) {
    let passwordNew = group.controls.passwordNew.value || '';
    let passwordNewRepeat = group.controls.passwordNewRepeat.value || '';

    if (passwordNewRepeat.length <= 0) {
      return null;
    }

    if (passwordNewRepeat !== passwordNew) {
      return {
        notEquivalent: true
      };
    }

    return null;
  }

  private updateBePasswordOldErrorKey(key: string) {
    this.bePasswordOldErrorKey = key;
    this.setPasswordForm.get('passwordOld').updateValueAndValidity();
  }
}
