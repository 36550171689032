import { Injectable } from '@angular/core';
import { PService } from './p-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { AuthorizationService } from './authorization-service';
import { QueryRequestWsDTO } from '../types/ycommercewebservices';
import { PRequestWsDTO, Translation } from '../types/planseeoccaddon';
import { Observable } from 'rxjs';
import { defaultTo } from 'lodash';
import { Shipments } from '../../components/shipments-v2/shipments-model';

// new version of p-invoice-service. The old one can be removed when whole app will switch to new version
@Injectable()
export class PlanseeShipmentsService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO): Observable<Shipments> {
    const params = defaultTo(queryParams, { query: ''});
    this.setCurrentQueryParams(queryParams);
    return this.get<Shipments>(`consignments/searchV2?query=${this.encodeLimitsForDateRange(params.query)}`,
      {params: this.stripQueryStringFromQueryObject(params)})
      .map(data => ({...data, items: defaultTo(data.consignments, [])}));
  }

  getListCsvContent(translations: Translation[], params?: QueryRequestWsDTO & PRequestWsDTO): Observable<any> {
    this.setCurrentQueryParams(params);
    return this.getCsvContent(`consignments/downloadV2`, translations);
  }

  private setCurrentQueryParams(queryParams?: QueryRequestWsDTO & PRequestWsDTO) {
    this.currentQueryParams = Object.assign({}, queryParams);
  }
}
