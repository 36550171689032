import {Directive, ElementRef, Input} from '@angular/core';
import { CustomNgxOauthService } from '../../providers/plansee/custom-ngx-oauth.service';

@Directive({
  selector: '[pDownload]'
})
export class PDownloadDirective {

  constructor(private el: ElementRef,
              private oauthService: CustomNgxOauthService) {
  }

  private readonly TYPE_VIEW: string = '&type=view';
  private readonly TYPE_DOWNLOAD: string = '&type=download';
  private readonly ACCESS_TOKEN: string = '?access_token=';
  private readonly COMPANY_ID: string = '&companyId=';

  @Input()
  set href(href: string | URL) {
    this.setElementPreviewType(href, 'href');
  }

  @Input()
  set src(src: string | URL) {
    this.setElementPreviewType(src, 'src');
  }

  private setElementPreviewType(value: string | URL, prop: string) {
    const elem = this.el.nativeElement;
    elem[prop] = `${value}${this.ACCESS_TOKEN}${this.oauthService.token.access_token}`;
    // if preview type exists in request it is moved to the end of the URL after the access token
    elem[prop] = elem[prop].indexOf(this.TYPE_VIEW) >= 0 ? `${elem[prop].replace(this.TYPE_VIEW, '')}${this.TYPE_VIEW}`
                                                        : `${elem[prop]}${this.TYPE_DOWNLOAD}`;
    // move company if param to the end of URL
    elem[prop] = this.setPositionForCompanyIdParameter(elem[prop]);
  }

  private setPositionForCompanyIdParameter(link: string): string {
    if (link.indexOf(this.COMPANY_ID) >= 0) {
      let pCompanyId = link.substring(link.indexOf(this.COMPANY_ID), link.indexOf(this.ACCESS_TOKEN));
      link = `${link.replace(pCompanyId, '')}${pCompanyId}`;
    }
    return link;
  }
}
