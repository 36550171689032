import {Component, Input} from '@angular/core';
import {BreadcrumbWsDTO, FacetValueWsDTO, FacetWsDTO} from '../../providers/types/ycommercewebservices';
import {PFacet} from '../p-facet';

@Component({
  selector: 'facet-dimensions',
  template: require('./facet-dimensions.component.html'),
  styles: [require('./facet-dimensions.component.scss')]
})
export class FacetDimensionsComponent extends PFacet {

  @Input()
  facets: FacetWsDTO[];
  @Input()
  breadcrumbs: BreadcrumbWsDTO[];

  getFacetValues(values: FacetValueWsDTO[]) {
    return values.map(val => val.name);
  }

  getMin(values: FacetValueWsDTO[]) {
    return values && values.length > 0 ? values[0].name : 0;
  }

  getMax(values: FacetValueWsDTO[]) {
    return values && values.length > 0 ? values[values.length - 1].name : 0;
  }

  rangeChange(facet: FacetWsDTO, value: {
    minValue: number
    maxValue: number
  }) {
    if (value.minValue > value.maxValue) {
      const aux = value.minValue;
      value.minValue = value.maxValue;
      value.maxValue = aux;
    }
    let query = facet.values[0].query.query.value.replace(/\+/g, ' ');
    const words = query.split(':');
    let found = false;
    for (let i = 0; i < words.length; i++) {
      if (words[i] === facet.code) {
        if (!found) {
          found = true;
          words[i + 1] = `[${value.minValue} TO ${value.maxValue}]`;
        } else {
          words.splice(i, 2);
          break;
        }
      }
    }
    query = words.join(':');
    query = this.stripTimeFromDateString(query);
    this.submit.emit(query);
  }

  getMinValue(facetCode: string) {
    const dimensions = this.getSelectedDimensions(facetCode);
    return dimensions ? dimensions.min : null;
  }

  getMaxValue(facetCode: string) {
    const dimensions = this.getSelectedDimensions(facetCode);
    return dimensions ? dimensions.max : null;
  }

  private getSelectedDimensions(facetName: string): any {
    if (this.breadcrumbs) {
      return this.breadcrumbs.filter(value => value.facetCode === facetName).map(value => {
        let str = value.facetValueCode;
        str = str.slice(1, str.length - 1);
        const values = str.split(' ');
        return {
          min: values[0],
          max: values[2]
        };
      })[0];
    }
  }
}
