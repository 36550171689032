import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {PForecastService} from '../../providers/plansee/p-forecast-service';
import {Animations} from '../../animations/animations';
import {PSearch} from '../p-search';
import {TranslateService} from '@ngx-translate/core';
import {PForecastingSearchPageWsDTO, PDownloadModalOptions} from '../../providers/types/planseeoccaddon';
import {take} from 'rxjs/operators';

@Component({
  selector: 'forecasts-search',
  template: require('./forecasts-search.component.html'),
  animations: [
    Animations.expand()
  ]
})
export class ForecastsSearchComponent extends PSearch implements OnInit {

  get forecast(): boolean {
    return this._forecast;
  }

  set forecast(value: boolean) {
    this._forecast = value;
  }

  get confirmedQuantity(): boolean {
    return this._confirmedQuantity;
  }

  set confirmedQuantity(value: boolean) {
    this._confirmedQuantity = value;
  }

  get fromDate() {
    return this._fromDate;
  }

  set fromDate(strDate: string) {
    this._fromDate = strDate;
    this.checkTimePeriod();
  }

  get toDate() {
    return this._toDate;
  }

  set toDate(strDate: string) {
    this._toDate = strDate;
    this.checkTimePeriod();
  }

  options: PDownloadModalOptions = {};
  _forecast = false;
  _confirmedQuantity = false;
  _fromDate: string;
  _toDateFromSegment: string;
  _toDate: string;
  showModal = false;

  constructor(private forecastService: PForecastService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService) {
    super(i18nService, authorizationService);
  }

  ngOnInit(): void {
    this.setInitialDates();
  }

  setInitialDates() {
    const today = new Date();
    this.fromDate = this.toISO8601(new Date(today.setMonth(today.getMonth() - 1)));
    if (this._toDateFromSegment !== '' && typeof this._toDateFromSegment !== 'undefined') {
      this.toDate = this._toDateFromSegment;
    } else {
      this.toDate = this.toISO8601(new Date());
    }
  }

  updateSearchResult(searchResult: PForecastingSearchPageWsDTO) {
    this.state = searchResult;
    this.setToDateFromSegment(searchResult.productHistoryForecastRecords);
    // override query since it comes broken out of backend
    this.state.freeTextSearch = this._query && this._query.query ? this.decodeSearchQuery(this._query.query.split(':')[0]) : '';
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }

  search(query: string, emit = false) {
    // remove time from query cuz of :
    query = this.stripTimeFromDateString(query);
    let data = this.checkAndUpdateQuery(query);
    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;
        if (emit) {
          // trigger search in list component
          this.submit();
        } else {
          this.forecastService.search(data).pipe(take(1)).subscribe(val => {
            this.state = val;
            this.state.freeTextSearch = this.decodeSearchQuery(query.split(':')[0]);
            // override query since it comes broken out of backend
            this.state.currentQuery.query.value = query;
          });
        }
    }
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.forecastService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getCurrentSelectionDocument() {
    const keys = [
      'forecasting.customerMaterialNumber',
      'forecasting.description',
      'forecasting.planseeMaterialNumber',
      'forecasting.segments',
    ];
    let optionalFields = '';
    if (this.forecast) {
      optionalFields += 'forecastQuantity:';
    }
    if (this.confirmedQuantity) {
      optionalFields += 'orderedQuantity:';
    }
    const dates = this.formatDownloadDates(this.fromDate, this.toDate, this.toISO8601(new Date()));
    optionalFields += `timeRange=${dates.fromDate}TO${dates.toDate}`;

    this.forecastService.getListCsvContent(this.createTranslations(keys), optionalFields).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
      this.showModal = false;
    });
  }

  openDownloadModal() {
    this.showModal = true;
    this.forecast = false;
    this.confirmedQuantity = false;
    this.setInitialDates();
  }

  closeDownloadModal() {
    this.showModal = false;
  }

  setForecast(event) {
    this.forecast = event.target.checked;
  }

  setConfirmedQuantity(event) {
    this.confirmedQuantity = event.target.checked;
  }

  /*
    params: date
    return string of type 'yyyy-MM-dd';
  */
  private toISO8601(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    let monthStr = month < 10 ? `0${month}` : `${month}`;
    let dayStr = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${monthStr}-${dayStr}`;
  }

  private checkTimePeriod() {
    if (new Date(this.fromDate) > new Date(this.toDate)) {
      this.toDate = this.fromDate;
    }
  }

   /*
    params: forecastRecords got from server
    action: set TO date either today or last forecast record date
    whatever is later;
  */
  private setToDateFromSegment(forecastRecords = []) {
    if (forecastRecords.length > 0) {
      const lastForecastRecord = forecastRecords[forecastRecords.length - 1];
      if (lastForecastRecord.segments && lastForecastRecord.segments.length > 0) {
        const lastSegmentOfLastForecastRecord = lastForecastRecord.segments[lastForecastRecord.segments.length - 1];
        if (lastSegmentOfLastForecastRecord && lastSegmentOfLastForecastRecord.firstDayOfPeriod) {
          const lastDate = new Date(lastSegmentOfLastForecastRecord.firstDayOfPeriod);
          const today = new Date();
          if (lastDate > today) {
            this.toDate = this.toISO8601(lastDate);
             // the toDateFromSegment will be used later to reset dates
            // when user changed the dates to something else;
            this._toDateFromSegment = this.toDate;
          }
        }
        return;
      }
      return;
    }
    return;
  }
}
