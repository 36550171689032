import {NgModule} from '@angular/core';
import {DashboardBasicDataComponent} from './dashboard-basic-data/dashboard-basic-data.component';
import {DashboardContactComponent} from './dashboard-contact/dashboard-contact.component';
import {DashboardInvoicesComponent} from './dashboard-invoices/dashboard-invoices.component';
import {DashboardNotificationsComponent} from './dashboard-notifications/dashboard-notifications.component';
import {DashboardOrdersComponent} from './dashboard-orders/dashboard-orders.component';
import {DashboardShipmentsComponent} from './dashboard-shipments/dashboard-shipments.component';
import {RouterModule} from '@angular/router';
import {FiltersModule} from '../../filters/filters.module';
import {CommonModule} from '@angular/common';
import {PlanseeCarouselModule} from '../carousel/plansee-carousel.module';
import {TranslateModule} from '@ngx-translate/core';
import {DashboardInvoicesTableComponent} from './dashboard-invoices/dashboard-invoices-table/dashboard-invoices-table.component';
import {DashboardOrdersTableComponent} from './dashboard-orders/dashboard-orders-table/dashboard-orders-table.component';
import {DashboardShipmentsTableComponent} from './dashboard-shipments/dashboard-shipments-table/dashboard-shipments-table.component';
import {DataTableModule} from 'ngx-datatable-bootstrap4';
import {PlanseeDashboardComponent} from './plansee-dashboard.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FormComponentsModule} from '../../form-components/form-components.module';

@NgModule({
  imports: [
    CommonModule,
    FiltersModule,
    RouterModule,
    PlanseeCarouselModule,
    TranslateModule,
    DataTableModule,
    NgbTooltipModule,
    FormComponentsModule
  ],
  declarations: [
    PlanseeDashboardComponent,
    DashboardBasicDataComponent,
    DashboardContactComponent,
    DashboardInvoicesComponent,
    DashboardNotificationsComponent,
    DashboardOrdersComponent,
    DashboardShipmentsComponent,
    DashboardOrdersTableComponent,
    DashboardShipmentsTableComponent,
    DashboardInvoicesTableComponent
  ],
  exports: [
    PlanseeDashboardComponent
  ]
})
export class PlanseeDashboardModule { }
