import { PPage } from './p-page';
import { OnInit } from '@angular/core';
import { PSearchPageWsDTO } from '../providers/types/planseeoccaddon';
import { SearchTab } from '../shared/components/search-tabs/search-tab';

export abstract class PTabsPage<T, Q> extends PPage implements OnInit {
  tabs: SearchTab<T, Q>[];
  prevSelectedTabCode: string;
  selectedTab: SearchTab<T, Q>;

  abstract selectedTabQuery: any;

  ngOnInit() {
    this.setDefaultTab();
  }

  abstract onSearchResult(result: PSearchPageWsDTO);

  onFilterChanged() {
    if (this.selectedTab) {
      this.prevSelectedTabCode = this.selectedTab.code;
    }
    this.selectedTab = null;
  }

  protected setDefaultTab(checkPrevTab = false) {
    let prevSelectedTab = null;

    if (this.prevSelectedTabCode) {
      prevSelectedTab = this.tabs.find(t => t.count > 0 && t.code === this.prevSelectedTabCode);
    }

    if (prevSelectedTab) {
      this.selectedTab = prevSelectedTab;
      this.prevSelectedTabCode = null;
    } else if (this.tabs.length) {
      this.selectedTab = this.tabs[0];
    }
  }
}
