import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'imprint-text',
  template: require('./imprint-content.component.html'),
  styles: [require('./imprint-content.component.scss')]
})
export class ImprintContentComponent {

  constructor(
    public translateService: TranslateService
  ) {
  }
}
