/*
  Custom date pipe to change the behavior of date pip of angular
  to make Monday the start of the week instead of Sunday
  There is a bug report that should fix the issue
  Bug: https://github.com/angular/angular/issues/25380
*/
import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'pDatePipe'
})
export class PDatePipe implements PipeTransform {

  private datePipe = new DatePipe('en-US');

  // TODO: we have to refactor the whole usage of pDatePipe.
  // We generate one object of {week, month, year} and return it
  // right now we are calling for week, for month and year separately;
  transform(date: string | Date, format: string, weekStartsMondayTransform = false): any {
    // Since IE does not understand date in format where moliseconds does not have : or .
    // we have to replace '+' before miliseconds with '.' so the date will be parsed in IE;
    let clonedDate;
    if (typeof date === 'string') {
      const strDate = date.toString().slice(0, date.toString().indexOf('+')) + '+00:00';
      clonedDate = new Date(strDate);
    } else {
      clonedDate = new Date(date);
    }
    if (weekStartsMondayTransform && (format === 'w' || format === 'ww')) {
      const weekNumber = getWeek(clonedDate);
      return format === 'ww' ? weekNumber.toLocaleString('en-US', {minimumIntegerDigits: 2})
            : weekNumber.toLocaleString('en-US', {minimumIntegerDigits: 1});
    } else if (format === 'yyyy-ww' || format === 'ww-yyyy') {
      // seems like angular pipe returning wrong week number;
      // and when the date is in 2017 but the week is the last week of 2016
      // angular will return 2017, but we need 2016.
      const weekNumber = getWeek(clonedDate).toLocaleString('en-US', {minimumIntegerDigits: 2});
      const {year} = getYearMonth(clonedDate);
      return format === 'yyyy-ww' ? `${year}-${weekNumber}` : `${weekNumber}-${year}`;
    } else if (format === 'yyyy') {
      return getYearMonth(clonedDate).year;
    } else if (format === 'full') {
      const week = getWeek(clonedDate).toLocaleString('en-US', {minimumIntegerDigits: 2});
      const {year, month} = getYearMonth(clonedDate);
      return {week, month, year};
    }

    // if other format or other day is requested return the usual datepipe transform
    return this.datePipe.transform(date, format);
  }
}

function getYearMonth(date) {
  const clonedDate = new Date(new Date(date).getTime());
  const numberOfDay = clonedDate.getDate();
  const unixTimestamp = clonedDate.setDate(numberOfDay + 3 - (numberOfDay + 6) % 7);
  const unixDate = new Date(unixTimestamp);
  return {year: unixDate.getFullYear(), month: ('0' + (unixDate.getMonth() + 1)).slice(-2)};
}

function getWeek(date) {
  // source: https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php/39502645#39502645
  let clonedDate;
  if (!(date instanceof Date)) {
    clonedDate = new Date();
  } else {
    clonedDate = new Date(date.valueOf());
  }

  // ISO week date weeks start on Monday, so correct the day number
  const nDay = (clonedDate.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  clonedDate.setDate(clonedDate.getDate() - nDay + 3);

  // Store the millisecond value of the target date
  const n1stThursday = clonedDate.valueOf();

  // Set the target to the first Thursday of the year
  // First, set the target to January 1st
  clonedDate.setMonth(0, 1);

  // Not a Thursday? Correct the date to the next Thursday
  if (clonedDate.getDay() !== 4) {
    clonedDate.setMonth(0, 1 + ((4 - clonedDate.getDay()) + 7) % 7);
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  return 1 + Math.ceil((n1stThursday - clonedDate) / 604800000);
}
