// new version of p-orders-service. The old one can be removed when whole app will switch to new version
import { Injectable } from '@angular/core';
import { PService } from './p-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';
import { AuthorizationService } from './authorization-service';
import { PaginationWsDTO, QueryRequestWsDTO } from '../types/ycommercewebservices';
import {
  PConsignmentEntryDetailsWsDTOV2,
  POrderEntrySearchPageWsDTO,
  PRequestWsDTO,
  Translation,
} from '../types/planseeoccaddon';
import { Observable } from 'rxjs';
import { Orders } from '../../components/orders-v2/orders-model';
import { defaultTo } from 'lodash';

@Injectable()
export class PlanseeOrdersService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  search(queryParams?: QueryRequestWsDTO & PRequestWsDTO, onlyBookmarks = false): Observable<Orders> {
    this.currentQueryParams = Object.assign({}, queryParams);

    if (onlyBookmarks) {
      return this.get<POrderEntrySearchPageWsDTO>(
        `orderentries/bookmarkedOrderEntriesV2?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
        {params: this.stripQueryStringFromQueryObject(queryParams)}
      )
        .map(data => ({...data, items: defaultTo(data.orderEntries, [])}));
    }

    return this.get<POrderEntrySearchPageWsDTO>(
      `orderentries/searchV2?query=${this.encodeLimitsForDateRange(queryParams.query)}`,
      {params: this.stripQueryStringFromQueryObject(queryParams)}
    )
      .map(data => ({...data, items: defaultTo(data.orderEntries, [])}));
  }

  getListCsvContent(translations: Translation[], pagination: PaginationWsDTO): Observable<any> {
    this.setPaginationInformations(pagination);
    return this.getCsvContent(`orderentries/downloadV2`, translations);
  }

  getConsignmentEntryDetails(orderCode: string, entryNumber: number, queryParams?: PRequestWsDTO): Observable<PConsignmentEntryDetailsWsDTOV2> {
    return this.get<PConsignmentEntryDetailsWsDTOV2>(`orderentries/${orderCode}/${entryNumber}/shipmentsummaryV2`, {params: queryParams});
  }

  updateBookmark(orderCode: string, entryNumber: number, selected: boolean): Observable<string> {
    return selected ? this.addBookmark(orderCode, entryNumber) : this.removeBookmark(orderCode, entryNumber);
  }

  private addBookmark(orderCode: string, entryNumber: number): Observable<string> {
    return this.get<string>(`orderentries/bookmark/add/${orderCode}/${entryNumber}`);
  }

  private removeBookmark(orderCode: string, entryNumber: number): Observable<string> {
    return this.get<string>(`orderentries/bookmark/remove/${orderCode}/${entryNumber}`);
  }
}
