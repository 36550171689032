import {Component} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';

@Component({
  selector: 'error-page',
  template: require('./error.page.html'),
  styles: [require('./error.page.scss')]
})
export class ErrorPageComponent {
  static returnUrl: string;

  constructor(private router: Router) {
    this.router.events.filter(event => event instanceof RoutesRecognized).pairwise().subscribe((routeChangeEvents: any[]) => {
      // Save the url of the last route before the one that generates error
      let parentOfErrorRoute = routeChangeEvents[0];
      ErrorPageComponent.returnUrl = parentOfErrorRoute && parentOfErrorRoute.url ? parentOfErrorRoute.url : '/menu';
    });
  }

  goBack() {
    if (window.location.pathname.indexOf('/menu/products/') >= 0) {
      this.router.navigate(['/menu/products']);
    } else if (window.location.pathname.indexOf('/menu/documents/') >= 0) {
      this.router.navigate(['/menu/documents']);
    } else {
      window.history.back();
    }
  }

  goHome() {
    // /menu/dashboard
    this.router.navigate(['/']);
  }
}
