import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bookmark-button',
  template: require('./bookmark-button.component.html'),
  styles: [require('./bookmark-button.component.scss')],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BookmarkButtonComponent), multi: true}
  ],
  encapsulation: ViewEncapsulation.None
})
export class BookmarkButtonComponent implements ControlValueAccessor {

  value = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    // do nothing
  }

  writeValue(value: boolean) {
      this.value = value;
  }

  onButtonClick() {
    this.value = !this.value;
    this.onChange(this.value);
  }
}
