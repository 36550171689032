import { Injectable } from '@angular/core';
import { APP_MODE_KEY, AppMode } from '../types/app-mode';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeCompanyIdFromStorage } from '../../shared/utils/authorization-storage-utils';

@Injectable()
export class AppModeService {
  protected currentModeSubject = new BehaviorSubject<AppMode>(AppMode.OLD_VERSION);
  currentMode$ = this.currentModeSubject.asObservable()
    .pipe(
      map(currentMode => {
        const customerId = takeCompanyIdFromStorage();

        if (!customerId) {
          return AppMode.OLD_VERSION;
        }

        return currentMode;
      })
    );

  constructor() {
    this.checkCurrentModeInLocalStorage();
  }

  setCurrentMode(mode: AppMode) {
    localStorage.setItem(APP_MODE_KEY, mode);
    this.currentModeSubject.next( mode);
  }

  setNewMode() {
    this.setCurrentMode(AppMode.NEW_VERSION);
  }

  setOldMode() {
    this.setCurrentMode(AppMode.OLD_VERSION);
  }

  removeCurrentMode()  {
    this.currentModeSubject.next(AppMode.OLD_VERSION);
    localStorage.removeItem(APP_MODE_KEY);
  }

  private checkCurrentModeInLocalStorage() {
    const modeFromLocalStorage = localStorage.getItem(APP_MODE_KEY);

    if (modeFromLocalStorage === AppMode.NEW_VERSION) {
      this.currentModeSubject.next(AppMode.NEW_VERSION);
    } else if (modeFromLocalStorage === AppMode.OLD_VERSION) {
      this.currentModeSubject.next(AppMode.OLD_VERSION);
    } else {
      // set old version as Default one;
      this.setCurrentMode(AppMode.OLD_VERSION);
    }
  }
}
