import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Translation } from '../types/planseeoccaddon';

@Injectable()
export class PlanseeTranslateService {

  constructor(
    private translateService: TranslateService
  ) {
  }

  onLangChange(): Observable<LangChangeEvent> {
    return this.translateService.onLangChange
      .pipe(startWith(({
        lang: this.translateService.currentLang,
        translations: {}
      })));
  }

  translateMonth(monthName: string): string {
    const lowerCaseMonthName = monthName ? monthName.toLowerCase() : '';

    return lowerCaseMonthName ? this.translateService.instant(`general.monthsName.${lowerCaseMonthName}`) : '';
  }

  createTranslations(keys: string[]): Translation[] {
    return keys.reduce((acc: Translation[], curr) => {
      if (curr) {
        acc[curr] = this.translateService.instant(`download.${curr.toLowerCase()}`) || curr;
      }
      return acc;
    }, {} as Translation[]);
  }
}
