import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimsPageComponent } from './pages/claims/claims.page';
import { ConsignmentsPageComponent } from './pages/consignments/consignments.page';
import { DashboardPageComponent } from './pages/dashboard/dashboard.page';
import { DocumentsPageComponent } from './pages/documents/documents.page';
import { ForecastingPageComponent } from './pages/forecasting/forecasting.page';
import { HomePageComponent } from './pages/home/home.page';
import { InvoicesPageComponent } from './pages/invoices/invoices.page';
import { LoginPageComponent } from './pages/login/login.page';
import { OrdersPageComponent } from './pages/orders/orders.page';
import { ProductPageComponent } from './pages/product/product.page';
import { ProductsPageComponent } from './pages/products/products.page';
import { SettingsPageComponent } from './pages/settings/settings.page';
import { ShipmentsPageComponent } from './pages/shipments/shipments.page';
import { ClaimPageComponent } from './pages/claim/claim.page';
import { ClaimFormStep1Component } from './components/claim-form-step-1/claim-form-step-1.component';
import { ClaimFormStep2Component } from './components/claim-form-step-2/claim-form-step-2.component';
import { ClaimFormStep3Component } from './components/claim-form-step-3/claim-form-step-3.component';
import { QuotationsPageComponent } from './pages/quotations/quotations.page';
import { QuotationPageComponent } from './pages/quotation/quotation.page';
import { ImprintPageComponent } from './pages/imprint/imprint.page';
import { ErrorPageComponent } from './pages/error/error.page';
import { ClaimFormStep4Component } from './components/claim-form-step-4/claim-form-step-4.component';
import { ClaimConfirmationPageComponent } from './pages/claim-confirmation/claim-confirmation.page';
import { QuotationConfirmationPageComponent } from './pages/quotation-confirmation/quotation-confirmation.page';
import { SetPasswordPageComponent } from './pages/set-password/set-password.page';
import { ForgottenPasswordPageComponent } from './pages/forgotten-password/forgotten-password.page';
import { UserAdministrationPageComponent } from './pages/userAdministration/userAdministration.page';
import { scrollToNotificationsParam, updatePassword } from '../utils';
import { TermConditionsComponent } from './pages/term-conditions/term-conditions.component';
import { PublicPageComponent } from './pages/public/public.page';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { MenuGuard } from './shared/guards/menu-guard.service';
import { RegisterPageComponent } from './pages/register/register.page';
import { DataProtectionPage } from './pages/data-protection/data-protection.page';
import { GeneralConditionsOfSalePage } from './pages/general-conditions-of-sale/general-conditions-of-sale.page';
import { PageRights } from './rights/page-rights';
import { HotZoneConfiguratorPageComponent } from './pages/hot-zone-configurator/hot-zone-configurator.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: '',
    component: PublicPageComponent,
    children: [
      {
        path: 'login',
        component: LoginPageComponent
      },
      {
        path: 'setPassword/:id',
        component: SetPasswordPageComponent
      },
      {
        path: 'updatePassword/:id',
        component: SetPasswordPageComponent,
        data: {
          [updatePassword]: true
        }
      },
      {
        path: 'termConditions',
        component: TermConditionsComponent
      },
      {
        path: 'forgottenPassword',
        component: ForgottenPasswordPageComponent
      },
      {
        path: 'imprint',
        component: ImprintPageComponent
      },
      {
        path: 'dataProtection',
        component: DataProtectionPage
      },
      {
        path: 'termsAndCondition',
        component: GeneralConditionsOfSalePage
      },
      {
        path: 'register',
        component: RegisterPageComponent
      },
    ]
  },
  {
    path: 'menu',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    canActivateChild: [MenuGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardPageComponent,
        data: {
          pageRightsKey: PageRights.DASHBOARD,
        }
      },
      {
        path: 'claims/new',
        component: ClaimPageComponent,
        data: {
          pageRightsKey: PageRights.CLAIMS,
        },
        children: [
          {
            path: '',
            component: ClaimFormStep1Component
          },
          {
            path: 'step2',
            component: ClaimFormStep2Component
          },
          {
            path: 'step3',
            component: ClaimFormStep3Component
          },
          {
            path: 'step4',
            component: ClaimFormStep4Component
          }
        ]
      },
      {
        path: 'claims',
        component: ClaimsPageComponent,
        data: {
          pageRightsKey: PageRights.CLAIMS,
        }
      },
      {
        path: 'claims/confirmation',
        component: ClaimConfirmationPageComponent,
        data: {
          pageRightsKey: PageRights.CLAIMS,
        }
      },
      {
        path: 'claims/:id',
        component: ClaimsPageComponent,
        data: {
          pageRightsKey: PageRights.CLAIMS,
        }
      },
      {
        path: 'consignments',
        component: ConsignmentsPageComponent,
        data: {
          pageRightsKey: PageRights.CONSIGNMENTS,
        }
      },
      {
        path: 'consignments/:id',
        component: ConsignmentsPageComponent,
        data: {
          pageRightsKey: PageRights.CONSIGNMENTS,
        }
      },
      {
        path: 'documents',
        component: DocumentsPageComponent,
        data: {
          pageRightsKey: PageRights.DOCUMENTS,
        }
      },
      {
        path: 'documents/:id',
        component: DocumentsPageComponent,
        data: {
          pageRightsKey: PageRights.DOCUMENTS,
        }
      },
      {
        path: 'forecasts',
        component: ForecastingPageComponent,
        data: {
          pageRightsKey: PageRights.FORECASTS,
        }
      },
      {
        path: 'forecasts/:id',
        component: ForecastingPageComponent,
        data: {
          pageRightsKey: PageRights.FORECASTS,
        }
      },
      {
        path: 'invoices',
        component: InvoicesPageComponent,
        data: {
          pageRightsKey: PageRights.INVOICES,
        }
      },
      {
        path: 'invoices/:id',
        component: InvoicesPageComponent,
        data: {
          pageRightsKey: PageRights.INVOICES,
        }
      },
      {
        path: 'orders',
        component: OrdersPageComponent,
        data: {
          pageRightsKey: PageRights.ORDERS,
        }
      },
      {
        path: 'orders/:id',
        component: OrdersPageComponent,
        data: {
          pageRightsKey: PageRights.ORDERS,
        }
      },
      {
        path: 'products',
        component: ProductsPageComponent,
        data: {
          pageRightsKey: PageRights.PRODUCTS,
        }
      },
      {
        path: 'products/:id',
        component: ProductPageComponent,
        data: {
          pageRightsKey: PageRights.PRODUCTS,
        }
      },
      {
        path: 'products/:id/:b2bUnit',
        component: ProductPageComponent,
        data: {
          pageRightsKey: PageRights.PRODUCTS,
        }
      },
      {
        path: 'rfqs/new',
        component: QuotationPageComponent,
        data: {
          pageRightsKey: PageRights.RFQS,
        }
      },
      {
        path: 'rfqs',
        component: QuotationsPageComponent,
        data: {
          pageRightsKey: PageRights.RFQS,
        }
      },
      {
        path: 'rfqs/confirmation',
        component: QuotationConfirmationPageComponent,
        data: {
          pageRightsKey: PageRights.RFQS,
        }
      },
      {
        path: 'rfqs/:id',
        component: QuotationsPageComponent,
        data: {
          pageRightsKey: PageRights.RFQS,
        }
      },
      {
        path: 'shipments',
        component: ShipmentsPageComponent,
        data: {
          pageRightsKey: PageRights.SHIPMENTS,
        }
      },
      {
        path: 'shipments/:id',
        component: ShipmentsPageComponent,
        data: {
          pageRightsKey: PageRights.SHIPMENTS,
        }
      },
      {
        path: 'hotZoneConfigurator',
        component: HotZoneConfiguratorPageComponent,
        data: {
          pageRightsKey: PageRights.HOT_ZONE_CONFIGURATOR,
        }
      },
      {
        path: 'settings',
        component: SettingsPageComponent
      },
      {
        path: 'settingsNotifications',
        component: SettingsPageComponent,
        data: {
          [scrollToNotificationsParam]: true
        }
      },
      {
        path: 'imprint',
        component: ImprintPageComponent
      },
      {
        path: 'userAdministration',
        component: UserAdministrationPageComponent
      }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: '**',
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard,
    MenuGuard
  ]
})
export class AppRoutingModule {
}
