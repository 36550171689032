import { Component, Input } from '@angular/core';
import { DataTableParams, DataTableRowEvent } from 'ngx-datatable-bootstrap4';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PQuotationStatus, PQuotationWsDTO } from '../../providers/types/planseeoccaddon';
import { PQuotationsService } from '../../providers/plansee/p-quotations-service';
import { PDataTable } from '../p-data-table';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../providers/plansee/loading-service';
import { PDocumentsService } from '../../providers/plansee/p-documents-service';
import { take } from 'rxjs/operators';

export enum QuotationsListStatus {
  IN_PROGRESS = 1,
  COMPLETED
}

const statusesForFirstTable = [PQuotationStatus.SUBMITTED, PQuotationStatus.DETERMINING_COSTS, PQuotationStatus.COSTS_DETERMINED, PQuotationStatus.COMPLETE];
const statusesForSecondTable = [PQuotationStatus.COMPLETE_PDF_PRINTED];

@Component({
  selector: 'quotations-list',
  template: require('./quotations-list.component.html'),
  styles: [require('./quotations-list.component.scss')]
})
export class QuotationsListComponent extends PDataTable<PQuotationWsDTO> {

  defaultSort = 'submissionDate-desc';
  items: PQuotationWsDTO[] = [];
  @Input()
  filter: QuotationsListStatus;
  isSearching: boolean;

  constructor(private quotationsService: PQuotationsService,
    authorizationService: AuthorizationService,
    i18nService: TranslateService,
    loadingService: LoadingService,
    protected documentsService: PDocumentsService) {
    super(i18nService, authorizationService, loadingService);
    this._query.pageSize = 10;
  }

  /* tslint:disable:cognitive-complexity */
  reloadItems(params: DataTableParams) {
    if (!this.filter) {
      return;
    }
    this._query.query += this._query.query.indexOf(':') >= 0 ? '' : ':';
    let compiledQuery = this._query.query;

    this.isSearching = compiledQuery.indexOf(':') !== 0;
    if (compiledQuery.indexOf('status') < 0) {
      // check relevance tag
      this.areFacetsApplied = compiledQuery.split(':').length !== compiledQuery.indexOf('relevance') + 1;
      // add status codes
      for (let status of !this.isCompleted() ? statusesForFirstTable : statusesForSecondTable) {
        compiledQuery += `:status:${status}`;
      }
    } else {
      // remove statusesForSecondTable from query based on the list type
      for (let status of this.isCompleted() ? statusesForFirstTable : statusesForSecondTable) {
        const value = `:status:${status}`;
        if (compiledQuery.indexOf(value) >= 0) {
          compiledQuery = compiledQuery.replace(value, '');
        }
      }
      this.areFacetsApplied = this.isFilterApplied(compiledQuery);
    }

    if (compiledQuery.indexOf('status') < 0) {
      this.items = [];
      // reset but do not alter the query since reloadItems will be triggered again cuz of pagination changed programmatically
      this.refreshPagination({ totalResults: 0 });
      return;
    } else {
      this._query.query = compiledQuery;
    }
    this.updateQuery(params);
    this.quotationsService.search(this._query, this.onlyBookmarks).subscribe(searchResult => {
      this.items = searchResult.quotations;

      // Update pagination with the counts comming from BE
      this.refreshPagination(searchResult.pagination);
    });
  }
  /* tslint:enable:cognitive-complexity */

  handleStatusCodes(compiledQuery: string) {
    if (compiledQuery.indexOf('status') < 0) {
      // check relevance tag
      this.areFacetsApplied = compiledQuery.split(':').length !== compiledQuery.indexOf('relevance') + 1;
      // add status codes
      for (let status of !this.isCompleted() ? statusesForFirstTable : statusesForSecondTable) {
        compiledQuery += `:status:${status}`;
      }
    } else {
      // remove statusesForSecondTable from query based on the list type
      for (let status of this.isCompleted() ? statusesForFirstTable : statusesForSecondTable) {
        const value = `:status:${status}`;
        if (compiledQuery.indexOf(value) >= 0) {
          compiledQuery = compiledQuery.replace(value, '');
        }
      }
      this.areFacetsApplied = this.isFilterApplied(compiledQuery);
    }

    if (compiledQuery.indexOf('status') < 0) {
      this.items = [];
      // reset but do not alter the query since reloadItems will be triggered again cuz of pagination changed programmatically
      this.refreshPagination({ totalResults: 0 });
      return;
    } else {
      this._query.query = compiledQuery;
    }
  }

  isCompleted() {
    return QuotationsListStatus.COMPLETED === this.filter;
  }

  isFilterApplied(query: string) {
    return query.split(':').filter(item => item !== '').some(item => {
      if (this.isCompleted()) {
        if (item === 'status' || statusesForSecondTable.find(status => status === item)) {
          return false;
        } else {
          return true;
        }
      } else {
        if (item === 'status' || statusesForFirstTable.find(status => status === item)) {
          return false;
        } else  {
          return true;
        }
      }
    });
  }

  rowClick(event: DataTableRowEvent<PQuotationWsDTO>) {
    const item: {
      detailsExpanded?: boolean
    } & PQuotationWsDTO = event.row.item;

    if (!this.isCompleted()) {
      if (!event.row.item.entries) {
        this.searchEntries(event, item);
      } else {
        event.row.expanded = !event.row.expanded;
      }
    }
    item.detailsExpanded = event.row.expanded;
  }

  rowExpand(event: DataTableRowEvent<PQuotationWsDTO>) {
    const item: {
      detailsExpanded?: boolean
    } & PQuotationWsDTO = event.row.item;

    let expanded = event.row.expanded;
    event.row.expanded = false;
    if (!event.row.item.entries) {
      this.searchEntries(event, item);
    } else {
      event.row.expanded = expanded;
    }
    item.detailsExpanded = event.row.expanded;
  }

  searchEntries(event: DataTableRowEvent<PQuotationWsDTO>, item) {
    const id = event.row.item.sapId ? event.row.item.sapId : event.row.item.id;
    // queryParams
    let data: any = {}; // this._query
    this.quotationsService.searchEntries(id, data).subscribe(searchResult => {
      event.row.item.entries = searchResult.entries;
      event.row.expanded = searchResult.entries && searchResult.entries.length > 0;
      item.detailsExpanded = event.row.expanded;
    });
  }

  onBookmarkChange(selected: boolean, quotation: PQuotationWsDTO) {
    if (quotation && quotation.id) {
      this.quotationsService.updateBookmark(quotation.id, quotation.sapId || '', selected)
        .pipe(take(1))
        .subscribe(() => this.reloadItems(this._query));
    }
  }
}
