import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'data-protection',
  template: require('./data-protection.component.html'),
  styles: [require('./data-protection.component.scss')]
})
export class DataProtectionComponent {
  constructor(
    public translateService: TranslateService
  ) {
  }
}
