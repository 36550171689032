import { Component, OnInit } from '@angular/core';
import { Animations } from '../../animations/animations';
import { DataTableParams } from 'ngx-datatable-bootstrap4';
import {
  PlanseeTable,
  PlanseeTableDateFilter,
  PlanseeTableDefaultFilter,
  PlanseeTableSearchEvent,
} from '../plansee-table/plansee-table-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { TranslateService } from '@ngx-translate/core';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { PQuotationStatus, PRequestWsDTO } from '../../providers/types/planseeoccaddon';
import { defaultTo, get, isEqual } from 'lodash';
import { PlanseeQuotationsService } from '../../providers/plansee/plansee-quotations.service';
import { QuotationBookmarkChangeEvent } from './quotations-model';
import { distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { NONE_PARAM } from '../../providers/plansee/p-service';

@Component({
  selector: 'quotations',
  template: require('./quotations.component.html'),
  animations: [Animations.expand()]
})
export class QuotationsComponent extends SubscriptionManager implements OnInit {

  dataTableParams: DataTableParams = {};
  dateFilters: PlanseeTableDateFilter[];
  defaultFilters: PlanseeTableDefaultFilter[];
  filterSubmittedDateParamKey = 'submit';
  filterExpirationDateParamKey = 'deadline';
  filterDefaultParamKey = 'statuses';
  statusesForFirstTable = [
    PQuotationStatus.SUBMITTED_V2,
    PQuotationStatus.DETERMINING_COSTS_V2,
    PQuotationStatus.COSTS_DETERMINED_V2,
    PQuotationStatus.COMPLETE_V2
  ];
  statusesForSecondTable = [PQuotationStatus.COMPLETE_PDF_PRINTED_V2];

  secondTableFilters$ = new BehaviorSubject<PlanseeTableSearchEvent>({ filters: { searchTerm: '', dates: {  } } });
  secondTableParams$ = new BehaviorSubject<DataTableParams>({ offset: 0, limit: 50 });
  secondTableData$: Observable<PlanseeTable> = combineLatest(
    this.secondTableFilters$,
    this.secondTableParams$.pipe(distinctUntilChanged((f1, f2) => isEqual(f1, f2)))
  ).pipe(
    switchMap(([ event ]) => {
      return this.searchFn({ ...(event || {}), secondTable: true } as PlanseeTableSearchEvent);
    })
  );

  constructor(
    private planseeTranslateService: PlanseeTranslateService,
    private translateService: TranslateService,
    private planseeQuotationsService: PlanseeQuotationsService,
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .subscribe(() => {
          this.dateFilters = [
            {
              key: this.filterSubmittedDateParamKey,
              label: this.translateService.instant('quotationsList.submittedDateFilter')
            },
            {
              key: this.filterExpirationDateParamKey,
              label: this.translateService.instant('quotationsList.expirationDateFilter')
            }
          ];
          this.defaultFilters = [
            {
              key: this.filterDefaultParamKey,
              label: this.translateService.instant('quotationsSearch.statusesLabel'),
              options: [
                { key: PQuotationStatus.SUBMITTED_V2, label: this.translateService.instant('quotationsSearch.statuses.s0') },
                { key: PQuotationStatus.DETERMINING_COSTS_V2, label: this.translateService.instant('quotationsSearch.statuses.s1') },
                { key: PQuotationStatus.COSTS_DETERMINED_V2, label: this.translateService.instant('quotationsSearch.statuses.s2') },
                { key: PQuotationStatus.COMPLETE_V2, label: this.translateService.instant('quotationsSearch.statuses.s3') },
                { key: PQuotationStatus.COMPLETE_PDF_PRINTED_V2, label: this.translateService.instant('quotationsSearch.statuses.s4') },
              ]
            }
          ];
        })
    );
  }

  searchFn = (event: PlanseeTableSearchEvent): Observable<any> => {
    const secondTable = get(event, 'secondTable', false);
    return this.planseeQuotationsService.search(
      this.prepareParams(event, secondTable ? this.statusesForSecondTable : this.statusesForFirstTable),
      get(event, 'bookmarkSelected', false)
    );
  }

  reload(options: DataTableParams) {
    // we need to add currentTime to fired setter inside plansee-table.component.ts
    const tempOptions: any = { ...options, currentTime: new Date().getTime() };
    this.onReloadItems(tempOptions);
    this.secondTableParams$.next(tempOptions);
  }

  onReloadItems(options: DataTableParams) {
    this.dataTableParams = options;
  }

  onFiltersChange(event: PlanseeTableSearchEvent) {
    this.secondTableFilters$.next(event);
  }

  onBookmarkChange(event: QuotationBookmarkChangeEvent) {
    const quotation = event.quotation || {};
    if (quotation && (quotation.id || quotation.sapId)) {
      this.planseeQuotationsService.updateBookmark(quotation.id, quotation.sapId || '', event.selected)
        .pipe(take(1))
        .subscribe(() => this.reload(this.dataTableParams));
    }
  }

  private prepareParams(event?: PlanseeTableSearchEvent, statusesPerTable?: string[]): QueryRequestWsDTO & PRequestWsDTO {
    const params = { query: get(event, 'filters.searchTerm', '') || '' };
    const dates = get(event, 'filters.dates', {}) || {};
    const defaults = get(event, 'filters.defaults', {}) || {};
    const paramDefaults = defaults[this.filterDefaultParamKey] || [];

    params['tabId'] = defaultTo(event.currentTab, '');

    if (paramDefaults && paramDefaults.length > 0) {
      const results = defaultTo(statusesPerTable, []).filter(s => paramDefaults.includes(s));
      // 'none' because we don't want to add any statuses there but with empty list BE will return values for all selected
      params[this.filterDefaultParamKey] = results && results.length ? results : [NONE_PARAM];
    } else {
      params[this.filterDefaultParamKey] = defaultTo(statusesPerTable, []);
    }

    const submittedDates = dates[this.filterSubmittedDateParamKey];
    const expirationDates = dates[this.filterExpirationDateParamKey];
    if (submittedDates) {
      params[`${this.filterSubmittedDateParamKey}From`] = defaultTo(submittedDates.from, '');
      params[`${this.filterSubmittedDateParamKey}To`] = defaultTo(submittedDates.until, '');
    }
    if (expirationDates) {
      params[`${this.filterExpirationDateParamKey}From`] = defaultTo(expirationDates.from, '');
      params[`${this.filterExpirationDateParamKey}To`] = defaultTo(expirationDates.until, '');
    }
    const sortBy = get(event, 'dataTableParams.sortBy', '');
    params['sortMethod'] = sortBy ? get(event, 'dataTableParams.sortAsc', true) ? 'ASCENDING' : 'DESCENDING' : 'DESCENDING';
    params['sortField'] = sortBy || 'submittedDate';
    params['currentPage'] = (get(event, 'dataTableParams.offset', 0) / get(event, 'dataTableParams.limit', 0)) || 0;
    params['pageSize'] = get(event, 'dataTableParams.limit', '');

    return params;
  }
}
