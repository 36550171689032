import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PModuleNotificationWsDTO, PEmailFrequency} from '../../providers/types/planseeoccaddon';
import { LanguageSelectorType } from '../language-selector/language-selector.component';

@Component({
  selector: 'user-notifications',
  template: require('./user-notifications.component.html'),
  styles: [require('./user-notifications.component.scss')]
})
export class UserNotificationsComponent {
  notificationModules: string[] = ['rfq', 'order', 'shipment', 'forecasting', 'consignment', 'invoice', 'claim'];

  @Input() emailFrequency: PEmailFrequency = PEmailFrequency.DAILY;
  @Input() emailLanguageIso!: string;
  @Input() notifications: PModuleNotificationWsDTO[] = [];
  @Output() notificationsChanged: EventEmitter<any> = new EventEmitter();
  @Output() notificationsLangChanged: EventEmitter<string> = new EventEmitter();

  LanguageSelectorType = LanguageSelectorType;

  onRealTimeNotificationsChange(event, moduleNotification: PModuleNotificationWsDTO) {
    moduleNotification.realTimeNotifications = false;
    this.onNotificationsChanged();
  }

  onNotificationsChanged() {
    this.notificationsChanged.emit({notifications: this.notifications, emailFrequency: this.emailFrequency});
  }

  onNotificationsLangChange(isocode: string) {
    this.notificationsLangChanged.emit(isocode);
  }
}
