import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { Observable } from 'rxjs';
import { defaultTo } from 'lodash';
import { CustomNgxOauthService } from '../../providers/plansee/custom-ngx-oauth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authorizationService: AuthorizationService,
    private oauthService: CustomNgxOauthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authorizationService.isAuthorized()) {
      const profile = defaultTo(this.oauthService.profile, {termsAndConditionsAccepted: false});

      if (profile.termsAndConditionsAccepted) {
        return true;
      } else {
        this.authorizationService.logout();

        return false;
      }
    } else {
      this.router.navigate(['/login'], {queryParams: {
        ...route.queryParams,
        returnUrl: state.url.split('?')[0]
      }});

      return false;
    }
  }
}
