import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardShipment} from '../../../providers/types/dashboard/dashboard-shipment';

@Component({
  selector: 'dashboard-shipments',
  template: require('./dashboard-shipments.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardShipmentsComponent {

  @Input() shipments: DashboardShipment[];
}
