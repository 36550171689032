import {Component, DoCheck} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataTableCellEvent, DataTableParams} from 'ngx-datatable-bootstrap4';
import {AuthorizationService} from '../../providers/plansee/authorization-service';
import {POrdersService} from '../../providers/plansee/p-orders-service';
import { POrderEntryWsDTO } from '../../providers/types/planseeoccaddon';
import {PDataTable} from '../p-data-table';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../providers/plansee/loading-service';
import { take } from 'rxjs/operators';
import { defaultTo } from 'lodash';

@Component({
  selector: 'orders-list',
  template: require('./orders-list.component.html'),
  styles: [require('./orders-list.component.scss')]
})
export class OrdersListComponent extends PDataTable<POrderEntryWsDTO> implements DoCheck {

  static showDateTooltip(t: NgbTooltip, visible: boolean, quantityChanged: boolean) {
    if (visible) {
      if (quantityChanged) {
        t.ngbTooltip = 'There has been a change in your purchase order. This is the new confirmed date and quantity.';
      } else {
        t.ngbTooltip = 'There has been a change in your purchase order. This is the new confirmed date.';
      }
      t.open();
    }
  }

  static showQuantityTooltip(t: NgbTooltip, visible: boolean, dateChanged: boolean) {
    if (visible) {
      if (dateChanged) {
        t.ngbTooltip = 'There has been a change in your purchase order. This is the new confirmed date and quantity.';
      } else {
        t.ngbTooltip = 'There has been a change in your purchase order. This is the new confirmed quantity.';
      }
      t.open();
    }
  }

  static hideTooltip(t: NgbTooltip) {
    t.close();
  }

  items: POrderEntryWsDTO[] = [];
  defaultSort = 'requestedDeliveryDate-desc';

  constructor(private ordersService: POrdersService,
              authorizationService: AuthorizationService,
              i18nService: TranslateService,
              loadingService: LoadingService) {
    super(i18nService, authorizationService, loadingService);
  }

  //
  private cssCellWidthOrderNumber = 0;
  private cssCellWidthHistory = 0;
  private cssCellWidthTracking = 0;
  private cssCellWidthHistoryActualDate = 0;

  reloadItems(params: DataTableParams, strict = false) {
    this.updateQuery(params);
    if (JSON.stringify(this._query) === JSON.stringify(this.oldQuery) && !strict) {
      return;
    }
    this.areFacetsApplied = !!this._query.query;
    this.oldQuery = Object.assign({}, this._query);
    this.ordersService.search(this._query, this.onlyBookmarks).subscribe(searchResult => {
      this.items = searchResult.orderEntries;

      // Update pagination with the counts comming from BE
      this.refreshPagination(searchResult.pagination);

      // save current pagination information for using it in csv download
      this.ordersService.setPaginationInformations(searchResult.pagination);

      // Scheduled shipments functionality is not available yet for production
      // if (this.items) {
      //   this.getShipmentsForCurrentOrders();
      // }
      // send response to search components
      this.searchResultChange.emit(searchResult);
    });
  }

  /* tslint:disable:cognitive-complexity */
  cellClick(event: DataTableCellEvent<POrderEntryWsDTO>) {
    const item: {
      hasHistoryListing?: boolean,
      hasTrackingListing?: boolean
    } & POrderEntryWsDTO = event.row.item;

    if (item.shipmentInfoExpanded || item.consignmentEntries || item.consignmentSchedules) {
      delete item.consignmentEntries;
      delete item.consignmentSchedules;

      // set to false to hide information in row
      item.shipmentInfoExpanded = false;
    } else {
      if (item.order && item.order.orderCode && item.entryNumber >= 0) {
        this.ordersService.getConsignmentEntryDetails(
          item.order.orderCode,
          item.entryNumber,
          {}
        ).subscribe((result) => {
          // change history
          item.consignmentSchedules = (result && result.shipmentSchedule) ? result.shipmentSchedule.planseeScheduleEntries || [] : [];
          item.hasHistoryListing = item.consignmentSchedules.length > 0;
          // shipment tracking
          item.consignmentEntries = (result && result.shipmentTrackings) ? result.shipmentTrackings.consignments || [] : [];
          item.hasTrackingListing = item.consignmentEntries.length > 0;

          // set to true to show information in row
          item.shipmentInfoExpanded = true;
        });
      }
    }
    event.row.expanded = !event.row.expanded;
  }
  /* tslint:enable:cognitive-complexity */

  ngDoCheck(): void {
    this.checkForWidthChanges();
  }

  onBookmarkChange(selected: boolean, entry: POrderEntryWsDTO) {
    const orderCode = defaultTo(entry.order.orderCode, '');
    const entryNumber = defaultTo(entry.entryNumber, -1);
    if (orderCode && entryNumber !== -1) {
      this.ordersService.updateBookmark(orderCode, entryNumber, selected)
        .pipe(take(1))
        .subscribe(() => this.reloadItems(this._query, true));
    }
  }

  /**
   * Check if Col Widths have changed.
   */
  private checkForWidthChanges(): void {
    // get header cols
    let colHeaders: HTMLCollectionOf<HTMLElement> =
        document.getElementsByClassName('column-header') as HTMLCollectionOf<HTMLElement>;

    let ordernumberCellWidth = 0;
    let widthHistory = 0;
    let widthHistoryActualDate = 0;
    let widthTracking = 0;

    for (let i = 0; i < colHeaders.length; i++) {
      let colHeader = colHeaders[i];

      if (colHeader.classList.contains('column-purchaseOrderNr')) {
        // "Purchase Order Number" => Position for "Plansee Material Number" (PO Details)
        ordernumberCellWidth = colHeader.offsetWidth;
      } else if (colHeader.classList.contains('column-namedDeliveryDate') || colHeader.classList.contains('column-planseeQuantity')) {
        // "Purchase Order Number" => Position for "Change History" (PO Details)
        widthHistory += colHeader.offsetWidth;
        //
        if (colHeader.classList.contains('column-namedDeliveryDate')) {
          widthHistoryActualDate = colHeader.offsetWidth;
        }
      } else if (colHeader.classList.contains('column-deliveryDateConfirmed') || colHeader.classList.contains('column-quantityConfirmed')) {
        // "Purchase Order Number" => Position for "Shipment Tracking" (PO Details)
        widthTracking += colHeader.offsetWidth;
      }
    }

    // any col width changes?
    if (ordernumberCellWidth !== this.cssCellWidthOrderNumber
      || widthHistory !== this.cssCellWidthHistory
      || widthTracking !== this.cssCellWidthTracking
      || widthHistoryActualDate !== this.cssCellWidthHistoryActualDate) {
      // update private vars
      this.cssCellWidthOrderNumber = ordernumberCellWidth;
      this.cssCellWidthHistory = widthHistory;
      this.cssCellWidthTracking = widthTracking;
      this.cssCellWidthHistoryActualDate = widthHistoryActualDate;

      // update css via injection
      this.injectCSSForPODetails();
    }
  }

  /**
   * Use CSS injection for setting Order Number & Material Number col width for PO Details.
   */
  private injectCSSForPODetails(): void {
    // style id
    let cssId = 'order-details-css';

    // cleanup
    if (document.getElementById(cssId)) {
      // workaround for IE because document.getElementById(cssId).remove(); is not supported
      let _css = document.getElementById(cssId);
      _css.parentElement.removeChild(_css);
    }

    // inject
    let css = document.createElement('style');
    css.id = cssId;
    css.type = 'text/css';
    css.innerHTML = ``;

    if (this.cssCellWidthOrderNumber > 0) {
      css.innerHTML += `table.po-details td.order-number { width: ${this.cssCellWidthOrderNumber}px !important; }`;
    }
    if (this.cssCellWidthHistory > 0) {
      css.innerHTML += `table.po-details td.change-history { width: ${this.cssCellWidthHistory}px !important; }`;
    }
    if (this.cssCellWidthTracking > 0) {
      css.innerHTML += `table.po-details td.shipment-tracking { width: ${this.cssCellWidthTracking}px !important; }`;
    }
    // ?!
    if (this.cssCellWidthHistoryActualDate > 0) {
      css.innerHTML += `table.po-details td.change-history table th:first-child { width: `
        + `${this.cssCellWidthHistoryActualDate}px !important; color: red!important; }`;
    }

    document.getElementsByTagName('head')[0].appendChild(css);
  }
}
