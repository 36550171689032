import { Pipe, PipeTransform  } from '@angular/core';

@Pipe({
  name: 'floor'
})
export class FloorPipe implements PipeTransform {

  transform (value: number, precision = 0): any {

    let number = Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision);
    return number !== 0 ? number : '';
  }
}
