import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(injector: Injector) {
    setTimeout(() => {
      this.translateService = injector.get(TranslateService);
    });
  }

  private translateService: TranslateService;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.translateService && this.translateService.currentLang) {
      // workaround since setParams in clone duplicates the params.
      // https://github.com/angular/angular/issues/18812
      let params = new HttpParams({fromString: req.params.toString()});
      params = params.set('lang', this.translateService.currentLang);
      req = req.clone({
        params: params
      });
    }
    return next.handle(req);
  }
}
