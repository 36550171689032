import {EventEmitter, Output} from '@angular/core';

export abstract class PFacet<T = string> {
  @Output()
  submit: EventEmitter<string | T> = new EventEmitter();

  stripTimeFromDateString(dateString: string) {
    const regx = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/;
    const timeRegx = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}T[0-9]{2}(:|%3A)[0-9]{2}(:|%3A)[0-9]{2,3}Z?/;
    let match = dateString.match(timeRegx) ? dateString.match(timeRegx)[0] : null;
    while (match) {
      dateString = dateString.replace(match, match.match(regx)[0]);
      match = dateString.match(timeRegx) ? dateString.match(timeRegx)[0] : null;
    }
    return dateString;
  }
}
