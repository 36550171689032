import { Component, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PInvoicesService } from '../../providers/plansee/p-invoices-service';
import {
  FacetWidgetType,
  PFacetWsDTO,
  PInvoiceEntrySearchPageWsDTO,
} from '../../providers/types/planseeoccaddon';
import { Animations } from '../../animations/animations';
import { TranslateService } from '@ngx-translate/core';
import { PSearch } from '../p-search';
import { combineLatest } from 'rxjs';
import { startWith, take } from 'rxjs/operators';
import { DownloadEvent, DownloadType } from '../../providers/types/download';

const invoicesSearchCurrency = 'invoicesSearch.currency';

@Component({
  selector: 'invoices-search',
  template: require('./invoices-search.component.html'),
  animations: [Animations.expand()]
})
export class InvoicesSearchComponent extends PSearch implements OnInit {
  @Input() set downloadEvent(event: DownloadEvent) {
    if (event && event.downloadType === DownloadType.ENTIRE_AS_XLS) {
      this.downloadEntireAsXls();
    } else if (event && event.downloadType === DownloadType.SELECTED_AS_XLS) {
      this.downloadSelectedAsXls(event.selectedCodes || []);
    }
  }
  i18n: TranslateService;

  constructor(
    private invoicesService: PInvoicesService,
    authorizationService: AuthorizationService,
    i18nService: TranslateService
  ) {
    super(i18nService, authorizationService);
    i18nService.stream([
      invoicesSearchCurrency
    ]).subscribe(i18n => {
      this.i18n = i18n;
    });
  }

  private totalResults = 0;

  ngOnInit() {
    let searchQuery = '';
    let forceUpdate = false;

    // handle search query by url
    if ((window.location.pathname).indexOf('invoices') >= 0) {
      let urlsplit = (window.location.pathname).split('/');
      let lastparam = urlsplit[urlsplit.length - 1];
      searchQuery = lastparam !== 'invoices' ? lastparam : '';
      forceUpdate = true;
    }

    this.addSubscriptions(
      // handle customer/location switch
      combineLatest(
        this.authorizationService.changedCompany.pipe(startWith('')),
        this.authorizationService.changedLocation.pipe(startWith(''))
      ).subscribe(([company, location]) => {
        if (company || location) {
          // remove searchQuery from url
          if (searchQuery) {
            window.location.pathname = window.location.pathname.replace(`/${searchQuery}`, ``);
          }
          // empty oldQuery for customer switch
          searchQuery = '';
          this.search(searchQuery, true, true);
        }
      })
    );

    // initial search
    this.search(searchQuery, true, forceUpdate);
  }

  updateSearchResult(searchResult: PInvoiceEntrySearchPageWsDTO) {
    this.state = searchResult;
    // override query since it comes broken out of backend
    this.state.freeTextSearch = this._query && this._query.query ? this.decodeSearchQuery(this._query.query.split(':')[0]) : '';
    this.state.currentQuery.query.value = this._query && this._query.query ? this._query.query : '';
  }

  search(query: string, emit = false, forceUpdate = false) {
    // remove time from query cuz of :
    query = this.stripTimeFromDateString(query);
    let data = this.checkAndUpdateQuery(query, forceUpdate);

    if (data) {
      this._query = Object.assign({}, data);
      this.state.currentQuery.query.value = query;
      this._query.query = query;
      this.invoicesService.search(data).subscribe(val => {
        this.state = val;
        this.state.freeTextSearch = this.decodeSearchQuery(query.split(':')[0]);
        // override query since it comes broken out of backend
        this.state.currentQuery.query.value = query;
        this.totalResults = val.pagination.totalResults;

        if (emit) {
          this.submit();
        }
      });
    }
  }

  /**
   * Extendet version for super.checkAndUpdateQuery()
   * @param query
   * @param forceUpdate
   */
  checkAndUpdateQuery(query: string, forceUpdate = false) {
    let data: any = {
      query: query
    };
    if (!forceUpdate) {
      return super.checkAndUpdateQuery(query);
    }
    return data;
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };
    this.invoicesService.suggestions(data).subscribe(val => {
      if (val.suggestions) {
        this.suggestions = val.suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.collapse = true;
      } else {
        this.collapse = false;
        delete this.suggestions;
      }
    });
  }

  getSliderFacets(): PFacetWsDTO[] {
    return this.state.facets ? this.state.facets.filter(facet => facet.widgetType === FacetWidgetType.SLIDER).map(facet => {
      if (facet.code.toLowerCase().indexOf('amount') >= 0) {
        facet.unit = this.i18n[invoicesSearchCurrency];
      }
      return facet;
    }) : null;
  }

  private downloadEntireAsXls() {
    // set invoices count as page size
    let params = {
      pageSize: this.totalResults
    };


    this.invoicesService.getListCsvContent(this.createTranslations(this.translationKeys()), params).subscribe(value => {
      if (value.body) {
        this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
      }
    });
  }

  private downloadSelectedAsXls(selectedCodes: string[]) {
    this.invoicesService.getSelectedAsXls(this.createTranslations(this.translationKeys()), selectedCodes)
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  private translationKeys(): string[] {
    return [
      'invoice.paymentStatus',
      'invoice.invoiceNumber',
      'invoice.poNumber',
      'invoice.paymentDueDate',
      'invoice.invoiceDate',
      'invoice.amount',
      'invoice.dunningNote',
      'invoice.invoiceType',
      'invoice.currency',
    ];
  }
}
