import { InvoiceType } from './invoices/invoice-type.enum';

export enum DownloadDocumentType {
  INVOICE = 'INVOICE', // this is for v2
  INVOICE_CREDIT_DEBIT_NOTES = 'INVOICE_CREDIT_DEBIT_NOTE', // this is for v2
  INVOICE_ADVANCE_PAYMENT = 'INVOICE_ADVANCE_PAYMENT', // this is for v2
  QUOTATION_FIRST = 'QUOTATION_FIRST', // this is for v2
  QUOTATION = 'QUOTATION', // this is for v2
  DELIVERY = 'DELIVERY', // this is for v2
  DELIVERY_RETURN = 'DELIVERY_RETURN', // this is for v2
}

export enum DownloadType {
  ENTIRE_AS_XLS= 'ENTIRE_AS_XLS',
  SELECTED_AS_XLS= 'SELECTED_AS_XLS',
  SELECTED_AS_PDF= 'SELECTED_AS_PDF',
}

export interface DownloadEvent {
  downloadType: DownloadType;
  selectedCodes: string[];
}

export interface DownloadDocuments {
  documents?: DownloadDocument[];
}

export interface DownloadDocument {
  documentNumber: string;
  documentType: InvoiceType;
  attachmentId: string;
}
