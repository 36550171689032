import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardInvoice} from '../../../providers/types/dashboard/dashboard-invoice';

@Component({
  selector: 'dashboard-invoices',
  template: require('./dashboard-invoices.component.html'),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardInvoicesComponent {

  @Input() invoices: DashboardInvoice[];
}
