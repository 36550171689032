import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import {
  PB2bUnitWsDTO,
  PModuleNotificationWsDTO,
  PUserSettingsWsDTO,
  PUserWsDTO,
  PB2UnitContactInfo,
  PB2UnitContactAddress,
  PEmailFrequency
} from '../../providers/types/planseeoccaddon';
import { PSettingsService } from '../../providers/plansee/p-settings-service';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { take } from 'rxjs/operators';
import { scrollToNotificationsParam } from '../../../utils';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { combineLatest } from 'rxjs';
import { defaultTo } from 'lodash';

@Component({
  selector: 'settings-page',
  template: require('./settings.page.html'),
  styles: [require('./settings.page.scss')]
})
export class SettingsPageComponent extends SubscriptionManager implements OnInit, AfterViewInit {

  @ViewChild('notificationsSection') notificationsSection: ElementRef;

  user: PUserWsDTO;
  notifications: PModuleNotificationWsDTO[];
  emailFrequency: PEmailFrequency;
  emailLanguageIso: string;
  company: PB2bUnitWsDTO;
  contactInfo: PB2UnitContactInfo;
  contactAddress: PB2UnitContactAddress;
  rootCompany: PB2bUnitWsDTO;
  changedConfirmed = null;

  constructor(
    public authorizationService: AuthorizationService,
    private settingsService: PSettingsService,
    private route: ActivatedRoute,
    private planseeTranslateService: PlanseeTranslateService
  ) {
    super();
  }

  private query: QueryRequestWsDTO;

  ngOnInit() {
    this.addSubscriptions(
      combineLatest(
        this.authorizationService.permissionsChanged,
        this.planseeTranslateService.onLangChange()
      ).subscribe(() => {
        this.setQueryFromParam();
      })
    );
  }

  ngAfterViewInit() {
    const notificationsSection = this.notificationsSection;
    this.route.data
      .pipe(take(1))
      .subscribe(data => {
        const shouldScrollToNotification = data[scrollToNotificationsParam];
        if (shouldScrollToNotification && notificationsSection) {
          notificationsSection.nativeElement.scrollIntoView();
        }
      });
  }

  setQueryFromParam() {
    this.route.paramMap
      .pipe(take(1))
      .subscribe((params: ParamMap) => {
        const id = params.get('id');
        this.query = {
          query: id ? id : ''
        };
      });

    this.user = this.authorizationService.getUserProfile();

    this.takeCurrentSettings();
  }

  onChange(updatedUserNotifications: any) {
    this.notifications = updatedUserNotifications && updatedUserNotifications.notifications ?
      updatedUserNotifications.notifications : this.notifications;
    this.emailFrequency = updatedUserNotifications && updatedUserNotifications.emailFrequency ?
      updatedUserNotifications.emailFrequency : this.emailFrequency;

    this.updateSettings();
  }

  onNotificationsLangChanged(isocode: string) {
    this.emailLanguageIso = isocode;

    this.updateSettings();
  }

  setChangedConfirmed(value: string) {
    this.changedConfirmed = value;

    // ! value for timeout = $animation-duration + $animation-delay from settings.page.scss !
    setTimeout(() => {
      this.changedConfirmed = false;
    }, 1500);
  }

  private takeCurrentSettings() {
    this.settingsService.getCustomerProfile(this.query)
      .pipe(take(1))
      .subscribe(profile => {
        this.notifications = profile.notifications;
        this.emailFrequency = (profile.emailFrequency || PEmailFrequency.DAILY);
        this.emailLanguageIso = profile.emailLanguageIso;
        this.company = profile.company || null;
        this.contactInfo = profile.contactInfo;
        this.contactAddress = defaultTo(profile.company, {contactAddress: null}).contactAddress;
        this.rootCompany = profile.rootCompany || null;
      });
  }

  private updateSettings() {
    const pUserSettingsWsDTO: PUserSettingsWsDTO = {
      user: this.user,
      notifications: this.notifications,
      emailFrequency: this.emailFrequency,
      emailLanguageIso: this.emailLanguageIso
    };

    this.settingsService.updateSettings(pUserSettingsWsDTO)
      .pipe(take(1))
      .subscribe(response => {
        this.setChangedConfirmed('success');
      }, err => {
        this.setChangedConfirmed('error');
        this.takeCurrentSettings();
      });
  }
}
