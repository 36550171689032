import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PQuotationAditionalServices } from '../../providers/types/planseeoccaddon';
import { PUnitsService } from '../../providers/plansee/p-units-service';
import { isNumber } from '../../../utils';
import { PSelectValue } from '../../form-components/p-select/p-select-value';
import { defaultTo } from 'lodash';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'quotation-entry-form',
  template: require('./quotation-entry-form.component.html'),
  styles: [require('./quotation-entry-form.component.scss')]
})
export class QuotationEntryFormComponent extends SubscriptionManager implements OnInit {

  @Input()
  set errorMessagesTriggered(errorMessagesTriggered: boolean) {
    if (errorMessagesTriggered && this.quotationEntryFormGroup) {
      if (this.quotationEntryFormGroup.get('product').invalid) {
        this.quotationEntryFormGroup.get('product').markAsTouched();
        this.quotationEntryFormGroup.get('product').markAsDirty();
      }
      if (this.quotationEntryFormGroup.get('technical').invalid) {
        this.quotationEntryFormGroup.get('technical').markAsTouched();
        this.quotationEntryFormGroup.get('technical').markAsDirty();
      }
      if ((this.quotationEntryFormGroup.get('quantity') as any).controls.quantity.invalid) {
        (this.quotationEntryFormGroup.get('quantity') as any).controls.quantity.markAsTouched();
        (this.quotationEntryFormGroup.get('quantity') as any).controls.quantity.markAsDirty();
      }
      if ((this.quotationEntryFormGroup.get('quantity') as any).controls.unitOfMeasure.invalid) {
        (this.quotationEntryFormGroup.get('quantity') as any).controls.unitOfMeasure.markAsTouched();
        (this.quotationEntryFormGroup.get('quantity') as any).controls.unitOfMeasure.markAsDirty();
      }
      if (((this.quotationEntryFormGroup.get('additionalQuantities') as FormArray).controls.length > 0)
        && (this.quotationEntryFormGroup.get('additionalQuantities') as FormArray).controls[0].invalid) {
        (this.quotationEntryFormGroup.get('additionalQuantities') as FormArray).controls[0].markAsTouched();
        (this.quotationEntryFormGroup.get('additionalQuantities') as FormArray).controls[0].markAsDirty();
      }
    }
  }

  @Input() quotationEntryFormGroup: FormGroup;

  query: QueryRequestWsDTO;
  additionalServices: string[];
  errorMessageOpen = false;
  units: PSelectValue[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private unitsService: PUnitsService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private planseeTranslateService: PlanseeTranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.route.paramMap.subscribe((params: ParamMap) => {
        const id = params.get('id');
        this.query = {
          query: id ? id : ''
        };
      })
    );

    this.additionalServices = Object.keys(PQuotationAditionalServices).map(key => PQuotationAditionalServices[key]);

    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .pipe(
          switchMap(() => this.unitsService.search(this.query))
        ).subscribe(unitList => {
          if (unitList.unitsOfMeasure) {
            this.units = unitList.unitsOfMeasure.map(val => ({value: val.code, label: val.name}));
            if (this.quotationEntryFormGroup && this.quotationEntryFormGroup.get('quantity')) {
              (this.quotationEntryFormGroup.get('quantity') as any).controls.unitOfMeasure.setValue(this.takeFirstUnit());
            }
          }
        })
    );
    this.addControl();
  }

  addControl() {
    this.quotationEntryFormGroup.addControl('product', new FormGroup({
      customerMaterialNumber: new FormControl(null),
      planseeMaterialNumber: new FormControl(null),
      materialType: new FormControl(null)
    }, (formGroup: FormGroup) => {
      return formGroup.controls['customerMaterialNumber'].value
      || formGroup.controls['planseeMaterialNumber'].value
      || formGroup.controls['materialType'].value ? null : {validateProduct: {valid: false}};
    }));
    this.quotationEntryFormGroup.addControl('technical', new FormGroup({
      tolerances: new FormControl(null),
      medias: new FormControl(null)
    }, (formGroup: FormGroup) => {
      return formGroup.controls['tolerances'].value ||
      (formGroup.controls['medias'].value && formGroup.controls['medias'].value.length > 0) ? null : {validateTechnical: {valid: false}};
    }));
    this.quotationEntryFormGroup.addControl('additionalInformation', new FormControl(null));
    this.quotationEntryFormGroup.addControl('additionalServices', this.createAdditionalServiceFormGroup());
    this.quotationEntryFormGroup.addControl('quantity', new FormGroup({
      quantity: this.createQuantityFormControl(),
      unitOfMeasure: new FormControl(this.takeFirstUnit(), Validators.required)
    }));
    this.quotationEntryFormGroup.addControl('additionalQuantities', this.formBuilder.array([]));
  }

  addQuantity() {
    const formArray = this.quotationEntryFormGroup.get('additionalQuantities') as FormArray;
    formArray.push(this.createQuantity());
  }

  removeQuantity(quantityControl: FormControl) {
    const formArray = this.quotationEntryFormGroup.get('additionalQuantities') as FormArray;
    if (formArray.controls.indexOf(quantityControl) >= 0) {
      this.removeQuantityValidationAlerts(quantityControl);

      if (formArray.controls.splice(formArray.controls.indexOf(quantityControl), 1)) {
        formArray.updateValueAndValidity();
      }
    }
  }

  removeQuantityValidationAlerts(quantityControl: FormControl) {
    quantityControl.clearValidators();
    // 'reset' control to remove any error messages
    quantityControl.markAsUntouched();
    quantityControl.markAsPristine();
  }

  hasCustomUnit() {
    const formGroup = this.quotationEntryFormGroup.get('quantity') as FormGroup;
    const unitOfMeasure = formGroup.controls['unitOfMeasure'];
    if (unitOfMeasure && unitOfMeasure.value === 'other') {
      formGroup.addControl('customUnitOfMeasure', new FormControl(null, Validators.required));
      return true;
    } else {
      formGroup.removeControl('customUnitOfMeasure');
      return false;
    }
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  private createAdditionalServiceFormGroup(): FormGroup {
    const formGroup = new FormGroup({});
    for (let additionalService of this.additionalServices) {
      formGroup.addControl(additionalService, new FormControl(false));
    }
    return formGroup;
  }

  private takeFirstUnit() {
    return defaultTo(this.units[0], {value: null}).value;
  }

  private createQuantity(): FormGroup {
    return new FormGroup({quantity: this.createQuantityFormControl()});
  }

  private createQuantityFormControl(): FormControl {
    return new FormControl(null, [Validators.required, Validators.min(0), (formControl: FormControl) => {
      return isNumber(formControl.value) && formControl.value > 0 ? null : {valid: false};
    }]);
  }
}
