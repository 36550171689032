import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type InputType = 'password' | 'text';

@Component({
  selector: 'password-input',
  template: require('./password-input.component.html'),
  styles: [require('./password-input.component.scss')],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PasswordInputComponent), multi: true}
  ]
})
export class PasswordInputComponent implements ControlValueAccessor {

  @Input() error = false;
  @Input() placeholder = '';
  @Input() required = true;

  type: InputType = 'password';
  value = '';
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
  }

  writeValue(value: string) {
    if (value) {
      this.value = value;
    }
  }

  onValueChange(value: string) {
    this.value = value;
    this.onChange(this.value);
  }

  changeType() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
