import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {formatNumber} from '@angular/common';
import {ChartViewType, PStockLevelHistoryEntryWsDTO, PStockLevelWsDTO} from '../../providers/types/planseeoccaddon';
import {ChartData, ChartTooltipItem, LinearTickOptions} from 'chart.js';
import {ChartAnnotatedOptions} from '../../providers/types/chartjs-annotations';
import {PConsignmentsGraphService} from '../../providers/plansee/p-consignments-graph-service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import Chart = require('chart.js');

interface ChartExtended extends Chart {
  annotation: any;
}

const WEEK_POINTS = 9;
const MONTH_POINTS = 4;
let TODAY_LABEL = '';
const X_ID = 'x-axis-0';

const drawTodayLine = {
  id: 'drawTodayLine',
  beforeDraw(chartInstance: Chart, easing: string, options?: any) {
    // @ts-ignore
    const { ctx, chartArea: { top, bottom }, scales } = chartInstance;
    ctx.save();

    if (scales && scales[X_ID] && scales[X_ID].getPixelForValue) {
      const xValueWidth = scales[X_ID].width / chartInstance.data.labels.length;
      ctx.strokeStyle = '#22A2EC';
      ctx.strokeRect(scales[X_ID].getPixelForValue(TODAY_LABEL) - (xValueWidth / 2), top, 0, bottom);

      ctx.restore();
    }
  }
};

@Component({
  selector: 'stock-volume-chart',
  template: require('./stock-volume-chart.component.html'),
  styles: [require('./stock-volume-chart.component.scss')]
})
export class StockVolumeChartComponent implements OnInit, OnDestroy {
  @Input()
  stockLevel: PStockLevelWsDTO;
  @Input()
  additionalTopPadding = '';

  topBorderLines = [];
  readonly TOP_LINES_MARGIN_BOTTOM = 5;
  lineData: ChartData = {
    labels: [],
    datasets: [
      {
        hideInLegendAndTooltip: true,
        label: this.translateService.instant('consignmentsList.consignmentChart.forecasted'),
        type: 'line',
        lineTension: 0.1,
        backgroundColor: 'transparent',
        borderColor: '#3bdbfb',
        borderWidth: 2,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        pointStyle: 'line',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 8,
        data: [],
        spanGaps: false
      },
      {
        label: this.translateService.instant('consignmentsList.consignmentChart.shipments'),
        fill: true,
        backgroundColor: '#0973bb',
        borderColor: '#0973bb',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        pointStyle: 'line',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 8,
        data: [],
        spanGaps: false,
      },
      {
        hideInLegendAndTooltip: true,
        label: this.translateService.instant('consignmentsList.consignmentChart.stockLevel'),
        fill: true,
        type: 'line',
        lineTension: 0.1,
        backgroundColor: '#3bdbfb',
        borderColor: '#357DC9',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 1.5,
        borderJoinStyle: 'miter',
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        pointHoverRadius: 3,
        pointStyle: 'line',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 8,
        data: [],
        spanGaps: false
      }
    ]
  };
  lineOptions: ChartAnnotatedOptions = {
    animation: {
      duration: 0
    },
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    maintainAspectRatio: false,
    tooltips: {
      mode: 'single',
      backgroundColor: '#fff',
      bodyFontColor: '#000',
      borderColor: '#97b3ce',
      borderWidth: 1,
      displayColors: false,
      callbacks: {
        title: () => {
          return '';
        },
        label: (tooltipItem: ChartTooltipItem, data: ChartData) => {
          return `${formatNumber(+tooltipItem.yLabel, 'de', '1.0-3')} ${this.stockLevel.unitOfMeasureLocalized}`;
        }
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        generateLabels: function (chart) {
          let labels = Chart.defaults.global.legend.labels.generateLabels(chart);
          const position3 = labels[1];
          const position2 = labels[0];
          labels[0] = labels[2];
          labels[1] = position2;
          labels[2] = position3;
          labels[0].pointStyle = 'rect';
          labels[0].fillStyle = '#1ec3fa';
          labels[0].strokeStyle = 'transparent';
          labels[0].lineWidth = 0;
          labels[2].pointStyle = 'rect';
          return labels;
        }
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            offsetGridLines: true,
            color: 'rgba(0, 0, 0, 0.1)'
          },
          offset: true,
          barPercentage: 0.7,
          categoryPercentage: 0.9,
          id: X_ID,
        }
      ],
      yAxes: [{
        gridLines: {
          color: 'rgba(0, 0, 0, 0)'
        },
        afterFit: (scaleInstance) => {
          scaleInstance.width = this.STOCK_Y_AXES_WIDTH; // sets the width of the Ylabels to 70px in order to align graph with table above
        },
        ticks: <LinearTickOptions>{
          suggestedMin: 0,
          // this value will be overridden if the scale is greater than this value
          suggestedMax: 10,
          beginAtZero: true,
          labelOffset: 0,
          fontSize: 9
        }
      }]
    },
    plugins: ['drawTodayLine']
  };

  constructor(
    private elementRef: ElementRef,
    private consignmentChartService: PConsignmentsGraphService,
    private translateService: TranslateService
  ) {
  }

  private chart: ChartExtended;
  private readonly STOCK_Y_AXES_WIDTH = 70;
  private forecastedValues: number[] = [];
  private confirmedQuantity: number[] = [];
  private plannedShipmentsValues: number[] = [];
  private subscription: Subscription;

  init() {
    this.lineData.datasets[2].backgroundColor = this.buildFillGradient();
    this.consignmentChartService.labels.forEach(label => {
      this.forecastedValues.push(0);
      this.confirmedQuantity.push(0);
      this.plannedShipmentsValues.push(0);
    });
    for (let i = 0; i < this.stockLevel.segments.length; i++) {
      let entry = this.stockLevel.segments[i];
      const dateObject = this.consignmentChartService.datePipe.transform(entry.entryUpdateDate, 'full');
      const newLabel = this.consignmentChartService.viewType === ChartViewType.WEEKLY ?
        `${dateObject.week} ${dateObject.year}` :
        `${dateObject.month} ${dateObject.year}`;
      this.createChartData(entry, newLabel);
    }
    TODAY_LABEL = this.consignmentChartService.todayLabel;
    this.subscription = this.consignmentChartService.positionSubject.subscribe(position => {
      this.setChartData();
    });
  }

  buildFillGradient() {
    const ctx = this.elementRef.nativeElement.querySelector('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 300, 0);
    gradient.addColorStop(0, '#418fde');
    gradient.addColorStop(1, '#1ec3fa');
    return gradient;
  }

  setChartData() {
    if (this.consignmentChartService.pos >= 0 && this.consignmentChartService.pos <= this.consignmentChartService.labels.length) {
      const POINTS = this.consignmentChartService.viewType === ChartViewType.WEEKLY ? WEEK_POINTS - 1 : MONTH_POINTS;
      this.lineData.labels = this.consignmentChartService.labels.slice(this.consignmentChartService.pos,
        this.consignmentChartService.pos + POINTS);
      this.lineData.datasets[0].data = this.forecastedValues.slice(this.consignmentChartService.pos,
        this.consignmentChartService.pos + POINTS);
      this.lineData.datasets[2].data = this.confirmedQuantity.slice(this.consignmentChartService.pos,
        this.consignmentChartService.pos + POINTS);
      this.lineData.datasets[1].data = this.plannedShipmentsValues.slice(this.consignmentChartService.pos,
        this.consignmentChartService.pos + POINTS);
      this.topBorderLines = this.lineData.labels.map(l => l === this.consignmentChartService.todayLabel ? 'today' : '');

      if (this.chart) {
        this.chart.update();
      }
    }
  }

  onChartCreated($event) {
    this.chart = $event.chart;
  }

  ngOnInit() {
    if (this.stockLevel && this.stockLevel.segments) {
      Chart.plugins.register(drawTodayLine);
      this.init();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    Chart.plugins.unregister(drawTodayLine);
  }

  private createChartData(entry: PStockLevelHistoryEntryWsDTO, newLabel: string) {
    const existingIndex = this.consignmentChartService.labels.indexOf(newLabel);
    this.forecastedValues[existingIndex] = entry.entryForecasted > 0 ?
      Math.round((this.forecastedValues[existingIndex] + Number(entry.entryForecasted)) * 1000) / 1000 : 0;
    this.confirmedQuantity[existingIndex] = entry.entryStockLevel ?
      Math.round((this.confirmedQuantity[existingIndex] + Number(entry.entryStockLevel)) * 1000) / 1000 : 0;
    this.plannedShipmentsValues[existingIndex] = entry.entryShipments > 0 ?
      Math.round((this.plannedShipmentsValues[existingIndex] + Number(entry.entryShipments)) * 1000) / 1000 : 0;
  }
}
