import { Pipe, PipeTransform } from '@angular/core';
import { FacetWsDTO } from '../../../providers/types/ycommercewebservices';
import { PlanseeTableDefaultFilter } from '../plansee-table-model';

@Pipe({
  name: 'makeDefaultFacet'
})
export class MakeDefaultFacetPipe implements PipeTransform {
  transform(event: PlanseeTableDefaultFilter): FacetWsDTO {
    return {
      category: false,
      code: event.key || '',
      multiSelect: true,
      name: event.label || '',
      priority: 100,
      values: event.options.map(option => ({
        code: option.key,
        name: option.label,
        selected: false,
        query: {
          query: {
            value: option.key
          }
        }
      })),
      visible: true,
    };
  }
}
