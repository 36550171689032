import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[tooltipIfEllipsis]'
})
export class TooltipIfEllipsisDirective {

  constructor(
    private elementRef: ElementRef,
    private ngbTooltip: NgbTooltip
  ) {
  }

  @HostListener('mouseover', ['$event.target'])
  checkIfTooltipIsNeeded() {
    const domElement = this.elementRef.nativeElement;

    const isTextEllipsis = domElement.offsetWidth < domElement.scrollWidth;
    if (isTextEllipsis) {
      this.ngbTooltip.container = 'body';
      this.ngbTooltip.placement = 'bottom';
      this.ngbTooltip.disableTooltip = false;
    } else {
      this.ngbTooltip.disableTooltip = true;
    }
  }
}
