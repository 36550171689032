import {Directive, ElementRef, Input} from '@angular/core';
import {AuthorizationService} from '../../providers/plansee/authorization-service';

@Directive({
  selector: '[pA]'
})
export class PADirective {

  module: string;

  @Input('pA')
  set pA(module: string) {
    this.module = module;
    this.authorize(this.authorizationService.permissions.blacklist);
  }

  constructor(private el: ElementRef,
              private authorizationService: AuthorizationService) {
    this.el.nativeElement.classList.add('p-a');
    this.authorizationService.permissionsChanged.subscribe(permissions => this.authorize(permissions.blacklist));
  }

  private authorize(blacklist) {
    if (this.module && blacklist) {
      for (let path of blacklist) {
        if (path.includes(this.module)) {
          this.el.nativeElement.classList.add('disabled');
          this.el.nativeElement.onclick = (e) => {
            e.preventDefault();
          };
          return;
        }
      }
    }
    this.el.nativeElement.classList.remove('disabled');
  }
}
