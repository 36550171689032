import { Pipe, PipeTransform } from '@angular/core';
import { BreadcrumbWsDTO } from '../providers/types/ycommercewebservices';

@Pipe({
  name: 'filterBreadcrumbsBySelectedTabKey'
})
export class FilterBreadcrumbsBySelectedTabKeyPipe implements PipeTransform {
  transform(value: BreadcrumbWsDTO[], selectedTabKey: string): BreadcrumbWsDTO[] {
    if (selectedTabKey) {
      return (value || []).filter(f => f.facetCode && f.facetCode !== selectedTabKey);
    }

    return value || [];
  }
}
