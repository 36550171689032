import {Pipe, PipeTransform} from '@angular/core';
import { PInvoiceWsDTO, PPaymentStatus } from '../../providers/types/planseeoccaddon';

@Pipe({
  name: 'isCancelled'
})
export class IsCancelledPipe implements PipeTransform {
  transform(invoice: PInvoiceWsDTO): boolean {
    return invoice.paymentStatus === PPaymentStatus.STORNO || false;
  }
}
